import createSvgIcon from "./utils";

const IconCalendar28x32 = createSvgIcon((
    <>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4 5.51666C2.63818 6.42657 1.54273 7.69635 0.84323 9.19644C0.47667 9.98253 0.269457 10.8675 0.152322 12H27.8477C27.7305 10.8675 27.5233 9.98253 27.1568 9.19644C26.2933 7.34468 24.8265 5.84388 23 4.93774V6C23 7.65685 21.6569 9 20 9C18.3431 9 17 7.65685 17 6V4.01193C16.1193 4 15.1279 4 14 4C12.4235 4 11.1134 4 10 4.03258V6C10 7.65685 8.65685 9 7 9C5.34315 9 4 7.65685 4 6V5.51666ZM0 18C0 15.9247 0 14.3111 0.0743126 13H27.9257C28 14.3111 28 15.9247 28 18C28 22.6635 28 24.9953 27.1568 26.8036C26.2625 28.7212 24.7212 30.2625 22.8036 31.1568C20.9953 32 18.6635 32 14 32C9.3365 32 7.00475 32 5.19644 31.1568C3.27876 30.2625 1.73746 28.7212 0.84323 26.8036C0 24.9953 0 22.6635 0 18ZM10.5 19C11.3284 19 12 18.3284 12 17.5C12 16.6716 11.3284 16 10.5 16C9.67157 16 9 16.6716 9 17.5C9 18.3284 9.67157 19 10.5 19ZM6 23.5C6 24.3284 5.32843 25 4.5 25C3.67157 25 3 24.3284 3 23.5C3 22.6716 3.67157 22 4.5 22C5.32843 22 6 22.6716 6 23.5ZM4.5 19C5.32843 19 6 18.3284 6 17.5C6 16.6716 5.32843 16 4.5 16C3.67157 16 3 16.6716 3 17.5C3 18.3284 3.67157 19 4.5 19Z"
              stroke="transparent"/>
        <path
            d="M6 1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6V1Z"
            stroke="transparent"/>
        <path
            d="M19 1C19 0.447715 19.4477 0 20 0C20.5523 0 21 0.447715 21 1V6C21 6.55228 20.5523 7 20 7C19.4477 7 19 6.55228 19 6V1Z"
            stroke="transparent"/>
    </>
), {width: 28, height: 32});

export default IconCalendar28x32;