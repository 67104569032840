import {findLastIndex} from "common/utils";
import Cookies from "js-cookie";
import {makeAutoObservable} from "mobx";
import {AuthRepository, DeviceIdRepository} from "../repositories";
import {AuthToken, mapToAuthToken} from "../types";

export function getCanonicalHost(hostname: string) {
    const MAX_TLD_LENGTH = 3;

    const isNotTLD = (s: string) => s.length > MAX_TLD_LENGTH;

    let host = hostname.split('.');
    host = host.slice(Math.max(0, findLastIndex(host, isNotTLD)));

    return host.join('.');
}

export class SessionStore {
    private static readonly ACCESS_TOKEN_KEY = "token";
    private static readonly REFRESH_TOKEN_KEY = "refresh_token";
    private readonly DOMAIN = getCanonicalHost(window.location.hostname);

    constructor(
        private readonly authRepo: AuthRepository,
        private readonly deviceRepo: DeviceIdRepository,
    ) {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    async getToken() {
        const accessToken = Cookies.get(SessionStore.ACCESS_TOKEN_KEY);
        const refreshToken = Cookies.get(SessionStore.REFRESH_TOKEN_KEY);
        if (!accessToken || !refreshToken) {
            if (process.env.NODE_ENV === "production") {
                this.clear();
                return null;
            } else {
                const login = process.env.REACT_APP_LOGIN;
                const password = process.env.REACT_APP_PASSWORD;
                if (!login || !password) {
                    throw new Error("Dev environment properties is required");
                }

                const token = await this.authRepo.login(login, password, this.deviceRepo.find());
                this.setToken(token);
                return token;
            }
        }

        const token = mapToAuthToken({
            access_token: {
                access_token: accessToken,
            },
            refresh_token: {
                refresh_token: refreshToken,
            }
        });

        if (token.refreshExpirationDate.getTime() < Date.now()) {
            this.clear();
            return null;
        }

        return token || null;
    }

    clear() {
        Cookies.remove(SessionStore.ACCESS_TOKEN_KEY, {domain: this.DOMAIN});
        Cookies.remove(SessionStore.REFRESH_TOKEN_KEY, {domain: this.DOMAIN});
    }

    setToken(token: AuthToken) {
        Cookies.set(SessionStore.ACCESS_TOKEN_KEY, token.accessToken, {domain: this.DOMAIN, expires: 999});
        Cookies.set(SessionStore.REFRESH_TOKEN_KEY, token.refreshToken, {domain: this.DOMAIN, expires: 999});
    }
}
