import createSvgIcon from "./utils";

const IconReceipt16x16 = createSvgIcon((
    <>
        <path
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M14.667 4v1.613c0 1.054-.667 1.72-1.72 1.72h-2.28v-4.66c0-.74.606-1.34 1.346-1.34a2.68 2.68 0 0 1 1.874.78c.48.487.78 1.154.78 1.887Z"/>
        <path
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M1.333 4.667V14c0 .553.627.867 1.067.533l1.14-.853c.267-.2.64-.173.88.067l1.107 1.113c.26.26.686.26.946 0l1.12-1.12a.66.66 0 0 1 .867-.06l1.14.853A.668.668 0 0 0 10.667 14V2.667c0-.734.6-1.334 1.333-1.334H4C2 1.333 1.333 2.527 1.333 4v.667Z"/>
        <path
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M4 6h4M4.5 8.667h3"/>

    </>
), {width: 16, height: 16});

export default IconReceipt16x16;
