import FuzzySort from "fuzzysort";
import {action, computed, flow, IReactionDisposer, makeObservable, reaction} from "mobx";
import {IMenuItemUiState} from "../../components/suggestions_popup";
import {debounce} from "../../utils";
import {AsyncJob, CachePolicy} from "../job";
import {IEntryFilter} from "./IEntryFilter";
import {FetchCursorResult, TAsyncFilterOptions, TFetchValuesFunc} from "./TFilterOptions";

export abstract class ValuesEntryFilter<T, R, Q, S> implements IEntryFilter<Q, R> {
    items: IMenuItemUiState<T>[];
    page: number;
    nextCursor: number | null;
    search: string;
    protected isAllPagesLoaded: boolean;
    protected readonly options: TAsyncFilterOptions<T, Q, any>;
    private searchDisposer: IReactionDisposer | null;
    private readonly fetchJob: AsyncJob<typeof ValuesEntryFilter.prototype.fetchItems>
    private readonly fetchMoreJob: AsyncJob<typeof ValuesEntryFilter.prototype._fetchMore>

    protected constructor(options: TAsyncFilterOptions<T, Q, any>) {
        this.page = 0;
        this.nextCursor = null;
        this.search = "";
        this.items = [];
        this.isAllPagesLoaded = false;
        this.options = options;
        this.searchDisposer = null

        makeObservable<this, "fetchItems" | "_fetchMore" | "isAllPagesLoaded">(this, {
            items: true,
            isLoading: computed,
            filteredItems: true,
            page: true,
            nextCursor: true,
            search: true,
            isAllPagesLoaded: true,
            fetch: action.bound,
            retryFetch: action.bound,
            fetchMore: action.bound,
            fetchItems: flow.bound,
            _fetchMore: flow.bound,
            setSearch: action.bound,
            isDisabled: computed,
        });

        makeObservable(this.options, {
            apply: action.bound,
        });

        this.fetchJob = new AsyncJob({
            job: this.fetchItems,
            policy: options.cachePolicy ?? CachePolicy.MINUTES_5,
        });

        this.fetchMoreJob = new AsyncJob({
            job: this._fetchMore,
        });
    }

    get isDisabled() {
        return this.options.isDisabled || false;
    }

    abstract get selected(): S;

    abstract get isUsing(): boolean;

    abstract get query(): Q | null;

    get icon() {
        return this.options.icon;
    }

    get isDisplayed() {
        return this.options.isDisplayed?.() ?? true;
    }

    get title() {
        return this.options.title;
    }

    get type() {
        return this.options.type;
    }

    get isFilterable() {
        return this.options.isFilterable || false;
    }

    get isLoading() {
        return this.fetchJob.isCreated || this.fetchJob.isPending;
    }

    get failMessage() {
        return this.fetchJob.error?.message;
    }

    get isMoreLoading() {
        return this.fetchMoreJob.isPending;
    }

    get filteredItems() {
        if (!this.search || this.options.isPagination || this.options.isCursorPagination) {
            return this.items;
        }

        return FuzzySort.go(this.search, this.items, {key: "label"}).map(item => item.obj)
    }

    abstract serialize(): R | null;

    abstract deserialize(value: R): void;

    abstract reset(): void;

    init() {
        if (this.options.isPagination || this.options.isCursorPagination) {
            this.searchDisposer = reaction(
                () => this.search,
                debounce(action(() => {
                    this.fetchJob.resetTimeout();
                    this.fetchJob.start();
                }), 250),
            );
        }
    }

    free() {
        this.searchDisposer?.();
    }

    setSearch(value: string) {
        this.search = value;
    }

    fetch() {
        this.fetchJob.start();
    }

    retryFetch() {
        this.fetchJob.startForce();
    }

    fetchMore() {
        if ((!this.options.isPagination && !this.options.isCursorPagination) || this.isAllPagesLoaded) {
            return;
        }

        if (this.fetchJob.isPending || this.fetchMoreJob.isPending) {
            return;
        }

        this.fetchMoreJob.start();
    }

    private* fetchItems() {
        const result: ReturnType<TFetchValuesFunc<T>> = yield this.options.fetchValues(0, this.search, null);
        if (Array.isArray(result)) {
            this.items = result;
            this.page = 0;
            this.nextCursor = null;
        } else {
            this.items = (result as any).items;
            this.page = 0;
            this.nextCursor = (result as any).nextCursor;
        }
    }

    private* _fetchMore(_: AbortSignal) {
        if (this.options.isCursorPagination) {
            if (!this.nextCursor) {
                this.isAllPagesLoaded = true;
                return;
            }

            const result: FetchCursorResult<T> = yield this.options.fetchValues(this.page, this.search, this.nextCursor);
            this.items = [...this.items, ...result.items];
            this.nextCursor = result.nextCursor;
            return;
        }

        const page = this.page + 1;
        const items: IMenuItemUiState<T>[] = yield this.options.fetchValues(page, this.search, null);
        if (!items.length) {
            this.isAllPagesLoaded = true;
            this.page = page;
            return;
        }
        this.items = [...this.items, ...items];
        this.page = page;
    }
}
