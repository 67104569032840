import {MutableRefObject, useEffect, useRef} from "react";

function useRemembered<T>(value: T): MutableRefObject<T> {
    const ref = useRef(value)
    useEffect(() => {
        ref.current = value;
    }, [ref, value]);

    return ref;
}

export default useRemembered;