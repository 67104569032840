import {HouseSystem, HouseSystemResponse, mapToHouseSystem} from "./HouseSystem";

export interface HouseAccess {
    id: number;
    fullName: string;
    avatar: string;
    position: string;
    companyName: string;
    systems: number[];
    companies: { id: number, name: string }[];
    availableSystems: HouseSystem[];
}

export interface HouseAccessResponse {
    id: number;
    full_name: string;
    avatar: string;
    position: string;
    company_name: string;
    companies: { id: number, name: string }[];
    available_systems: HouseSystemResponse[];
    systems: number[];
}

export interface HouseAccessCreationParams {
    userId: number;
    systems: number[];
}

export interface FindAllAccessParams {
    search?: string;
    limit: number;
    page: number;
    houseId: number;
    companyId?: number;
}

export function mapToHouseAccess(entity: HouseAccessResponse): HouseAccess {
    return {
        id: entity.id,
        fullName: entity.full_name,
        avatar: entity.avatar,
        position: entity.position,
        companyName: entity.company_name,
        availableSystems: entity.available_systems.map(mapToHouseSystem),
        systems: entity.systems,
        companies: entity.companies,
    };
}
