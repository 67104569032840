import {
    CitizenBalance,
    CitizenBalanceResponse,
    FindAllBalanceHistoryParams,
    mapToCitizenBalance
} from "citizens/types/CitizenBalance";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";

export class CitizenBalanceRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllBalanceHistoryParams): Promise<Pageable<CitizenBalance>> {
        const response = await this.client.get<PageableResponse<CitizenBalanceResponse>>(
            `/citizens/${params.citizenId}/balance-history`, {
                params: {
                    page: params.page + 1,
                    limit: params.limit,
                },
            });
        return mapPageable(response, mapToCitizenBalance);
    }
}
