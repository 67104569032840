import classNames from "classnames";
import {ReactNode, SVGProps} from "react";
import s from "./index.module.css";

interface IProps extends SVGProps<SVGSVGElement> {
}

interface IIconProps {
    width: number;
    height: number;
    viewBox?: string;
}

function createSvgIcon(paths: ReactNode, {width, height, viewBox}: IIconProps) {
    return (props: IProps) => {
        const {className, fill = "#717688"} = props;

        return (
            <svg
                {...props}
                className={classNames(s.icon, className)}
                width={width}
                height={height}
                fill={fill}
                stroke={fill}
                viewBox={viewBox}>
                {paths}
            </svg>
        );
    };
}

export default createSvgIcon;