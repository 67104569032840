import {AuthorizedHttpClient} from "common/net";
import {mapPageable, PageableResponse} from "common/types";

import {toLocalISODate} from "common/utils";
import {
    CreateScheduledTaskParam,
    FindAllScheduledTasksParam,
    mapToScheduledTask,
    ScheduledTask,
    ScheduledTaskResponse,
    UpdateScheduledTaskParam
} from "../types";

export class ScheduledTaskRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllScheduledTasksParam) {
        const response = await this.client.get<PageableResponse<ScheduledTaskResponse>>(
            `web/v1/scheduled-tasks`,
            {
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search,
                    company_ids: params.companyIds,
                    exclude_company_ids: params.excludeCompanyIds,
                    house_ids: params.houseIds,
                    exclude_house_ids: params.excludeHouseIds,
                    tag_ids: params.tagIds,
                    exclude_tag_ids: params.excludeTagIds,
                    system_ids: params.systemIds,
                    exclude_system_ids: params.excludeSystemIds,
                    employee_ids: params.employeeIds,
                    exclude_employee_ids: params.excludeEmployeeIds,
                    type_ids: params.typeIds,
                    exclude_type_ids: params.excludeTypeIds,
                    creation_date_from: params.creationDateFrom ? params.creationDateFrom / 1000 : undefined,
                    creation_date_to: params.creationDateTo ? params.creationDateTo / 1000 : undefined,
                    periods: params.periods,
                },
                isUseQueryArrays: true,
            },
        );
        return mapPageable(response, mapToScheduledTask);
    }

    async findAllByExecutor(executorId: number): Promise<ScheduledTask[]> {
        const response = await this.client.get<{
            tasks: ScheduledTaskResponse[]
        }>(`v1/users/${executorId}/scheduled_tasks`);
        return response.tasks.map(mapToScheduledTask)
    }

    create(houseId: number, companyId: number, task: CreateScheduledTaskParam): Promise<void> {
        return this.client.post(`v1/companies/${companyId}/houses/${houseId}/scheduled-tasks`, {
            body: {
                interval: task.interval,
                starting_date: toLocalISODate(task.startingDate),
                system_id: task.systemId,
                executor_id: task.employeeId,
                type_id: task.typeId,
                description: task.description,
            },
        });
    }

    updateAll(tasks: UpdateScheduledTaskParam[]): Promise<void> {
        return this.client.put(`v1/scheduled-tasks`, {
            body: tasks.map(task => ({
                interval: task.interval,
                starting_date: toLocalISODate(task.startingDate),
                system_id: task.systemId,
                executor_id: task.employeeId,
                type_id: task.typeId,
                id: task.id,
                description: task.description,
            })),
        });
    }

    deleteAll(ids: string[]): Promise<void> {
        return this.client.delete(`v1/scheduled-tasks`, {body: ids});
    }
}
