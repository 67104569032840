import {P} from "../typography";
import s from "./Modal.module.css";

type Props = {
    message?: string | null;
};

export const ModalError = (props: Props) => {
    const {message} = props;
    if (!message) {
        return null;
    }

    return (
        <P className={s.error} variant="Paragraph/Default/p" color="dark-status-danger-d300">
            {message}
        </P>
    )
};
