import {P} from "common/components/typography";
import {observer} from "mobx-react-lite";
import {AttachButtons} from "tasks/components/message_box/AttachButtons";
import {DocumentTypeListStore} from "tasks/stores";
import {AttachmentUploaderStore} from "../../stores/attachment_uploader";
import {AttachedFiles} from "./AttachedFiles";
import s from "./MessageBox.module.css";

type Props = {
    store: AttachmentUploaderStore;
    typeList: DocumentTypeListStore;
};

export const AttachmentsContainer = observer((props: Props) => {
    const {store, typeList} = props;
    return (
        <div className={s.attachments}>
            <div className={s.attachmentsHeader}>
                <P>Прикрепить</P>
                <AttachButtons
                    types={typeList.types}
                    error={typeList.errorMessage}
                    isLoading={typeList.isLoading}
                    onAttach={store.attachFiles}/>
            </div>

            <AttachedFiles
                className={s.attachmentsList}
                attachments={store.attachments}
                onDetach={store.removeDocument}/>
        </div>
    );
});
