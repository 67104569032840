import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {Cell} from "react-table";
import {TableBodyCell as TableBodyCellBase} from "../../components/table";
import {Checkbox} from "../checkbox";
import {TableColumn} from "./index";
import s from "./index.module.css";

interface IProps<D extends object> {
    bodyCellClassName?: string;
    isDragDist: boolean;
    cell: Cell<D, any>;
    canSelectRows?: boolean;
    isAllRowsSelected?: boolean;
    isRowSelected?: (item: D) => void;
    onSelect?: (item: D) => void;
    onDragEnd: (dropColumnId: string | null) => void;
    onDragOver: (columnId: string | null) => void;
}

function TableBodyCell<D extends object>(props: IProps<D>) {
    const {
        bodyCellClassName,
        cell,
        canSelectRows,
        isAllRowsSelected,
        isRowSelected,
        isDragDist,
        onDragOver,
        onDragEnd,
        onSelect,
    } = props;
    const rendered = cell.render('Cell');
    const hasPadding = (cell.column as TableColumn<D>).hasPadding ?? true;
    const heightHack = (cell.column as TableColumn<D>).heightHack;
    return (
        <TableBodyCellBase
            className={classNames(
                bodyCellClassName,
                {[s.tableCellDrag]: isDragDist})
            }
            hasDefaultPadding={hasPadding}
            {...cell.getCellProps({
                style: {
                    minWidth: cell.column.minWidth, width:
                    cell.column.width,
                    height: heightHack ? 1 : undefined,
                }
            })}
            onDragOver={e => {
                e.preventDefault();
                onDragOver(cell.column.id);
            }}
            onDrop={() => onDragEnd(cell.column.id)}>
            {canSelectRows && (
                <div className={s.tableCellWithCheck}>
                    <Checkbox
                        value={isAllRowsSelected || isRowSelected?.(cell.row.original) || false}
                        onClick={e => e.stopPropagation()}
                        onToggle={onSelect ? () => onSelect(cell.row.original) : undefined}/>
                    {rendered}
                </div>
            )}
            {!canSelectRows && rendered}
        </TableBodyCellBase>
    );
}

export default observer(TableBodyCell);
