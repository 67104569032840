import {useChangeCitizenTypeStore} from "citizens/modals/change_citizen_type/ChangeCitizenTypeStore";
import {Avatar} from "common/components/avatar";
import {Button, ButtonSkeleton} from "common/components/button";
import {AsyncInputBox, InputBoxOption} from "common/components/input/box";
import {InputLabel} from "common/components/input/label";
import {Modal, ModalContent, ModalFooter, ModalHeader} from "common/components/modal";
import {observer} from "mobx-react-lite";
import React from "react";

export const SelectOwnerModal = observer(() => {
    const store = useChangeCitizenTypeStore();
    return (
        <Modal width={572} onDismiss={store.close}>
            <ModalHeader title="Смена статуса"/>

            <ModalContent>
                <InputLabel title="Выберите нового владельца квартиры" isRequired={true}>
                    <AsyncInputBox
                        selected={store.newOwner ? {
                            value: store.newOwner,
                            label: store.newOwner.fullName,
                            id: store.newOwner.id,
                        } : null}
                        renderOption={state => (
                            <InputBoxOption
                                state={state}
                                heading={<Avatar size={32} src={state.item.value.avatarUrl}/>}/>
                        )}
                        loadOptions={store.searchNewOwner}
                        onChangeValue={store.setNewOwner}/>
                </InputLabel>
            </ModalContent>

            <ModalFooter
                negative={<Button color="Warning" onClick={store.close}>Выйти без изменений</Button>}
                positive={
                    store.isLoading
                        ? <ButtonSkeleton width={99}/>
                        : <Button color="Primary" onClick={store.next}>Сохранить</Button>
                }
            />
        </Modal>
    );
});