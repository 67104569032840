import {CommentAttachmentParams} from "./TaskEvent";

export type DocumentProcessStatus = "processed" | "not_processed";

export const TaskDocumentConstraints = {
    nameMaxLength: 150,
};

export interface TaskDocumentType {
    id: string;
    name: string;
}

export interface TaskDocument {
    id: number;
    name: string;
    taskId: number;
    taskNumber: number;
    type: string;
    typeName: string;
    companyId: number;
    companyName: string;
    creatorName: string;
    creatorAvatar: string;
    creationDate: Date;
    address: string;
    url: string;
    isProcessed: boolean;
    description: string;
}

export interface CreateTaskDocumentParams {
    companyId: number | null;
    taskNumber: number;
    attachments: CommentAttachmentParams[];
}

export interface FindAllTaskDocumentsParams {
    limit: number;
    page: number;
    filter?: {
        companyId?: number | null;
        types?: string[];
        search?: string;
        searchFields?: number[];
        companies?: number[];
        excludeCompanies?: number[];
        creators?: number[];
        excludeCreators?: number[];
        houses?: number[];
        excludeHouses?: number[];
        creationDateFrom?: number;
        creationDateTo?: number;
        orderBy?: number;
        processStatus?: DocumentProcessStatus;
    };
}

export interface UpdateTaskDocumentParam {
    id: number;
    name?: string;
    type?: string;
    isProcessed?: boolean;
}

export function mapDocumentFiltersToRequest(filter: FindAllTaskDocumentsParams["filter"]) {
    return {
        search: filter?.search || undefined,
        search_fields: filter?.searchFields?.join(",") || undefined,
        company_id: filter?.companyId || undefined,
        types: filter?.types,
        companies: filter?.companies?.join(","),
        exclude_companies: filter?.excludeCompanies?.join(","),
        creators: filter?.creators?.join(","),
        exclude_creators: filter?.excludeCreators?.join(","),
        houses: filter?.houses?.join(","),
        exclude_houses: filter?.excludeHouses?.join(","),
        creation_date_from: filter?.creationDateFrom ? filter.creationDateFrom / 1000 : undefined,
        creation_date_to: filter?.creationDateTo ? filter.creationDateTo / 1000 : undefined,
        order_by: filter?.orderBy,
        process_status: filter?.processStatus,
    }
}

export interface TaskDocumentResponse {
    id: number;
    name: string;
    task_id: number;
    task_number: number;
    type: string;
    type_name: string;
    company_id: number;
    company_name: string;
    creator_name: string;
    creator_avatar: string;
    creation_date: number;
    address: string;
    url: string;
    is_processed: boolean;
    description: string;
}

export function mapToTaskDocument(response: TaskDocumentResponse): TaskDocument {
    return {
        id: response.id,
        name: response.name,
        taskId: response.task_id,
        taskNumber: response.task_number,
        type: response.type,
        companyId: response.company_id,
        companyName: response.company_name,
        creatorName: response.creator_name,
        creatorAvatar: response.creator_avatar,
        creationDate: new Date(response.creation_date * 1000),
        address: response.address,
        url: response.url,
        isProcessed: response.is_processed,
        typeName: response.type_name,
        description: response.description,
    };
}