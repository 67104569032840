import Cookies from "js-cookie";
import {v4 as uuidv4} from "uuid";

export class DeviceIdRepository {
    private deviceIdKey = "device_id";
    private uuidRegexp = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

    find(): string {
        const deviceId = Cookies.get(this.deviceIdKey);
        if (deviceId) {
            if (this.uuidRegexp.test(deviceId)) {
                return deviceId;
            } else {
                Cookies.remove(this.deviceIdKey);
            }
        }

        const newDeviceId = uuidv4();
        Cookies.set(this.deviceIdKey, newDeviceId);
        return newDeviceId;
    }
}