import classNames from "classnames";
import {MouseEventHandler, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Row} from "react-table";
import {TableBodyRow as TableBodyRowBase} from "../../components/table";
import s from "./index.module.css";
import TableBodyCell from "./TableBodyCell";

interface IProps<D extends object> {
    className?: string | ((item: D) => string | null);
    bodyCellClassName?: string;
    distColumnId: string | null;
    clickable: boolean | ((item: Row<D>) => boolean);
    row: Row<D>;
    link?: (data: D) => string;
    shouldScrollTo?: (row: D) => boolean;
    prepareRow: (row: Row<D>) => void;
    canSelectRows?: boolean;
    isAllRowsSelected?: boolean;
    isRowSelected?: (item: D) => void;
    onDragEnd: (dropColumnId: string | null) => void;
    onDragOver: (columnId: string | null) => void;
    onSelect?: (item: D) => void;

    onClick?(data: D, button: number): void;
}

export function parseClickable<D>(value: boolean | ((item: D) => boolean), item: D): boolean {
    if (typeof value === "boolean") {
        return value;
    }

    return value(item);
}

function TableBodyRow<D extends object>(props: IProps<D>) {
    const {
        className,
        distColumnId,
        bodyCellClassName,
        row,
        link,
        shouldScrollTo,
        clickable,
        prepareRow,
        isRowSelected,
        canSelectRows,
        isAllRowsSelected,
        onClick,
        onDragEnd,
        onDragOver,
        onSelect,
    } = props;

    const ref = useRef<HTMLTableRowElement>(null);
    useEffect(() => {
        if (shouldScrollTo && shouldScrollTo(row.original)) {
            ref.current?.scrollIntoView({block: "center"});
        }
    }, [shouldScrollTo, row.original]);

    const canClick = parseClickable(clickable, row);
    prepareRow(row);

    const handleMouseDown: MouseEventHandler | undefined = canClick && link ? e => {
        if (e.button === 1) {
            e.preventDefault();
            e.stopPropagation();
            window.open(link(row.original), "_blank");
        }
    } : undefined;

    const navigate = useNavigate();
    const handleClick: MouseEventHandler | undefined = canClick && (onClick || link) ? e => {
        if (onClick) {
            onClick(row.original, e.button);
        } else if (link) {
            navigate(link(row.original));
        }
    } : undefined;

    return (
        <TableBodyRowBase
            ref={ref} {...row.getRowProps()}
            className={classNames(
                {
                    [s.tableRowClickable]: handleClick || handleMouseDown,
                },
                typeof className === "function" ? className(row.original) : className,
            )}
            onClick={handleClick}
            onMouseDown={handleMouseDown}>
            {row.cells.map((cell, index) => (
                <TableBodyCell
                    key={index}
                    bodyCellClassName={bodyCellClassName}
                    cell={cell}
                    canSelectRows={canSelectRows && index === 0}
                    isAllRowsSelected={isAllRowsSelected}
                    isRowSelected={isRowSelected}
                    isDragDist={cell.column.id === distColumnId}
                    onDragEnd={onDragEnd}
                    onDragOver={onDragOver}
                    onSelect={onSelect}/>
            ))}
        </TableBodyRowBase>
    );
}

export default TableBodyRow;
