import classNames from "classnames";
import {observer} from "mobx-react-lite";
import React, {CSSProperties, useEffect, useState} from "react";
import useGlobalEvent from "../../../common/hooks/useGlobalEvent";
import {Button} from "../button";
import {TriState} from "../checkbox";
import {P} from "../typography";
import {usePopupSize} from "./context";
import SearchField from "./SearchField";
import {IMenuItemUiState, TPopupPos} from "./state";
import s from "./SuggestionsPopup.module.css";
import {useSuggestionsSearch} from "./useSuggestionsSearch";

export type PopupConnectedSide = "right" | "left";

interface IProps<T> {
    items: IMenuItemUiState<T>[];
    position?: TPopupPos;
    trailingActions?: JSX.Element;
    children: (suggestions: IMenuItemUiState<T>[]) => JSX.Element;
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    error?: string;
    search?: string;
    connectedSide?: PopupConnectedSide;
    canExclude?: boolean;
    selectionState?: TriState;
    onExcludeToggle?: () => void;
    isFilterable?: boolean;
    isResizable?: boolean;
    setSearch?: (value: string) => void;

    retryLoad?(): void;
}

enum InteractSide {
    BOTTOM,
    LEFT,
    LEFT_BOTTOM,
    RIGHT,
    RIGHT_BOTTOM,
}

type ResizableParams = {
    minWidth: number,
    minHeight: number,
    maxWidth: number | undefined,
    maxHeight: number | undefined,
    width: number,
    height: number,
};

const DEFAULT_POPUP_SIZE = 250;

function useResizable(props: ResizableParams) {
    const {
        width: defaultWidth,
        height: defaultHeight,
        minWidth,
        minHeight,
        maxWidth = Number.MAX_VALUE,
        maxHeight = Number.MAX_VALUE
    } = props;
    const popupSize = usePopupSize();
    const [width, setWidth] = useState(defaultWidth);
    const [height, setHeight] = useState(defaultHeight);
    const [offsetX, setOffsetX] = useState(0);

    const [interactSide, setInteractSide] = useState<InteractSide | null>(null);
    const [startHeight, setStartHeight] = useState(0);
    const [startWidth, setStartWidth] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [mouseX, setMouseX] = useState(0);

    useEffect(() => {
        if (popupSize) {
            popupSize.width = width;
            popupSize.height = height;
        }
    }, [popupSize, width, height]);

    useGlobalEvent("mousemove", e => {
        switch (interactSide) {
            case InteractSide.BOTTOM: {
                setHeight(Math.min(maxHeight, Math.max(minHeight, startHeight + (e.clientY - mouseY))));
                break;
            }
            case InteractSide.RIGHT: {
                setWidth(Math.min(maxWidth, Math.max(minWidth, startWidth + (e.clientX - mouseX))));
                break;
            }
            case InteractSide.LEFT: {
                const newWidth = Math.min(maxWidth, Math.max(minWidth, startWidth - (e.clientX - mouseX)));
                setWidth(newWidth);
                setOffsetX(offsetX + width - newWidth);
                break;
            }
            case InteractSide.RIGHT_BOTTOM: {
                setWidth(Math.min(maxWidth, Math.max(minWidth, startWidth + (e.clientX - mouseX))));
                setHeight(Math.min(maxHeight, Math.max(minHeight, startHeight + (e.clientY - mouseY))));
                break;
            }
            case InteractSide.LEFT_BOTTOM: {
                const newWidth = Math.min(maxWidth, Math.max(minWidth, startWidth - (e.clientX - mouseX)));
                setWidth(newWidth);
                setOffsetX(offsetX + width - newWidth);
                setHeight(Math.max(minHeight, startHeight + (e.clientY - mouseY)));
                break;
            }
        }
    });

    useGlobalEvent("mouseup", () => {
        if (interactSide != null) {
            setInteractSide(null);
        }
    });

    return {
        width,
        height,
        offsetX,
        onBottomDown(e: React.MouseEvent<HTMLElement>) {
            e.preventDefault();
            setInteractSide(InteractSide.BOTTOM);
            setMouseY(e.clientY);
            setStartHeight(height);
        },
        onLeftDown(e: React.MouseEvent<HTMLElement>) {
            e.preventDefault();
            setInteractSide(InteractSide.LEFT);
            setMouseX(e.clientX);
            setStartWidth(width);
        },
        onRightDown(e: React.MouseEvent<HTMLElement>) {
            e.preventDefault();
            setInteractSide(InteractSide.RIGHT);
            setMouseX(e.clientX);
            setStartWidth(width);
        },
        onRightBottomDown(e: React.MouseEvent<HTMLElement>) {
            e.preventDefault();
            setInteractSide(InteractSide.RIGHT_BOTTOM);
            setMouseX(e.clientX);
            setStartWidth(width);
            setMouseY(e.clientY);
            setStartHeight(height);
        },
        onLeftBottomDown(e: React.MouseEvent<HTMLElement>) {
            e.preventDefault();
            setInteractSide(InteractSide.LEFT_BOTTOM);
            setMouseX(e.clientX);
            setStartWidth(width);
            setMouseY(e.clientY);
            setStartHeight(height);
        },
    };
}

function SuggestionsPopupContent<T>(props: IProps<T>) {
    const {
        items,
        search: searchControlled,
        position,
        width,
        height,
        maxWidth,
        maxHeight,
        trailingActions,
        connectedSide,
        children,
        retryLoad,
        isFilterable,
        isResizable,
        error,
        canExclude,
        selectionState,
        onExcludeToggle,
        setSearch: setSearchControlled,
    } = props;
    const {suggestions, search, setSearch} = useSuggestionsSearch(
        items,
        searchControlled,
        setSearchControlled,
    );

    const popupSize = usePopupSize();
    const {
        width: currentWidth,
        height: currentHeight,
        offsetX,
        onBottomDown,
        onLeftDown,
        onRightDown,
        onRightBottomDown,
        onLeftBottomDown,
    } = useResizable({
        width: popupSize?.width || width || DEFAULT_POPUP_SIZE,
        height: popupSize?.height || height || DEFAULT_POPUP_SIZE,
        minWidth: width || DEFAULT_POPUP_SIZE,
        minHeight: height || DEFAULT_POPUP_SIZE,
        maxWidth,
        maxHeight,
    });

    const positionStyle: CSSProperties | undefined = position ? {
        position: "absolute",
        left: position.x,
        top: position.y,
    } : undefined;

    const sizeStyle: CSSProperties | undefined = {
        position: isResizable ? "relative" : undefined,
        left: offsetX,
        width: currentWidth,
        height: currentHeight,
    };

    return (
        <div className={classNames({
            [s.popup]: true,
            [s.popupConnectedRight]: connectedSide === "right",
            [s.popupConnectedLeft]: connectedSide === "left",
        })} style={{...sizeStyle, ...positionStyle}}>
            {isFilterable ?
                <SearchField
                    search={search}
                    setSearch={setSearch}
                    canSelectAll={canExclude}
                    selectionState={selectionState}
                    onSelectAll={onExcludeToggle}
                /> : null}

            {error
                ? <div className={s.loadError}>
                    <P className={s.loadErrorMessage}>Произошла ошибка загрузки!</P>
                    <P className={s.loadErrorMessage}>{error}</P>
                    {retryLoad && (
                        <Button
                            color="Primary"
                            className={s.retryBtn}
                            onClick={retryLoad}>
                            Повторить
                        </Button>
                    )}
                </div>
                : children(suggestions)}

            {trailingActions}

            {isResizable && <>
                <div className={s.bottomResizer} onMouseDown={onBottomDown}></div>
                <div className={s.leftResizer} onMouseDown={onLeftDown}></div>
                <div className={s.rightResizer} onMouseDown={onRightDown}></div>
                <div className={s.rightBottomResizer} onMouseDown={onRightBottomDown}></div>
                <div className={s.leftBottomResizer} onMouseDown={onLeftBottomDown}></div>
            </>}
        </div>
    );
}

export default observer(SuggestionsPopupContent);
