export class ItemLimitRepository {
    private static readonly LIMIT_POSTFIX = "_page_limit";

    find(page: string): number | null {
        const limit = Number(localStorage.getItem(page + ItemLimitRepository.LIMIT_POSTFIX));

        if (!Number.isFinite(limit) || limit <= 0) {
            return null;
        }

        return limit;
    }

    save(page: string, limit: number) {
        localStorage.setItem(page + ItemLimitRepository.LIMIT_POSTFIX, limit.toString());
    }
}