import {HiddenColumn} from "../types";

/**
 * Репозиторий для хранения колонок в таблицах, видимость которых отключена.
 */
export class HiddenColumnRepository {
    private readonly KEY = "columns_visibility";

    /**
     * Сохраняет массив скрытых колонок для страницы {@link page}.
     */
    upsertByPage(page: string, columns: HiddenColumn[]) {
        const all = this.findAll();
        all[page] = columns;
        localStorage.setItem(this.KEY, JSON.stringify(all));
    }

    /**
     * Возвращает массив скрытых колонок для страницы {@link page}. Если данные еще не были сохранены, то возвращает null.
     */
    findByPage(page: string): HiddenColumn[] | null {
        const all = this.findAll();
        return all[page] || null;
    }

    private findAll(): Record<string, HiddenColumn[]> {
        const hidden = localStorage.getItem(this.KEY);
        if (!hidden) {
            return {};
        }

        try {
            const parsed = JSON.parse(hidden);
            if (typeof parsed !== "object" || Array.isArray(parsed)) {
                return {};
            }

            //Convert from old format
            const formattedPages: Record<string, HiddenColumn[]> = {};
            for (const page in parsed) {
                const formattedColumns: HiddenColumn[] = [];
                const columns = parsed[page];
                for (const column of columns) {
                    if (typeof column !== "object") {
                        formattedColumns.push({id: column, isVisible: false, hiddenChildren: []});
                    } else if (typeof column.isVisible !== "boolean") {
                        formattedColumns.push({...column, isVisible: false});
                    } else {
                        formattedColumns.push(column);
                    }
                }

                formattedPages[page] = formattedColumns;
            }

            return formattedPages;
        } catch (e) {
            return {};
        }
    }
}