import {makeAutoObservable} from "mobx";
import {PendingCommentStore} from "../../components/message_box";

export class CommentModalStore {
    isShow: boolean;
    newStatus: string;
    taskId: number | null;

    constructor(readonly pendingComment: PendingCommentStore) {
        this.isShow = false;
        this.newStatus = "";
        this.taskId = null;
        this.pendingComment.setMinimalMessageLength(5);
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get canSend() {
        return this.pendingComment.canSend;
    }

    get isLoading() {
        return this.pendingComment.isSending;
    }

    setSendListener(callback: (() => void) | null) {
        this.pendingComment.setSendListener(() => {
            callback?.();
            this.close();
        });
    }

    show(taskId: number, newStatus: string) {
        this.newStatus = newStatus;
        this.taskId = taskId;
        this.isShow = true;
        this.pendingComment.setTaskId(taskId);
    }

    send() {
        this.pendingComment.send();
    }

    close() {
        this.isShow = false;
    }
}
