import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {EntryFilters} from "./EntryFilters";

export function useFilterSerialization(key: string, filters: EntryFilters<any>) {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        filters.setSaveListener(serialized => {
            setSearchParams(searchParams => {
                const params = new URLSearchParams(searchParams);
                if (serialized === null) {
                    params.delete(key);
                } else {
                    params.set(key, serialized);
                }
                return params;
            }, {replace: true});
        });

        return () => filters.setSaveListener(null);
    }, [searchParams, setSearchParams, key, filters]);

    useEffect(() => {
        //Не используем useSearchParams, чтобы сохраненный фильтр десериализовался только один раз (при загрузке страницы)
        const url = new URL(window.location.href);
        const serializedRaw = url.searchParams.get(key);
        if (serializedRaw) {
            filters.deserializeFilters(serializedRaw);
        }
    }, [filters, key]);
}
