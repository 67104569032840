import Skeleton from "react-loading-skeleton";

type Props = {
    className?: string;
    size: number;
};

export const AvatarSkeleton = (props: Props) => {
    const {className, size} = props;
    return <Skeleton containerClassName={className} borderRadius="50%" width={size} height={size}/>;
}
