import classNames from "classnames";
import {ForwardedRef, forwardRef, ReactNode} from "react";
import {Checkbox} from "../../checkbox";
import {P} from "../../typography";
import s from "./InputBox.module.css";

export type OptionProps = {
    label?: string;
    isFocused: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    isSelected?: boolean;
    heading?: JSX.Element;
    caption?: string | ReactNode;
} & JSX.IntrinsicElements['div'];

export const InputBoxOptionBase = forwardRef((props: OptionProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {className, label, caption, heading, isFocused, isMulti, isSelected, isDisabled, ...defaultProps} = props;

    const hasLabel = label !== undefined || caption;
    return (
        <div
            ref={ref}
            className={classNames(
                s.option,
                {
                    [s.optionUnlabeled]: !hasLabel,
                    [s.optionDisabled]: isDisabled,
                    [s.optionActive]: isFocused,
                },
                className
            )}
            {...defaultProps}>
            {isMulti && (
                <Checkbox
                    variant="listed"
                    value={isSelected || false}
                    isDarkerBackground={isFocused}
                    //Предотвращаем скрытие InputBox при клике на галочку
                    onClick={e => e.preventDefault()}/>
            )}
            {heading && <span style={{flexShrink: 0}}>{heading}</span>}
            {hasLabel && (
                <div className={s.labelContainer}>
                    <P className={s.labelText} variant="Paragraph/Default/p" color="inherit">{label}</P>
                    {caption && typeof caption === "string"
                        && <P className={s.labelText} variant="Paragraph/Small/p" color="inherit">{caption}</P>}
                    {caption && typeof caption !== "string" && caption}
                </div>
            )}
        </div>
    )
});
