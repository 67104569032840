import {AuthorizedHttpClient} from "common/net";
import {CursorPageableResponse, mapCursorPageable, mapPageable, Pageable, PageableResponse} from "common/types";
import {FindReportSliceParams, mapReportSliceParamsToBody} from "reports/types";
import {FindScheduledTaskFilterParam, mapToTaskType, TaskType, TaskTypeResponse} from "../types";

export class TaskTypeRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(signal?: AbortSignal): Promise<TaskType[]> {
        const response = await this.client.postV0<TaskTypeResponse[]>({type: "get_types"}, signal);
        return response.map(mapToTaskType);
    }

    async findAllBySlices(params: FindReportSliceParams): Promise<Pageable<TaskType>> {
        const response = await this.client.post<PageableResponse<TaskTypeResponse>>("web/v1/reports/types", {
            body: mapReportSliceParamsToBody(params),
            signal: params.signal,
        });
        return mapPageable(response, mapToTaskType);
    }

    async findAllForScheduledTaskFilter(param: FindScheduledTaskFilterParam) {
        const response = await this.client.get<CursorPageableResponse<TaskTypeResponse>>(
            "web/v1/scheduled-tasks/filters", {
                params: {
                    type_filter: "type",
                    cursor: param.cursor || undefined,
                    limit: param.limit,
                    search: param.search.trim() || undefined,
                    company_id: param.companyId || undefined,
                },
            });
        return mapCursorPageable(response, mapToTaskType);
    }
}
