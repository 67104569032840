import createSvgIcon from "./utils";

const IconMan = createSvgIcon((
    <>
        <path
            stroke="transparent"
            d="M17.5 16.644a7.701 7.701 0 0 0 0-15.4 7.7 7.7 0 0 0 0 15.4ZM17.5 33.755c7.088 0 12.834-3.449 12.834-7.7 0-4.25-5.746-7.7-12.834-7.7-7.087 0-12.833 3.45-12.833 7.7 0 4.251 5.746 7.7 12.833 7.7Z"/>
    </>
), {width: 35, height: 35});

export default IconMan;