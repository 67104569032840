import classNames from "classnames";
import {observer} from "mobx-react-lite";
import React, {forwardRef, KeyboardEvent, MouseEvent, ReactNode, Ref, useEffect, useState} from "react";
import s from "./index.module.css";
import RippleStore from "./store";

interface IProps {
    className?: string;
    isCentered?: boolean;
    isButton?: boolean;
    isEnabled?: boolean;
    tabIndex?: number;
    children: ReactNode;

    onKeyPress?(e: KeyboardEvent<HTMLDivElement>): void;

    onClick?(e: MouseEvent<HTMLDivElement>): void;
}

function Ripple(props: IProps, ref: Ref<HTMLDivElement>) {
    const {className, tabIndex, isCentered, isButton, isEnabled = true, children, onClick, onKeyPress} = props;
    const [store] = useState(() => new RippleStore());

    useEffect(() => {
        store.isCentered = isCentered || false;
    }, [store, isCentered]);

    function handleClick(e: MouseEvent<HTMLDivElement>) {
        if (isEnabled) {
            store.handleClick(e);
        }

        onClick?.(e);
    }

    return (
        <div
            ref={ref}
            className={classNames(
                s.container,
                {[s.containerButton]: isButton},
                className,
            )}
            tabIndex={tabIndex}
            onClick={handleClick}
            onKeyPress={onKeyPress}>
            {children}
            <div className={s.ripples}>
                {store.ripples.map((ripple) => (
                    <span
                        key={ripple.key}
                        className={isButton ? s.rippleButton : s.ripple}
                        style={ripple.style}
                        onAnimationEnd={() => store.removeRipple(ripple)}/>
                ))}
            </div>
        </div>
    );
}

export default observer(forwardRef(Ripple));