import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";
import IconArrowDown16x16 from "../../../components/icons/IconArrowDown16x16";
import {InputBase} from "../base";

type Props = {
    value: Date | null;
    placeholder?: string;
    min?: Date;
    max?: Date;
    showTimeInput?: boolean;
    isDisabled?: boolean;
    onChange: (value: Date) => void;
};

export const DateInput = (props: Props) => {
    const {
        value,
        min,
        max,
        showTimeInput,
        placeholder,
        isDisabled,
        onChange,
    } = props;

    return (
        <DatePicker
            selected={value}
            placeholderText={placeholder}
            dateFormat={showTimeInput ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
            locale={ru}
            minDate={min}
            maxDate={max}
            showTimeInput={showTimeInput}
            enableTabLoop={false}
            preventOpenOnFocus
            customInput={(
                <InputBase trailing={(
                    <IconArrowDown16x16
                        fill={isDisabled ? "var(--dark-gray-mian)" : undefined}
                        style={{paddingRight: 15}}/>
                )}/>
            )}
            disabled={isDisabled}
            onChange={onChange}/>
    );
};
