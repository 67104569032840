import {SearchFieldsStore, SearchFieldsStoreBuilder} from "common/components/search_bar/with_fields";
import {SearchFieldRepository} from "common/repositories";
import {makeAutoObservable} from "mobx";
import {TaskFilterValue} from "tasks/types";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

export class SearchFilterStore implements BaseTaskFilterStore {
    search: string;
    searchInternal: string;
    readonly isVisible = false;
    readonly base: SearchFieldsStore;

    constructor(
        fieldsBuilder: SearchFieldsStoreBuilder,
        fieldsRepo: SearchFieldRepository,
        private readonly onRefresh: () => void,
    ) {
        this.search = "";
        this.searchInternal = "";
        this.base = fieldsBuilder(() => fieldsRepo.findAllForTasks());
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        return this.base.selectedKeys.length > 0 || this.search.trim().length > 0;
    }

    private get truncatedSearch() {
        if (this.search.length > 25) {
            return this.search.substring(0, 25) + "...";
        }

        return this.search;
    }

    private get selectedFieldValues(): TaskFilterValue[] {
        return this.base.selectedKeys.map(key => {
            const field = this.base.all.find(field => field.id === key);
            if (field == null) {
                return {title: key.toString(), value: key.toString()};
            }

            return {title: field.name, value: key.toString()};
        })
    }

    isOwnFilter(key: string) {
        return key === "search" || key === "search_fields";
    }

    setSearch(value: string) {
        this.searchInternal = value;
    }

    applySearch() {
        if (this.search !== this.searchInternal) {
            this.search = this.searchInternal;
            this.onRefresh();
        }
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.search.trim().length > 0) {
            builder.upsert("search", this.truncatedSearch, this.search);
        }

        if (this.base.selectedKeys.length > 0) {
            builder.upsertMany("search_fields", this.selectedFieldValues)
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        const fields = builder.findManyValues("search_fields")
            ?.map(Number)
            ?.filter(Boolean);
        this.search = builder.findValue("search") || "";
        this.searchInternal = this.search;
        this.base.setSelectedKeysImmediate(fields || []);
    }

    reset() {
        this.search = "";
        this.searchInternal = "";
        this.base.reset();
    }
}