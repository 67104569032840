import createSvgIcon from "./utils";

const IconClosedEye = createSvgIcon((
    <>
        <g fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
           strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
           fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
            <path d="M0,172v-172h172v172z" fill="none"/>
            <g>
                <path
                    d="M86.09798,23.01172c-31.83433,0 -61.69716,18.89749 -77.9655,49.31283c-0.55183,1.04633 -0.85384,2.19804 -0.85384,3.37337v0.78385c0,1.1825 0.29484,2.34103 0.85384,3.38737c3.58455,6.67055 7.86561,12.74465 12.63965,18.22461l-14.47331,14.47331c-1.87214,1.79754 -2.62626,4.46673 -1.97153,6.97818c0.65473,2.51145 2.61602,4.47273 5.12747,5.12747c2.51145,0.65474 5.18064,-0.09938 6.97818,-1.97153l14.5153,-14.5153c5.04095,4.27262 10.46304,7.9627 16.18099,10.98795l-9.85417,19.3444c-1.24919,2.28978 -1.15583,5.07742 0.24378,7.27849c1.39961,2.20106 3.88446,3.46801 6.48791,3.30798c2.60345,-0.16003 4.91443,-1.72177 6.03393,-4.07768l10.31608,-20.24024c5.98576,1.97899 12.1705,3.28468 18.47656,3.84929v21.86393c-0.03655,2.58456 1.32136,4.98858 3.55376,6.29153c2.2324,1.30295 4.99342,1.30295 7.22582,0c2.2324,-1.30295 3.59031,-3.70697 3.55376,-6.29153v-21.86393c5.93141,-0.52965 11.76014,-1.70182 17.41276,-3.48535l10.13411,19.8763c1.79735,3.529 6.11521,4.93277 9.6442,3.13542c3.529,-1.79735 4.93277,-6.11521 3.13542,-9.6442l-9.60222,-18.85449c5.82214,-2.98652 11.37825,-6.60407 16.51693,-10.87598l13.91341,13.91341c1.79754,1.87212 4.46672,2.62622 6.97816,1.97148c2.51144,-0.65474 4.47271,-2.61601 5.12745,-5.12745c0.65474,-2.51144 -0.09936,-5.18061 -1.97148,-6.97816l-13.78743,-13.78743c5.06185,-5.70211 9.5708,-12.07263 13.32551,-19.09244c0.55184,-1.04633 0.83985,-2.21204 0.83985,-3.38738v-0.76986c0,-1.18249 -0.29484,-2.35503 -0.85384,-3.40136c-16.28267,-30.30067 -46.12601,-49.11686 -77.88151,-49.11686z"/>
            </g>
        </g>
    </>
), {width: 24, height: 24, viewBox: "0 0 172 172"});

export default IconClosedEye;