import {Fragment, ReactNode} from "react";
import IconArrowRight6x10 from "../../components/icons/IconArrowRight6x10";
import s from "./Breadcrumbs.module.css";

type Props = {
    children: ReactNode;
};

function addArrowsTo(children: ReactNode) {
    if (Array.isArray(children)) {
        return children.map((item, index) => (
            <Fragment key={index}>
                {item}
                {index !== children.length - 1 ? <IconArrowRight6x10/> : null}
            </Fragment>
        ));
    }

    return children;
}

export const Breadcrumbs = (props: Props) => {
    const {children} = props;

    return (
        <nav className={s.container}>
            {addArrowsTo(children)}
        </nav>
    )
};
