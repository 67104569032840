import {minutesToMs} from "../utils";

interface SocketLifecycleParams {
    isConnectionAlive: () => boolean;
    closeConnection: () => void;
    startConnection: () => void;
}

export class SocketLifecycleStore {
    private static readonly suspendDelayMs = minutesToMs(1);
    private timeoutId: number | null;
    private prevState: string;
    private isLoaded: boolean;
    private readonly visibilityListener: () => void;
    private readonly isConnectionAlive: () => boolean;
    private readonly closeConnection: () => void;
    private readonly startConnection: () => void;

    constructor(params: SocketLifecycleParams) {
        this.timeoutId = null;
        this.isLoaded = false;
        this.prevState = document.visibilityState;
        this.isConnectionAlive = params.isConnectionAlive;
        this.closeConnection = params.closeConnection;
        this.startConnection = params.startConnection;
        this.visibilityListener = () => {
            if (document.visibilityState !== this.prevState) {
                if (this.timeoutId !== null) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }

                if (document.visibilityState === "hidden") {
                    if (this.isConnectionAlive()) {
                        this.timeoutId = setTimeout(
                            () => {
                                this.closeConnection();
                            },
                            SocketLifecycleStore.suspendDelayMs,
                        ) as any as number;
                    }
                } else if (!this.isConnectionAlive()) {
                    this.startConnection();
                }

                this.prevState = document.visibilityState;
            }
        };
    }

    load() {
        if (!this.isLoaded) {
            this.isLoaded = true;
            this.prevState = document.visibilityState;
            document.addEventListener("visibilitychange", this.visibilityListener);
            this.startConnection();
        }
    }

    unload() {
        if (this.isLoaded) {
            this.isLoaded = false;
            document.removeEventListener("visibilitychange", this.visibilityListener);
            this.closeConnection();

            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
        }
    }
}