import {IObservableArray, makeAutoObservable, observable} from "mobx";
import {CSSProperties, MouseEvent} from "react";

const MAX_RIPPLES = 2;

interface IRippleEffect {
    key: number;
    style?: CSSProperties;
}

class RippleStore {
    isCentered = false;
    readonly ripples: IObservableArray<IRippleEffect>;
    private nextKey = 0;

    constructor() {
        this.ripples = observable.array([], {deep: false});
        makeAutoObservable(this, {}, {autoBind: true});
    }

    handleClick(e: MouseEvent<HTMLDivElement>) {
        const container = e.currentTarget;
        const size = container.offsetWidth;
        const pos = container.getBoundingClientRect();

        if (this.ripples.length > MAX_RIPPLES) {
            this.ripples.shift();
        }

        let x;
        let y;
        if (this.isCentered) {
            x = pos.width / 2 - size / 2;
            y = pos.height / 2 - size / 2;
        } else {
            x = e.pageX - pos.x - (size / 2);
            y = e.pageY - pos.y - (size / 2);
        }

        this.ripples.push({
            key: this.nextKey,
            style: {
                top: `${y}px`,
                left: `${x}px`,
                height: `${size}px`,
                width: `${size}px`,
            },
        });
        this.nextKey++;
    }

    removeRipple(ripple: IRippleEffect) {
        this.ripples.remove(ripple);
    }
}

export default RippleStore;