import createSvgIcon from "./utils";

const IconPeoples = createSvgIcon((
    <>
        <path
            stroke="transparent"
            d="M14.184 16.671a7.461 7.461 0 0 0 0-14.921 7.461 7.461 0 0 0 0 14.921ZM14.184 33.25c6.867 0 12.435-3.342 12.435-7.46 0-4.12-5.568-7.461-12.435-7.461-6.867 0-12.434 3.342-12.434 7.46 0 4.12 5.567 7.461 12.434 7.461Z"/>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="M27.087 29.51c-.086.129.028.302.178.263 3.522-.92 5.985-3.026 5.985-5.476 0-3.295-4.454-5.968-9.948-5.968-.224 0-.288.304-.09.41.21.112.417.228.617.349 2.516 1.51 4.447 3.825 4.447 6.696 0 1.38-.446 2.63-1.189 3.726Zm-5.436-15.072c-.641.914-.517 2.27.581 2.469a5.97 5.97 0 1 0 1.07-11.841c-.475 0-.738.508-.572.953.37.992.572 2.065.572 3.186 0 1.947-.61 3.752-1.65 5.233Z"
            clipRule="evenodd"/>
    </>
), {width: 35, height: 35});

export default IconPeoples;