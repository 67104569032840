import {FormEvent, ForwardedRef, forwardRef, HTMLAttributes, ReactNode} from "react";
import {PatternFormat} from "react-number-format";
import {InputBase} from "../base";

type Props<N extends string> = {
    type?: "text" | "tel" | "password";
    name?: N;
    mask?: string;
    value?: string;
    disabled?: boolean;
    maxLength?: number;
    heading?: ReactNode;
    trailing?: ReactNode;
    autoFocus?: boolean;
    onValueChange?: (value: string, unmaskedValue: string) => void;
    onFormChange?: (name: N, value: string) => void;
    containerClassName?: string;
    readOnly?: boolean;
} & Omit<HTMLAttributes<HTMLInputElement>, "type" | "checked" | "defaultChecked" | "onChange">;

export const TextInput = forwardRef(<N extends string>(props: Props<N>, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        containerClassName,
        type = "text",
        mask,
        name,
        value,
        maxLength,
        autoFocus,
        onKeyDown,
        onBlur,
        onClick,
        heading,
        trailing,
        disabled,
        onInput,
        onValueChange,
        onFormChange,
        ...defaultProps
    } = props;

    if (mask) {
        return (
            <PatternFormat
                type={type}
                format={mask}
                mask="_"
                value={value}
                disabled={disabled}
                allowEmptyFormatting
                getInputRef={ref}
                onValueChange={values => {
                    onValueChange?.(values.formattedValue, values.value)
                    if (name) {
                        onFormChange?.(name, values.formattedValue)
                    }
                }}
                customInput={InputBase}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onClick={onClick}/>
        )
    }

    const handleInput = (e: FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        onValueChange?.(value, value);
        onInput?.(e);
        if (name) {
            onFormChange?.(name, value);
        }
    };

    return (
        <InputBase
            ref={ref}
            containerClassName={containerClassName}
            type={type}
            name={name}
            maxLength={maxLength}
            heading={heading}
            trailing={trailing}
            value={value}
            autoFocus={autoFocus}
            disabled={disabled}
            onInput={handleInput}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            {...defaultProps}/>
    );
}) as <N extends string>(props: Props<N> & { ref?: any }, ref: ForwardedRef<HTMLInputElement>) => JSX.Element;
