import {makeAutoObservable} from "mobx";
import {DefinedUserRole} from "users/types";
import {UserStore} from "./UserStore";

export class CommonPermissions {
    private userStore: UserStore | null;

    constructor() {
        this.userStore = null;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    /**
     * Определяет, может ли авторизированный пользователь фильтровать элементы (заявки, пользователей, дома) на странице
     * по компании.
     */
    get canFilterByCompany() {
        const userStore = this.requireUserStore();
        const roleId = userStore.roleId;
        const isCompanyGroup = userStore.isCompanyGroup;
        return (roleId !== DefinedUserRole.ADMIN && roleId !== DefinedUserRole.ENGINEER) || isCompanyGroup;
    }

    setUserStore(userStore: UserStore) {
        this.userStore = userStore;
    }

    private requireUserStore(): UserStore {
        if (this.userStore === null) {
            throw new Error("UserStore is not set");
        }

        return this.userStore;
    }
}
