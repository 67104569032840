import {UploadsRepository} from "common/repositories";
import {IUploadedFileEntity} from "common/types";
import {makeAutoObservable} from "mobx";
import {AttachedFileUiState} from "../../components/message_box/state";
import {CommentAttachmentParams} from "../../types";
import {HeicConverter} from "./HeicConverter";

const MAX_ATTACHMENT_SIZE_MB = 15;
const MAX_ATTACHMENTS_PER_MESSAGE = 6;

const MAX_AMOUNT_ERROR = `Максимальное количество вложений — ${MAX_ATTACHMENTS_PER_MESSAGE}`;
const MAX_SIZE_ERROR = `Максимальный размер файла — ${MAX_ATTACHMENT_SIZE_MB}МБ`;

export class AttachmentUploaderStore {
    attachments: AttachedFileUiState[];

    private uploadedAttachments: Map<AttachedFileUiState, IUploadedFileEntity>;
    private attachmentAddError: string | null;

    constructor(
        private readonly uploadRepo: UploadsRepository,
    ) {
        this.uploadedAttachments = new Map();
        this.attachments = [];
        this.attachmentAddError = null;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get hasAttachments() {
        return this.attachments.length > 0;
    }

    get errorMessage() {
        return this.attachmentAddError;
    }

    dismissError() {
        this.attachmentAddError = null;
    }

    async attachFiles(type: string, typeName: string, files: File[]) {
        let free = MAX_ATTACHMENTS_PER_MESSAGE - this.attachments.length;
        if (free < files.length) {
            this.attachmentAddError = MAX_AMOUNT_ERROR;
            return;
        }

        for (const file of files) {
            if (file.size > MAX_ATTACHMENT_SIZE_MB * 1024 * 1024) {
                this.attachmentAddError = MAX_SIZE_ERROR;
            } else {
                if (HeicConverter.isHeic(file)) {
                    try {
                        const converted = await HeicConverter.convert(file);
                        this.attachments.push({type, typeName, file: converted})
                    } catch (e) {
                        if (e instanceof Error) {
                            this.attachmentAddError = `Не удалось конвертировать формат heic: ${e.message}`;
                        } else throw e;
                    }
                } else {
                    this.attachments.push({type, typeName, file});
                }
            }
        }
    }

    removeDocument(attachment: AttachedFileUiState) {
        const index = this.attachments.indexOf(attachment);
        if (index !== -1) {
            this.attachments.splice(index, 1);
            this.uploadedAttachments.delete(attachment);
        }
    }

    async uploadAll(): Promise<CommentAttachmentParams[]> {
        for (const attachment of this.attachments) {
            const item = this.uploadedAttachments.get(attachment);
            if (item) {
                continue;
            }

            const uploaded = await this.uploadRepo.upload(attachment.file);
            this.uploadedAttachments.set(attachment, uploaded);
        }

        const all: CommentAttachmentParams[] = [];
        for (const [state, file] of Array.from(this.uploadedAttachments.entries())) {
            all.push({
                uri: file.link,
                sizeBytes: file.size,
                name: file.name,
                type: state.type,
            });
        }

        return all;
    }

    reset() {
        this.attachments = [];
        this.uploadedAttachments.clear();
    }
}
