import {ReactNode} from "react";
import UserAside from "../../components/user_aside";
import {BreadcrumbsSkeleton} from "../breadcrums";
import {P} from "../typography";
import s from "./Route.module.css";
import {RouteError} from "./RouteError";

type Props = {
    title: string;
    navigation?: ReactNode;
    actions?: ReactNode;
    error?: string | null;
    isLoading?: boolean;
    children: ReactNode;
    onRetry?: () => void;
};

export const Route = (props: Props) => {
    const {title, navigation, actions, error, isLoading, children, onRetry} = props;

    const isShowNavigation = navigation && !error;
    return (
        <main className={s.route}>
            <div className={s.header}>
                <div className={s.headerContent}>
                    <P className={s.title} variant="Header/H1">{error ? "Произошла ошибка" : title}</P>
                    {!isLoading && !error && actions}
                    <UserAside/>
                </div>
            </div>

            {isShowNavigation && (
                <div className={s.navigation}>
                    {!isLoading && navigation}
                    {isLoading && <BreadcrumbsSkeleton/>}
                </div>
            )}

            <div className={isShowNavigation ? s.contentWithNavigation : s.content}>
                {!error && children}
                {error && <RouteError message={error} onRetry={onRetry}/>}
            </div>
        </main>
    );
};
