import {CitizenAccessRepository, CitizenRepository} from "citizens/repositories";
import {Citizen, CitizenAccessType} from "citizens/types";
import {AsyncJob} from "common/stores/job";
import {createContextAndHook} from "common/utils";
import {makeAutoObservable} from "mobx";

export enum ChangeTypeStep {
    AGREE,
    SELECT_OWNER,
    SELECT_ACCESS_DATE,
}

interface Params {
    accessId: string;
    citizenId: string;
    currentType: CitizenAccessType;
    newType: CitizenAccessType;
}

export class ChangeCitizenTypeStore {
    isShow: boolean;
    step: ChangeTypeStep;
    accessId: string;
    citizenId: string;
    currentType: CitizenAccessType;
    newType: CitizenAccessType;
    newOwner: Citizen | null;
    accessTo: Date | null;

    private saveListener: (() => void) | null;
    private saveJob: AsyncJob<typeof ChangeCitizenTypeStore.prototype._save>;

    constructor(
        private readonly citizenRepo: CitizenRepository,
        private readonly accessRepo: CitizenAccessRepository,
    ) {
        this.isShow = false;
        this.saveListener = null;
        this.accessId = "";
        this.citizenId = "";
        this.currentType = "owner";
        this.newOwner = null;
        this.accessTo = null;
        this.step = ChangeTypeStep.AGREE;
        this.newType = "citizen";
        makeAutoObservable(this, {}, {autoBind: true});
        this.saveJob = new AsyncJob({job: this._save});
    }

    get isLoading() {
        return this.saveJob.isPending;
    }

    get errorMessage() {
        return this.saveJob.errorMessage;
    }

    async searchNewOwner(search: string, page: number) {
        const response = await this.citizenRepo.findAllBrief({
            page,
            search,
            limit: 25,
        });
        return {
            canLoadMore: response.maxPage > page,
            items: response.items.filter(user => user.id !== this.citizenId).map(item => ({
                value: item,
                label: item.fullName,
                id: item.id,
            }))
        };
    }

    setNewOwner(owner: Citizen | null) {
        this.newOwner = owner;
    }

    setAccessTo(owner: Date | null) {
        this.accessTo = owner;
    }

    dismissError() {
        this.saveJob.clearError();
    }

    setSaveListener(callback: (() => void) | null) {
        this.saveListener = callback;
    }

    next() {
        if (this.newType === "guest") {
            if (this.currentType === "owner" && this.newOwner == null) {
                this.step = ChangeTypeStep.SELECT_OWNER;
            } else if (this.accessTo === null) {
                this.step = ChangeTypeStep.SELECT_ACCESS_DATE;
            } else {
                this.saveJob.start();
            }
        } else if (this.newType === "citizen") {
            if (this.currentType === "owner" && this.newOwner == null) {
                this.step = ChangeTypeStep.SELECT_OWNER;
            } else {
                this.saveJob.start();
            }
        } else {
            this.saveJob.start();
        }
    }

    show(params: Params) {
        if (params.currentType === params.newType) {
            throw new Error("citizen.type === newType");
        }

        this.isShow = true;
        this.accessId = params.accessId;
        this.citizenId = params.citizenId;
        this.currentType = params.currentType;
        this.newType = params.newType;
    }

    close() {
        this.isShow = false;
        this.accessId = "";
        this.citizenId = "";
        this.currentType = "owner";
        this.newOwner = null;
        this.accessTo = null;
        this.step = ChangeTypeStep.AGREE;
    }

    private* _save() {
        yield this.accessRepo.update(this.accessId, {
            type: this.newType,
            access_to: this.accessTo || undefined,
            newOwnerId: this.newOwner?.id || undefined,
        });
        this.saveListener?.();
        this.close();
    }
}

const [ChangeCitizenTypeStoreContext, useChangeCitizenTypeStore] = createContextAndHook<ChangeCitizenTypeStore>();
export {ChangeCitizenTypeStoreContext, useChangeCitizenTypeStore};