import heic2any from "heic2any";

export class HeicConverter {
    static convertIfNeed(file: File) {
        if (HeicConverter.isHeic(file)) {
            return HeicConverter.convert(file);
        }

        return Promise.resolve(file);
    }

    static isHeic(file: File): boolean {
        return file.name.toLowerCase().endsWith(".heic");
    }

    static async convert(file: File): Promise<File> {
        try {
            const converted = await heic2any({blob: file}) as Blob;
            return new File([converted], this.getFileNewName(file.name), {type: "image/png"});
        } catch (e: any) {
            if (typeof e === "object" && e && "message" in e) {
                throw new HeicConverterError(e.message);
            }

            throw new HeicConverterError("unknown reason");
        }
    }

    private static getFileNewName(name: string) {
        const index = name.lastIndexOf(".");
        if (index === -1) {
            return name + ".png";
        }

        return name.substring(0, index) + ".png";
    }
}

export class HeicConverterError extends Error {
}
