import {ServicesConfig} from "common/config";
import {AuthorizedHttpClient} from "common/net";
import {DumpStateRepository} from "common/repositories";
import {ItemLimitStore, SocketLifecycleStore} from "common/stores";
import {AsyncJob} from "common/stores/job";
import {mapPageable, Pageable} from "common/types";
import {debounce, waitDelay} from "common/utils";
import {action, makeAutoObservable, reaction} from "mobx";
import {ApplyTransitionsStore} from "../../components/transitions_popup";
import {GetTasksParam, mapToTask, Task} from "../../types";
import {TaskFiltersStore} from "./filters";
import {LocalTasksCacheStore} from "./LocalTasksCacheStore";
import {SavedFilterStore} from "./SavedFilterStore";
import {TaskDumperStore} from "./TaskDumperStore";

export const PAGE_LIMIT_KEY = "task_list";

const RECONNECT_ATTEMPTS = 5;

export class TaskListStore {
    readonly dumper: TaskDumperStore;
    private isFilterDebounceWait: boolean;
    private refreshListDisposer: (() => void) | null;
    private socketError: string | null;
    private readonly suspender: SocketLifecycleStore;
    private readonly loadJob: AsyncJob<typeof TaskListStore.prototype._load>;

    constructor(
        dumpRepo: DumpStateRepository,
        public page: number,
        readonly companyId: number | null,
        readonly savedFilter: SavedFilterStore,
        readonly transitions: ApplyTransitionsStore,
        readonly filters: TaskFiltersStore,
        private readonly limitStore: ItemLimitStore,
        private readonly httpClient: AuthorizedHttpClient,
        private readonly servicesConfig: ServicesConfig,
        private readonly taskListCache: LocalTasksCacheStore,
    ) {
        this.dumper = new TaskDumperStore({
            companyId,
            dumpRepo,
            provideTotalCount: () => this.totalTasks,
            providePageIds: () => this.tasks.map(task => task.id),
            provideSelectedFilters: () => this.filters.applied,
        });
        this.isFilterDebounceWait = this.taskListCache.getSnapshot(this.serializedFilters) === null;
        this.refreshListDisposer = null;
        this.socketError = null;
        this.suspender = new SocketLifecycleStore({
            startConnection: () => this.loadJob.start(0),
            isConnectionAlive: () => this.refreshListDisposer !== null,
            closeConnection: () => {
                this.unloadSocket();
                this.dumper.reset();
            },
        });

        makeAutoObservable(this, {}, {autoBind: true});
        this.loadJob = new AsyncJob({job: this._load});
    }

    get isLoading() {
        return this.isFilterDebounceWait;
    }

    get errorMessage() {
        return this.socketError;
    }

    get modalErrorMessage() {
        return this.savedFilter.modalError || this.dumper.base.modalError;
    }

    get limit() {
        return this.limitStore.value;
    }

    get tasks() {
        return this.taskListCache.getSnapshot(this.serializedFilters)?.tasks || [];
    }

    get maxPage() {
        return this.taskListCache.getSnapshot(this.serializedFilters)?.maxPage || 0;
    }

    get totalTasks() {
        return this.taskListCache.getSnapshot(this.serializedFilters)?.total || 0;
    }

    get serializedFilters(): GetTasksParam {
        return {
            page: this.page,
            limit: this.limit,
            company_id: this.companyId,
            order_by: this.filters.applied.order_by,
            filter_by: this.filters.applied,
        };
    }

    navigateTo(page: number) {
        this.page = page;
    }

    setLimit(value: number) {
        this.page = 0;
        this.limitStore.setLimit(value);
    }

    onMount() {
        this.savedFilter.onMount();
    }

    onUnmount() {
        this.savedFilter.onUnmount();
    }

    clearError() {
        this.savedFilter.clearModalError();
        this.dumper.clearError();
    }

    load() {
        this.suspender.load();
    }

    unload() {
        this.suspender.unload();
    }

    resetFilters() {
        this.savedFilter.reset();
        this.filters.resetAllImmediately();
    }

    private unloadSocket() {
        this.refreshListDisposer?.();
        this.refreshListDisposer = null;
        this.loadJob.stop();
    }

    private* _load(_: AbortSignal, attempts: number) {
        if (attempts > 0) {
            //Задержка при повторных попытках подключения к сокету
            yield waitDelay(attempts * 2000);
        }

        let _attempts = attempts;
        const token: string = yield this.httpClient.getToken();
        const socket = new WebSocket(`${this.servicesConfig.mainWs}web/get-tasks?token=${token}`);
        let sentRequests = 0;
        let receivedRequests = 0;
        let isExpectedSocketClose = false;
        socket.onmessage = action((event) => {
            receivedRequests++;
            if (receivedRequests >= sentRequests) {
                sentRequests = 0;
                receivedRequests = 0;
            } else {
                return;
            }

            this.isFilterDebounceWait = false;
            try {
                const response: Pageable<Task> = mapPageable(JSON.parse(event.data), mapToTask);
                this.taskListCache.setSnapshot(this.serializedFilters, {
                    maxPage: response.maxPage,
                    total: response.count || 0,
                    tasks: response.items
                });
                //attempts - количество попыток подключения подряд, при успешном подключении сбрасываем счетчик
                _attempts = 0;
            } catch (e) {
                console.error(e);
            }
        });

        socket.onopen = () => {
            let prevPage = this.page;
            const handleRefresh = debounce(action(() => {
                socket.send(JSON.stringify(this.serializedFilters));
                sentRequests++;
            }), 800);
            const disposeReaction = reaction(
                () => [this.serializedFilters],
                action(() => {
                    if (prevPage === this.page) {
                        this.page = 0;
                    } else {
                        prevPage = this.page;
                    }

                    this.isFilterDebounceWait = true;
                    handleRefresh();
                }),
            );
            this.refreshListDisposer = () => {
                isExpectedSocketClose = true;
                socket.close();
                disposeReaction();
                handleRefresh.cancel();
            };

            socket.send(JSON.stringify(this.serializedFilters));
            sentRequests = 1;
        }

        socket.onclose = action(e => {
            if (!isExpectedSocketClose) {
                if (attempts < RECONNECT_ATTEMPTS - 1) {
                    this.refreshListDisposer?.();
                    this.loadJob.start(_attempts + 1);
                } else {
                    this.socketError = `Socket closed with code: ${e.code}: ${e.reason || "no reason provided"}`;
                }
            }
        });
    }
}