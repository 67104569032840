import classNames from "classnames";
import {ButtonHTMLAttributes, MouseEventHandler, ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import s from "./Button.module.css";

export type ButtonColor = "Warning" | "Primary" | "Secondary" | "Gray" | "TransparentPrimary" | "TransparentWarning";
export type ButtonSize = "Default" | "Small";

type Props = {
    color: ButtonColor;
    size?: ButtonSize;
    heading?: ReactNode;
    link?: string;
    trailing?: ReactNode;
    children?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = (props: Props) => {
    const {
        className,
        heading,
        trailing,
        link,
        color = "Primary",
        size = "Default",
        children,
        disabled,
        onClick,
        onMouseDown,
        ...defaultProps
    } = props;
    const colorClass = s[`color${color}`];
    const sizeClass = s[`size${size}`];

    const navigate = useNavigate();
    const handleClick: MouseEventHandler<HTMLButtonElement> | undefined = link || onClick ? e => {
        if (disabled) {
            return;
        }

        if (link) {
            e.stopPropagation();
            navigate(link);
            return;
        }

        onClick?.(e);
    } : undefined;

    const handleMouseDown: MouseEventHandler<HTMLButtonElement> | undefined = link || onMouseDown ? e => {
        if (disabled) {
            return;
        }

        if (e.button === 1 && link) {
            e.preventDefault();
            e.stopPropagation();
            window.open(link, "_blank");
            return;
        }

        onMouseDown?.(e);
    } : undefined;

    return (
        <button
            className={classNames(s.button, colorClass, sizeClass, className)}
            onMouseDown={handleMouseDown}
            onClick={handleClick}
            disabled={disabled}
            {...defaultProps}>
            {heading}
            {children}
            {trailing}
        </button>
    );
};
