import classNames from "classnames";
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import Ripple from "../../ripple";
import Tooltip from "../../tooltip/Tooltip";
import s from "./Navigation.module.css";

type Props = {
    title: string;
    icon: ReactNode;
    path: string;
    isSelected: boolean;
};

export const NavigationItem = (props: Props) => {
    const {title, icon, path, isSelected} = props;
    return (
        <Tooltip title={title} position="right center" mouseEnterDelay={150}>
           <span>
               <Ripple className={s.navigationItemRipple}>
                   <Link className={classNames(
                       s.navigationItem,
                       {[s.navigationItemSelected]: isSelected},
                   )} to={path}>{icon}</Link>
               </Ripple>
           </span>
        </Tooltip>
    );
}