type DebounceFn<F extends (...args: any[]) => void> = ((...args: Parameters<F>) => void) & { cancel(): void };

export function debounce<F extends (...args: any[]) => void>(callee: F, timeoutMs: number = 350): DebounceFn<F> {
    let lastCall: number = -1;
    let lastCallTimer: number = -1;

    function perform<T>(...args: T[]) {
        let previousCall = lastCall;
        lastCall = Date.now();

        if (previousCall && lastCall - previousCall <= timeoutMs) {
            clearTimeout(lastCallTimer);
        }

        lastCallTimer = setTimeout(() => callee(...args), timeoutMs) as any as number;
    }

    perform.cancel = () => {
        clearTimeout(lastCallTimer);
        lastCall = -1;
        lastCallTimer = -1;
    };

    return perform;
}

export function debounceSleep(ms: number = 350) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// noinspection JSUnusedGlobalSymbols
export function delayedResolve<T>(func: () => T, timeout = 1000): Promise<T> {
    return new Promise<T>(resolve => {
        setTimeout(() => {
            resolve(func());
        }, timeout);
    });
}

// noinspection JSUnusedGlobalSymbols
export function delayedResolveWith<T>(value: T, timeout = 1000): Promise<T> {
    return new Promise<T>(resolve => {
        setTimeout(() => {
            resolve(value);
        }, timeout);
    });
}

// noinspection JSUnusedGlobalSymbols
export function waitDelay(delay: number): Promise<void> {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            resolve();
        }, delay);
    });
}