import {TokenExpirationError} from "common/net";
import {InvalidTokenError, jwtDecode} from "jwt-decode";

export interface AuthTokenResponse {
    access_token: {
        access_token: string;
    },
    refresh_token: {
        refresh_token: string;
    }
}

export interface AuthToken {
    accessToken: string;
    accessExpirationDate: Date;
    refreshToken: string;
    refreshExpirationDate: Date;
}

export function mapToAuthToken(response: AuthTokenResponse): AuthToken {
    try {
        const accessExpirationEpoch = jwtDecode(response.access_token.access_token).exp || 0;
        const refreshExpirationEpoch = jwtDecode(response.refresh_token.refresh_token).exp || 0;
        return {
            accessExpirationDate: new Date(accessExpirationEpoch * 1000),
            refreshExpirationDate: new Date(refreshExpirationEpoch * 1000),
            accessToken: response.access_token.access_token,
            refreshToken: response.refresh_token.refresh_token,
        };
    } catch (e) {
        if (e instanceof InvalidTokenError) {
            throw new TokenExpirationError();
        }

        throw e;
    }
}
