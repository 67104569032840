import {AsyncJob} from "common/stores/job";
import {makeAutoObservable, reaction} from "mobx";
import {SaveFilterStore} from "../../modals/save_filter";
import {SavedTaskFilterRepository} from "../../repositories";
import {SavedTaskFilter} from "../../types/SavedTaskFilter";
import {TaskFiltersStore} from "./filters";

interface SelectedFilterUiState {
    id: number;
    name: string;
    isDirty: boolean;
}

export class SavedFilterStore {
    selected: SelectedFilterUiState | null;
    possibleFilters: SavedTaskFilter[];
    serialized: string | null;

    private readonly fetchFiltersJob: AsyncJob<typeof SavedFilterStore.prototype._fetchFilters>;
    private readonly updateJob: AsyncJob<typeof SavedFilterStore.prototype._updateFilter>;
    private disposeChangeObserver: (() => void) | null;

    constructor(
        private companyId: number,
        readonly saveModal: SaveFilterStore,
        private readonly serializedValue: string,
        private readonly filterRepo: SavedTaskFilterRepository,
        private readonly filters: TaskFiltersStore,
    ) {
        this.selected = null;
        this.possibleFilters = [];
        this.disposeChangeObserver = null;
        this.serialized = null;
        makeAutoObservable(this, {}, {autoBind: true});

        this.saveModal.setSaveListener(this.selectFilter);
        this.fetchFiltersJob = new AsyncJob({job: this._fetchFilters});
        this.updateJob = new AsyncJob({job: this._updateFilter});
    }

    get isLoadingFilters() {
        return this.fetchFiltersJob.isCreated || this.fetchFiltersJob.isPending;
    }

    get modalError() {
        return this.updateJob.errorMessage;
    }

    get fetchingErrorMessage() {
        return this.fetchFiltersJob.errorMessage;
    }

    get canSave() {
        if (this.selected) {
            return this.selected.isDirty;
        }

        return this.filters.hasActive;
    }

    refreshSerialized() {
        this.serialized = this.selected ? JSON.stringify(this.selected) : null;
    }

    clearModalError() {
        this.updateJob.clearError();
    }

    fetchFilters() {
        this.possibleFilters = [];
        this.fetchFiltersJob.start();
    }

    selectFilter(savedFilter: SavedTaskFilter) {
        if (savedFilter.id === -1) {
            this.reset();
            this.filters.resetAll();
            return;
        }

        if (this.selected?.id === savedFilter.id) {
            return;
        }

        this.selected = {
            id: savedFilter.id,
            name: savedFilter.name,
            isDirty: false,
        };
        this.filters.deserialize(savedFilter.filters);
        this.startChangeObserver();
    }

    save() {
        if (this.selected) {
            this.updateJob.start();
        } else {
            this.saveModal.show(this.companyId, this.filters.realtimeFilters.all);
        }
    }

    reset() {
        this.selected = null;
        this.disposeChangeObserver?.();
    }

    notifyFilterRenamed(filters: { id: number, name: string }[]) {
        if (!this.selected) {
            return;
        }

        const found = filters.find(item => item.id === this.selected?.id);
        if (found) {
            this.selected.name = found.name;
        }
    }

    notifyFilterDeleted(deleted: number[]) {
        if (!this.selected) {
            return;
        }

        const isDeleted = deleted.some(id => id === this.selected?.id);
        if (isDeleted) {
            this.reset();
        }
    }

    onMount() {
        this.deserialize(this.serializedValue);
    }

    onUnmount() {
        this.fetchFiltersJob.stop();
        this.disposeChangeObserver?.();
    }

    private startChangeObserver() {
        this.disposeChangeObserver?.();
        this.disposeChangeObserver = reaction(
            () => [this.filters.realtimeFilters.all],
            () => {
                if (this.selected) {
                    this.selected.isDirty = true;
                }
            },
        );
    }

    private deserialize(serialized: string) {
        if (!serialized) {
            return;
        }

        try {
            const state = JSON.parse(serialized);
            if (typeof state === "object" && typeof state.id === "number" && typeof state.name === "string") {
                this.selected = state;
                this.startChangeObserver();
            }
            this.refreshSerialized();
        } catch (e) {
            console.error("Unable to deserialize saved filter", e);
            this.reset();
        }
    }

    private* _fetchFilters() {
        const filters: SavedTaskFilter[] = yield this.filterRepo.findAll(this.companyId);
        if (filters.length) {
            this.possibleFilters = [
                {
                    id: -1,
                    name: "Макет не выбран",
                    filters: [],
                },
                ...filters,
            ];
        } else {
            this.possibleFilters = [];
        }
    }

    private* _updateFilter() {
        const selected = this.selected;
        if (selected === null) throw new Error("Illegal state: selected is null");

        yield this.filterRepo.updateAll([
            {
                id: selected.id,
                name: selected.name,
                filters: this.filters.realtimeFilters.all,
            }
        ]);
        selected.isDirty = false;
    }
}
