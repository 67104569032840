import {useRepositories} from "common/components/app/RepositoriesStore";
import {ErrorModal} from "common/components/modal/error";
import {observer} from "mobx-react-lite";
import {useMemo} from "react";
import {useAttachmentUploaderStoreMemo} from "../../stores/attachment_uploader";
import {BasePendingCommentStore} from "./BasePendingCommentStore";
import {MessageBox} from "./MessageBox";
import {PendingCommentStore} from "./PendingCommentStore";

type Props = {
    store: BasePendingCommentStore | PendingCommentStore;
    placeholder?: string;
    canKeyboardSend?: boolean;
    isShowSend?: boolean;
};

export function useBasePendingCommentStoreMemo() {
    const repos = useRepositories();
    const uploaderStore = useAttachmentUploaderStoreMemo();
    return useMemo(() => new BasePendingCommentStore(
        repos.taskDocumentTypeListStore,
        uploaderStore,
    ), [repos, uploaderStore]);
}

export function usePendingCommentStoreMemo() {
    const repos = useRepositories();
    const baseCommentStore = useBasePendingCommentStoreMemo();
    return useMemo(() => new PendingCommentStore(
        repos.taskEvent,
        baseCommentStore,
    ), [repos, baseCommentStore]);
}

export const CommentMessageBox = observer((props: Props) => {
    const {store, placeholder, isShowSend = true, canKeyboardSend = true} = props;

    return <>
        <MessageBox
            message={store.message}
            placeholder={placeholder}
            types={store.typeList.types}
            attachments={store.attachments}
            typesError={store.typeList.errorMessage}
            canSend={store.canSend}
            isDisplaySend={isShowSend}
            isTypesLoading={store.typeList.isLoading}
            setMessage={store.setMessage}
            onDetach={store.removeDocument}
            onAttach={store.attachFiles}
            onLoadTypes={store.typeList.loadIfNeed}
            onSend={isShowSend || canKeyboardSend ? store.send : undefined}/>
        <ErrorModal error={store.errorMessage} dismiss={store.dismissError}/>
    </>;
});
