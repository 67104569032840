import {observer} from "mobx-react-lite";
import React from "react";
import {TriState} from "../checkbox";
import {SelectableSuggestion, SelectableSuggestionSkeleton} from "./SelectableSuggestion";
import {IMenuItemUiState} from "./state";
import SuggestionListBase from "./SuggestionListBase";
import SuggestionsPopupContentBase, {PopupConnectedSide} from "./SuggestionsPopupContentBase";

type Props<T> = {
    width?: number;
    height?: number;
    search?: string;
    maxWidth?: number;
    maxHeight?: number;
    items: IMenuItemUiState<T>[];
    selected?: IMenuItemUiState<T>[] | null;
    trailingAction?: JSX.Element;
    isItemSelected?: (item: IMenuItemUiState<T>) => boolean;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    isFilterable?: boolean;
    isResizable?: boolean;
    error?: string;
    canExclude?: boolean;
    isExcludeMode?: boolean;
    selectionState?: TriState;
    onExcludeToggle?: () => void;
    isInvertSelection?: boolean;
    connectedSide?: PopupConnectedSide;
    onFetchMore?: () => void;
    setSearch?: (value: string) => void;

    render?(item: IMenuItemUiState<T>, isSelected: boolean, onToggle: (isSelected: boolean) => void): JSX.Element | null;

    retryLoad?(): void;

    onToggle(item: IMenuItemUiState<T>): void;
};

export const MultiSuggestionsPopupBase = observer(<T extends any>(props: Props<T>) => {
    const {
        items,
        search,
        selected = null,
        render,
        width,
        height,
        maxWidth,
        maxHeight,
        trailingAction,
        isItemSelected,
        isLoading,
        isMoreLoading,
        error,
        connectedSide,
        canExclude,
        isExcludeMode,
        selectionState,
        onExcludeToggle,
        isFilterable,
        isResizable,
        isInvertSelection = false,
        retryLoad,
        onToggle,
        onFetchMore,
        setSearch,
    } = props;

    return (
        <SuggestionsPopupContentBase
            search={search}
            items={items}
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            trailingActions={trailingAction}
            connectedSide={connectedSide}
            isFilterable={isFilterable}
            isResizable={isResizable}
            error={error}
            canExclude={canExclude}
            selectionState={selectionState}
            onExcludeToggle={onExcludeToggle}
            retryLoad={retryLoad}
            setSearch={setSearch}
        >
            {suggestions => (
                <SuggestionListBase
                    items={suggestions}
                    selected={selected}
                    Skeleton={SelectableSuggestionSkeleton}
                    isLoading={isLoading}
                    isMoreLoading={isMoreLoading}
                    render={item => {
                        const key = item.key;

                        let isSelected: boolean;
                        if (isItemSelected) {
                            isSelected = isItemSelected(item);
                        } else if (isExcludeMode) {
                            isSelected = selected?.findIndex(it => it.key === key) === -1 || false;
                        } else {
                            isSelected = selected?.findIndex(it => it.key === key) !== -1 || false;
                        }
                        const onToggleWrapped = () => onToggle(item);

                        if (render) {
                            const rendered = render(item, isSelected, onToggleWrapped);
                            if (rendered) {
                                return rendered;
                            }
                        }

                        return (
                            <SelectableSuggestion
                                key={key}
                                isSelected={isSelected}
                                isInvert={isInvertSelection}
                                onToggle={onToggleWrapped}>
                                {item.label.toString()}
                            </SelectableSuggestion>
                        )
                    }}
                    onFetchMore={onFetchMore}
                />
            )}
        </SuggestionsPopupContentBase>
    );
});