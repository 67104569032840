export function getNounByNumber(number: number, one: string, two: string, five: string) {
    number = Math.abs(number);
    number %= 100;
    if (number >= 5 && number <= 20) {
        return five;
    }

    number %= 10;
    if (number === 1) {
        return one;
    }

    if (number >= 2 && number <= 4) {
        return two;
    }

    return five;
}

export function getNameWithoutExt(fileName: string) {
    const index = fileName.lastIndexOf(".");
    return index === -1 ? fileName : fileName.substring(0, index);
}

export function getFileExt(fileName: string) {
    const index = fileName.lastIndexOf(".");
    return index === -1 ? "" : fileName.substring(index + 1);
}
