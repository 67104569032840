import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    FindAllAccessParams,
    HouseAccess,
    HouseAccessCreationParams,
    HouseAccessResponse,
    mapToHouseAccess
} from "../types";

export class HouseAccessRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllAccessParams): Promise<Pageable<HouseAccess>> {
        const response = await this.client.get<PageableResponse<HouseAccessResponse>>(`v2/houses/${params.houseId}/accesses`, {
            params: {
                search: params.search,
                limit: params.limit,
                page: params.page,
                company_id: params.companyId,
            },
        });

        return mapPageable(response, mapToHouseAccess);
    }

    createAllByHouseId(houseId: number, accesses: HouseAccessCreationParams[]): Promise<void> {
        return this.client.put(`v1/houses/${houseId}/accesses`, {
            body: accesses.map(access => ({
                user_id: access.userId,
                systems: access.systems,
            })),
        });
    }

    deleteById(houseId: number, userId: number): Promise<void> {
        return this.client.delete(`v1/houses/${houseId}/accesses/${userId}`);
    }
}
