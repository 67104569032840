import {ReactComponent as AssistantErrorSvg} from "common/assets/assistant_error.svg";
import {Button} from "common/components/button";
import {P} from "common/components/typography";
import {UserStore, UserStoreContext} from "common/stores";
import {observer} from "mobx-react-lite";
import {useEffect, useMemo} from "react";
import {LocalTasksCacheStore, LocalTasksCacheStoreContext} from "tasks/stores/task_list";
import s from "./index.module.css";
import {useRepositories} from "./RepositoriesStore";

interface IProps {
    children: JSX.Element;
}

function useStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new UserStore(
        repos.session,
        repos.users,
        repos.auth,
        repos.companies,
        repos.deviceRepo,
        repos.servicesConfig.landing,
    ), [repos]);
}

function GlobalLoadingScreen(props: IProps) {
    const {children} = props;
    const store = useStoreMemo();
    const taskCacheStore = useMemo(() => new LocalTasksCacheStore(), []);
    const {
        servicesConfig,
        taskPermissions,
        commonPermissions,
        companyPermissions,
    } = useRepositories();

    useEffect(() => {
        taskPermissions.setUserStore(store);
        commonPermissions.setUserStore(store);
        companyPermissions.setUserStore(store);
        store.login();
        return () => store.dismissLogin();
    }, [store, taskPermissions, commonPermissions, companyPermissions]);

    if (!store.isLoggedIn || store.error || store.isLoading) {
        return (
            <div className={s.loadingContainer}>
                {store.error
                    ? <>
                        <AssistantErrorSvg/>
                        <P>Ошибка авторизации</P>
                        <P>{store.error}</P>
                        <Button color="Primary" onClick={() => window.location.href = servicesConfig.landing}>
                            Вернуться на главную
                        </Button>
                    </>
                    : <P>Загрузка...</P>
                }
            </div>
        );
    }

    return (
        <LocalTasksCacheStoreContext.Provider value={taskCacheStore}>
            <UserStoreContext.Provider value={store}>
                {children}
            </UserStoreContext.Provider>
        </LocalTasksCacheStoreContext.Provider>
    );
}

export default observer(GlobalLoadingScreen);
