import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {TriState} from "../checkbox";
import {Popup} from "../popup";
import {PopupSizeContextProvider} from "./context";
import {MultiSuggestionsPopupBase} from "./MultiSuggestionPopupBase";
import {IMenuItemUiState} from "./state";
import {useEscapeBlock} from "./useEscapeBlock";

interface IProps<T> {
    width?: number;
    height?: number;
    search?: string;
    maxWidth?: number;
    maxHeight?: number;
    items: IMenuItemUiState<T>[];
    selected?: IMenuItemUiState<T>[];
    trailingAction?: JSX.Element;
    isItemSelected?: (item: IMenuItemUiState<T>) => boolean;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    isDisabled?: boolean;
    isFilterable?: boolean;
    isResizable?: boolean;
    isKeepTooltipInside?: boolean;
    error?: string;
    canExclude?: boolean;
    isExcludeMode?: boolean;
    selectionState?: TriState;
    onExcludeToggle?: () => void;
    isInvertSelection?: boolean;
    onFetchMore?: () => void;
    setSearch?: (value: string) => void;

    render?(item: IMenuItemUiState<T>, isSelected: boolean, onToggle: (isSelected: boolean) => void): JSX.Element | null;

    children(isOpened: boolean): JSX.Element;

    retryLoad?(): void;

    onToggle(item: IMenuItemUiState<T>): void;

    onOpen?(): void;

    onClose?(): void;
}

function MultiSuggestionsPopup<T>(props: IProps<T>) {
    const {
        items,
        search,
        selected = null,
        render,
        width,
        height,
        maxWidth,
        maxHeight,
        trailingAction,
        isItemSelected,
        isLoading,
        isMoreLoading,
        error,
        canExclude,
        isExcludeMode,
        selectionState,
        onExcludeToggle,
        isFilterable,
        isResizable,
        isInvertSelection = false,
        isKeepTooltipInside,
        children,
        retryLoad,
        onToggle,
        onOpen,
        onClose,
        onFetchMore,
        setSearch,
    } = props;
    const [isOpened, setOpened] = useState(false);
    useEscapeBlock(isOpened);

    function handleOpen() {
        setOpened(true);
        onOpen?.();
    }

    function handleClose() {
        setOpened(false);
        onClose?.();
    }

    return (
        <PopupSizeContextProvider>
            <Popup
                isOpened={isOpened}
                trigger={children}
                keepTooltipInside={isKeepTooltipInside}
                onOpen={handleOpen}
                onClose={handleClose}>
                <MultiSuggestionsPopupBase
                    width={width}
                    height={height}
                    search={search}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    items={items}
                    selected={selected}
                    trailingAction={trailingAction}
                    isItemSelected={isItemSelected}
                    isLoading={isLoading}
                    isMoreLoading={isMoreLoading}
                    isFilterable={isFilterable}
                    isResizable={isResizable}
                    error={error}
                    canExclude={canExclude}
                    isExcludeMode={isExcludeMode}
                    selectionState={selectionState}
                    onExcludeToggle={onExcludeToggle}
                    isInvertSelection={isInvertSelection}
                    onFetchMore={onFetchMore}
                    setSearch={setSearch}
                    render={render}
                    retryLoad={retryLoad}
                    onToggle={onToggle}/>
            </Popup>
        </PopupSizeContextProvider>
    );
}

export default observer(MultiSuggestionsPopup);
