import {action, computed, makeObservable, observable} from "mobx";
import {
    deserializeMenuItem,
    IMenuItemUiState,
    MenuItemSerialized,
    serializeMenuItem,
    TMenuItemKey
} from "../../components/suggestions_popup/state";
import {TAsyncFilterOptions} from "./TFilterOptions";
import {ValuesEntryFilter} from "./ValuesEntryFilter";

export class SingleEntryFilter<T, Q> extends ValuesEntryFilter<T, MenuItemSerialized<T> | null, Q, IMenuItemUiState<T> | null> {
    selected: IMenuItemUiState<T> | null;
    selectedKey: TMenuItemKey | null;
    order: number | null;
    changeListener: (() => void) | null;

    constructor(options: TAsyncFilterOptions<T, Q, any>) {
        super(options);
        this.selected = null;
        this.selectedKey = null;
        this.order = options.order ?? null;
        this.changeListener = null;

        makeObservable(this, {
            selected: observable,
            isUsing: computed,
            reset: action.bound,
            select: action.bound,
            deserialize: true,
            tooltip: true,
        });
    }

    get isUsing() {
        return this.selected != null;
    }

    get query() {
        if (this.selected === null) {
            return null;
        }

        return this.options.apply(this.selected.key, false);
    }

    get tooltip() {
        return this.selected ? this.selected.label : null;
    }

    setChangeListener(listener: () => void) {
        this.changeListener = listener;
    }

    setSelected(value: IMenuItemUiState<T> | null) {
        this.selected = value;
    }

    serialize() {
        return this.selected ? serializeMenuItem(this.selected) : null;
    }

    deserialize(value: MenuItemSerialized<T> | null) {
        this.selected = value ? deserializeMenuItem(value) : null;
    }

    reset() {
        this.selected = null;
    }

    select(item: IMenuItemUiState<T>) {
        this.selected = item.value !== null ? item : null;
        this.changeListener?.();
    }
}
