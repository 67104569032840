import {CitizenAccessType} from "citizens/types/CitizenAccess";

export function getCitizenTypeName(type: CitizenAccessType): string {
    switch (type) {
        case "owner":
            return "Владелец";
        case "citizen":
            return "Житель";
        case "guest":
            return "Гость";
    }
}

export interface Citizen {
    id: string;
    accessId: string;
    firstName: string;
    middleName: string;
    secondName: string;
    address: string;
    apartment: string;
    entrance: string;
    tariffId: string | null;
    tariffName: string;
    balance: number;
    type: CitizenAccessType | null;
    phone: string;
    email: string;
    avatarUrl: string | null;
    createdAt: Date | null;
    fullName: string;
    bonuses: number;
    accessTo: Date | null;
    status: "active" | "blocked" | "finance-blocked";
}

export interface CitizenUpdateParams {
    firstName?: string;
    middleName?: string;
    secondName?: string;
    phone?: string;
    email?: string;
}

export interface CitizenCreateParams {
    firstName: string;
    middleName: string;
    secondName: string;
    houseId: string;
    entrance: string;
    apartment: string;
    phone: string;
    email: string;
    is_commercial: boolean;
    type: CitizenAccessType;
    access_to: Date | null;
}

export interface CitizenResponse {
    id: string;
    access_id: string;
    first_name: string;
    middle_name: string;
    second_name: string;
    address: string | null;
    apartment: string | null;
    entrance: string;
    tariff_id: string | null;
    tariff_name: string;
    balance: number;
    type: CitizenAccessType;
    phone: string;
    email: string;
    avatar_url: string | null;
    status: "active" | "blocked";
    access_to: string | null;
    bonuses: number;
    created_at?: number;
}

export interface BriefCitizenResponse {
    id: string;
    first_name: string;
    middle_name: string;
    second_name: string;
    avatar_url?: string;
}

export type FindAllCitizenParams = {
    limit: number;
    page: number;
    houses?: string[];
    role?: "citizen" | "owner" | "guest";
    status?: "active" | "blocked" | "finance-blocked";
    search: string;
    users?: string[];
};

export type FindAllBriefCitizenParams = {
    limit: number;
    page: number;
    search?: string;
}

export function mapToCitizen(citizen: CitizenResponse): Citizen {
    return {
        id: citizen.id,
        accessId: citizen.access_id || "",
        fullName: `${citizen.second_name} ${citizen.first_name} ${citizen.middle_name}`.trim(),
        firstName: citizen.first_name,
        secondName: citizen.second_name,
        middleName: citizen.middle_name,
        phone: citizen.phone,
        status: citizen.status,
        type: citizen.type,
        balance: citizen.balance || 0,
        bonuses: citizen.bonuses || 0,
        tariffId: citizen.tariff_id || null,
        tariffName: citizen.tariff_name || "",
        entrance: citizen.entrance,
        email: citizen.email,
        apartment: citizen.apartment || "",
        address: citizen.address || "",
        createdAt: citizen.created_at ? new Date(citizen.created_at * 1000) : null,
        avatarUrl: citizen.avatar_url || null,
        accessTo: citizen.access_to ? new Date(citizen.access_to) : null,
    };
}

export function mapBriefToCitizen(citizen: BriefCitizenResponse): Citizen {
    return {
        id: citizen.id,
        accessId: "",
        fullName: `${citizen.second_name} ${citizen.first_name} ${citizen.middle_name}`.trim(),
        firstName: citizen.first_name,
        secondName: citizen.second_name,
        middleName: citizen.middle_name,
        phone: "",
        status: "active",
        type: "guest",
        balance: 0,
        bonuses: 0,
        tariffId: null,
        tariffName: "",
        entrance: "",
        email: "",
        apartment: "",
        address: "",
        createdAt: null,
        avatarUrl: citizen.avatar_url || null,
        accessTo: null,
    };
}
