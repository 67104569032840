import {AsyncJob} from "common/stores/job";
import {SearchField} from "common/types";
import {makeAutoObservable} from "mobx";

export class SearchFieldsStore {
    all: SearchField[];
    internalSelectedKeys: number[];
    selectedKeys: number[]; //Пустой массив - выбраны все поля
    private isLoaded: boolean;
    private readonly fetchJob: AsyncJob<typeof SearchFieldsStore.prototype._fetch>;

    constructor(private readonly fetchFields: () => Promise<SearchField[]>) {
        this.all = [];
        this.internalSelectedKeys = [];
        this.selectedKeys = [];
        this.isLoaded = false;
        makeAutoObservable(this, {}, {autoBind: true});

        this.fetchJob = new AsyncJob({job: this._fetch});
    }

    get isLoading() {
        return this.fetchJob.isCreated || this.fetchJob.isPending;
    }

    get errorMessage() {
        return this.fetchJob.errorMessage;
    }

    get serialized() {
        if (this.selectedKeys.length === 0) {
            return null;
        }

        return this.selectedKeys.join(",") || null;
    }

    setSelectedKeys(keys: number[]) {
        this.internalSelectedKeys = keys;
    }

    setSelectedKeysImmediate(keys: number[]) {
        this.internalSelectedKeys = keys;
        this.selectedKeys = keys;
    }

    applySelected() {
        this.selectedKeys = this.internalSelectedKeys;
    }

    deserialize(serialized: any) {
        if (typeof serialized !== "string") {
            return;
        }

        this.selectedKeys = serialized.split(",").map(Number).filter(Boolean);
        this.internalSelectedKeys = this.selectedKeys;
    }

    fetchIfNeed() {
        if (!this.isLoaded) {
            this.fetchJob.start();
        }
    }

    reset() {
        this.internalSelectedKeys = [];
        this.selectedKeys = [];
    }

    private* _fetch() {
        this.all = yield this.fetchFields();
        this.isLoaded = true;
    }
}
