import {AuthorizedHttpClient} from "common/net";
import {
    CitizenTariff,
    CitizenTariffResponse,
    CreateCitizenTariffBody,
    mapToTariff,
    UpdateCitizenTariffBody
} from "../types";

export class CitizenTariffRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(signal: AbortSignal): Promise<CitizenTariff[]> {
        const response = await this.client.get<CitizenTariffResponse[]>(
            "tariff",
            {signal}
        );
        return response.map(mapToTariff);
    }

    async create(body: CreateCitizenTariffBody, signal: AbortSignal): Promise<void> {
        return this.client.post(
            "tariff",
            {
                body: {
                    name: body.name,
                    price: body.price,
                    description: body.description,
                },
                signal,
            }
        );
    }

    async update(body: UpdateCitizenTariffBody, signal: AbortSignal): Promise<void> {
        return this.client.put(
            `tariff/${body.id}`,
            {
                body: {
                    name: body.name,
                    price: body.price,
                    description: body.description,
                },
                signal,
            }
        );
    }

    async updateDefault(tariffId: string, signal: AbortSignal): Promise<void> {
        return this.client.post(
            "tariff/update_tariff",
            {
                body: {tariff_id: tariffId},
                signal,
            }
        );
    }

    deleteById(id: string): Promise<void> {
        return this.client.delete(`tariff/${id}`);
    }
}
