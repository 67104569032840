import createSvgIcon from "./utils";

const IconSmallDocument16x16 = createSvgIcon((
    <g stroke="transparent" style={{maskType: "alpha"}}>
        <path
            d="M10 15.167H6C2.38 15.167.833 13.62.833 10V6C.833 2.38 2.38.833 6 .833h3.333c.273 0 .5.227.5.5 0 .274-.227.5-.5.5H6C2.926 1.833 1.833 2.928 1.833 6v4c0 3.073 1.093 4.167 4.167 4.167h4c3.073 0 4.166-1.094 4.166-4.167V6.667c0-.273.227-.5.5-.5.274 0 .5.227.5.5V10c0 3.62-1.546 5.167-5.166 5.167Z"/>
        <path
            d="M14.666 7.167H12C9.72 7.167 8.833 6.28 8.833 4V1.333c0-.2.12-.386.307-.46.186-.08.4-.033.546.107l5.334 5.333a.502.502 0 0 1-.354.854ZM9.833 2.54V4c0 1.72.447 2.167 2.167 2.167h1.46L9.833 2.54ZM8.667 9.166h-4a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h4c.273 0 .5.227.5.5 0 .274-.227.5-.5.5ZM7.334 11.834H4.667a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h2.667c.273 0 .5.226.5.5 0 .273-.227.5-.5.5Z"/>
    </g>
), {width: 16, height: 16});

export default IconSmallDocument16x16;
