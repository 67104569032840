import {InputBoxBulletBase, InputBoxBulletProps} from "./InputBoxBulletBase";
import {InputBoxItemBulletState} from "./types";

type Props<T> = {
    state: InputBoxItemBulletState<T>;
} & Partial<InputBoxBulletProps>;

export const InputBoxBullet = <T extends unknown>(props: Props<T>) => {
    const {
        state,
        ...defaultProps
    } = props;

    return (
        <InputBoxBulletBase
            label={state.item.label}
            onDeselect={state.deselectItem}
            {...state.innerProps}
            {...defaultProps}/>
    )
};
