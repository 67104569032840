const ADDRESS_FORMAT_REGEXP = /(.+),[ ]?((((ул|улица) .+)|(.+ (район|бульвар|б-р|проспект|пр-кт|шоссе))).+)/i;

export function formatAddress(address: string) {
    const matchResult = ADDRESS_FORMAT_REGEXP.exec(address);
    if (!matchResult) {
        return address;
    }

    return `${matchResult[1]}\n${matchResult[2]}`;
}

export function formatName(name: string) {
    const parts = name.replaceAll(/\s+/g, " ").trim().split(" ", 3);
    if (parts.length === 2) {
        return `${parts[0]} ${parts[1][0].toUpperCase()}.`;
    }

    if (parts.length === 3) {
        return `${parts[0]} ${parts[1][0].toUpperCase()}. ${parts[2][0].toUpperCase()}.`;
    }

    return name;
}
