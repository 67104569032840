import createSvgIcon from "./utils";

const IconBiArrows24x24 = createSvgIcon((
    <path
        fill="transparent"
        strokeWidth="2"
        d="M7 6.5v11m0 0L2.5 13M7 17.5l4.5-4.5M17 17.5v-11m0 0 4.5 4.5M17 6.5 12.5 11"/>

), {width: 24, height: 24});

export default IconBiArrows24x24;
