import {observer} from "mobx-react-lite";
import {forwardRef, Ref, useState} from "react";
import {PopupActions, PopupPosition} from "reactjs-popup/dist/types";
import {Popup} from "../popup";
import {PopupSizeContextProvider} from "./context";
import {IMenuItemUiState} from "./state";
import SuggestionsPopupBase from "./SuggestionsPopupBase";
import {useEscapeBlock} from "./useEscapeBlock";

interface IProps<T, S extends IMenuItemUiState<any> | null> {
    items: IMenuItemUiState<T>[];
    selected?: S;
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    showIndicator?: boolean;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    isFilterable?: boolean;
    error?: string;
    search?: string;
    isResizable?: boolean;
    keepTooltipInside?: boolean;
    position?: PopupPosition[];
    onFetchMore?: () => void;
    setSearch?: (value: string) => void;

    render?(item: IMenuItemUiState<T>, onSelect: () => void): JSX.Element | null;

    children(isOpened: boolean): JSX.Element;

    retryLoad?(): void;

    onSelect(item: IMenuItemUiState<T>): void;

    onOpen?(): void;

    onClose?(): void;
}

function SuggestionsPopup<T, S extends IMenuItemUiState<any> | null>(props: IProps<T, S>, ref: Ref<PopupActions>) {
    const {
        items,
        selected,
        width,
        search,
        height,
        maxWidth,
        maxHeight,
        error,
        position,
        render,
        retryLoad,
        keepTooltipInside = true,
        showIndicator,
        isLoading,
        isMoreLoading,
        isFilterable,
        isResizable,
        children,
        onSelect,
        onOpen,
        onClose,
        onFetchMore,
        setSearch,
    } = props;
    const [isOpened, setOpened] = useState(false);
    useEscapeBlock(isOpened);

    function handleOpen() {
        setOpened(true);
        onOpen?.();
    }

    function handleClose() {
        setOpened(false);
        onClose?.();
    }

    function handleSelect(item: IMenuItemUiState<T>) {
        setOpened(false);
        onSelect(item);
        onClose?.();
    }

    return (
        <PopupSizeContextProvider>
            <Popup
                ref={ref}
                isOpened={isOpened}
                keepTooltipInside={keepTooltipInside}
                position={keepTooltipInside ? position || ["bottom center", "bottom right", "bottom left"] : undefined}
                trigger={children}
                onOpen={handleOpen}
                onClose={handleClose}>
                <SuggestionsPopupBase
                    items={items}
                    selected={selected || null}
                    width={width}
                    height={height}
                    search={search}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    render={render}
                    showIndicator={showIndicator}
                    isLoading={isLoading}
                    isMoreLoading={isMoreLoading}
                    isFilterable={isFilterable}
                    error={error}
                    retryLoad={retryLoad}
                    isResizable={isResizable}
                    onFetchMore={onFetchMore}
                    onSelect={handleSelect}
                    setSearch={setSearch}/>
            </Popup>
        </PopupSizeContextProvider>
    );
}

export default observer(forwardRef(SuggestionsPopup));
