import React, {useEffect, useRef} from "react";

function useClickOutside(callback: () => void, elementRef: React.RefObject<HTMLElement>) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
        const eventListener = (e: MouseEvent) => {
            const element = elementRef.current;
            if (element && !element.contains(e.target as Node)) {
                callbackRef.current();
            }
        };
        document.addEventListener("click", eventListener);

        return () => document.removeEventListener("click", eventListener);
    }, [elementRef]);

}

export default useClickOutside;