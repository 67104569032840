import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    CreateTaskDocumentParams,
    FindAllTaskDocumentsParams,
    mapDocumentFiltersToRequest,
    mapToTaskDocument,
    TaskDocument,
    TaskDocumentResponse,
    UpdateTaskDocumentParam
} from "../types";

export class TaskDocumentRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async create(params: CreateTaskDocumentParams): Promise<void> {
        return await this.client.post("web/v1/tasks/documents", {
            body: {
                company_id: params.companyId || undefined,
                task_num: params.taskNumber,
                attachments: params.attachments,
            }
        })
    }

    async findAll(params: FindAllTaskDocumentsParams): Promise<Pageable<TaskDocument>> {
        const response = await this.client.get<PageableResponse<TaskDocumentResponse>>("web/v1/tasks/documents", {
            params: {
                page: params.page,
                limit: params.limit,
                ...mapDocumentFiltersToRequest(params.filter),
            },
        });

        return mapPageable(response, mapToTaskDocument);
    }

    async update(params: UpdateTaskDocumentParam): Promise<void> {
        return this.client.patch(`web/v1/tasks/documents/${params.id}`, {
            body: {
                name: params.name,
                type: params.type,
                is_processed: params.isProcessed,
            },
        });
    }

    async delete(id: number): Promise<void> {
        return this.client.delete(`web/v1/tasks/documents/${id}`);
    }
}
