import * as Yup from "yup";

export const NO_SPACES_REGEXP = /^[^ ]*$/i;
export const PHONE_REGEXP = /^[+]?[0-9]+ [(][0-9]{3}[)] [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
export const URL_REGEXP = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export function formatToPhone(phone: string): string {
    if (phone.length === 12 && phone[0] === "+") {
        phone = phone.substring(1);
    }

    if (phone.length !== 11) {
        return phone;
    }

    const code = phone.substring(1, 4);
    const triple = phone.substring(4, 7);
    const duo1 = phone.substring(7, 9);
    const duo2 = phone.substring(9, 11);
    return `+${phone[0]} (${code}) ${triple}-${duo1}-${duo2}`;
}

export function escapeRegExp(str: string) {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const PASSWORD_REGEXP = /^$|(^(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\d.*)[ -~]{8,35}$)/;

export function matchOptionalPassword() {
    return Yup.string().matches(
        PASSWORD_REGEXP,
        "От 8 до 35 символов, одна прописная и строчная буква латинского алфавита, одна цифра. Разрешено использование спецсимволов",
    );
}

export function matchPasswordConfirm(passwordField: string = "password") {
    return Yup.string().when(passwordField, {
        is: (value: string) => value,
        then: Yup.string().oneOf([Yup.ref(passwordField)], "Пароли не совпадают").required(),
    });
}

export function isValidUrl(value: string) {
    return URL_REGEXP.test(value);
}
