import createSvgIcon from "./utils";

const IconPaperClip16x16 = createSvgIcon((
    <path
        stroke="transparent"
        d="M7.313 15.667c-2.48 0-4.5-2.02-4.5-4.5v-4A5.175 5.175 0 0 1 7.98 2a5.175 5.175 0 0 1 5.167 5.167v3.666a2.84 2.84 0 0 1-2.834 2.834 2.84 2.84 0 0 1-2.833-2.834V8.5c0-.273.227-.5.5-.5s.5.227.5.5v2.333a1.832 1.832 0 1 0 3.667 0V7.167C12.147 4.867 10.28 3 7.98 3a4.168 4.168 0 0 0-4.167 4.167v4c0 1.926 1.567 3.5 3.5 3.5a.5.5 0 1 1 0 1Z"/>

), {width: 16, height: 17});

export default IconPaperClip16x16;
