import createSvgIcon from "./utils";

const IconEye = createSvgIcon((
    <>
        <g fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
           strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
           fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
            <path d="M0,172v-172h172v172z" fill="none"/>
            <g>
                <path
                    d="M86,28.66667c-57.33333,0 -78.83333,57.33333 -78.83333,57.33333c0,0 21.5,57.33333 78.83333,57.33333c57.33333,0 78.83333,-57.33333 78.83333,-57.33333c0,0 -21.5,-57.33333 -78.83333,-57.33333zM86,50.16667c19.78717,0 35.83333,16.04617 35.83333,35.83333c0,19.78717 -16.04617,35.83333 -35.83333,35.83333c-19.78717,0 -35.83333,-16.04617 -35.83333,-35.83333c0,-19.78717 16.04617,-35.83333 35.83333,-35.83333zM86,64.5c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c11.87412,0 21.5,-9.62588 21.5,-21.5c0,-11.87412 -9.62588,-21.5 -21.5,-21.5z"/>
            </g>
        </g>
    </>
), {width: 24, height: 24, viewBox: "0 0 172 172"});

export default IconEye;