import {SearchField} from "common/types";
import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SearchBar, SearchBarProps} from "../SearchBar";
import {SearchFieldsBadge} from "./SearchFieldsBadge";
import {SearchFieldsStore} from "./SearchFieldsStore";

type Props = {
    store: SearchFieldsStore;
} & Omit<SearchBarProps, "heading">;

export type SearchFieldsStoreBuilder = (fetchFields: () => Promise<SearchField[]>) => SearchFieldsStore;

export function useSearchFieldsStoreBuilderMemo(): SearchFieldsStoreBuilder {
    return useMemo(() => {
        return fetchFields => {
            return new SearchFieldsStore(fetchFields)
        }
    }, []);
}

export function useSearchFieldsSerialization(key: string, store: SearchFieldsStore) {
    const [isDeserialized, setDeserialized] = useState(false);
    useEffect(() => {
        const url = new URL(window.location.href);
        const serializedRaw = url.searchParams.get(key);
        if (serializedRaw) {
            store.deserialize(serializedRaw);
        }
        setDeserialized(true);
    }, [key, store]);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const serialized = store.serialized;
    useEffect(() => {
        if (!isDeserialized) {
            return;
        }

        const params = new URLSearchParams(searchParams);
        if (serialized === null) {
            if (params.has(key)) {
                params.delete(key);
                setSearchParams(params, {replace: true});
            }
        } else {
            if (params.get(key) !== serialized) {
                params.set(key, serialized);
                setSearchParams(params, {replace: true});
            }
        }
    }, [key, navigate, searchParams, setSearchParams, serialized, isDeserialized]);
}

export const SearchBarWithFields = observer((props: Props) => {
    const {store, onChange, onSearchApply, ...defaultProps} = props;
    return (
        <SearchBar
            {...defaultProps}
            heading={
                <SearchFieldsBadge
                    selected={store.internalSelectedKeys}
                    all={store.all}
                    errorMessage={store.errorMessage}
                    isLoading={store.isLoading}
                    onFetch={store.fetchIfNeed}
                    onChange={store.setSelectedKeys}/>
            }
            onSearchApply={() => {
                store.applySelected();
                onSearchApply?.();
            }}
            onChange={onChange}/>
    );
});
