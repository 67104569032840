import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    BriefCitizenResponse,
    Citizen,
    CitizenCreateParams,
    CitizenResponse,
    CitizenUpdateParams,
    FindAllBriefCitizenParams,
    FindAllCitizenParams,
    mapBriefToCitizen,
    mapToCitizen
} from "../types";

export class CitizenRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllCitizenParams, signal?: AbortSignal): Promise<Pageable<Citizen>> {
        const response = await this.client.get<PageableResponse<CitizenResponse>>(`/citizens`, {
            signal,
            params: {
                houses: params.houses?.join(","),
                users: params.users?.join(","),
                search: params.search,
                status: params.status,
                role: params.role,
                page: params.page + 1,
                limit: params.limit,
            },
        });
        return mapPageable(response, mapToCitizen);
    }

    async findAllBrief(params: FindAllBriefCitizenParams, signal?: AbortSignal): Promise<Pageable<Citizen>> {
        const response = await this.client.get<PageableResponse<BriefCitizenResponse>>(`/citizens/brief`, {
            signal,
            params: {
                search: params.search,
                page: params.page + 1,
                limit: params.limit,
            },
        });
        return mapPageable(response, mapBriefToCitizen);
    }

    async findAllByHouseId(houseId: string, params: Omit<FindAllCitizenParams, "houses">, signal?: AbortSignal): Promise<Pageable<Citizen>> {
        const response = await this.client.get<PageableResponse<CitizenResponse>>(`/houses/${houseId}/citizens`, {
            params: {
                search: params.search,
                status: params.status,
                role: params.role,
                page: params.page + 1,
                limit: params.limit,
            },
            signal,
        });
        return mapPageable(response, mapToCitizen);
    }

    async findById(id: string, signal?: AbortSignal): Promise<Citizen> {
        const response = await this.client.get<CitizenResponse>(`citizens/${id}`, {
            signal,
        });
        return mapToCitizen(response);
    }

    create(params: CitizenCreateParams): Promise<void> {
        return this.client.post(`citizens`, {
            body: {
                first_name: params.firstName,
                second_name: params.secondName,
                middle_name: params.middleName,
                house_id: params.houseId,
                entrance: params.entrance,
                apartment: params.apartment,
                phone: params.phone,
                email: params.email,
                is_commercial: params.is_commercial,
                type: params.type,
                access_to: params.access_to ? params.access_to.getTime() / 1000 : null,
            },
        });
    }

    putById(id: string, params: CitizenUpdateParams): Promise<void> {
        return this.client.put(`citizens/${id}`, {
            body: {
                first_name: params.firstName,
                second_name: params.secondName,
                middle_name: params.middleName,
                phone: params.phone,
                email: params.email,
            },
        });
    }

    banUser(id: string, isFinance: boolean): Promise<void> {
        return this.client.post(`users/${id}/ban`, {
            body: {
                is_finance: isFinance,
            }
        });
    }

    unbanUser(id: string): Promise<void> {
        return this.client.post(`users/${id}/unban`);
    }

    deleteUser(id: string): Promise<void> {
        return this.client.delete(`users/${id}`);
    }
}