import {observer} from "mobx-react-lite";
import {useMemo} from "react";
import EditProfilePopup from "../../../users/modals/edit_profile/EditProfilePopup";
import {useUserStore} from "../../stores";
import Profile from "./Profile";
import {UserAsideContext, UserAsideStore} from "./store";

interface IProps {
    className?: string;
}

function UserAside(props: IProps) {
    const {className} = props;
    const store = useMemo(() => new UserAsideStore(), []);
    const user = useUserStore();

    function handleProfileUpdate(firstName: string, surName: string, avatar: string) {
        user.firstName = firstName;
        user.lastName = surName;
        user.avatar = avatar;
        store.setEditShowed(false);
    }

    function handleProfileCancel() {
        store.setEditShowed(false);
    }

    return (
        <UserAsideContext.Provider value={store}>
            <div className={className}>
                <Profile name={`${user.firstName} ${user.lastName}`} email={user.email} avatar={user.avatar}/>
            </div>
            {store.isEditShowed ? (
                <EditProfilePopup
                    onUpdate={handleProfileUpdate}
                    onCancel={handleProfileCancel}
                />
            ) : null}
        </UserAsideContext.Provider>
    );
}

export default observer(UserAside);
