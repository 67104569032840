import {FlowCancellationError, makeAutoObservable} from "mobx";
import {RefreshTokenError} from "../../net";

export interface RepeatableJobOptions {
    job: AsyncFunc | MobXFlow | MobXFlowUndecorated;
    debugKey?: string;
    delay: number;
}

export class RepeatableAsyncJob {
    isPending: boolean;

    private nextTimerId: number;
    private isPaused: boolean;
    private promise: Promise<void> | null;
    private timeoutId: number | null;
    private timerStartedAt: number | null;
    private error: Error | null;
    private abortController: AbortController;
    private isCompletedAtLeastOnce: boolean;

    private prevVisibilityState: string;
    private isPausedOnHidden: boolean;

    constructor(private readonly params: RepeatableJobOptions) {
        this.nextTimerId = 0;
        this.isPending = false;
        this.isPaused = false;
        this.promise = null;
        this.timeoutId = null;
        this.error = null;
        this.abortController = new AbortController();
        this.isCompletedAtLeastOnce = false;
        this.timerStartedAt = null;
        this.prevVisibilityState = "";
        this.isPausedOnHidden = false;
        makeAutoObservable(this, {}, {autoBind: true});

        document.addEventListener("visibilitychange", this.handleVisibilityChanged);
    }

    get errorMessage(): string | null {
        return this.error?.message || null;
    }

    get isLoading(): boolean {
        return !this.isCompletedAtLeastOnce;
    }

    clearError() {
        this.error = null;
    }

    /**
     * Запускает периодическое выполнение работы. Если работа уже выполняется, вызов метода игнорируется.
     */
    start() {
        if (!this.isPending) {
            if (this.isPaused) {
                this.debug("Start (from paused)");
                this.isPaused = false;
                this.restorePaused();
            } else {
                this.debug("Start");
                this.startInternal();
            }
        } else {
            this.debug("Already started");
        }
    }

    pause() {
        if (this.isPending) {
            this.debug("Paused")
            this.isPaused = true;
            this.stopInternal(true);
        } else {
            this.debug("Paused ignored");
        }
    }

    /**
     * Запускает периодическое выполнение работы. Если работа уже выполняется, завершает её и запускает заново.
     */
    reset() {
        this.debug("Reset");
        if (this.isPending) {
            this.stopInternal();
        }

        this.startInternal();
    }

    /**
     * Запускает периодическое выполнение работы. Если работа уже выполняется, завершает её и запускает заново.
     */
    resetIfWork() {
        if (this.isPending) {
            this.debug("Reset if work");
            this.stopInternal();
            this.startInternal();
        } else {
            this.debug("Reset if work ignored");
        }
    }

    call() {
        if (this.isPending) {
            this.debug("Call");
            this.stopInternal();
            this.startInternal();
        } else {
            this.debug("Call ignored")
        }
    }

    /**
     * Прекращает выполнение работы.
     */
    stop() {
        if (this.isPending) {
            this.debug("Cancel");
            this.isPausedOnHidden = false;
            this.stopInternal();
        } else {
            this.debug("Cancel ignored");
        }
    }

    private stopInternal(isPause: boolean = false, isHidden = false) {
        this.isPending = false;

        if (!isPause) {
            this.isCompletedAtLeastOnce = false;
        }

        if (!isHidden) {
            this.isPausedOnHidden = false;
        }

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }

        this.abortController.abort();
        this.abortController = new AbortController();

        const cancellable = this.promise as CancellablePromise;
        if (cancellable) {
            cancellable.cancel();
            this.promise = null;
        }
    }

    private startInternal() {
        this.error = null;
        this.isPending = true;
        this.timerStartedAt = null;
        this.timeoutId = null;
        const timerId = ++this.nextTimerId;
        this.promise = this.params.job(this.abortController.signal) as Promise<void>;
        this.promise.then(() => {
            if (timerId === this.nextTimerId) {
                this.executeTimeout();
            }
        })
            .catch(e => {
                if (e instanceof FlowCancellationError) {
                    return;
                }

                if (e instanceof RefreshTokenError) {
                    return;
                }

                this.error = e as Error;
                this.isPending = false;
                console.error(this.error);
            });
    }

    private executeTimeout() {
        this.timerStartedAt = Date.now();
        if (this.isPausedOnHidden) {
            return;
        }

        this.timeoutId = setTimeout(this.startInternal, this.params.delay) as any as number;
        this.isCompletedAtLeastOnce = true;
    }

    private restorePaused() {
        const startedAt = this.timerStartedAt || 0;
        const elapsed = Date.now() - startedAt;
        if (elapsed >= this.params.delay) {
            this.startInternal();
        } else {
            this.isPending = true;
            this.timeoutId = setTimeout(this.startInternal, this.params.delay - elapsed) as any as number;
        }
    }

    private handleVisibilityChanged() {
        if (document.visibilityState !== this.prevVisibilityState) {
            this.prevVisibilityState = document.visibilityState;
            if (document.visibilityState === "hidden") {
                if (this.isPending) {
                    this.debug("Hidden");
                    this.isPausedOnHidden = true;
                    if (this.timeoutId) {
                        clearTimeout(this.timeoutId);
                        this.timeoutId = null;
                    }
                }
            } else if (this.isPausedOnHidden) {
                this.debug("Visible");
                this.isPausedOnHidden = false;
                this.restorePaused();
            }
        }
    }

    private debug(msg: string) {
        if (this.params.debugKey) {
            console.debug(`[${this.params.debugKey}] ${msg}`);
        }
    }
}