export interface BasipBackupResponse {
    id: string;
    created_at: number;
    description: string;
    download_url: string;
}

export interface BasipBackup {
    id: string;
    createdAt: Date;
    description: string;
    downloadUrl: string;
}

export interface FindAllBasipBackup {
    intercomId: string;
    search: string;
    page: number;
    limit: number;
    type: "settings" | "data";
    signal: AbortSignal;
}

export function mapToBasipBackup(model: BasipBackupResponse): BasipBackup {
    return {
        id: model.id,
        downloadUrl: model.download_url,
        createdAt: new Date(model.created_at),
        description: model.description,
    };
}