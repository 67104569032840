import createSvgIcon from "./utils";

const IconDelimiter18x15 = createSvgIcon((
    <>
        <path
            d="M4 4.5L1.70711 6.79289C1.31658 7.18342 1.31658 7.81658 1.70711 8.20711L4 10.5"
            fill="transparent"
            strokeWidth="2"
            strokeLinecap="round"/>
        <path
            d="M14 10.5L16.2929 8.20711C16.6834 7.81658 16.6834 7.18342 16.2929 6.79289L14 4.5"
            fill="transparent"
            strokeWidth="2"
            strokeLinecap="round"/>
        <path d="M9 1.5V13.5"
              fill="transparent"
              strokeWidth="2"
              strokeLinecap="round"/>
    </>
), {width: 18, height: 15});

export default IconDelimiter18x15;