import classNames from "classnames";
import {DetailedHTMLProps, TdHTMLAttributes, useMemo} from "react";
import s from "./Table.module.css";

type Props = {
    className?: string;
    hasDefaultPadding?: boolean;
    width?: number | string;
} & DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>

export const TableBodyCell = (props: Props) => {
    const {className, hasDefaultPadding = true, width, style, ...defaultProps} = props;

    const mergedStyle = useMemo(() => {
        if (width) {
            return {
                ...style,
                width,
            };
        } else {
            return style;
        }
    }, [style, width])

    return (
        <td className={classNames(
            s.cell,
            hasDefaultPadding && s.cellDefaultPadding,
            className,
        )} {...defaultProps} style={mergedStyle}/>
    );
};