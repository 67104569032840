import {action, computed, makeObservable, observable, reaction} from "mobx";
import {TriState} from "../../components/checkbox";
import {
    deserializeMenuItem,
    IMenuItemUiState,
    MenuItemSerialized,
    SelectionMode,
    serializeMenuItem
} from "../../components/suggestions_popup/state";
import {MultiValueBuilderOptions} from "./EntryFiltersBuilder";
import {TAsyncFilterOptions} from "./TFilterOptions";
import {ValuesEntryFilter} from "./ValuesEntryFilter";

type SerializedMultiFilter<T> = { i: MenuItemSerialized<T>[], m: number | undefined };

export class MultiEntryFilter<T, Q, K> extends ValuesEntryFilter<T, SerializedMultiFilter<T> | null, Q, IMenuItemUiState<T>[]> {
    selected: IMenuItemUiState<T>[];
    canExclude: boolean;
    selectionMode: SelectionMode;
    query: Q | null;
    private isDirty: boolean;
    private serialized: SerializedMultiFilter<T> | null;

    constructor(options: TAsyncFilterOptions<T, Q, K[]> & MultiValueBuilderOptions) {
        super(options)
        this.selected = [];
        this.selectionMode = "include";
        this.canExclude = options.canExclude || false;
        this.query = null;
        this.serialized = null;
        this.isDirty = false;

        makeObservable(this, {
            query: true,
            tooltip: true,
            recalculateQuery: action.bound,
            selected: observable.ref,
            canExclude: observable,
            selectionMode: observable,
            isUsing: computed,
            selectionState: computed,
            reset: action.bound,
            toggle: action.bound,
            onExcludeToggle: action.bound,
        });

        reaction(
            () => [this.selected, this.selectionMode, this.isAllPagesLoaded],
            () => {
                if (this.selectionMode === "exclude"
                    && this.selected.length === this.items.length
                    && (!this.options.isPagination || this.isAllPagesLoaded)) {
                    this.reset();
                }
            }
        )
    }

    get isUsing() {
        return this.query !== null;
    }

    get selectionState(): TriState {
        if (this.items.length === 0 || this.isLoading) {
            return TriState.Unchecked;
        }

        const isAllSelected = this.selected.length === this.items.length;
        if (isAllSelected) {
            return TriState.Checked;
        }

        if (this.selectionMode === "exclude" && this.selected.length === 0) {
            return TriState.Checked;
        }

        return this.selected.length > 0 ? TriState.Indeterminate : TriState.Unchecked;
    }

    get tooltip() {
        return this.selected.length > 0 ? `Выбрано: ${this.selected.length}` : null;
    }

    recalculateQuery() {
        if (!this.isDirty) {
            return;
        }

        this.recalculateInternal();
    }

    onExcludeToggle() {
        if (this.items.length === 0 || this.isLoading) {
            return;
        }

        if (this.selectionMode === "exclude" && this.selected.length > 0) {
            this.selected = [];
            return;
        }

        if (this.selectionMode === "include" && this.selected.length === this.items.length) {
            this.selected = [];
            return;
        }

        this.selectionMode = this.selectionMode === "exclude" ? "include" : "exclude";
        this.selected = [];
        this.isDirty = true;
    }

    serialize() {
        return this.serialized;
    }

    deserialize(value: SerializedMultiFilter<T> | null) {
        if (value === null) {
            this.selected = [];
            this.selectionMode = "include";
            return;
        }

        this.selectionMode = value.m === 1 ? "exclude" : "include";
        this.selected = value.i.map(deserializeMenuItem);
        this.recalculateInternal();
    }

    reset() {
        this.selected = [];
        this.selectionMode = "include";
        this.query = null;
        this.serialized = null;
    }

    toggle(item: IMenuItemUiState<T>) {
        const isSelected = this.selected.some(it => item.key === it.key);
        if (isSelected) {
            this.selected = this.selected.filter(selected => selected.key !== item.key);
        } else {
            this.selected = [...this.selected, item];
        }
        this.isDirty = true;
    }

    private recalculateInternal() {
        if (this.selected.length > 0 || this.selectionMode === "exclude") {
            this.query = this.options.apply(
                this.selected.map(it => it.key),
                this.selectionMode === "exclude",
            );
        } else {
            this.query = null;
        }

        if (!this.selected.length && this.selectionMode === "include") {
            this.serialized = null;
        } else {
            this.serialized = {
                m: this.selectionMode === "exclude" ? 1 : undefined,
                i: this.selected.map(serializeMenuItem),
            };
        }
        this.isDirty = false;
    }
}
