import {ReactComponent as SvgAssistantError} from "../../../common/assets/assistant_error.svg";
import {Button} from "../button";
import {P} from "../typography";
import s from "./Route.module.css";

type Props = {
    message: string;
    onRetry?: () => void;
};

export const RouteError = (props: Props) => {
    const {message, onRetry} = props;
    return (
        <div className={s.errorContent}>
            <SvgAssistantError/>
            <div className={s.errorContentMessages}>
                <P color="dark-status-danger-d300">
                    {message}
                </P>

                <P className={s.errorContentGlitch}>
                    110100001001111111010001100000001101000010111110
                    110100001011100011010000101101111101000010111110110
                    10001100010001101000010111011110100001011000000100000
                </P>

                <P className={s.errorContentGlitch}>
                    110100001011110111010000101101011101000010111111110100011000000011
                    0100001011010111010000101101001101000010110010110100001011100011
                    0100001011010011010000101101011101000010111101110100001011110111010000101100001101000110001111
                </P>

                <P className={s.errorContentGlitch}>
                    11010000101111101101000110001000110100001011
                    1000110100001011000111010000101110101101000010110000
                </P>

                {onRetry && (
                    <Button color="Primary" onClick={onRetry}>
                        Повторить
                    </Button>
                )}
            </div>
        </div>
    );
};
