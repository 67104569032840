import React, {ErrorInfo, ReactNode} from "react";
import {RouteError} from "../route";

type Props = {
    children: ReactNode;
}

type State = {
    error: string | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {error: null};
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        if (this.isChunkLoadingError(error)) {
            window.location.reload();
        }

        this.setState({error: error.message + "\n" + info.componentStack});
    }

    private isChunkLoadingError(error: Error) {
        return error.name === "ChunkLoadError";
    }

    render() {
        if (this.state.error != null) {
            return <RouteError message={`Произошла ошибка: ${this.state.error}`}/>
        }

        return this.props.children;
    }
}
