import {makeAutoObservable} from "mobx";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

interface EquipmentDateFilterOptions {
    key: string;
    title: string;
}

export class EquipmentDateFilterStore implements BaseTaskFilterStore {
    isDatePassed: boolean;
    isDateFuture: boolean;
    readonly isVisible = true;

    constructor(private readonly options: EquipmentDateFilterOptions) {
        this.isDatePassed = false;
        this.isDateFuture = false;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        return this.isDatePassed || this.isDateFuture;
    }

    get key() {
        return this.options.key;
    }

    get title() {
        return this.options.title;
    }

    isOwnFilter(key: string) {
        return key === `${this.options.key}_date_done` || key === `${this.options.key}_date_pending`;
    }

    togglePassed() {
        this.isDatePassed = !this.isDatePassed;
    }

    toggleFuture() {
        this.isDateFuture = !this.isDateFuture;
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.isDatePassed) {
            builder.upsert(`${this.options.key}_date_done`, `${this.options.title}: дата прошла`, "true");
        }

        if (this.isDateFuture) {
            builder.upsert(`${this.options.key}_date_pending`, `${this.options.title}: дата не прошла`, "true");
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        this.isDatePassed = builder.findValue(`${this.options.key}_date_done`) === "true";
        this.isDateFuture = builder.findValue(`${this.options.key}_date_pending`) === "true";
    }

    reset() {
        this.isDatePassed = false;
        this.isDateFuture = false;
    }
}