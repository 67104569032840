import classNames from "classnames";
import {ReactNode} from "react";
import Skeleton from "react-loading-skeleton";
import {Checkbox} from "../checkbox";
import {P} from "../typography";
import s from "./SuggestionsPopup.module.css";

interface IProps {
    children: string;
    caption?: string | ReactNode | null;
    trailing?: JSX.Element;
    isSelected: boolean;
    isInvert?: boolean;

    onToggle(isSelected: boolean): void;
}

export function SelectableSuggestion(props: IProps) {
    const {children, caption, trailing, isSelected, isInvert = false, onToggle} = props;

    function handleToggle() {
        onToggle(!isSelected);
    }

    return (
        <li
            className={classNames(s.suggestionSelectable, s.suggestionInteractive)}
            onClick={handleToggle}>
            <Checkbox
                value={isInvert ? !isSelected : isSelected}
                onToggle={handleToggle}/>
            <div className={s.suggestionTextContainer}>
                <p className={s.suggestionText}>{children}</p>
                {typeof caption === "string" && <P variant="Paragraph/Small/p" color="dark-gray-p100">{caption}</P>}
                {typeof caption !== "string" && caption}
            </div>
            {trailing}
        </li>
    );
}

export function SelectableSuggestionSkeleton() {
    return (
        <li className={s.suggestionSelectable}>
            <Skeleton width={14} height={14} borderRadius={5}/>
            <Skeleton height={14} borderRadius={5}/>
        </li>
    );
}
