import {Button, ButtonSkeleton} from "common/components/button";
import {P} from "common/components/typography";
import {repeat} from "common/utils";
import {observer} from "mobx-react-lite";
import {FormEvent, MouseEvent, useRef, useState} from "react";
import {TaskDocumentType} from "../../types";
import s from "./MessageBox.module.css";

type Props = {
    types: TaskDocumentType[];
    error?: string;
    isLoading: boolean;
    onAttach: (type: string, typeName: string, files: File[]) => void;
};

export const AttachButtons = observer((props: Props) => {
    const {types, error, isLoading, onAttach} = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedType, setSelectedType] = useState<TaskDocumentType | null>(null);

    const onFileAttach = (e: MouseEvent, type: TaskDocumentType) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedType(type);
        inputRef.current?.click();
    };

    const onFileChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        const files = target.files;
        if (selectedType && files && files.length > 0) {
            onAttach(selectedType.id, selectedType.name, Array.prototype.slice.call(files));
            target.value = "";
        }
    }

    return (
        <div className={s.clipContainer}>
            {!isLoading && error && (
                <P color="dark-status-danger-d300">Не удалось загрузить типы документов</P>
            )}
            {isLoading && !error && repeat(3, i => (
                <ButtonSkeleton key={i} width={58} height={26}/>
            ))}
            {!isLoading && !error && types.map(type => (
                <Button
                    key={type.id}
                    color="Primary"
                    size="Small"
                    onClick={e => onFileAttach(e, type)}>
                    {type.name}
                </Button>
            ))}
            <input className={s.realFileInput} ref={inputRef} type="file" multiple onChange={onFileChange}/>
        </div>
    );
});
