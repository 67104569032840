function isObject(item: any): boolean {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target: object, ...sources: any[]): object {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                // @ts-ignore
                if (!target[key]) {
                    Object.assign(target, {[key]: {}});
                }

                // @ts-ignore
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function repeat<T>(count: number, populate: (index: number) => T): T[] {
    const result = [];
    for (let i = 0; i < count; i++) {
        result.push(populate(i));
    }

    return result;
}
