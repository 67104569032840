import {createContext, useContext} from "react";

type ModalContextValue = {
    onDismiss: () => void;
};

export const ModalContext = createContext<ModalContextValue | undefined>(undefined);

export function useModalContext() {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("ModalContext.Provider not found in tree");
    }

    return context;
}