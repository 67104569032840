import Skeleton from "react-loading-skeleton";
import s from "./Checkbox.module.css";

type Props = {
    label?: boolean;
};

export const CheckboxSkeleton = (props: Props) => {
    const {label} = props;

    if (label) {
        return (
            <div className={s.labelContainer}>
                <Skeleton width={16} height={16}/>
                <Skeleton width={70}/>
            </div>
        );
    }

    return <Skeleton width={16} height={16}/>;
};
