import {P} from "../typography";
import s from "./ContextMenu.module.css";
import {useDismissMenuContext} from "./DismissMenuContext";

type Props = {
    children: string;
    onClick: () => void;
}

export const ContextMenuItem = (props: Props) => {
    const {children, onClick} = props;
    const dismissMenu = useDismissMenuContext();
    const handleClick = () => {
        dismissMenu();
        onClick();
    };
    return (
        <P className={s.menuItem} onClick={handleClick}>
            {children}
        </P>
    );
};
