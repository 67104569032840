import createSvgIcon from "./utils";

const IconSortable = createSvgIcon((
    <>
        <path
            d="M4.29289 11.2929L1.70711 8.70711C1.07714 8.07714 1.52331 7 2.41421 7H7.58579C8.47669 7 8.92286 8.07714 8.2929 8.70711L5.70711 11.2929C5.31658 11.6834 4.68342 11.6834 4.29289 11.2929Z"
            stroke="transparent"/>
        <path
            d="M4.29289 0.707106L1.70711 3.29289C1.07714 3.92286 1.52331 5 2.41421 5H7.58579C8.47669 5 8.92286 3.92286 8.2929 3.29289L5.70711 0.707107C5.31658 0.316583 4.68342 0.316582 4.29289 0.707106Z"
            stroke="transparent"/>
    </>
), {width: 10, height: 12});

export default IconSortable;