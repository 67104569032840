import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    CitizenAccess,
    CitizenAccessResponse,
    FindAllAccessesParams,
    mapToCitizenAccess,
    UpdateCitizenAccessParams
} from "../types";

export class CitizenAccessRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAllAccesses(params: FindAllAccessesParams, signal?: AbortSignal): Promise<Pageable<CitizenAccess>> {
        const response = await this.client.get<PageableResponse<CitizenAccessResponse>>(
            `/citizens/${params.citizenId}/accesses`,
            {
                signal,
                params: {
                    page: params.page + 1,
                    limit: params.limit,
                },
            });
        return mapPageable(response, mapToCitizenAccess);
    }

    update(id: string, params: UpdateCitizenAccessParams): Promise<void> {
        return this.client.put(`accesses/${id}`, {
            body: {
                type: params.type,
                access_to: params.access_to ? params.access_to.getTime() / 1000 : undefined,
                new_owner_id: params.newOwnerId,
            },
        });
    }

    async updateTariff(id: string, tariffId: string) {
        await this.client.put(`tariff/access/${id}/`, {
            body: {
                tariff_id: tariffId,
            },
        });
    }

    delete(id: string): Promise<void> {
        return this.client.delete(`accesses/${id}`);
    }
}