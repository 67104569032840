import {AuthorizedHttpClient} from "common/net";
import {FindReportParams, mapFindReportParamsToBody} from "reports/types";
import {CreateDocumentDumpParams, CreateTaskDumpParams, mapDocumentFiltersToRequest, TaskDumpState} from "tasks/types";

export class DumpStateRepository {
    constructor(private client: AuthorizedHttpClient) {
    }

    create(params: CreateTaskDumpParams): Promise<void> {
        return this.client.post("web/v1/tasks/download", {
            body: {
                mode: params.mode,
                tasks: params.tasks,
                company_id: params.companyId || undefined,
                filter_by: params.filterBy,
            },
        });
    }

    createForReport(params: FindReportParams): Promise<void> {
        return this.client.post("web/v1/reports/download", {
            body: mapFindReportParamsToBody(params),
        });
    }

    createForDocuments(params: CreateDocumentDumpParams): Promise<void> {
        return this.client.post("web/v1/tasks/documents/download", {
            body: {
                mode: params.mode,
                documents: params.documents,
                company_id: params.companyId || undefined,
                filter_by: mapDocumentFiltersToRequest(params.filterBy),
            },
        });
    }

    find(companyId: number | null): Promise<TaskDumpState> {
        return this.client.get(`web/v1/tasks/download`, {
            params: {
                company_id: companyId || undefined,
            },
        });
    }

    findForReport(companyId: number | null): Promise<TaskDumpState> {
        return this.client.get(`web/v1/reports/download`, {
            params: {
                company_id: companyId || undefined,
            },
        });
    }

    findForDocuments(companyId: number | null): Promise<TaskDumpState> {
        return this.client.get(`web/v1/tasks/documents/download`, {
            params: {
                company_id: companyId || undefined,
            },
        });
    }

    delete(companyId: number | null): Promise<void> {
        return this.client.delete(`web/v1/tasks/download`, {
            params: {
                company_id: companyId || undefined,
            },
        });
    }

    deleteForReport(companyId: number | null): Promise<void> {
        return this.client.delete(`web/v1/reports/download`, {
            params: {
                company_id: companyId || undefined,
            },
        });
    }

    deleteForDocuments(companyId: number | null): Promise<void> {
        return this.client.delete(`web/v1/tasks/documents/download`, {
            params: {
                company_id: companyId || undefined,
            },
        });
    }
}
