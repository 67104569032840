import React from "react";
import {NotificationModal, NotificationModalText} from "../notification";

type Props = {
    error?: string | null | Error;
    dismiss: () => void;
};

export const ErrorModal = (props: Props) => {
    const {error, dismiss} = props;
    if (!error) {
        return null;
    }

    return (
        <NotificationModal
            title="Произошла ошибка"
            positive="Закрыть"
            onPositive={dismiss}>
            <NotificationModalText>{error.toString()}</NotificationModalText>
        </NotificationModal>
    );
};
