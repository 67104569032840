import classNames from "classnames";
import {DetailedHTMLProps, HTMLAttributes} from "react";
import s from "./Table.module.css";

type Props = {
    className?: string;
    isStickyHeader: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;

export const TableHeader = (props: Props) => {
    const {className, isStickyHeader, ...defaultProps} = props;
    return (
        <thead
            className={classNames(
                s.header,
                isStickyHeader ? s.headerSticky : null,
                className
            )}
            {...defaultProps}/>
    )
};