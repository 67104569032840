import {makeAutoObservable} from "mobx";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

interface BooleanFilterOptions {
    key: string;
    title: string;
}

export class BooleanFilterStore implements BaseTaskFilterStore {
    state: boolean;
    readonly isVisible = true;

    constructor(private readonly options: BooleanFilterOptions) {
        this.state = false;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive(): boolean {
        return this.state;
    }

    get title() {
        return this.options.title;
    }

    isOwnFilter(key: string) {
        return key === this.options.key;
    }

    toggle() {
        this.state = !this.state;
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.state) {
            builder.upsert(this.options.key, this.options.title, "true");
        } else {
            throw new Error("Unexpected state");
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        this.state = builder.findValue(this.options.key) === "true";
    }

    reset() {
        this.state = false;
    }
}