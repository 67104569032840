import createSvgIcon from "./utils";

const IconClose16x16 = createSvgIcon((
    <path
        stroke="transparent"
        fillRule="evenodd"
        d="M3.934 3.434a.8.8 0 0 1 1.132 0L8.5 6.87l3.434-3.435a.8.8 0 1 1 1.132 1.131L9.63 8l3.435 3.434a.8.8 0 0 1-1.132 1.131L8.5 9.131l-3.434 3.434a.8.8 0 0 1-1.132-1.13L7.37 8 3.934 4.565a.8.8 0 0 1 0-1.13Z"
        clipRule="evenodd"/>
), {width: 16, height: 16});

export default IconClose16x16;
