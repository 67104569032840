import classNames from "classnames";
import Ripple from "common/components/ripple";
import {FormEvent, KeyboardEventHandler, ReactNode, useRef} from "react";
import IconClose16x16 from "../../components/icons/IconClose16x16";
import IconSearch16x16 from "../../components/icons/IconSearch16x16";
import s from "./SearchBar.module.css";

export type SearchBarProps = {
    className?: string;
    value: string;
    heading?: ReactNode;
    isLight?: boolean;
    onChange: (value: string) => void;
    onSearchApply?: () => void;
};

export const SearchBar = (props: SearchBarProps) => {
    const {className, value, heading, isLight, onChange, onSearchApply} = props;
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleClear = () => onChange("");
    const handleChange = (e: FormEvent) => onChange((e.target as HTMLInputElement).value);
    const handleContainerClick = () => inputRef?.current?.focus();
    const handleKeyDown: KeyboardEventHandler = e => {
        if(e.code === "Enter") {
            onSearchApply?.();
        }
    }

    return (
        <div className={s.containerWithBadges}>
            {heading}

            <div
                className={classNames(
                    s.container,
                    heading && s.containerWithHeading,
                    isLight && s.containerLight,
                    className,
                )}
                onClick={handleContainerClick}>
                <IconSearch16x16 className={s.searchIcon}/>

                <input
                    ref={inputRef}
                    className={s.realInput}
                    type="text"
                    placeholder="Поиск"
                    value={value}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}/>

                {value && <IconClose16x16 className={s.clearBtn} onClick={handleClear}/>}
            </div>

            <Ripple className={s.searchBtnContainer}>
                <button
                    className={s.searchBtn}
                    onClick={onSearchApply}>
                    Поиск
                </button>
            </Ripple>
        </div>
    );
};
