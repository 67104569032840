import * as Sentry from "@sentry/react";
import {CompanyRepository} from "companies/repositories";
import {ICompanyChild, ICompanyEntity} from "companies/types";
import {makeAutoObservable} from "mobx";
import {AuthRepository, DeviceIdRepository, UserRepository} from "users/repositories";
import {SessionStore} from "users/stores";
import {AuthToken, DefinedUserRole, IUserEntity} from "users/types";
import {createContextAndHook} from "../utils";
import {AsyncJob} from "./job";

export class UserStore {
    isLoggedIn: boolean;

    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    avatar: string;
    email: string;
    companyId: number;
    roleId: number;
    isCompanyGroup: boolean;
    accessedCompanies: ICompanyChild[];

    private readonly loginJob: AsyncJob<typeof UserStore.prototype.verifyLogin>;
    private readonly logoutJob: AsyncJob<typeof UserStore.prototype._logout>;

    constructor(
        private readonly session: SessionStore,
        private readonly usersRepo: UserRepository,
        private readonly authRepo: AuthRepository,
        private readonly companyRepo: CompanyRepository,
        private readonly deviceRepo: DeviceIdRepository,
        private readonly landingUrl: string,
    ) {
        this.id = -1;
        this.isLoggedIn = false;
        this.firstName = "";
        this.fullName = "";
        this.lastName = "";
        this.email = "";
        this.avatar = "";
        this.companyId = -1;
        this.roleId = 0;
        this.isCompanyGroup = false;
        this.accessedCompanies = [];
        makeAutoObservable(this, {}, {autoBind: true});

        this.loginJob = new AsyncJob({job: this.verifyLogin});
        this.logoutJob = new AsyncJob({job: this._logout});
    }

    get isLoading() {
        return this.logoutJob.isPending;
    }

    get error() {
        return this.loginJob.errorMessage || this.logoutJob.errorMessage;
    }

    get isEngineer() {
        return this.roleId === DefinedUserRole.ENGINEER;
    }

    get isEtdAdmin() {
        return DefinedUserRole.ADMIN_ETD === this.roleId;
    }

    logout() {
        this.logoutJob.start();
    }

    login() {
        this.loginJob.start();
    }

    dismissLogin() {
        this.loginJob.stop();
    }

    private* _logout() {
        try {
            let token: AuthToken = yield this.session.getToken();
            yield this.authRepo.delete(token.refreshToken, this.deviceRepo.find());
            window.location.href = this.landingUrl;
        } finally {
            this.session.clear();
        }
    }

    private* verifyLogin() {
        try {
            const token: AuthToken = yield this.session.getToken();
            if (!token) {
                window.location.href = this.landingUrl;
                return;
            }
        } catch (e) {
            console.error(e);
            if (process.env.NODE_ENV === "production") {
                window.location.href = this.landingUrl;
            }

            return;
        }

        const user: IUserEntity = yield this.usersRepo.findByToken();
        this.id = user.id;
        this.email = user.email;
        this.fullName = user.name;
        this.companyId = user.company_id;
        this.avatar = user.avatar_url;

        const company: ICompanyEntity = yield this.companyRepo.find(this.companyId);
        this.isCompanyGroup = company.is_group;

        const [lastName, firstName] = this.fullName.split(" ");
        this.firstName = firstName;
        this.lastName = lastName;

        this.roleId = user.role_id;
        this.accessedCompanies = user.dispatcher_companies;
        this.isLoggedIn = true;
        Sentry.setUser({
            id: user.id,
            role_id: user.role_id,
        });
    }
}

const [UserStoreContext, useUserStore] = createContextAndHook<UserStore>();
export {UserStoreContext, useUserStore};
