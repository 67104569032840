import {
    CalcHouseAudienceBody,
    CreateNotificationBody,
    FindAllNotificationArchiveBody,
    mapToNotificationArchive,
    NotificationArchive,
    NotificationArchiveResponse
} from "citizens/types";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";

export class NotificationArchiveRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    create(params: CreateNotificationBody): Promise<void> {
        return this.client.post(`notifications`, {
            body: {
                audience: params.audience.type === "house-users" ? {
                    type: "house-users",
                    house_id: params.audience.houseId,
                    mode: params.audience.mode,
                    ids: params.audience.ids,
                } : params.audience,
                notification: params.notification,
            },
        });
    }

    async findAll(params: FindAllNotificationArchiveBody): Promise<Pageable<NotificationArchive>> {
        const response = await this.client.get<PageableResponse<NotificationArchiveResponse>>(
            `/notifications`, {
                signal: params.signal,
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search || undefined,
                    house_id: params.houseId,
                    citizen_id: params.citizenId,
                    apartment: params.apartment,
                    from_date: params.fromDate ? params.fromDate / 1000 : undefined,
                    to_date: params.toDate ? params.toDate / 1000 : undefined,
                },
            });
        return mapPageable(response, mapToNotificationArchive);
    }

    async findAudienceCount(body: CalcHouseAudienceBody): Promise<number> {
        const response = await this.client.post<{ count: number }>(
            "notifications/calculate-audience", {body},
        );

        return response.count;
    }

    async findById(id: string, signal: AbortSignal): Promise<NotificationArchive> {
        const response = await this.client.get<NotificationArchiveResponse>(
            `notifications/${id}`, {signal});
        return mapToNotificationArchive(response);
    }

    async findAudienceById(id: string, search: string, signal: AbortSignal): Promise<Pageable<string>> {
        const response = await this.client.get<PageableResponse<{ name: string }>>(
            `notifications/${id}/audience`, {
                params: {
                    search: search || undefined,
                },
                signal,
            });
        return mapPageable(response, item => item.name);
    }

    async reject(id: string): Promise<void> {
        return this.client.post(`notifications/${id}/reject`);
    }
}
