import {makeAutoObservable} from "mobx";
import {createContextAndHook} from "../../utils";

export class UserAsideStore {
    isProfileOpened: boolean;
    isEditShowed: boolean;

    constructor() {
        this.isProfileOpened = false;
        this.isEditShowed = false;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    toggleProfile() {
        this.setProfileOpened(!this.isProfileOpened);
    }

    setProfileOpened(value: boolean) {
        this.isProfileOpened = value;
    }

    setEditShowed(value: boolean) {
        this.isEditShowed = value;
        this.isProfileOpened = false;
    }
}

const [UserAsideContext, useUserAsideStore] = createContextAndHook<UserAsideStore>();
export {UserAsideContext, useUserAsideStore};