import createSvgIcon from "./utils";

const IconClose12 = createSvgIcon((
    <path
        stroke="transparent"
        fillRule="evenodd"
        d="M.293.793a1 1 0 0 1 1.414 0L6 5.086 10.293.793a1 1 0 1 1 1.414 1.414L7.414 6.5l4.293 4.293a1 1 0 0 1-1.414 1.414L6 7.914l-4.293 4.293a1 1 0 0 1-1.414-1.414L4.586 6.5.293 2.207a1 1 0 0 1 0-1.414Z"
        clipRule="evenodd"/>
), {width: 12, height: 12});

export default IconClose12;