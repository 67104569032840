import {
    NotificationModal,
    NotificationModalHighlight,
    NotificationModalText
} from "common/components/modal/notification";
import React from "react";

type Props = {
    status: string;
    onDismiss: () => void;
};

export const CompletionErrorModal = (props: Props) => {
    const {status, onDismiss} = props;
    return (
        <NotificationModal
            title="Завершение заявки"
            positive="ОК"
            onPositive={onDismiss}
            onDismiss={onDismiss}>
            <NotificationModalText>
                Завершите все дочерние заявки, чтобы перевести родительскую заявку в статус
                <NotificationModalHighlight>"{status}"</NotificationModalHighlight>
            </NotificationModalText>
        </NotificationModal>
    )
};
