import {AsyncJob} from "common/stores/job";
import {makeAutoObservable} from "mobx";
import {DocumentTypeListStore} from "tasks/stores";
import {AttachmentUploaderStore} from "../../stores/attachment_uploader";
import {CommentAttachmentParams} from "../../types";
import {AttachedFileUiState} from "./state";

const imageExtension = [
    "jpg",
    "jpeg",
    "png",
    "webp",
    "aviff",
    "bmp",
];

export function isImageAttachment(name: string) {
    const dotIndex = name.lastIndexOf(".");
    if (dotIndex === -1) {
        return false;
    }

    return imageExtension.includes(name.substring(dotIndex + 1).toLowerCase());
}

type SendListener = (message: string, uploads: CommentAttachmentParams[]) => Promise<void>;

export class BasePendingCommentStore {
    message: string;

    private minimalMessageLength: number;
    private sendListener: SendListener | null;
    private readonly sendJob: AsyncJob<typeof BasePendingCommentStore.prototype._send>;

    constructor(
        readonly typeList: DocumentTypeListStore,
        private readonly uploader: AttachmentUploaderStore
    ) {
        this.message = "";
        this.minimalMessageLength = 0;
        this.sendListener = null;
        makeAutoObservable(this, {}, {autoBind: true});

        this.sendJob = new AsyncJob({job: this._send});
    }

    get isSending() {
        return this.sendJob.isPending;
    }

    get canSend() {
        if (this.isSending) {
            return false;
        }

        const message = this.message.trim();
        if (message.length > 0) {
            return message.length >= this.minimalMessageLength;
        }

        return this.uploader.hasAttachments;
    }

    get errorMessage() {
        return this.uploader.errorMessage || this.sendJob.errorMessage;
    }

    get attachments() {
        return this.uploader.attachments;
    }

    setMinimalMessageLength(value: number) {
        this.minimalMessageLength = value;
    }

    setMessage(value: string) {
        if (this.isSending) {
            return;
        }

        this.message = value;
    }

    dismissError() {
        this.sendJob.clearError();
        this.uploader.dismissError();
    }

    setSendListener(callback: SendListener | null) {
        this.sendListener = callback;
    }

    async attachFiles(type: string, typeName: string, files: File[]) {
        if (!this.isSending) {
            await this.uploader.attachFiles(type, typeName, files);
        }
    }

    removeDocument(attachment: AttachedFileUiState) {
        if (!this.isSending) {
            this.uploader.removeDocument(attachment);
        }
    }

    send() {
        this.sendJob.start();
    }

    private* _send() {
        const sendListener = this.sendListener;
        if (sendListener === null) throw new Error("Illegal state: sendListener is null");

        const message = this.message.trim();
        const attachments: CommentAttachmentParams[] = yield this.uploader.uploadAll();
        yield sendListener(message, attachments);
        this.message = "";
        this.uploader.reset();
    }
}
