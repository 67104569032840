import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {adjustTimezoneMillis} from "common/utils";
import {
    FindAllListedUsersParam,
    FindAllPossibleUsersParam,
    FindAllReportExecutorsParam,
    FindAllUsersParam,
    ListedUser,
    ListedUserResponse,
    mapToListedUser,
    mapToUser,
    User,
    UserResponse,
    UserUpsertParams
} from "../types";

export class NewUserRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    create(companyId: number, params: UserUpsertParams): Promise<void> {
        return this.client.post(
            `v1/companies/${companyId}/users`, {
                body: {
                    first_name: params.firstName,
                    last_name: params.secondName,
                    middle_name: params.middleName,
                    phone: params.phone,
                    email: params.email,
                    role_id: params.roleId,
                    position: params.position,
                    password: params.password,
                    dispatcher_companies: params.dispatcherCompanies,
                },
            }
        );
    }

    update(userId: number, params: UserUpsertParams): Promise<void> {
        return this.client.put(
            `v1/users/${userId}`, {
                body: {
                    first_name: params.firstName,
                    last_name: params.secondName,
                    middle_name: params.middleName,
                    phone: params.phone,
                    email: params.email,
                    role_id: params.roleId,
                    position: params.position,
                    password: params.password,
                    dispatcher_companies: params.dispatcherCompanies,
                },
            }
        );
    }

    async findById(id: number): Promise<User> {
        const response = await this.client.get<UserResponse>(`v1/users/${id}`);
        return mapToUser(response);
    }

    async findAllByCompanyId(companyId: number, params: FindAllUsersParam): Promise<Pageable<User>> {
        const response = await this.client.get<PageableResponse<UserResponse>>(
            `v1/companies/${companyId}/users`, {
                params: {
                    page: params.page,
                    limit: params.limit || 10,
                    search: params.search,
                    companies: params.companies?.join(","),
                    include_child_companies: params.isIncludeChildCompanies,
                    exclude_with_access_to_house: params.excludeWithHouseAccess,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToUser);
    }

    async findAll(params: FindAllUsersParam): Promise<Pageable<User>> {
        const response = await this.client.get<PageableResponse<UserResponse>>(
            `v1/users/`, {
                params: {
                    page: params.page,
                    limit: params.limit || 10,
                    search: params.search,
                    companies: params.companies?.join(","),
                    include_child_companies: params.isIncludeChildCompanies,
                    exclude_with_access_to_house: params.excludeWithHouseAccess,
                },
            }
        );

        return mapPageable(response, mapToUser);
    }

    /**
     * Запрос на получение списка объектов, несущих минимальную информацию о пользователях.
     *
     * В отличие от полноценного {@link findAll} выполняется намного быстрее за счет отсутствия время затратной для
     * формирования на сервере информации.
     *
     * Опциональное поле {@link params.companyId} определяет пользователей какой компании необходимо вернуть. В случае отсутствия
     * данного поля будут отданы пользователи компании, которой принадлежит авторизированный пользователь. Если пользователь имеет
     * роль <i>Диспетчер ЕТД</i>, метод возвращает пользователей всех компаний, к которым авторизированному пользователю выдан доступ.
     *
     * Для группы компаний ответ включает пользователей дочерних компаний.
     */
    async findAllListedExecutors(params: FindAllListedUsersParam): Promise<Pageable<ListedUser>> {
        const response = await this.client.get<PageableResponse<ListedUserResponse>>(
            `web/v1/tasks/filters/executors`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search,
                    companies_ids: params.companyId ? [params.companyId] : undefined,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToListedUser);
    }

    async findAllListedCreators(params: FindAllListedUsersParam): Promise<Pageable<ListedUser>> {
        const response = await this.client.get<PageableResponse<ListedUserResponse>>(
            `web/v1/tasks/filters/creators`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search,
                    companies_ids: params.companyId ? [params.companyId] : undefined,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToListedUser);
    }

    async findAllListedCommentAuthors(params: FindAllListedUsersParam): Promise<Pageable<ListedUser>> {
        const response = await this.client.get<PageableResponse<ListedUserResponse>>(
            `web/v1/tasks/filters/comment-authors`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search,
                    companies_ids: params.companyId ? [params.companyId] : undefined,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToListedUser);
    }

    async findAllListedHistoryAuthors(params: FindAllListedUsersParam): Promise<Pageable<ListedUser>> {
        const response = await this.client.get<PageableResponse<ListedUserResponse>>(
            `web/v1/tasks/filters/history-authors`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search,
                    companies_ids: params.companyId ? [params.companyId] : undefined,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToListedUser);
    }

    async findDocumentCreatorFilters(params: FindAllListedUsersParam): Promise<Pageable<ListedUser>> {
        const response = await this.client.get<PageableResponse<ListedUserResponse>>(
            `web/v1/tasks/documents/filters/creators`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search || undefined,
                    company_id: params.companyId || undefined,
                },
            }
        );
        return mapPageable(response, mapToListedUser);
    }

    async findReportExecutorFilters(params: FindAllReportExecutorsParam): Promise<Pageable<ListedUser>> {
        const response = await this.client.get<PageableResponse<ListedUserResponse>>(
            `web/v1/reports/executor`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search || undefined,
                    company_id: params.companyId || undefined,
                    from_date: adjustTimezoneMillis(params.periodFrom) / 1000,
                    to_date: adjustTimezoneMillis(params.periodTo) / 1000,
                },
            }
        );
        return mapPageable(response, mapToListedUser);
    }

    async findAllPossible(params: FindAllPossibleUsersParam): Promise<Pageable<User>> {
        const response = await this.client.get<PageableResponse<UserResponse>>(
            `v1/houses/${params.houseId}/possible-users`, {
                params: {
                    page: params.page,
                    limit: params.limit || 10,
                    search: params.search,
                },
            }
        );

        return mapPageable(response, mapToUser);
    }

    deleteById(id: number): Promise<void> {
        return this.client.delete(`v1/users/${id}`);
    }

    async resetPasswordById(id: number): Promise<void> {
        return this.client.patch(`v1/users/${id}/pass`);
    }
}
