export function mapQueryToInt(value?: string | null) {
    if (value === null) {
        return -1;
    }

    const number = Number(value);
    return Number.isInteger(number) ? number : -1;
}

export function mapIdParam(value: string | null | undefined) {
    if (value === null || value === undefined) {
        return null;
    }

    const number = Number(value);
    return Number.isInteger(number) ? number : null;
}
