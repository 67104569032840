import {NO_SPACES_REGEXP, PHONE_REGEXP} from "common/utils";
import * as yup from "yup";

export const UserConstraints = {
    firstNameMinLength: 1,
    firstNameMaxLength: 30,
    secondNameMinLength: 1,
    secondNameMaxLength: 30,
    middleNameMaxLength: 30,
    positionMaxLength: 40,
};

export const UserValidations = {
    firstName: yup.string().required("Заполните поле").min(UserConstraints.firstNameMinLength).max(UserConstraints.firstNameMaxLength).trim().matches(NO_SPACES_REGEXP, "Недопустимое значение"),
    secondName: yup.string().required("Заполните поле").min(UserConstraints.secondNameMinLength).max(UserConstraints.secondNameMaxLength).trim().matches(NO_SPACES_REGEXP, "Недопустимое значение"),
    middleName: yup.string().max(UserConstraints.middleNameMaxLength).matches(NO_SPACES_REGEXP, "Недопустимое значение"),
    phone: yup.string().required("Заполните поле").matches(PHONE_REGEXP, "Недопустимое значение"),
    position: yup.string().max(UserConstraints.positionMaxLength),
};
