import {HeaderGroup} from "react-table";
import {TableHeader as TableHeaderBase} from "../../components/table/TableHeader";
import {TriState} from "../checkbox";
import TableHeaderRow from "./TableHeaderRow";

interface IProps<D extends object> {
    className?: string;
    headCellClassName?: string;
    headerGroups: HeaderGroup<D>[];
    distColumnId: string | null;
    canSelectRows?: boolean;
    allSelectionState?: TriState;
    isSortable: boolean;
    isResizable: boolean;
    isDraggable: boolean;
    isStickyHeader: boolean;
    onColumnOrderStart: (sourceColumnId: string) => void;
    onColumnOrderEnd: (distColumnId: string | null) => void;
    onColumnOrderOver: (distColumnId: string | null) => void;
    onSelectAllRows?: () => void;
}

function TableHead<D extends object>(props: IProps<D>) {
    const {
        className,
        headerGroups,
        headCellClassName,
        distColumnId,
        canSelectRows,
        allSelectionState,
        isSortable,
        isResizable,
        isDraggable,
        isStickyHeader,
        onColumnOrderStart,
        onColumnOrderEnd,
        onColumnOrderOver,
        onSelectAllRows,
    } = props;
    return (
        <TableHeaderBase className={className} isStickyHeader={isStickyHeader}>
            {headerGroups.map((headerGroup, index) =>
                <TableHeaderRow
                    key={index}
                    headerGroup={headerGroup}
                    headCellClassName={headCellClassName}
                    distColumnId={distColumnId}
                    canSelectRows={canSelectRows}
                    allSelectionState={allSelectionState}
                    isSortable={isSortable}
                    isResizable={isResizable}
                    isDraggable={isDraggable}
                    onColumnOrderStart={onColumnOrderStart}
                    onColumnOrderEnd={onColumnOrderEnd}
                    onColumnOrderOver={onColumnOrderOver}
                    onSelectAllRows={onSelectAllRows}/>,
            )}
        </TableHeaderBase>
    );
}

export default TableHead;
