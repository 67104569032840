import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    Apartment,
    ApartmentResponse,
    FindAllApartmentParams,
    ListedApartment,
    ListedApartmentResponse,
    mapToApartment,
    mapToListedApartment,
    UpdateApartmentParams
} from "../types";

export class ApartmentRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllApartmentParams): Promise<Pageable<ListedApartment>> {
        const response = await this.client.get<PageableResponse<ListedApartmentResponse>>(
            `houses/${params.houseId}/apartments`,
            {
                signal: params.signal,
                params: {
                    search: params.search || undefined,
                    page: params.page,
                    limit: params.limit,
                    view_all: params.isHideEmpty === undefined ? true : !params.isHideEmpty,
                }
            }
        );
        return mapPageable(response, mapToListedApartment);
    }

    async findById(houseId: string, apartment: string, signal: AbortSignal): Promise<Apartment> {
        const response = await this.client.get<ApartmentResponse>(
            `houses/${houseId}/apartments/${apartment}`,
            {signal}
        );
        return mapToApartment(response);
    }

    async update(params: UpdateApartmentParams): Promise<void> {
        return this.client.put(`houses/${params.houseId}/apartments/${params.apartment}`, {
            body: {
                is_cellphone_disabled: params.isCellphoneDisabled,
            }
        })
    }
}
