import createSvgIcon from "./utils";

const IconArrowDown16x16 = createSvgIcon((
    <path
        stroke="transparent"
        fillRule="evenodd"
        d="M4.234 5.834a.8.8 0 0 1 1.132 0L8 8.47l2.634-2.635a.8.8 0 1 1 1.132 1.132l-3.2 3.2a.8.8 0 0 1-1.132 0l-3.2-3.2a.8.8 0 0 1 0-1.132Z"
        clipRule="evenodd"/>
), {width: 16, height: 16});

export default IconArrowDown16x16;
