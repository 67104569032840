import {AuthorizedHttpClient} from "common/net";
import {CursorPageableResponse, mapCursorPageable} from "common/types";
import {FindScheduledTaskFilterParam} from "tasks/types";
import {HouseTag, HouseTagCreateParams, HouseTagResponse, HouseTagUpdateParams, mapToHouseTag} from "../types";

export class HouseTagRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    /**
     * Получение всех тегов из всех компаний.
     */
    async findAll(): Promise<HouseTag[]> {
        const response = await this.client.get<HouseTagResponse[]>("v1/houses/tags");
        return response.map(mapToHouseTag);
    }

    async findAllByCompanyId(companyId: number): Promise<HouseTag[]> {
        const response = await this.client.get<HouseTagResponse[]>(`v1/companies/${companyId}/houses/tags`);
        return response.map(mapToHouseTag);
    }

    async findAllForScheduledTaskFilter(param: FindScheduledTaskFilterParam) {
        const response = await this.client.get<CursorPageableResponse<HouseTagResponse>>(
            "web/v1/scheduled-tasks/filters", {
                params: {
                    type_filter: "tag",
                    cursor: param.cursor || undefined,
                    limit: param.limit,
                    search: param.search.trim() || undefined,
                    company_id: param.companyId || undefined,
                },
            });
        return mapCursorPageable(response, mapToHouseTag);
    }

    createByCompanyId(companyId: number, params: HouseTagCreateParams[]): Promise<number[]> {
        return this.client.post(`v1/companies/${companyId}/houses/tags`, {
            body: params,
        });
    }

    update(params: HouseTagUpdateParams[]): Promise<void> {
        return this.client.put(`v1/houses/tags`, {
            body: params,
        });
    }

    delete(ids: number[]): Promise<void> {
        return this.client.delete(`v1/houses/tags`, {
            body: ids,
        });
    }
}
