import React from "react";
import IconCheckmark10x8 from "../../components/icons/IconCheckmark10x8";
import {CheckboxBase, CheckboxBaseProps} from "./CheckboxBase";

export enum TriState {
    Indeterminate = 1,
    Checked,
    Unchecked,
}

type Props = {
    value: TriState;
    variant?: "primary" | "danger";
    onToggle?: (value: TriState) => void;
} & CheckboxBaseProps;

function getIcon(state: TriState) {
    switch (state) {
        case TriState.Checked:
            return <IconCheckmark10x8/>;
        case TriState.Indeterminate:
            return (
                <svg width="13" height="2" viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 1L11.5 1" stroke="#FFFFDD" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            );
        default:
            return null;
    }
}

function getNextValue(state: TriState) {
    switch (state) {
        case TriState.Checked:
            return TriState.Indeterminate;
        case TriState.Indeterminate:
            return TriState.Checked;
        default:
            return TriState.Unchecked;
    }
}

export const IndeterminateCheckbox = (props: Props) => {
    const {value, variant = "primary", onToggle, ...defaultProps} = props;
    const handleValueChange = () => onToggle?.(getNextValue(value));
    return (
        <CheckboxBase
            value={value === TriState.Indeterminate || value === TriState.Checked}
            icon={getIcon(value)}
            backgroundColor={variant === "danger" && value === TriState.Indeterminate ? "var(--dark-status-danger-d300)" : null}
            onToggle={handleValueChange}
            {...defaultProps} />
    );
};
