import {InputBoxOptionBase, OptionProps} from "./InputBoxOptionBase";
import {InputBoxItemState} from "./types";

type Props<T> = {
    state: InputBoxItemState<T>,
} & Omit<Partial<OptionProps>, "label" | "ref">

export const InputBoxOption = <T extends unknown>(props: Props<T>) => {
    const {state, ...defaultProps} = props;
    return (
        <InputBoxOptionBase
            ref={state.innerRef}
            label={state.item.label}
            isMulti={state.isMulti}
            isSelected={state.isSelected}
            isFocused={state.isFocused}
            isDisabled={state.isDisabled}
            {...state.innerProps}
            {...defaultProps}/>
    );
};
