import {AuthorizedHttpClient} from "common/net";
import {FindReportParams, mapFindReportParamsToBody, mapToReport, Report, ReportResponse} from "../types";

export class ReportRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async find(params: FindReportParams): Promise<Report> {
        const response = await this.client.post<ReportResponse>("web/v1/reports/houses", {
            body: mapFindReportParamsToBody(params),
        });
        return mapToReport(response);
    }
}
