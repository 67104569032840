import classNames from "classnames";
import {useRef} from "react";
import Popup from "reactjs-popup";
import {PopupActions, PopupPosition} from "reactjs-popup/dist/types";
import {P} from "../typography";
import s from "./SimplePopup.module.css";

interface IProps {
    className?: string;
    title: string;
    mouseEnterDelay?: number;
    children: JSX.Element;
    position?: PopupPosition | PopupPosition[];
}

function Tooltip(props: IProps) {
    const {className, title, position, mouseEnterDelay = 1000, children} = props;
    const popup = useRef<PopupActions>(null);

    function onMouseEnter() {
        popup.current?.close();
    }

    return (
        <Popup
            ref={popup}
            on="hover"
            trigger={children}
            mouseEnterDelay={mouseEnterDelay}
            keepTooltipInside
            position={position}
            arrowStyle={{color: "var(--dark-secondary-p400)"}}>
            <P className={classNames(s.container, className)} color="none" onMouseEnter={onMouseEnter}>
                {title}
            </P>
        </Popup>
    );
}

export default Tooltip;
