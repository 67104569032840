import {TaskFilter} from "./TaskFilter";

export const SavedTaskFilterConstraints = {
    nameMaxLength: 80,
};

export interface SavedTaskFilter {
    id: number;
    name: string;
    filters: TaskFilter[];
}

export interface UpdateSavedTaskFilterParams {
    id: number;
    name: string;
    filters: TaskFilter[];
}