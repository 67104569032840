import {AsyncJob, CachePolicy} from "common/stores/job";
import {makeAutoObservable} from "mobx";
import {TaskDocumentTypeRepository} from "tasks/repositories";
import {TaskDocumentType} from "tasks/types";

export class DocumentTypeListStore {
    types: TaskDocumentType[];
    private readonly fetchJob: AsyncJob<typeof this._fetch>

    constructor(
        private readonly typeRepo: TaskDocumentTypeRepository,
    ) {
        this.types = [];
        makeAutoObservable(this, {}, {autoBind: true});
        this.fetchJob = new AsyncJob({
            job: this._fetch,
            policy: CachePolicy.MINUTES_30,
        });
    }

    get isLoading() {
        return this.fetchJob.isCreated || this.fetchJob.isPending;
    }

    get errorMessage() {
        return this.fetchJob.errorMessage;
    }

    loadIfNeed() {
        this.fetchJob.start();
    }

    private* _fetch() {
        this.types = yield this.typeRepo.findAll();
    }
}