import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    FindAllUserGeoDetailParam,
    FindAllUserGeoParam,
    mapToUserGeo,
    mapToUserGeoDetails,
    UserGeo,
    UserGeoDetails,
    UserGeoDetailsResponse,
    UserGeoResponse
} from "../types";

export class UserGeoRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllUserGeoParam): Promise<Pageable<UserGeo>> {
        const response = await this.client.get<PageableResponse<UserGeoResponse>>(
            `web/v1/geo/users`, {
                params: {
                    page: params.page + 1,
                    limit: params.limit,
                    search: params.search,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToUserGeo);
    }

    async findByUserId(params: FindAllUserGeoDetailParam): Promise<UserGeoDetails> {
        const response = await this.client.get<UserGeoDetailsResponse>(
            `web/v1/geo/users/${params.userId}`, {
                params: {
                    page: params.page + 1,
                    limit: params.limit,
                },
                signal: params.signal,
            }
        );

        return mapToUserGeoDetails(response);
    }
}
