import classNames from "classnames";
import React, {forwardRef} from "react";
import s from "./index.module.css";

export type InputVariant = "primary" | "form";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    variant?: InputVariant;
}

function DeprecatedInput(props: IProps, ref: React.Ref<HTMLInputElement>) {
    const {className, variant = "primary"} = props;
    return (
        <input ref={ref} {...props} className={classNames(s[variant], className)}/>
    );
}

export default forwardRef(DeprecatedInput);
