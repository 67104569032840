import Skeleton from "react-loading-skeleton";

type Props = {
    width?: number | string;
    height?: number | string;
};

export const InputSkeleton = (props: Props) => {
    const {width, height = 36} = props;
    return <Skeleton width={width} height={height} borderRadius={10}/>;
};
