import {TriState} from "common/components/checkbox";
import {SearchField} from "common/types";
import {getNounByNumber} from "common/utils";
import {observer} from "mobx-react-lite";
import {useMemo} from "react";
import IconArrowDown16x16 from "../../../components/icons/IconArrowDown16x16";
import {IMenuItemUiState, MultiSuggestionsPopup} from "../../../components/suggestions_popup";
import {P} from "../../typography";
import s from "./SearchBarWithFields.module.css";

type Props = {
    all: SearchField[];
    selected: number[];
    errorMessage?: string;
    isLoading: boolean;
    onFetch: () => void;
    onChange: (selected: number[]) => void;
};

export const SearchFieldsBadge = observer((props: Props) => {
    const {all, selected, errorMessage, isLoading, onChange, onFetch} = props;

    const items: IMenuItemUiState<SearchField>[] = useMemo(() => {
        return all.map(searchField => ({
            key: searchField.id,
            label: searchField.name,
            value: searchField,
        }));
    }, [all]);

    const isAllSelected = selected.length === all.length;
    const selectionState = useMemo(() => {
        if (isLoading) {
            return TriState.Unchecked;
        }

        if (isAllSelected) {
            return TriState.Checked;
        }

        return selected.length > 0 ? TriState.Indeterminate : TriState.Unchecked;
    }, [selected, isAllSelected, isLoading]);

    const isItemSelected = (item: IMenuItemUiState<SearchField>) => selected.includes(item.value.id);
    const toggleAll = () => {
        if (isAllSelected) {
            onChange([]);
        } else {
            onChange(all.map(item => item.id));
        }
    }
    const toggleItem = (field: IMenuItemUiState<SearchField>) => {
        const isSelected = selected.some(item => item === field.value.id);
        if (isSelected) {
            onChange(selected.filter(item => item !== field.value.id))
        } else {
            onChange([...selected, field.value.id])
        }
    }
    return (
        <MultiSuggestionsPopup
            items={items}
            error={errorMessage}
            canExclude
            isFilterable
            isLoading={isLoading}
            isItemSelected={isItemSelected}
            selectionState={selectionState}
            onExcludeToggle={toggleAll}
            onToggle={toggleItem}
            onOpen={onFetch}>
            {isOpened => {
                return (
                    <div className={s.fieldsBadge}>
                        <P className={s.fieldsBadgeTitle} variant="Button/Small" color="inherit">
                            {isAllSelected || selected.length === 0
                                ? "Все"
                                : `${selected.length} ${getNounByNumber(selected.length, "фильтр", "фильтра", "фильтров")}`}
                        </P>
                        <IconArrowDown16x16 className={isOpened ? s.fieldsBadgeDropdownOpened : s.fieldsBadgeDropdown}/>
                    </div>
                );
            }}
        </MultiSuggestionsPopup>
    );
});
