import {AuthorizedHttpClient} from "common/net";

import {CreateCommentParams} from "tasks/types";
import {
    ChildCompany,
    CreateDelegationSettingParam,
    DelegationSetting,
    DelegationSettingResponse,
    mapToDelegationSetting,
    UpdateDelegationSettingParam
} from "../types";

export class DelegationSettingRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    findPossibleDelegations(taskId: number): Promise<ChildCompany[]> {
        return this.client.get<ChildCompany[]>(`web/v1/tasks/${taskId}/delegate`);
    }


    async findAllByCompanyId(companyId: number): Promise<DelegationSetting[]> {
        const response = await this.client.get<DelegationSettingResponse[]>(`web/v1/companies/${companyId}/delegations`);
        return response.map(mapToDelegationSetting);
    }

    createAll(params: CreateDelegationSettingParam[]): Promise<DelegationSetting[]> {
        return this.client.post("web/v1/companies/delegations", {
            body: params.map(param => ({
                from_id: param.fromId,
                to_id: param.toId,
            }))
        });
    }

    deleteAll(settingIds: number[]): Promise<void> {
        return this.client.delete("web/v1/companies/delegations", {
            body: settingIds,
        })
    }

    updateAll(params: UpdateDelegationSettingParam[]): Promise<DelegationSetting[]> {
        return this.client.put("web/v1/companies/delegations", {
            body: params.map(param => ({
                id: param.id,
                from_id: param.fromId,
                to_id: param.toId,
            }))
        });
    }

    delegate(taskId: number, companyId: number): Promise<void> {
        return this.client.post(`web/v1/tasks/${taskId}/delegate`, {
            body: {
                id: companyId,
            }
        });
    }

    withdraw(taskId: number, comment: CreateCommentParams): Promise<void> {
        return this.client.delete(`web/v1/tasks/${taskId}/delegate`, {
            body: comment,
        });
    }
}
