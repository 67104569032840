export class ServerError extends Error {
    constructor(readonly code: number, message: string | null) {
        super(message || "Неизвестная ошибка сервера");
    }
}

export class RefreshTokenError extends Error {
    constructor() {
        super("refresh token error");
    }
}

export class TokenExpirationError extends Error {
    constructor() {
        super("Access token expired");
    }
}
