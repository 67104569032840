import {observer} from "mobx-react-lite";
import {FormEvent} from "react";
import {IndeterminateCheckbox, TriState} from "../checkbox";
import IconClear from "../icons/IconClear";
import s from "./SuggestionsPopup.module.css";

interface IProps {
    search: string;
    canSelectAll?: boolean;
    selectionState?: TriState;
    onSelectAll?: () => void;
    setSearch: (value: string) => void;
}

function SearchField(props: IProps) {
    const {search, setSearch, canSelectAll, selectionState, onSelectAll} = props;

    function handleSearch(e: FormEvent<HTMLInputElement>) {
        setSearch((e.target as HTMLInputElement).value)
    }

    function handleClearSearch() {
        setSearch("");
    }

    return (
        <div className={s.searchWrapper}>
            {canSelectAll && (
                <IndeterminateCheckbox
                    value={selectionState || TriState.Unchecked}
                    onToggle={onSelectAll}/>
            )}

            <input
                className={s.search}
                type="text"
                placeholder="Поиск"
                value={search}
                onInput={handleSearch}/>

            {search.length ? <IconClear className={s.searchClear} onClick={handleClearSearch}/> : null}
        </div>
    );
}

export default observer(SearchField);
