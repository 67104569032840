import {createContextAndHook} from "common/utils";
import {makeAutoObservable} from "mobx";
import {GetTasksParam, Task} from "../../types";

type ListCacheSnapshot = {
    maxPage: number;
    total: number;
    tasks: Task[];
    cachedAt: number;
}

const MAX_INVALIDATE_CACHE_TIME = 1000 * 60 * 30; // 30 minutes
const MAX_CACHE_TIME = 1000 * 60 * 10; // 10 minutes

export class LocalTasksCacheStore {
    private invalidateCachesAt: number;
    private readonly snapshots: { [key: string]: ListCacheSnapshot };

    constructor() {
        this.snapshots = {};
        this.invalidateCachesAt = 0;
        makeAutoObservable(this);
    }

    setSnapshot(filters: GetTasksParam, snapshot: Omit<ListCacheSnapshot, "cachedAt">) {
        const now = Date.now();
        this.snapshots[JSON.stringify(filters)] = {
            ...snapshot,
            cachedAt: now,
        };

        if (now - this.invalidateCachesAt > MAX_INVALIDATE_CACHE_TIME) {
            for (let [key, snapshot] of Object.entries(this.snapshots)) {
                if (now - snapshot.cachedAt > MAX_CACHE_TIME) {
                    delete this.snapshots[key];
                }
            }

            this.invalidateCachesAt = now;
        }
    }

    getSnapshot(filters: GetTasksParam): ListCacheSnapshot | null {
        const key = JSON.stringify(filters);
        const snapshot = this.snapshots[key];
        if (!snapshot) {
            return null;
        }

        if (Date.now() - snapshot.cachedAt > MAX_CACHE_TIME) {
            delete this.snapshots[key];
            return null;
        }

        return snapshot;
    }
}

const [LocalTasksCacheStoreContext, useLocalTasksCacheStore] = createContextAndHook<LocalTasksCacheStore>();
export {LocalTasksCacheStoreContext, useLocalTasksCacheStore};
