import classNames from "classnames";
import {ReactNode} from "react";
import IconArrowLeft16x16 from "../../components/icons/IconArrowLeft16x16";
import IconArrowRight16x16 from "../../components/icons/IconArrowRight16x16";
import s from "./Pagination.module.css";
import {PaginationItem} from "./PaginationItem";
import {PaginationSpacer} from "./PaginationSpacer";

export type PaginationProps = {
    className?: string;
    page: number;
    floatVisible?: number;
    endVisible?: number;
    maxPage: number;
    onNavigate: (page: number) => void;
};

export const Pagination = (props: PaginationProps) => {
    const {className, page, floatVisible = 2, endVisible = 2, maxPage: maxPage_, onNavigate} = props;

    if (maxPage_ <= 1) {
        return null;
    }

    const maxPage = maxPage_ - 1
    const items: ReactNode[] = [];

    function renderItems(from: number, to: number) {
        for (let i = from; i <= to; i++) {
            items.push((
                <PaginationItem
                    key={i}
                    isActive={i === page}
                    onClick={() => onNavigate(i)}>
                    {i + 1}
                </PaginationItem>
            ));
        }
    }

    const isPrevDisabled = page <= 0;
    items.push((
        <PaginationItem
            key="__prev"
            isDisabled={isPrevDisabled}
            onClick={() => onNavigate(page - 1)}>
            <IconArrowLeft16x16 className={isPrevDisabled ? s.pageNavDisabled : s.pageNav} color="currentColor"/>
        </PaginationItem>
    ));

    if (page > floatVisible + endVisible) {
        renderItems(0, endVisible - 1);
        items.push(<PaginationSpacer key="__left_spacer"/>);
        renderItems(page - floatVisible, Math.min(page, maxPage));
    } else {
        renderItems(0, Math.min(page, maxPage))
    }

    if (maxPage - page > floatVisible + endVisible) {
        renderItems(page + 1, page + floatVisible);
        items.push(<PaginationSpacer key="__right_spacer"/>);
        renderItems(maxPage - endVisible + 1, maxPage);
    } else {
        renderItems(page + 1, maxPage)
    }

    const isNextDisabled = page >= maxPage;
    items.push((
        <PaginationItem
            key="__next"
            isDisabled={isNextDisabled}
            onClick={() => onNavigate(page + 1)}>
            <IconArrowRight16x16 className={isNextDisabled ? s.pageNavDisabled : s.pageNav} color="currentColor"/>
        </PaginationItem>
    ));

    return (
        <ul className={classNames(s.container, className)}>
            {items}
        </ul>
    );
};
