import React, {useContext, useMemo} from "react";

interface PopupStackHost {
    putOnStack(modalId: string): void;

    removeFromStack(modalId: string): void;

    isTopOnStack(modalId: string): boolean;
}

export function usePopupStackContext(): PopupStackHost {
    const modalStack: string[] = useMemo(() => [], []);

    return {
        putOnStack(modalId: string) {
            if (modalStack.includes(modalId)) {
                throw new Error("Popup already on stack");
            }

            modalStack.push(modalId);
        },
        removeFromStack(modalId: string) {
            if (!modalStack.includes(modalId)) {
                throw new Error("Popup not found on stack");
            }

            modalStack.splice(modalStack.indexOf(modalId), 1);
        },
        isTopOnStack(modalId: string) {
            return modalStack[modalStack.length - 1] === modalId;
        }
    };
}

export const PopupStackContext = React.createContext<PopupStackHost | null>(null);

export function usePopupStack(): PopupStackHost {
    const context = useContext(PopupStackContext);
    if (context === null) {
        throw new Error("ModalHost context not provided")
    }

    return context;
}
