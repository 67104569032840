import classNames from "classnames";
import {CSSProperties, ThHTMLAttributes, useState} from "react";
import {HeaderGroup} from "react-table";
import useGlobalEvent from "../../../common/hooks/useGlobalEvent";
import {TableHeaderCell as TableHeaderCellBase} from "../../components/table/TableHeaderCell";
import {IndeterminateCheckbox, TriState} from "../checkbox";
import s from "./index.module.css";
import TableResizer from "./TableResizer";
import TableSortableIndicator from "./TableSortableIndicator";

interface IProps<D extends object> {
    headCellClassName?: string;
    column: HeaderGroup<D>;
    canSelectRows?: boolean;
    allSelectionState?: TriState;
    isSortable: boolean;
    isResizable: boolean;
    isHovered: boolean;
    isDraggable: boolean;
    isDragDist: boolean;
    onDragStart: (columnId: string) => void;
    onDragEnd: (dropColumnId: string | null) => void;
    onDragOver: (columnId: string | null) => void;
    onSelectAllRows?: () => void;
}

function mergeStyles(...styles: (CSSProperties | undefined)[]) {
    const merged = {};
    for (const style of styles) {
        for (const key in style) {
            const value = style[key as keyof CSSProperties];
            if (!value) {
                continue;
            }

            // @ts-ignore
            merged[key] = value;
        }
    }

    return merged;
}

function useOrderPrevent(): boolean {
    const [isDragPrevent, setDragPrevent] = useState(false);
    useGlobalEvent("mousedown", e => {
        const target = e.target as HTMLElement;
        if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
            setDragPrevent(true);
        }
    });

    useGlobalEvent("mouseup", () => {
        setDragPrevent(false);
    });

    return isDragPrevent;
}

function TableHeaderColumn<D extends object>(props: IProps<D>) {
    const {
        column,
        headCellClassName,
        canSelectRows,
        allSelectionState,
        isSortable,
        isResizable,
        isHovered,
        isDraggable,
        isDragDist,
        onDragEnd,
        onDragStart,
        onDragOver,
        onSelectAllRows,
    } = props;

    let replacedProps: ThHTMLAttributes<HTMLTableHeaderCellElement> = {
        style: {
            minWidth: column.minWidth,
            width: column.width,
            maxWidth: column.maxWidth,
            cursor: isDraggable ? "move" : "default",
        },
    };

    const canSort = isSortable ? column.canSort : (column.defaultCanSort || false);
    if (canSort) {
        const sortByProps = column.getSortByToggleProps();
        replacedProps = {
            ...replacedProps,
            ...sortByProps,
            style: mergeStyles(sortByProps.style, replacedProps.style),
        };
    }

    const isDragPrevent = useOrderPrevent();


    const headerProps = column.getHeaderProps(replacedProps);
    return (
        <TableHeaderCellBase
            className={classNames(headCellClassName, {[s.tableCellDrag]: isDragDist})}
            {...headerProps}
            title=""
            draggable={isDraggable && !isDragPrevent}
            onDragStart={() => onDragStart(column.id)}
            onDragEnter={e => e.preventDefault()}
            onDragOver={e => {
                e.preventDefault();
                onDragOver(column.id);
            }}
            onDrop={() => onDragEnd(column.id)}
            onDragEnd={() => onDragEnd(null)}>
            {canSelectRows && (
                <div className={s.tableCellWithCheck}>
                    <IndeterminateCheckbox
                        value={allSelectionState || TriState.Unchecked}
                        onToggle={onSelectAllRows}/>
                    {column.render("Header")}
                </div>
            )}
            {!canSelectRows && column.render("Header")}
            {isResizable && <TableResizer column={column} isHovered={isHovered} isResizing={column.isResizing}/>}
            <TableSortableIndicator
                isSortable={canSort}
                isSorted={column.isSorted}
                isSortedDesc={column.isSortedDesc}/>
        </TableHeaderCellBase>
    );
}

export default TableHeaderColumn;
