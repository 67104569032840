export class VisibleSystemsRepository {
    private readonly KEY = "systems_visibility";

    findAll(): number[] {
        const sizes = localStorage.getItem(this.KEY);
        if (!sizes) {
            return [];
        }

        try {
            const parsed = JSON.parse(sizes);
            if (typeof parsed !== "object" || !Array.isArray(parsed)) {
                return [];
            }

            return parsed;
        } catch (e) {
            return [];
        }
    }

    upsert(systems: number[]) {
        localStorage.setItem(this.KEY, JSON.stringify(systems));
    }
}
