import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {ReactNode, useMemo} from "react";
import {useUserStore} from "../../stores";
import IconGear from "../icons/IconGear";
import IconLogout from "../icons/IconLogout";
import Ripple from "../ripple";
import {P} from "../typography";
import s from "./index.module.css";
import {useUserAsideStore} from "./store";

interface IMenuItem {
    title: string;
    icon: ReactNode;

    onClick(): void;
}

function ProfileMenu() {
    const store = useUserAsideStore();
    const userStore = useUserStore();

    const menuItems: IMenuItem[] = useMemo(() => {
        return [
            {
                title: "Настройки",
                icon: <IconGear className={s.profileMenuItemIcon}/>,
                onClick() {
                    store.setEditShowed(true);
                }
            },
            {
                title: "Выход",
                icon: <IconLogout className={s.profileMenuItemIcon}/>,
                onClick() {
                    userStore.logout();
                }
            },
        ]
    }, [store, userStore]);

    return (
        <ul
            className={classNames(
                s.profileMenu,
                {[s.profileMenuOpened]: store.isProfileOpened},
            )}>
            {menuItems.map((item, index) => (
                <Ripple key={index} onClick={e => {
                    e.stopPropagation();
                    item.onClick();
                    store.setProfileOpened(false);
                }}>
                    <li className={s.profileMenuItem}>
                        {item.icon}
                        <P className={s.profileMenuItemTitle} color="none">{item.title}</P>
                    </li>
                </Ripple>
            ))}
        </ul>
    );
}

export default observer(ProfileMenu);