import FuzzySort from "fuzzysort";
import {useMemo, useState} from "react";
import {IMenuItemUiState} from "./state";

export function useSuggestionsSearch<T>(
    items: IMenuItemUiState<T>[],
    searchControlled?: string,
    setSearchControlled?: (value: string) => void,
) {
    const [search, setSearch] = useState("");

    const suggestions = useMemo(() => {
        if (!search.length) {
            return items;
        }

        return FuzzySort.go(search, items, {key: "label"}).map(item => item.obj);
    }, [search, items]);

    return {
        suggestions: searchControlled === undefined ? suggestions : items,
        search: searchControlled || search,
        setSearch: setSearchControlled || setSearch,
    }
}
