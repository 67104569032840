import createSvgIcon from "./utils";

const IconBell16 = createSvgIcon((
    <>
        <path
            d="M3.47344 5.21534C3.73127 2.90991 5.68052 1.1665 8.00033 1.1665V1.1665C10.3201 1.1665 12.2694 2.9099 12.5272 5.21533L12.7287 7.01714C12.8345 7.96261 13.1439 8.87393 13.6357 9.68832L14.1055 10.4662C14.3762 10.9145 14.5116 11.1386 14.5448 11.3176C14.6331 11.7939 14.3672 12.2651 13.9138 12.4357C13.7435 12.4998 13.4817 12.4998 12.958 12.4998H3.04268C2.51899 12.4998 2.25715 12.4998 2.08685 12.4357C1.63343 12.2651 1.36757 11.7939 1.45588 11.3176C1.48905 11.1386 1.62441 10.9145 1.89513 10.4662L2.36493 9.68832C2.85675 8.87393 3.16619 7.96262 3.27193 7.01714L3.47344 5.21534Z"
            fill="transparent"/>
        <path
            d="M5.42452 12.6959C5.57646 13.4047 5.91124 14.031 6.37696 14.4777C6.84268 14.9244 7.4133 15.1665 8.00032 15.1665C8.58735 15.1665 9.15797 14.9244 9.62369 14.4777C10.0894 14.031 10.4242 13.4047 10.5761 12.6959"
            fill="transparent" strokeLinecap="round"/>
    </>

), {width: 16, height: 16});

export default IconBell16;