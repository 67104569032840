import {TColumnOrder} from "../types";

/**
 * Репозиторий для хранения порядка колонок в таблицах. Например, на странице со списком заявок.
 */
export class ColumnOrderRepository {
    private readonly KEY = "columns_order";

    /**
     * Обновляет порядок колонок для страницы {@link page}.
     */
    upsert(page: string, columnOrder: TColumnOrder) {
        const all = this.findAll();
        all[page] = columnOrder;
        localStorage.setItem(this.KEY, JSON.stringify(all));
    }

    /**
     * Возвращает порядок колонок для страницы {@link page} или **null**, если порядок не сохранен.
     */
    findByPage(page: string): TColumnOrder | null {
        const all = this.findAll();
        return all[page] || null;
    }

    /**
     * Удаляет порядок колонок для страницы {@link page}.
     */
    deleteByPage(page: string) {
        const all = this.findAll();
        delete all[page];
        localStorage.setItem(this.KEY, JSON.stringify(all));
    }

    private findAll(): Record<string, TColumnOrder> {
        const sizes = localStorage.getItem(this.KEY);
        if (!sizes) {
            return {};
        }

        try {
            const parsed = JSON.parse(sizes);
            if (typeof parsed !== "object" || Array.isArray(parsed)) {
                return {};
            }

            return parsed;
        } catch (e) {
            return {};
        }
    }
}