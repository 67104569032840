import {DateRangeEntryFilter} from "./DateRangeEntryFilter";
import {IEntryFilter} from "./IEntryFilter";
import {MultiEntryFilter} from "./MultiEntryFilter";
import {SingleEntryFilter} from "./SingleEntryFilter";
import {TAsyncFilterOptions, TDateRangeFilterOptions} from "./TFilterOptions";

export interface MultiValueBuilderOptions {
    isAvailable?: boolean;
    canExclude?: boolean;
}

export class EntryFiltersBuilder<Q> {
    filters: IEntryFilter<Q, any>[];

    constructor() {
        this.filters = [];
    }

    addSingleValueAsync<T, K = number>(options: TAsyncFilterOptions<T, Q, K>) {
        this.filters.push(new SingleEntryFilter<T, Q>(options));
        return this;
    }

    addMultiValueAsync<T, K = number>(options: TAsyncFilterOptions<T, Q, K[]> & MultiValueBuilderOptions) {
        if (options.isAvailable === undefined || options.isAvailable) {
            this.filters.push(new MultiEntryFilter(options));
        }

        return this;
    }

    addDateRange(options: TDateRangeFilterOptions<Q>) {
        this.filters.push(new DateRangeEntryFilter(options));
        return this;
    }

    build() {
        return this.filters;
    }
}
