import {AsyncJob} from "common/stores/job";
import {makeAutoObservable} from "mobx";
import {SavedTaskFilterRepository} from "../../repositories";
import {TaskFilter} from "../../types";
import {SavedTaskFilter} from "../../types/SavedTaskFilter";

export class SaveFilterStore {
    isShow: boolean;
    name: string;

    private filters: TaskFilter[] | null;
    private companyId: number | null;
    private saveListener: ((filter: SavedTaskFilter) => void) | null;
    private readonly saveJob: AsyncJob<typeof SaveFilterStore.prototype._save>;

    constructor(private readonly filterRepo: SavedTaskFilterRepository) {
        this.isShow = false;
        this.companyId = null;
        this.name = "";
        this.filters = null;
        this.saveListener = null;
        makeAutoObservable(this, {}, {autoBind: true});

        this.saveJob = new AsyncJob({job: this._save});
    }

    get isLoading() {
        return this.saveJob.isPending;
    }

    get errorMessage() {
        return this.saveJob.errorMessage;
    }

    get canSave() {
        return this.name.trim().length > 0;
    }

    setSaveListener(listener: (filter: SavedTaskFilter) => void) {
        this.saveListener = listener;
    }

    setName(value: string) {
        this.name = value;
    }

    save() {
        this.saveJob.start();
    }

    show(companyId: number, filters: TaskFilter[]) {
        this.isShow = true;
        this.name = "";
        this.companyId = companyId;
        this.filters = filters;
        this.saveJob.clearError();
    }

    close() {
        this.isShow = false;
    }

    private* _save() {
        const companyId = this.companyId;
        const filters = this.filters;
        if (companyId === null || filters === null) throw new Error("Illegal state: companyId or filters is null");

        const savedFilter: SavedTaskFilter = yield this.filterRepo.save(companyId, this.name.trim(), filters);
        this.saveListener?.(savedFilter);
        this.close();
    }
}
