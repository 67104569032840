import {ReactNode} from "react";
import {P} from "../../typography";

type Props = {
    children: ReactNode;
};

export const NotificationModalText = (props: Props) => {
    const {children} = props;
    return <P variant="Paragraph/Default/p">{children}</P>
};
