import {AuthorizedHttpClient} from "../net";
import {SortOrder} from "../types";

export class SortOrderRepository {
    constructor(private client: AuthorizedHttpClient) {
    }

    async findAllForTasks(): Promise<SortOrder[]> {
        return this.client.get<SortOrder[]>("v1/tasks/sort-orders");
    }

    async findAllForDocuments(): Promise<SortOrder[]> {
        return this.client.get<SortOrder[]>("web/v1/tasks/documents/sort-orders");
    }
}
