import {DumpStateRepository} from "common/repositories";
import {DumperStore} from "common/stores";
import {makeAutoObservable} from "mobx";

type Params = {
    companyId: number | null;
    dumpRepo: DumpStateRepository,
    provideTotalCount: () => number,
    providePageIds: () => number[],
    provideSelectedFilters: () => object,
}

export class TaskDumperStore {
    isActivated: boolean;
    base: DumperStore;
    private readonly provideTotalCount: Params["provideTotalCount"];

    constructor(params: Params) {
        const dumpRepo = params.dumpRepo;
        const companyId = params.companyId;
        this.base = new DumperStore({
            providePageIds: params.providePageIds,
            fetchDump: (mode, ids) => dumpRepo.create({
                companyId,
                tasks: ids,
                mode: mode,
                filterBy: params.provideSelectedFilters(),
            }),
            fetchCancelDump: () => dumpRepo.delete(companyId),
            fetchDumpState: () => dumpRepo.find(companyId),
        })

        this.isActivated = false;
        this.provideTotalCount = params.provideTotalCount;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get selectedCount() {
        return this.base.countSelected(this.totalCount);
    }

    get canSelectAll() {
        return this.base.canSelectAll;
    }

    get totalCount() {
        return this.provideTotalCount();
    }

    get canSelect() {
        return this.isActivated && this.base.pendingDump === null;
    }

    toggleSelectionMode() {
        this.isActivated = !this.isActivated;
        if (!this.isActivated) {
            this.base.reset();
        }
    }

    downloadDump() {
        this.isActivated = false;
        this.base.downloadDump();
    }

    dismissDump() {
        this.isActivated = false;
        this.base.dismissDump();
    }

    reset() {
        this.base.reset();
    }

    clearError() {
        this.base.clearError();
    }
}
