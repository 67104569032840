import {UserStore} from "common/stores";
import {makeAutoObservable} from "mobx";
import {DefinedUserRole} from "users/types";

export class CompanyPermissions {
    private userStore: UserStore | null;

    constructor() {
        this.userStore = null;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    /**
     * Определяет, может ли авторизированный пользователь изменять информацию внутри компании (название, ИНН, КПП, системы и тд).
     */
    get canEditDetails() {
        const roleId = this.requireUserStore().roleId;
        return [
            DefinedUserRole.ADMIN,
            DefinedUserRole.ADMIN_ETD
        ].includes(roleId);
    }

    //Класс UserStore инициализируется и передается с помощью контекста в компоненте GlobalLoadingScreen, а данный класс
    //инициализируется в RepositoriesStore. Этот метод - костыль, потому что нельзя получить UserStore из RepositoriesStore.
    setUserStore(userStore: UserStore) {
        this.userStore = userStore;
    }

    private requireUserStore(): UserStore {
        if (this.userStore === null) {
            throw new Error("UserStore is not set");
        }

        return this.userStore;
    }
}
