import {useState} from "react";
import {HeaderGroup} from "react-table";
import {TriState} from "../checkbox";
import TableHeaderColumn from "./TableHeaderColumn";

interface IProps<D extends object> {
    headCellClassName?: string;
    headerGroup: HeaderGroup<D>;
    distColumnId: string | null;
    canSelectRows?: boolean;
    allSelectionState?: TriState;
    isSortable: boolean;
    isResizable: boolean;
    isDraggable: boolean;
    onColumnOrderStart: (sourceColumnId: string) => void;
    onColumnOrderEnd: (distColumnId: string | null) => void;
    onColumnOrderOver: (distColumnId: string | null) => void;
    onSelectAllRows?: () => void;
}

function TableHeaderRow<D extends object>(props: IProps<D>) {
    const {
        headerGroup: group,
        headCellClassName,
        distColumnId,
        canSelectRows,
        allSelectionState,
        isSortable,
        isResizable,
        isDraggable,
        onColumnOrderStart,
        onColumnOrderEnd,
        onColumnOrderOver,
        onSelectAllRows,
    } = props;
    const [isHovered, setHovered] = useState(false);

    function onMouseEnter() {
        setHovered(true);
    }

    function onMouseLeave() {
        setHovered(false);
    }

    const isAnyResizing = isResizable && group.headers.some(x => x.isResizing);
    return (
        <tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...group.getHeaderGroupProps()}>
            {group.headers.map((column, index) => (
                <TableHeaderColumn
                    key={index}
                    headCellClassName={headCellClassName}
                    column={column}
                    canSelectRows={canSelectRows && index === 0}
                    allSelectionState={allSelectionState}
                    isHovered={!isAnyResizing || (isAnyResizing && column.isResizing) ? isHovered : false}
                    isSortable={isSortable}
                    isResizable={isResizable}
                    isDraggable={isDraggable}
                    isDragDist={column.id === distColumnId}
                    onDragStart={onColumnOrderStart}
                    onDragEnd={onColumnOrderEnd}
                    onDragOver={onColumnOrderOver}
                    onSelectAllRows={onSelectAllRows}/>
            ))}
        </tr>
    );
}

export default TableHeaderRow;
