import {BanCitizenStore} from "citizens/modals/ban_citizen/BanCitizenStore";
import {useRepositories} from "common/components/app/RepositoriesStore";
import {
    NotificationModal,
    NotificationModalHighlight,
    NotificationModalText
} from "common/components/modal/notification";
import {observer} from "mobx-react-lite";
import React, {useMemo} from "react";

type Props = {
    store: BanCitizenStore;
};

export function useBanCitizenStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new BanCitizenStore(repos.citizen), [repos]);
}

export const BanCitizenModal = observer((props: Props) => {
    const {store} = props;
    const modal = store.modal;
    return modal.isShow && modal.item ? (
        <NotificationModal
            title="Подтвердите действие"
            positive={modal.item.action === "ban" || modal.item.action === "ban-finance" ? "Заблокировать" : "Разблокировать"}
            negative="Закрыть"
            onNegative={modal.close}
            onPositive={modal.confirm}>
            <NotificationModalText>
                Вы уверены, что
                хотите {modal.item.action === "ban" || modal.item.action === "ban-finance" ? "заблокировать " : "разблокировать "}
                жителя <NotificationModalHighlight>{modal.item.citizen.fullName}</NotificationModalHighlight>?
            </NotificationModalText>
        </NotificationModal>
    ) : null;
});