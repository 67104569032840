import {DeleteCitizenStore} from "citizens/modals/delete_citizen/DeleteCitizenStore";
import {useRepositories} from "common/components/app/RepositoriesStore";
import {
    NotificationModal,
    NotificationModalHighlight,
    NotificationModalText
} from "common/components/modal/notification";
import {observer} from "mobx-react-lite";
import React, {useMemo} from "react";

type Props = {
    store: DeleteCitizenStore;
};

export function useDeleteCitizenStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new DeleteCitizenStore(repos.citizen), [repos]);
}

export const DeleteCitizenModal = observer((props: Props) => {
    const {store} = props;
    const modal = store.modal;
    return modal.isShow && modal.item ? (
        <NotificationModal
            title="Подтвердите действие"
            positive="Удалить"
            negative="Закрыть"
            onNegative={modal.close}
            onPositive={modal.confirm}>
            <NotificationModalText>
                Вы уверены, что
                хотите удалить
                жителя <NotificationModalHighlight>{modal.item.fullName}</NotificationModalHighlight>?
            </NotificationModalText>
        </NotificationModal>
    ) : null;
});