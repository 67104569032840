import {useEffect, useRef} from "react";

function useGlobalEvent<K extends keyof DocumentEventMap>(eventName: K, callback: (e: DocumentEventMap[K]) => void) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
        const eventListener = (e: any) => {
            callbackRef.current(e);
        };
        document.addEventListener(eventName, eventListener);

        return () => document.removeEventListener(eventName, eventListener);
    }, [eventName]);

}

export default useGlobalEvent;