import {AuthorizedHttpClient} from "common/net";
import {UpdateCompanyBillingQrParams} from "../types";

export class CompanyBillingQrRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    update(companyId: number, params: UpdateCompanyBillingQrParams): Promise<void> {
        return this.client.put(`web/v1/companies/${companyId}/billing-qr`, {
            body: {
                url: params.url || null,
            }
        });
    }
}
