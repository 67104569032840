import classNames from "classnames";
import {P} from "../typography";
import {Pagination, PaginationProps} from "./Pagination";
import s from "./Pagination.module.css";

type Props = {
    total: number;
    limit: number;
} & PaginationProps;

export const PaginationWithCount = (props: Props) => {
    const {className, page, maxPage, total, limit, ...defaultProps} = props;

    let viewed;
    if (page === maxPage - 1) {
        viewed = total;
    } else {
        viewed = (page + 1) * limit;
    }

    return (
        <div className={classNames(s.containerWrapper, className)}>
            <Pagination page={page} maxPage={maxPage} {...defaultProps} />
            {total > 0 && <P>{viewed} из {total}</P>}
        </div>
    );
};
