import {minutesToMs} from "../../utils";

/**
 * Используется в {@link AsyncJob} для указания стратегии кеширование, полученных от сервера данных.
 */
export interface ICachePolicy {
    /**
     * Возвращает истину, если ранее кешированные данные потеряли актуальность и необходимо повторно отправить запрос
     * на их получение.
     * @param lastCacheTime - время в миллисекундах, когда в последний раз данные были запрошены от сервера.
     * @returns - потеряли ли кешированные ранее данные актуальность.
     */
    isExpire(lastCacheTime: number): boolean;
}

class FixedCachePolicy implements ICachePolicy {
    /**
     * @param lifeTime - в миллисекундах
     */
    constructor(private readonly lifeTime: number) {
        if (lifeTime < 0) {
            throw Error("Cache life time must be > 0");
        }
    }

    isExpire(lastCacheTime: number): boolean {
        if (this.lifeTime === 0) {
            return true;
        }

        return Date.now() - lastCacheTime > this.lifeTime;
    }
}

/**
 * Определяет общие для всего проекта стратегии кеширования, полученных от сервера данных.
 */
export class CachePolicy {
    /**
     * Всегда отправлять запрос на получения данных, не кешируя результат.
     */
    static readonly NEVER = new FixedCachePolicy(0);

    /**
     * Отправлять запрос на получение данных либо при первом обращении, либо, если от последнего обращения прошло не менее
     * пяти минут, иначе возвращать полученные ранее данные.
     */
    static readonly MINUTES_5 = new FixedCachePolicy(minutesToMs(5));

    /**
     * Отправлять запрос на получение данных либо при первом обращении, либо, если от последнего обращения прошло не менее
     * тридцати минут, иначе возвращать полученные ранее данные.
     */
    static readonly MINUTES_30 = new FixedCachePolicy(minutesToMs(30));
}