import {observer} from "mobx-react-lite";
import {Fragment, FunctionComponent, UIEventHandler, useMemo} from "react";
import {P} from "../typography";
import NotFoundSuggestions from "./NotFoundSuggestions";
import {IMenuItemUiState} from "./state";
import s from "./SuggestionsPopup.module.css";

interface IProps<T, S extends IMenuItemUiState<any>[] | null> {
    items: IMenuItemUiState<T>[];
    selected: S;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    Skeleton: FunctionComponent;
    onFetchMore?: () => void;

    render(item: IMenuItemUiState<T>): JSX.Element;
}

const FETCH_MORE_THRESHOLD = 100;

function SuggestionListBase<T, S extends IMenuItemUiState<any>[] | null>(props: IProps<T, S>) {
    const {items, selected, Skeleton, render, isLoading, isMoreLoading, onFetchMore} = props;

    const unselectedItems = useMemo(() => {
        if (!selected) {
            return items;
        }

        return items.filter(item => !selected.some(it => it.key === item.key));
    }, [items, selected]);

    if (isLoading) {
        const items = [];
        for (let i = 0; i < 5; i++) {
            items.push(<Skeleton key={i}/>);
        }

        return (
            <ul className={s.suggestions}>
                {items}
            </ul>
        );
    }

    if (!items.length) {
        return <NotFoundSuggestions/>
    }

    const handleScroll: UIEventHandler = e => {
        const element = e.target as HTMLElement;
        if (element.scrollTop + element.getBoundingClientRect().height >= element.scrollHeight - FETCH_MORE_THRESHOLD) {
            onFetchMore?.();
        }
    };

    return (
        <ul className={s.suggestions} onScroll={onFetchMore ? handleScroll : undefined}>
            {selected && selected.map(item => (
                <Fragment key={`${item.key}_$selected`}>
                    {render(item)}
                </Fragment>
            ))}

            {unselectedItems.map(item => (
                <Fragment key={item.key}>
                    {render(item)}
                </Fragment>
            ))}

            {isMoreLoading && (
                <div className={s.loadingMore}>
                    <P variant="Paragraph/Default/p" color="dark-gray-p200">
                        Загрузка...
                    </P>
                </div>
            )}
        </ul>
    );
}

export default observer(SuggestionListBase);
