import classNames from "classnames";
import {DetailedHTMLProps, ForwardedRef, forwardRef, HTMLAttributes, MouseEventHandler} from "react";
import {useNavigate} from "react-router-dom";
import s from "./Table.module.css";

type Props = {
    className?: string;
    link?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>

export const TableBodyRow = forwardRef((props: Props, ref: ForwardedRef<HTMLTableRowElement>) => {
    const {className, link, onClick, onMouseDown, ...defaultProps} = props;
    const navigate = useNavigate();

    const handleMouseDown: MouseEventHandler<HTMLTableRowElement> | undefined = link ? e => {
        if (e.button === 1) {
            e.preventDefault();
            e.stopPropagation();
            window.open(link, "_blank");
        }
        onMouseDown?.(e);
    } : onMouseDown;

    const handleClick: MouseEventHandler<HTMLTableRowElement> | undefined = link ? e => {
        navigate(link);
        onClick?.(e);
    } : onClick;

    return (
        <tr ref={ref} className={classNames(
            s.row,
            handleClick || onMouseDown ? s.clickable : null,
            className,
        )} onClick={handleClick} onMouseDown={handleMouseDown} {...defaultProps} />
    );
});