import {FlatRange, FlatRangeResponse} from "citizens/types/HouseGroup";

export interface HouseFlatRange {
    from: number;
    to: number;
    entrance: number;
}

export interface CitizenHouse {
    id: string;
    address: string;
    tariffId: string | null;
    tariffName: string;
    description: string;
    apartmentCount: number;
    entranceCount: number;
    citizenApartmentCount: number;
    userCount: number;
    adminCompany: { id: number, name: string } | null;
    delegateCompany: { id: number, name: string } | null;
    hasVideo: boolean;
    hasIntercoms: boolean;
    flatRanges: HouseFlatRange[];
    groups: ConnectedGroup[];
}

export interface ConnectedGroup {
    id: string;
    name: string;
    flatRanges: FlatRange[];
}

export interface ConnectedGroupResponse {
    group_id: string;
    name: string;
    flat_ranges: FlatRangeResponse[];
}

export interface HouseFlatRangeResponse {
    first_flat_number: number;
    last_flat_number: number;
    entrance: number;
}

export interface CitizenHouseResponse {
    house_fias_id: string;
    address: string;
    address_standard: string;
    connected_flats: number;
    flats: number;
    user_count: number;
    admin_company: { id: number, name: string };
    delegate_company: { id: number, name: string };
    entrances: number;
    description: string;
    has_intercoms: boolean;
    has_video: boolean;
    tariff_id?: string;
    tariff_name?: string;
    flat_ranges: HouseFlatRangeResponse[];
    groups: ConnectedGroupResponse[];
}

export interface CreateCitizenHouseParams {
    address: string;
    adminCompanyId: number;
    delegateCompanyId: number;
    hasVideo: boolean;
    hasIntercoms: boolean;
    flatRanges: HouseFlatRangeResponse[];
    groups: ConnectedGroup[];
}

export interface UpdateCitizenHouseParams {
    adminCompanyId?: number;
    delegationCompanyId?: number;
    description?: string;
    hasIntercoms?: boolean;
    hasVideo?: boolean;
    flatRanges?: HouseFlatRangeResponse[];
    groups?: ConnectedGroup[];
}

export function mapToCitizenHouse(response: CitizenHouseResponse): CitizenHouse {
    return {
        id: response.house_fias_id,
        address: response.address_standard || response.address || "",
        tariffId: response.tariff_id || null,
        tariffName: response.tariff_name || "",
        description: response.description || "",
        apartmentCount: response.flats || 0,
        entranceCount: response.entrances || 0,
        citizenApartmentCount: response.connected_flats || 0,
        userCount: response.user_count || 0,
        adminCompany: response.admin_company || null,
        delegateCompany: response.delegate_company || null,
        hasIntercoms: response.has_intercoms || false,
        hasVideo: response.has_video || false,
        flatRanges: response.flat_ranges ? response.flat_ranges.map(range => ({
            from: range.first_flat_number,
            to: range.last_flat_number,
            entrance: range.entrance,
        })) : [],
        groups: response.groups ? response.groups.map(group => ({
            id: group.group_id,
            name: group.name,
            flatRanges: group.flat_ranges ? group.flat_ranges.map(range => ({
                from: range.first_flat_number,
                to: range.last_flat_number,
            })) : [],
        })) : [],
    };
}
