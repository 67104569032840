import {ReactNode} from "react";
import s from "./PopupTitle.module.css";

interface IProps {
    children: ReactNode;
}

function DeprecatedPopupTitle(props: IProps) {
    const {children} = props;
    return (
        <h1 className={s.text}>{children}</h1>
    );
}

export default DeprecatedPopupTitle;