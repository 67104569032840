import createSvgIcon from "./utils";

const IconShield28x32 = createSvgIcon((
    <path
        stroke="transparent"
        fillRule="evenodd"
        d="M14.359 33.105C11.602 32.087.899 27.285.899 14.087V6.771c0-2.032 2.509-3.181 4.363-2.35.929.417 1.874.675 2.688.57 1.651-.213 3.58-1.879 5.003-3.362 1.087-1.133 3.007-1.133 4.094 0C18.47 3.112 20.4 4.778 22.05 4.991c.814.105 1.76-.153 2.688-.57 1.854-.831 4.363.318 4.363 2.35v7.316c0 13.199-10.703 18-13.46 19.018a1.837 1.837 0 0 1-1.282 0Zm-3.947-19.169a4.593 4.593 0 0 1 9.187 0v1.29a2.244 2.244 0 0 1 1.94 2.224v5.312a2.244 2.244 0 0 1-2.245 2.244h-8.578a2.244 2.244 0 0 1-2.244-2.244V17.45c0-1.136.845-2.075 1.94-2.223v-1.29Zm7.26 0v1.27H12.34v-1.27a2.666 2.666 0 1 1 5.331 0Z"
        clipRule="evenodd"/>
), {width: 28, height: 32});

export default IconShield28x32;
