import {BanCitizenStore} from "citizens/modals/ban_citizen";
import {Citizen} from "citizens/types";
import {Button} from "common/components/button";
import {ContextMenu, ContextMenuItem} from "common/components/context_menu";
import IconCheck16 from "common/components/icons/IconCheck16";
import IconClose16x16 from "common/components/icons/IconClose16x16";
import IconEmptyWallet16 from "common/components/icons/IconEmptyWallet16";
import {observer} from "mobx-react-lite";

type Props = {
    citizen: Citizen;
    store: BanCitizenStore;
};

export const BanCitizenButton = observer((props: Props) => {
    const {citizen, store} = props;

    let trigger;
    if (citizen.status === "blocked") {
        trigger = (
            <Button
                color="TransparentWarning"
                heading={<IconClose16x16 fill="currentColor"/>}>
                Заблокирован
            </Button>
        );
    } else if (citizen.status === "finance-blocked") {
        trigger = (
            <Button
                color="TransparentWarning"
                heading={<IconEmptyWallet16 fill="currentColor"/>}>
                Финансовый блок
            </Button>
        );
    } else {
        trigger = (
            <Button
                color="TransparentPrimary"
                heading={<IconCheck16 fill="currentColor"/>}>
                Активен
            </Button>
        );
    }

    return (
        <ContextMenu trigger={trigger}>
            {(citizen.status === "active" || citizen.status === "finance-blocked") && (
                <ContextMenuItem onClick={() => store.ban(citizen)}>
                    Заблокирован
                </ContextMenuItem>
            )}

            {(citizen.status === "blocked" || citizen.status === "finance-blocked") && (
                <ContextMenuItem onClick={() => store.unban(citizen)}>
                    Разблокировать
                </ContextMenuItem>
            )}

            {(citizen.status === "blocked" || citizen.status === "active") && (
                <ContextMenuItem onClick={() => store.banFinance(citizen)}>
                    Финансовый блок
                </ContextMenuItem>
            )}
        </ContextMenu>
    );
});