import createSvgIcon from "./utils";

const IconClear = createSvgIcon((
    <>
        <path
            xmlns="http://www.w3.org/2000/svg"
            d="M15.8332 5.34199L14.6582 4.16699L9.99984 8.82533L5.3415 4.16699L4.1665 5.34199L8.82484 10.0003L4.1665 14.6587L5.3415 15.8337L9.99984 11.1753L14.6582 15.8337L15.8332 14.6587L11.1748 10.0003L15.8332 5.34199Z"
            stroke="none"/>
    </>
), {width: 20, height: 20});

export default IconClear;