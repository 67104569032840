import {AuthorizedHttpClient} from "../net";
import {SearchField} from "../types";

export class SearchFieldRepository {
    constructor(private client: AuthorizedHttpClient) {
    }

    async findAllForTasks(): Promise<SearchField[]> {
        return this.client.get<SearchField[]>("web/v1/tasks/search-fields");
    }

    async findAllForDocuments(): Promise<SearchField[]> {
        return this.client.get<SearchField[]>("web/v1/tasks/documents/search-fields");
    }
}
