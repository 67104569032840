import {useRepositories} from "common/components/app/RepositoriesStore";
import {Button, ButtonSkeleton} from "common/components/button";
import {InputLabel} from "common/components/input/label";
import {InputSkeleton} from "common/components/input/skeleton";
import {TextInput} from "common/components/input/text";
import {Modal, ModalContent, ModalError, ModalFooter, ModalHeader} from "common/components/modal";
import {observer} from "mobx-react-lite";
import React, {useMemo} from "react";
import {SavedTaskFilterConstraints} from "../../types/SavedTaskFilter";
import {SaveFilterStore} from "./SaveFilterStore";

type Props = {
    store: SaveFilterStore;
};

export function useSaveFilterStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new SaveFilterStore(repos.taskFilter), [repos]);
}

export const SaveFilterModal = observer((props: Props) => {
    const {store} = props;

    if (!store.isShow) {
        return null;
    }

    return (
        <Modal width={560} onDismiss={store.close}>
            <ModalHeader title="Сохранить фильтр"/>

            <ModalContent>
                <InputLabel title="Название фильтра" isRequired>
                    {!store.isLoading && (
                        <TextInput
                            value={store.name}
                            maxLength={SavedTaskFilterConstraints.nameMaxLength}
                            onValueChange={store.setName}/>
                    )}

                    {store.isLoading && (
                        <InputSkeleton/>
                    )}
                </InputLabel>
            </ModalContent>

            <ModalError message={store.errorMessage}/>

            <ModalFooter
                negative={
                    <Button color="Warning" onClick={store.close}>
                        Отмена
                    </Button>
                }
                positive={
                    store.isLoading
                        ? <ButtonSkeleton width={100}/>
                        : <Button color="Primary" disabled={!store.canSave} onClick={store.save}>
                            Сохранить
                        </Button>
                }/>
        </Modal>
    );
});
