import classNames from "classnames";
import {observer} from "mobx-react-lite";
import React, {useRef} from "react";
import useClickOutside from "../../../common/hooks/useClickOutside";
import useGlobalEvent from "../../../common/hooks/useGlobalEvent";
import {Avatar} from "../avatar";
import IconArrowDown from "../icons/IconArrowDown";
import Ripple from "../ripple";
import {P} from "../typography";
import s from "./index.module.css";
import ProfileMenu from "./ProfileMenu";
import {useUserAsideStore} from "./store";

interface IProps {
    name: string;
    email: string;
    avatar: string;
}

function Profile(props: IProps) {
    const {name, email, avatar} = props;
    const ref = useRef(null);
    const store = useUserAsideStore();

    useClickOutside(() => {
        store.setProfileOpened(false);
    }, ref);

    useGlobalEvent("keydown", (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            store.setProfileOpened(false);
        }
    });

    return (
        <div
            ref={ref}
            className={classNames(s.profileWrapper, {[s.profileOpened]: store.isProfileOpened})}
            onClick={store.toggleProfile}>
            <ProfileMenu/>
            <Ripple className={s.profile}>
                <Avatar src={avatar} size={40}/>
                <div className={s.profileUser}>
                    <div className={s.profileName}>
                        <P className={s.asideTitle} variant="Paragraph/Default/p-def">{name}</P>
                        <IconArrowDown className={s.profileDropdown}/>
                    </div>
                    <P className={s.profileEmail} variant="Paragraph/Small/p" color="dark-gray-p200">{email}</P>
                </div>
            </Ripple>
        </div>
    );
}

export default observer(Profile);
