import createSvgIcon from "./utils";

const IconRequests35x35 = createSvgIcon((
    <>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="M10.306 6c-1.14 1.14-1.14 2.973-1.14 6.64v9.722c0 3.666 0 5.5 1.14 6.638 1.033 1.034 2.638 1.13 5.666 1.138v-3.401a1.458 1.458 0 0 1 2.917 0v3.401c3.028-.008 4.633-.104 5.666-1.138 1.14-1.139 1.14-2.972 1.14-6.638v-9.723c0-3.666 0-5.5-1.14-6.638-1.139-1.14-2.972-1.14-6.638-1.14h-.973c-3.666 0-5.5 0-6.638 1.14Zm1.777 3.723a.972.972 0 0 0-.972.972v.972c0 .537.435.972.972.972h.973a.972.972 0 0 0 .972-.972v-.972a.972.972 0 0 0-.972-.972h-.973Zm-.972 5.833c0-.537.435-.972.972-.972h.973c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.973h-.973a.972.972 0 0 1-.972-.973v-.972Zm.972 3.889a.972.972 0 0 0-.972.972v.972c0 .537.435.973.972.973h.973a.972.972 0 0 0 .972-.973v-.972a.972.972 0 0 0-.972-.972h-.973Zm3.89-8.75c0-.537.434-.972.972-.972h.972c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.972h-.973a.972.972 0 0 1-.972-.972v-.972Zm.972 3.889a.972.972 0 0 0-.973.972v.972c0 .537.435.973.973.973h.972a.972.972 0 0 0 .972-.973v-.972a.972.972 0 0 0-.972-.972h-.973Zm-.973 5.833c0-.537.435-.972.973-.972h.972c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.973h-.973a.972.972 0 0 1-.972-.973v-.972Zm4.861-9.722c0-.537.436-.972.973-.972h.972c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.972h-.972a.972.972 0 0 1-.973-.972v-.972Zm.973 3.889a.972.972 0 0 0-.973.972v.972c0 .537.436.973.973.973h.972a.972.972 0 0 0 .972-.973v-.972a.972.972 0 0 0-.972-.972h-.972Zm-.973 5.833c0-.537.436-.972.973-.972h.972c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.973h-.972a.972.972 0 0 1-.973-.973v-.972Z"
            clipRule="evenodd"/>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="M26.268 29.538c.836.245 1.443.354 2.013.316a4.926 4.926 0 0 0 3.39-1.691c.647-.747 1.016-1.847 1.753-4.047l.528-1.574c.737-2.2 1.106-3.3 1.04-4.29a5 5 0 0 0-1.676-3.419 3.394 3.394 0 0 0-.203-.165l-.435 1.301a1.644 1.644 0 0 1-2.088 1.042 1.665 1.665 0 0 1-1.033-2.106l.527-1.573-.779-.267-.781-.266-.527 1.574c-.12.357-.349.646-.636.842v4.446a.814.814 0 0 1 .613-.034l2.34.798c.432.147.663.618.517 1.053a.822.822 0 0 1-1.044.52l-2.34-.797a.838.838 0 0 1-.086-.035v1.309c0 .46 0 .903-.003 1.326l1.638.559c.43.147.662.618.516 1.053a.822.822 0 0 1-1.044.52l-1.151-.392c-.024.47-.06.908-.113 1.31-.13.972-.387 1.889-.936 2.687Zm-.574-6.304-1.379-.47a.822.822 0 0 0-1.044.52.832.832 0 0 0 .517 1.053l1.883.643c-.061 1.988-.285 3.18-1.103 4.008l-.539-.184c-2.181-.743-3.272-1.115-4.011-1.768a5.001 5.001 0 0 1-1.677-3.42c-.029-.433.026-.889.159-1.448a.97.97 0 0 0 .389-.779v-.573c.14-.435.304-.926.492-1.49l.528-1.573c.737-2.2 1.106-3.3 1.753-4.046.39-.45.851-.82 1.361-1.099a.968.968 0 0 0 .355-.176 4.906 4.906 0 0 1 1.674-.417 3.38 3.38 0 0 1 .26-.007l-.436 1.3c-.266.794.097 1.65.818 2.016v7.91Zm1.667-9.56.91-2.715a.833.833 0 0 0-.516-1.053.813.813 0 0 0-.421-.029c.027.796.027 1.681.027 2.65v1.148Zm-1.667-.214v.741a.835.835 0 0 1-.038-.627l.038-.114Zm-3.402 2.572-.01.01.01.01.01-.01-.01-.01Zm0 4.861-.01.01.01.01.01-.01-.01-.01Zm-.487-6.31a.972.972 0 0 0-.972.973v.972c0 .537.436.973.973.973h.972a.972.972 0 0 0 .972-.973v-.972a.972.972 0 0 0-.972-.972h-.973Zm-.972 5.834c0-.537.436-.972.973-.972h.972c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.973h-.973a.972.972 0 0 1-.972-.973v-.972Zm11.603-8.915a.822.822 0 0 0-1.044.52l-1.055 3.149a.832.832 0 0 0 .517 1.053.822.822 0 0 0 1.043-.521l1.056-3.148a.832.832 0 0 0-.517-1.053Z"
            clipRule="evenodd"/>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="m7.523 10.007-.799.27h-.001l-.92.312.623 1.841a1.944 1.944 0 1 1-3.684 1.246l-.515-1.522a4.054 4.054 0 0 0-.239.193 5.833 5.833 0 0 0-1.98 4.001c-.077 1.158.359 2.446 1.23 5.02l.622 1.842c.87 2.575 1.306 3.863 2.07 4.736a5.833 5.833 0 0 0 4.002 1.98c.294.02.597.006.927-.04-.737-.875-1.052-1.919-1.202-3.035-.06-.448-.097-.939-.12-1.472a.973.973 0 0 1-.45-1.884l.414-.14-.001-.88v-2.198l-1.346.456a.972.972 0 0 1-.623-1.843l1.969-.665V12.526c0-.915 0-1.756.023-2.519Zm1.644 9.664a.973.973 0 0 0 0-1.587v-5.199a1.94 1.94 0 0 0 2.39-.4.967.967 0 0 0 .526.154h.973a.972.972 0 0 0 .972-.972v-.972a.972.972 0 0 0-.972-.972h-.973a.969.969 0 0 0-.388.08l-.258-.763c.104-.001.206.002.307.008a5.834 5.834 0 0 1 4.002 1.98c.078.09.154.184.226.284v.355c0 .443.297.817.702.934.23.535.465 1.18.74 1.983h-.47a.972.972 0 0 0-.972.972v.972c0 .537.435.972.972.972h.973c.162 0 .314-.04.449-.11l.072.215c.87 2.575 1.306 3.863 1.229 5.02a5.834 5.834 0 0 1-1.163 3.124 1.458 1.458 0 0 0-2.531.988v.879c-.756.315-1.726.643-3.021 1.081-.836.283-1.536.52-2.14.707a2.895 2.895 0 0 1-.506-.404c-.847-.847-1.064-2.078-1.12-4.161l4.05-1.37a.972.972 0 0 0 .528-1.413.969.969 0 0 0 .264-.667v-.972a.972.972 0 0 0-.972-.972h-.973a.972.972 0 0 0-.972.972v.972c0 .255.098.486.257.66l-2.201.744v-3.121Zm.001-8.235.02.06a.972.972 0 0 0 1.843-.623L9.785 7.19a.973.973 0 0 0-.117-.236c-.425.991-.49 2.384-.5 4.483Zm3.401-.265-.01.01.01.01.01-.01-.01-.01Zm0 4.861-.01.01.01.01.01-.01-.01-.01Zm0 4.861-.01.01.01.01.01-.01-.01-.01Zm4.862-4.86-.01.01.01.009.01-.01-.01-.01Zm0 4.86-.01.01.01.01.01-.01-.01-.01Zm-6.32-5.337c0-.537.435-.972.972-.972h.973c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.972h-.973a.972.972 0 0 1-.972-.972v-.972Zm4.861 4.861c0-.537.435-.972.972-.972h.973c.537 0 .972.435.972.972v.972a.972.972 0 0 1-.972.973h-.973a.972.972 0 0 1-.972-.973v-.972ZM3.027 8.448a.972.972 0 0 1 1.232.61l1.246 3.684a.972.972 0 1 1-1.842.622L2.417 9.68a.972.972 0 0 1 .61-1.232Z"
            clipRule="evenodd"/>
    </>
), {width: 35, height: 35});

export default IconRequests35x35;
