export enum HouseSystemIcon {
    FIRE_EXTINGUISHER = "fire_extinguisher",
    GEAR = "gear",
    WIRELESS = "wireless",
    ARROWS_PANEL = "arrows_panel",
    INTERCOM = "intercom",
    CAMERA = "camera",
    CHIPSET = "chipset",
    PIPE = "pipe",
    TEMPERATURE = "temperature",
    LIGHTNING = "lightning",
    WIRE_PLUG = "wire_plug",
    FAN = "fan",
    WINDOW = "window",
    CLEANING = "cleaning",
    GAS = "gas",
    WATER = "water",
    LAND = "land",
    PAPERS = "papers",
    REPAIR = "repair",
    PAINT_BRUSH = "paint_brush",
    ROOF = "roof",
    LIFT = "lift",
}

export const possibleSystemIcons = Object.values(HouseSystemIcon) as HouseSystemIcon[];

export interface HouseSystemResponse {
    id: number;
    name: string;
    icon: HouseSystemIcon;
    company_id: number;
    company_name: string;
}

export interface HouseSystem {
    id: number;
    name: string;
    icon: HouseSystemIcon;
    companyId: number;
    companyName: string;
}

/**
 * Минимальная информация о системе. Полный объект - {@link HouseSystem}.
 *
 * Используется для оптимизации поиска по системам на страницах заявок, благодаря отсутствию время затратных полей для
 * формирования на сервере.
 */
export interface ListedHouseSystem {
    id: number;

    name: string;
}

export interface CreateHouseSystemParam {
    name: string;
    icon: HouseSystemIcon;
    companyId: number;
}

export interface UpdateHouseSystemParam {
    id: number;
    name: string;
    companyId: number;
    icon: HouseSystemIcon;
}

export function mapToHouseSystem(response: HouseSystemResponse): HouseSystem {
    return {
        id: response.id,
        name: response.name,
        icon: response.icon,
        companyId: response.company_id,
        companyName: response.company_name,
    };
}
