import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {ForwardedRef, forwardRef, HTMLProps} from "react";
import {Link} from "react-router-dom";
import s from "./Typography.module.css";

const TypographyVariants = {
    "Header/H1": s.headerH1,
    "Header/H2": s.headerH2,
    "Header/H3": s.headerH3,
    "Header/H4": s.headerH4,
    "Paragraph/Large/p": s.paragraphLargeP,
    "Paragraph/Default/p": s.paragraphDefaultP,
    "Paragraph/Default/p-def": s.paragraphDefaultPDef,
    "Paragraph/Small/p": s.paragraphSmallP,
    "Button/Small": s.buttonSmall,
    "Button/Large": s.buttonLarge,
};

export type TypographyVariant = keyof typeof TypographyVariants;
export type TypographyColor =
    "dark-primary-main"
    | "dark-gray-p50"
    | "dark-gray-p75"
    | "dark-gray-main"
    | "dark-gray-p100"
    | "dark-gray-p200"
    | "dark-gray-p500"
    | "dark-white"
    | "dark-status-danger-d300"
    | "dark-status-warning-w200";

type Props = {
    className?: string;
    variant?: TypographyVariant;
    color?: TypographyColor | "inherit" | "none";
};

function parseColor(color: Props["color"]) {
    let colorProperty;
    if (color === "none") {
        colorProperty = undefined;
    } else if (color === "inherit") {
        colorProperty = "inherit";
    } else {
        colorProperty = `var(--${color})`;
    }

    return colorProperty;
}

export const P = observer(forwardRef((props: Props & HTMLProps<HTMLParagraphElement>, ref: ForwardedRef<HTMLParagraphElement>) => {
    const {className, variant = "Paragraph/Default/p", color = "dark-white", ...defaultProps} = props;

    return (
        <p
            ref={ref}
            className={classNames(TypographyVariants[variant], className)}
            style={{color: parseColor(color)}}
            {...defaultProps} />
    );
}));

export const NativeA = observer((props: Props & Omit<HTMLProps<HTMLAnchorElement>, "ref">) => {
    const {className, variant = "Paragraph/Default/p", color = "dark-white", ...defaultProps} = props;

    /* eslint-disable jsx-a11y/anchor-has-content */
    return (
        <a
            className={classNames(TypographyVariants[variant], className)}
            style={{color: parseColor(color)}}
            {...defaultProps} />
    );
    /* eslint-enable jsx-a11y/anchor-has-content */
});


export const A = observer((props: Props & Omit<HTMLProps<HTMLAnchorElement>, "ref">) => {
    const {className, variant = "Paragraph/Default/p", color = "dark-white", href, ...defaultProps} = props;

    /* eslint-disable jsx-a11y/anchor-has-content */
    return (
        <Link
            className={classNames(TypographyVariants[variant], className)}
            style={{color: parseColor(color)}}
            to={href || ""}
            {...defaultProps} />
    );
    /* eslint-enable jsx-a11y/anchor-has-content */
});
