import {P} from "common/components/typography";
import {ReactNode} from "react";
import IconSmallDocument16x16 from "../../../common/components/icons/IcomSmallDocument16x16";
import IconCamera16x16 from "../../../common/components/icons/IconCamera16x16";
import IconClose16x16 from "../../../common/components/icons/IconClose16x16";
import IconReceipt16x16 from "../../../common/components/icons/IconReceipt16x16";
import s from "./MessageBox.module.css"
import {AttachedFileUiState} from "./state";

type Props = {
    attachment: AttachedFileUiState;
    onDetach: (attachment: AttachedFileUiState) => void;
};

const fallbackIcon = <IconSmallDocument16x16 className={s.attachmentIcon} fill="currentColor"/>;
const documentIcons: Record<string, ReactNode> = {
    "invoice": <IconReceipt16x16 className={s.attachmentIcon} fill="currentColor"/>,
    "photo": <IconCamera16x16 className={s.attachmentIcon} fill="currentColor"/>,
};

export const Attachment = (props: Props) => {
    const {attachment, onDetach} = props;

    const handleRemove = () => onDetach(attachment);
    return (
        <div className={s.attachment} onClick={handleRemove}>
            {documentIcons[attachment.type] || fallbackIcon}
            <P className={s.attachmentType} variant="Button/Small">{attachment.typeName}</P>
            <P className={s.attachmentName} variant="Button/Small" color="dark-primary-main">{attachment.file.name}</P>
            <IconClose16x16 className={s.attachmentRemove} fill="currentColor"/>
        </div>
    );
};
