import createSvgIcon from "./utils";

const IconArrowRight6x10 = createSvgIcon((
    <path
        stroke="transparent"
        fillRule="evenodd"
        d="M.564 9.236a.9.9 0 0 1 0-1.272L3.528 5 .563 2.036A.9.9 0 0 1 1.836.764l3.6 3.6a.9.9 0 0 1 0 1.272l-3.6 3.6a.9.9 0 0 1-1.272 0Z"
        clipRule="evenodd"/>
), {width: 6, height: 10});

export default IconArrowRight6x10;
