import {AuthorizedHttpClient} from "common/net";

export class CitizenTariffIconRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(signal: AbortSignal): Promise<string[]> {
        return this.client.get<string[]>(
            "tariff/icons",
            {signal}
        );
    }
}
