import {HTMLProps, ReactNode} from "react";
import s from "./Modal.module.css";

type Props = {
    negative?: ReactNode;
    positive?: ReactNode;
} & HTMLProps<HTMLDivElement>;

export const ModalFooter = (props: Props) => {
    const {negative, positive, ...defaultProps} = props;
    return (
        <div className={s.footer} {...defaultProps}>
            {negative ? <div className={s.footerNegativeActions}>{negative}</div> : null}
            {positive ? <div className={s.footerPositiveActions}>{positive}</div> : null}
        </div>
    );
};
