import IconSortable from "../icons/IconSortable";
import IconSortAsc from "../icons/IconSortAsc";
import IconSortDesc from "../icons/IconSortDesc";
import s from "./index.module.css";

interface IProps {
    isSortable: boolean;
    isSorted: boolean;
    isSortedDesc?: boolean;
}

function TableSortableIndicator(props: IProps) {
    const {isSortable, isSorted, isSortedDesc} = props;

    if (isSortable) {
        if (isSorted) {
            return isSortedDesc ? <IconSortDesc className={s.iconSortable}/> :
                <IconSortAsc className={s.iconSortable}/>;
        } else {
            return <IconSortable className={s.iconSortable}/>;
        }
    }

    return null;
}

export default TableSortableIndicator;