import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {Attachment} from "./Attachment";
import s from "./MessageBox.module.css";
import {AttachedFileUiState} from "./state";

type Props = {
    className?: string;
    attachments: AttachedFileUiState[];
    onDetach: (attachment: AttachedFileUiState) => void;
};

export const AttachedFiles = observer((props: Props) => {
    const {className, attachments, onDetach} = props;
    if (attachments.length === 0) {
        return null;
    }

    return (
        <div className={classNames(s.attachmentList, className)}>
            {attachments.map((attachment, index) =>
                <Attachment key={index} attachment={attachment} onDetach={onDetach}/>)}
        </div>
    );
});
