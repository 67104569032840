import {AuthorizedHttpClient} from "common/net";
import {TaskDocumentType} from "../types";

export class TaskDocumentTypeRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(): Promise<TaskDocumentType[]> {
        return this.client.get<TaskDocumentType[]>("web/v1/tasks/documents/types");
    }
}
