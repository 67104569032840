import {AuthorizedHttpClient} from "common/net";
import {TagColor} from "houses/types";

export class TagColorRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(): Promise<TagColor[]> {
        return this.client.get<TagColor[]>("web/v1/tags/colors");
    }
}
