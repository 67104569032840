import classNames from "classnames";
import React, {KeyboardEvent, ReactNode} from "react";
import {P} from "../typography";
import s from "./Pagination.module.css";

type IProps = {
    children: ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
    onClick: () => void;
};

export const PaginationItem = (props: IProps) => {
    const {children, isActive, isDisabled, onClick} = props;

    const canInteract = !isActive && !isDisabled;
    const handleClick = () => {
        if (canInteract) {
            onClick();
        }
    };
    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter" && canInteract) {
            onClick()
        }
    };

    return (
        <li
            className={classNames({
                [s.item]: !isActive && !isDisabled,
                [s.itemDisabled]: !isActive && isDisabled,
                [s.itemActive]: isActive && !isDisabled,
            })}
            tabIndex={canInteract ? 0 : undefined}
            onClick={handleClick}
            onKeyPress={handleKeyPress}>
            {typeof children === "string" || typeof children === "number" ? (
                <P variant="Button/Large" color="inherit">{children}</P>
            ) : children}
        </li>
    );
};
