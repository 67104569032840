export interface UserGeoResponse {
    employee_id: number;
    lat: number;
    lon: number;
    first_name: string;
    last_name: string;
    patronymic: string;
    avatar_url?: string;
    company_name: string;
    position: string;
    timestamp: number;
    is_online: boolean;
}

export interface UserGeo {
    id: number;
    lat: number;
    lon: number;
    fullName: string;
    avatarUrl: string | null;
    companyName: string;
    position: string;
    createdAt: Date;
    isOnline: boolean;
}

export interface FindAllUserGeoParam {
    page: number;
    limit: number;
    search: string;
    signal: AbortSignal;
}

export function mapToUserGeo(response: UserGeoResponse): UserGeo {
    return {
        id: response.employee_id,
        lat: response.lat,
        lon: response.lon,
        position: response.position,
        createdAt: new Date(response.timestamp * 1000),
        isOnline: response.is_online,
        companyName: response.company_name,
        avatarUrl: response.avatar_url || null,
        fullName: `${response.last_name} ${response.first_name} ${response.patronymic}`.trim(),
    };
}