import classNames from "classnames";
import {HTMLProps} from "react";
import s from "./InputBase.module.css";

type Props = {
    isFocus: boolean;
    hasTrailing: boolean;
    hasHeading: boolean;
} & HTMLProps<HTMLDivElement>;

export const InputBaseContainer = (props: Props) => {
    const {className, disabled, hasHeading, hasTrailing, isFocus, ...defaultProps} = props;
    return (
        <div
            className={classNames(isFocus ? s.containerFocus : s.container, className, {
                [s.containerDisabled]: disabled,
            })}
            style={{
                paddingLeft: hasHeading ? undefined : "var(--input-horizontal-padding)",
                paddingRight: hasTrailing ? undefined : "var(--input-horizontal-padding)",
            }}
            {...defaultProps}/>
    );
};
