import createSvgIcon from "./utils";

const IconNotebook = createSvgIcon((
    <>
        <path
            stroke="transparent"
            d="M22.129 2.117c.622.167.991.807.825 1.43l-1.208 4.507a1.167 1.167 0 1 1-2.254-.604L20.7 2.942a1.167 1.167 0 0 1 1.429-.825ZM28.89 3.929c.623.167.992.806.825 1.429l-1.208 4.507a1.167 1.167 0 0 1-2.254-.603l1.208-4.508a1.167 1.167 0 0 1 1.43-.825Z"/>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="M24.604 6.404c.4.107.775.207 1.126.303l-.603 2.253a2.333 2.333 0 1 0 4.507 1.207l.5-1.862c.104.068.203.139.3.213a7 7 0 0 1 2.679 4.64c.181 1.38-.24 2.955-1.085 6.106l-.604 2.253c-.844 3.15-1.266 4.726-2.114 5.83a7 7 0 0 1-4.64 2.68c-1.38.181-2.955-.24-6.106-1.085-3.15-.844-4.725-1.266-5.83-2.114a7 7 0 0 1-2.679-4.64c-.181-1.38.24-2.955 1.085-6.106l.604-2.254c.844-3.15 1.266-4.725 2.114-5.83a7 7 0 0 1 4.64-2.679c.12-.016.242-.027.366-.034l-.499 1.863a2.333 2.333 0 0 0 4.508 1.208l.603-2.253 1.128.3ZM16.17 19.846a1.167 1.167 0 1 0-.604 2.254l6.761 1.811a1.167 1.167 0 1 0 .604-2.253l-6.761-1.812Zm.08-4.81a1.167 1.167 0 0 1 1.43-.825l3.38.906a1.167 1.167 0 1 1-.604 2.254l-3.38-.906a1.167 1.167 0 0 1-.826-1.429Z"
            clipRule="evenodd"/>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="M22.07 29.827a7 7 0 0 0 2.876-4.783c.181-1.38-.24-2.956-1.085-6.107l-.604-2.253c-.844-3.15-1.266-4.726-2.114-5.83a6.999 6.999 0 0 0-.69-.774 2.347 2.347 0 0 1-.88-.241 2.331 2.331 0 0 1-1.062-1.086 6.998 6.998 0 0 0-2.007-.578 4.816 4.816 0 0 0-.367-.034l.499 1.862a2.333 2.333 0 0 1-4.314 1.7c-.175.619-.363 1.32-.579 2.126l-.604 2.254c-.844 3.15-1.266 4.726-1.084 6.106a7 7 0 0 0 2.679 4.64c1.104.847 2.68 1.27 5.83 2.114 1.462.391 2.584.692 3.506.884Zm-1.655.81c-.967.353-2.23.692-3.978 1.16-3.15.845-4.726 1.267-6.107 1.085a7 7 0 0 1-4.64-2.679c-.847-1.105-1.27-2.68-2.113-5.83l-.604-2.254c-.844-3.15-1.266-4.726-1.085-6.106a7 7 0 0 1 2.68-4.64c.096-.074.195-.145.3-.213l.499 1.863a2.333 2.333 0 0 0 4.507-1.208l-.603-2.253a393.288 393.288 0 0 1 2.254-.604l.197.735c-.01.025-.018.05-.027.076-.33.963-.655 2.176-1.067 3.716L10 15.823c-.412 1.54-.737 2.754-.933 3.753-.2 1.019-.284 1.898-.17 2.766a8.167 8.167 0 0 0 3.126 5.413c.694.534 1.498.9 2.48 1.237.963.33 2.176.655 3.717 1.067l.082.023c.784.21 1.483.397 2.112.556Zm-4.245-10.79a1.167 1.167 0 1 0-.604 2.253l6.762 1.812a1.167 1.167 0 1 0 .604-2.254l-6.762-1.812Zm.081-4.81a1.167 1.167 0 0 1 1.429-.825l3.38.906a1.167 1.167 0 1 1-.603 2.254l-3.381-.906a1.167 1.167 0 0 1-.825-1.43ZM7.54 7.609a1.167 1.167 0 1 0-2.254.604l1.208 4.508a1.167 1.167 0 0 0 2.254-.604L7.54 7.609Z"
            clipRule="evenodd"/>
    </>
), {width: 35, height: 35});

export default IconNotebook;