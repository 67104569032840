import {CitizenAccessRepository} from "citizens/repositories";
import {ConfirmationModal} from "common/stores";
import {makeAutoObservable} from "mobx";

interface AccessForDelete {
    id: string;
    citizenName: string;
    address: string;
    apartment: string;
}

export class DeleteAccessStore {
    readonly modal: ConfirmationModal<AccessForDelete>;
    private deleteListener: (() => void) | null;

    constructor(private readonly accessRepo: CitizenAccessRepository) {
        this.deleteListener = null;
        makeAutoObservable(this, {}, {autoBind: true});
        this.modal = new ConfirmationModal(this._deleteAccess);
    }

    get errorMessage() {
        return this.modal.errorMessage;
    }

    dismissError() {
        return this.modal.dismissError();
    }

    setDeleteListener(listener: (() => void) | null) {
        this.deleteListener = listener;
    }

    show(access: AccessForDelete) {
        this.modal.show(access);
    }

    private async _deleteAccess(citizen: AccessForDelete) {
        await this.accessRepo.delete(citizen.id);
        this.deleteListener?.();
    }
}