import classNames from "classnames";
import {
    ContextMenu,
    ContextMenuItem,
    SkeletonContextMenuContent,
    SkeletonContextMenuError
} from "common/components/context_menu";
import {observer} from "mobx-react-lite";
import React, {FormEvent, useRef, useState} from "react";
import IconPaperClip16x16 from "../../../common/components/icons/IconPaperClip16x16";
import {TaskDocumentType} from "../../types";
import s from "./MessageBox.module.css";

type Props = {
    types: TaskDocumentType[];
    isLoading: boolean;
    error?: string;
    onLoadTypes: () => void;
    onAttach: (type: string, typeName: string, files: File[]) => void;
};

export const AttachClip = observer((props: Props) => {
    const {types, isLoading, error, onAttach, onLoadTypes} = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedType, setSelectedType] = useState<TaskDocumentType | null>(null)

    const onFileAttach = (selectedType: TaskDocumentType) => {
        setSelectedType(selectedType);
        inputRef.current?.click();
    }

    const onFileChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        const files = target.files;
        if (files && files.length > 0 && selectedType) {
            onAttach(selectedType.id, selectedType.name, Array.prototype.slice.call(files));
            target.value = "";
        }
    };

    return (
        <>
            <ContextMenu
                trigger={
                    <span>
                        <IconPaperClip16x16
                            className={classNames(s.action, s.actionClip)}
                            fill="var(--dark-primary-main)"/>
                    </span>
                }
                height={200}
                onOpen={onLoadTypes}>
                {!isLoading && error && (
                    <SkeletonContextMenuError message={error}/>
                )}
                {isLoading && !error && <SkeletonContextMenuContent items={5}/>}
                {!isLoading && !error && types.map(type => (
                    <ContextMenuItem key={type.id} onClick={() => onFileAttach(type)}>
                        {type.name}
                    </ContextMenuItem>
                ))}
            </ContextMenu>
            <input className={s.realFileInput} ref={inputRef} type="file" multiple onChange={onFileChange}/>
        </>
    );
});
