export interface CitizenBalance {
    text: string;
    color: "danger" | "primary" | "default";
    date: Date;
}

export interface CitizenBalanceResponse {
    text: string;
    color: "danger" | "primary" | "default";
    date: number;
}

export type FindAllBalanceHistoryParams = {
    citizenId: string;
    limit: number;
    page: number;
};

export function mapToCitizenBalance(response: CitizenBalanceResponse): CitizenBalance {
    return {
        text: response.text,
        color: response.color,
        date: new Date(response.date * 1000),
    }
}