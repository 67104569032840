import classNames from "classnames";
import {ButtonHTMLAttributes, forwardRef, ReactNode, Ref} from "react";
import Ripple from "../ripple";
import s from "./index.module.css";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: ReactNode;
    variant?: Variant;
    isClickable?: boolean;
    containerClassName?: string;
}

type Variant = "Primary" | "PopupFilled" | "PopupOutlined" | "Disabled";

function DeprecatedButton(props: IProps, ref: Ref<HTMLDivElement>) {
    const {
        className,
        containerClassName,
        variant = "Primary",
        icon,
        children,
        isClickable = true,
        ...defaultProps
    } = props;

    return (
        <Ripple
            ref={ref}
            className={classNames(s.rippleContainer, s[`rippleContainer${variant}`], containerClassName)}
            isEnabled={isClickable}>
            <button {...defaultProps} className={classNames(s[`button${variant}`], className)}>
                {icon}
                {children}
            </button>
        </Ripple>
    );
}

export default forwardRef(DeprecatedButton);
