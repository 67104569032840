import {AuthorizedHttpClient} from "../net";
import {IUploadedFileEntity} from "../types";

export class UploadsRepository {
    constructor(private readonly httpClient: AuthorizedHttpClient) {
    }

    async upload(file: File): Promise<IUploadedFileEntity> {
        const body = new FormData();
        body.append("userfile[]", file, file.name);
        const response = await this.httpClient.post<{ answer: IUploadedFileEntity[] }>(
            "v1/attachment/",
            {body},
        );
        return response.answer[0];
    }
}
