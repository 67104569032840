import classNames from "classnames";
import {ReactNode} from "react";
import {Task} from "tasks/types";
import {P} from "./Typography";
import s from "./Typography.module.css";

type Props = {
    className?: string;
    task?: Task;
    children: ReactNode;
};

export const ColorfulP = (props: Props) => {
    const {className, task, children} = props;

    let color;
    if (task) {
        color = task.delegationStatus == null ? task.status.darkColor : undefined
    } else {
        color = undefined;
    }
    return (
        <P variant="Paragraph/Small/p" className={classNames(className, s.colorfullP)} style={{color}}>
            {children}
        </P>
    );
};
