import s from "citizens/routes/citizen_user/CitizenUserRoute.module.css";
import {useCitizenUserStore} from "citizens/routes/citizen_user/CitizenUserStore";
import classNames from "classnames";
import {P} from "common/components/typography";
import React, {ReactNode} from "react";
import Skeleton from "react-loading-skeleton";
import {ReactComponent as AssistantWelcoming} from "../../../common/assets/assistant_welcoming_100.svg";


type Props = {
    className: string;
    title: string;
    error?: string | null;
    isPlaceholder?: boolean;
    children?: ReactNode;
};

export const ContentBlock = (props: Props) => {
    const {className, title, isPlaceholder, error, children} = props;
    const store = useCitizenUserStore();

    return (
        <div className={classNames(s.contentBlock, className)}>
            {!store.isLoading && <P variant="Header/H4">{title}</P>}
            {store.isLoading && <Skeleton width={100} height={23}/>}
            {error && <P className={s.error} variant="Paragraph/Small/p" color="dark-status-danger-d300">{error}</P>}
            {!isPlaceholder && !error && children}
            {isPlaceholder && !store.isLoading && (
                <div className={s.tasksPlaceholder}>
                    <AssistantWelcoming width={150} height={193}/>
                    <P variant="Paragraph/Large/p" color="dark-gray-p200">Нет элементов</P>
                </div>
            )}
        </div>
    );
};