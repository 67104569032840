import {IMenuItemUiState, TPopupPos} from "./state";
import {Suggestion, SuggestionSkeleton} from "./Suggestion";
import SuggestionListBase from "./SuggestionListBase";
import SuggestionsPopupContentBase, {PopupConnectedSide} from "./SuggestionsPopupContentBase";

interface IProps<T, S extends IMenuItemUiState<any> | null> {
    items: IMenuItemUiState<T>[];
    selected: S;
    position?: TPopupPos;
    showIndicator?: boolean;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    isFilterable?: boolean;
    isResizable?: boolean;
    width?: number;
    height?: number;
    search?: string;
    maxWidth?: number;
    maxHeight?: number;
    error?: string;
    connectedSide?: PopupConnectedSide;
    onFetchMore?: () => void;
    setSearch?: (value: string) => void;

    render?(item: IMenuItemUiState<T>, onSelect: () => void): JSX.Element | null;

    retryLoad?(): void;

    onSelect(item: IMenuItemUiState<T>): void;
}

function SuggestionsPopupBase<T, S extends IMenuItemUiState<any> | null>(props: IProps<T, S>) {
    const {
        items,
        selected,
        position,
        showIndicator,
        isFilterable,
        isLoading,
        isResizable,
        isMoreLoading,
        error,
        connectedSide,
        render,
        retryLoad,
        width,
        height,
        search,
        maxWidth,
        maxHeight,
        onSelect,
        onFetchMore,
        setSearch,
    } = props;

    return (
        <SuggestionsPopupContentBase
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            position={position}
            items={items}
            error={error}
            search={search}
            connectedSide={connectedSide}
            isFilterable={isFilterable}
            retryLoad={retryLoad}
            isResizable={isResizable}
            setSearch={setSearch}>
            {suggestions => (
                <SuggestionListBase
                    items={suggestions}
                    selected={selected ? [selected] : null}
                    Skeleton={SuggestionSkeleton}
                    isLoading={isLoading}
                    isMoreLoading={isMoreLoading}
                    render={item => {
                        if (render) {
                            return <>{render(item, () => onSelect(item))}</>;
                        }

                        return (
                            <Suggestion
                                key={item.key}
                                isSelected={(item.value === null && !selected) || selected?.key === item.key}
                                showIndicator={showIndicator}
                                onClick={() => onSelect(item)}>
                                {item.label.toString()}
                            </Suggestion>
                        );
                    }}
                    onFetchMore={onFetchMore}/>
            )}
        </SuggestionsPopupContentBase>
    );
}

export default SuggestionsPopupBase;
