import {useEffect, useId} from "react";
import {usePopupStack} from "../../hooks";

export function useEscapeBlock(isOpened: boolean) {
    const popupId = useId();
    const popupHost = usePopupStack();

    useEffect(() => {
        if (isOpened) {
            popupHost.putOnStack(popupId);
            return () => popupHost.removeFromStack(popupId);
        }
    }, [popupHost, popupId, isOpened]);
}
