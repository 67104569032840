import classNames from "classnames";
import {forwardRef, HTMLProps} from "react";
import s from "./InputBase.module.css";

type Props = HTMLProps<HTMLInputElement>;

export const InputBaseRealInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {className, ...defaultProps} = props;
    return (
        <input
            ref={ref}
            className={classNames(s.realInput, className)}
            {...defaultProps}/>
    );
});
