import s from "./PopupSubTitle.module.css";

interface IProps {
    children: string;
}

function DeprecatedPopupSubTitle(props: IProps) {
    const {children} = props;
    return <h1 className={s.text}>{children}</h1>;
}

export default DeprecatedPopupSubTitle;