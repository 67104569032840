import {TariffToggleStore} from "citizens/components/tariff_toggle/TariffToggleStore";
import {useRepositories} from "common/components/app/RepositoriesStore";
import IconArrowDown16x16 from "common/components/icons/IconArrowDown16x16";
import {IMenuItemUiState, SuggestionsPopup} from "common/components/suggestions_popup";
import {P} from "common/components/typography";
import {observer} from "mobx-react-lite";
import {useMemo} from "react";
import Skeleton from "react-loading-skeleton";
import s from "./TariffToggle.module.css";

type Props = {
    itemId: string;
    tariffId: string;
    tariffName: string;
    isHouseTariff?: boolean;
    store: TariffToggleStore;
}

export function useTariffToggleStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new TariffToggleStore(
        repos.citizenTariff,
        repos.citizenAccess,
        repos.citizenHouse,
    ), [repos]);
}

export const TariffToggle = observer((props: Props) => {
    const {itemId, tariffId, tariffName, store, isHouseTariff = false} = props;

    const items: IMenuItemUiState<string>[] = useMemo(() => {
        return store.tariffs
            .filter(tariff => tariff.id !== tariffId)
            .map(tariff => ({
                key: tariff.id,
                label: tariff.name,
                value: tariff.id,
            }))
    }, [store.tariffs, tariffId])

    if (store.isChanging(itemId)) {
        return <Skeleton width={80} height={16}/>;
    }

    return (
        <SuggestionsPopup
            items={items}
            onOpen={store.load}
            error={store.errorMessage}
            isFilterable
            isLoading={store.isLoading}
            position={["bottom center", "top center"]}
            onSelect={item => {
                store.change(itemId, item.value as string, isHouseTariff);
            }}>
            {() => (
                <P className={s.container} variant="Paragraph/Small/p">
                    {tariffName}
                    <IconArrowDown16x16 fill="currentColor"/>
                </P>
            )}
        </SuggestionsPopup>
    );
});