import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    Column,
    Row,
    useBlockLayout,
    useColumnOrder,
    useExpanded,
    useResizeColumns,
    UseResizeColumnsState,
    useSortBy,
    useTable
} from "react-table";
import {ReactComponent as AssistantWelcoming} from "../../../common/assets/assistant_welcoming_100.svg";
import useRemembered from "../../../common/hooks/useRemembered";
import {Table as TableBase} from "../../components/table";
import {TriState} from "../checkbox";
import {P} from "../typography";
import s from "./index.module.css";
import TableBodyRow from "./TableBodyRow";
import TableHead from "./TableHead";

export type TableVariant = "default" | "modal" | "embed";

export type TableColumn<D extends object> = {
    hasPadding?: boolean;
    heightHack?: boolean;
    title?: string;
    options?: {
        id: string;
        title: string;
        isDefaultVisible?: boolean;
    }[];
} & Column<D>;

interface IProps<D extends object> {
    className?: string;
    bodyCellClassName?: string;
    headCellClassName?: string;
    rowClassName?: string | ((item: D) => string | null);
    highlightable?: boolean;
    clickable?: boolean | ((item: Row<D>) => boolean);
    headClassName?: string;
    fullWidth?: boolean;
    columns: readonly Column<D>[];
    data: readonly D[];
    rowLink?: (data: D) => string;
    columnOrder?: string[];
    hiddenColumns?: string[];
    initialResized?: UseResizeColumnsState<D>["columnResizing"]["columnWidths"];
    expandedKey?: keyof D;
    isResizable?: boolean;
    isBlockLayout?: boolean;
    isOrderable?: boolean;
    isNoElementsEnabled?: boolean;
    isShowResizeIndicator?: boolean;
    isStickyHeader?: boolean;
    getSubRows?: (row: D, relativeIndex: number) => D[];
    shouldScrollTo?: (row: D) => boolean;
    onResize?: (column: UseResizeColumnsState<D>) => void;
    onColumnOrderChange?: (columnOrder: string[]) => void;
    variant?: TableVariant;
    canSelectRows?: boolean;
    allSelectionState?: TriState;
    isRowSelected?: (row: D) => boolean;
    onSelectAllRows?: () => void;
    onSelectRow?: (item: D) => void;

    onRowClick?(data: D, button: number): void;
}

function AutoTable<D extends object>(props: IProps<D>) {
    const {
        className,
        bodyCellClassName,
        headCellClassName,
        variant = "default",
        fullWidth,
        columns,
        data,
        rowLink,
        getSubRows,
        headClassName,
        columnOrder,
        hiddenColumns,
        rowClassName,
        initialResized = null,
        expandedKey,
        canSelectRows,
        isRowSelected,
        allSelectionState,
        highlightable = true,
        clickable = true,
        shouldScrollTo,
        isResizable = false,
        isOrderable = false,
        isStickyHeader = true,
        isBlockLayout = false,
        isShowResizeIndicator = true,
        isNoElementsEnabled = true,
        onRowClick,
        onResize,
        onColumnOrderChange,
        onSelectAllRows,
        onSelectRow,
    } = props;

    const memoColumns = useMemo(() => {
        if (!initialResized) {
            return columns;
        }

        return columns.slice().map((column) => {
            if (initialResized[column.id]) {
                column.width = initialResized[column.id];
            }

            return column;
        });
    }, [initialResized, columns]);

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        defaultCanSort,
        state,
        setColumnOrder,
        setHiddenColumns,
    } = useTable(
        {
            initialState: {
                columnOrder,
                hiddenColumns: hiddenColumns || [],
            },
            autoResetSortBy: false,
            getSubRows: getSubRows,
            columns: memoColumns,
            manualExpandedKey: expandedKey as string,
            data,
        },
        useSortBy,
        useExpanded,
        //@ts-ignore
        ...[
            isResizable ? useResizeColumns : undefined,
            isBlockLayout ? useBlockLayout : undefined,
            columnOrder ? useColumnOrder : undefined,
        ].filter(Boolean),
    );

    const rememberedOnResize = useRemembered(onResize)
    useEffect(() => {
        rememberedOnResize.current?.(state);
    }, [state, rememberedOnResize]);

    useEffect(() => {
        if (columnOrder && setColumnOrder) {
            setColumnOrder(columnOrder);
        }
    }, [setColumnOrder, columnOrder])

    useEffect(() => {
        if (hiddenColumns && setHiddenColumns) {
            setHiddenColumns(hiddenColumns);
        }
    }, [setHiddenColumns, hiddenColumns]);

    const [draggedColumnId, setDraggedColumnId] = useState<string | null>(null);
    const [distColumnId, setDistColumnId] = useState<string | null>(null);
    const handleOrderEnd = useCallback((dropColumnId: string | null) => {
        if (draggedColumnId && dropColumnId && draggedColumnId !== dropColumnId) {
            if (!columnOrder) {
                return;
            }

            const sourceIndex = columnOrder?.indexOf(draggedColumnId);
            const distIndex = columnOrder?.indexOf(dropColumnId);

            if (sourceIndex === undefined || distIndex === undefined) {
                return;
            }

            const newColumnOrder = columnOrder.slice();
            newColumnOrder.splice(sourceIndex, 1);
            newColumnOrder.splice(distIndex, 0, draggedColumnId);

            onColumnOrderChange?.(newColumnOrder);
        }

        setDraggedColumnId(null);
        setDistColumnId(null);
    }, [columnOrder, draggedColumnId, onColumnOrderChange]);

    const handleDragOver = useCallback((columnId: string | null) => {
        setDistColumnId(columnId);
    }, []);

    if (data.length === 0 && isNoElementsEnabled) {
        return (
            <div className={s.noElements}>
                <AssistantWelcoming width={150} height={193}/>
                <P variant="Paragraph/Large/p" color="dark-gray-p200">Нет элементов</P>
            </div>
        );
    }

    return (
        <TableBase
            variant={variant}
            className={classNames(
                {
                    [s.tableFullWidth]: fullWidth,
                    [s.tableNative]: !isBlockLayout,
                }, className)
            } {...getTableProps()}
            isHighlightedRows={highlightable}>
            <TableHead
                className={headClassName}
                headCellClassName={headCellClassName}
                headerGroups={headerGroups}
                distColumnId={distColumnId}
                canSelectRows={canSelectRows}
                allSelectionState={allSelectionState}
                isSortable={defaultCanSort}
                isStickyHeader={isStickyHeader}
                isResizable={isResizable && isShowResizeIndicator}
                isDraggable={isOrderable}
                onColumnOrderStart={setDraggedColumnId}
                onColumnOrderEnd={handleOrderEnd}
                onColumnOrderOver={handleDragOver}
                onSelectAllRows={onSelectAllRows}/>

            {rows.map((row, index) => (
                <TableBodyRow
                    className={rowClassName}
                    key={index}
                    bodyCellClassName={bodyCellClassName}
                    distColumnId={distColumnId}
                    clickable={clickable}
                    row={row}
                    link={rowLink}
                    shouldScrollTo={shouldScrollTo}
                    prepareRow={prepareRow}
                    canSelectRows={canSelectRows}
                    isRowSelected={isRowSelected}
                    isAllRowsSelected={allSelectionState === TriState.Checked}
                    onSelect={onSelectRow}
                    onDragEnd={handleOrderEnd}
                    onDragOver={handleDragOver}
                    onClick={onRowClick}/>
            ))}
        </TableBase>
    );
}

export default observer(AutoTable);
