import {useEffect, useRef} from 'react'

export function useDocumentTitle(title: string | null) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        const title = defaultTitle.current;
        document.title = title || title;
        return () => {
            document.title = title;
        };
    }, [title]);
}
