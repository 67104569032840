import {DateFormatter} from "common/utils";
import {makeAutoObservable} from "mobx";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

interface FilterOptions {
    startKey: string;
    endKey: string;
    title: string;
    isPassedDatesOnly?: boolean;
}

export class DateRangeFilterStore implements BaseTaskFilterStore {
    startDate: Date | null;
    endDate: Date | null;
    readonly isVisible = true;

    constructor(private readonly options: FilterOptions) {
        this.startDate = null;
        this.endDate = null;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        return this.startDate !== null || this.endDate !== null;
    }

    get title() {
        return this.options.title;
    }

    get key() {
        return this.options.startKey;
    }

    get isPassedDatesOnly() {
        return this.options.isPassedDatesOnly || false;
    }

    isOwnFilter(key: string) {
        return key === this.options.startKey || key === this.options.endKey;
    }

    setStartDate(date: Date | null) {
        this.startDate = date;
        if (date && this.endDate && this.endDate.getTime() < date.getTime()) {
            this.endDate = null;
        }
    }

    setEndDate(date: Date | null) {
        this.endDate = date;
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.startDate) {
            builder.upsert(
                this.options.startKey,
                `${this.options.title} от ${DateFormatter.formatDefault(this.startDate)}`,
                Math.floor(this.startDate.getTime() / 1000).toString(),
            );
        }

        if (this.endDate) {
            builder.upsert(
                this.options.endKey,
                `${this.options.title} до ${DateFormatter.formatDefault(this.endDate)}`,
                Math.floor(this.endDate.getTime() / 1000).toString(),
            );
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        const startDate = builder.findValue(this.options.startKey);
        if (startDate) {
            this.startDate = new Date(Number(startDate) * 1000);
        } else {
            this.startDate = null;
        }

        const endDate = builder.findValue(this.options.endKey);
        if (endDate) {
            this.endDate = new Date(Number(endDate) * 1000);
        } else {
            this.endDate = null;
        }
    }

    reset() {
        this.startDate = null;
        this.endDate = null;
    }
}