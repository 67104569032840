import createSvgIcon from "./utils";

const IconArrowLeft16x16 = createSvgIcon((
    <path
        stroke="transparent" fillRule="evenodd"
        d="M10.165 4.234a.8.8 0 0 1 0 1.132L7.531 8l2.635 2.634a.8.8 0 0 1-1.132 1.132l-3.2-3.2a.8.8 0 0 1 0-1.132l3.2-3.2a.8.8 0 0 1 1.131 0Z"
        clipRule="evenodd"/>
), {width: 16, height: 16});

export default IconArrowLeft16x16;
