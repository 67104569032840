import classNames from "classnames";
import {ReactNode} from "react";
import s from "./Label.module.css";

interface IProps {
    className?: string;
    title?: string;
    error?: string | null;
    isBottomPadding?: boolean;
    children: ReactNode;
    isSpan?: boolean;
    marginBottom?: number;
}

function DeprecatedLabel(props: IProps) {
    const {className, title, error, marginBottom, children, isSpan, isBottomPadding = true} = props;

    const content = <>
        {title && <span className={s.labelTitle}>{title}</span>}
        {children}
        {error && <span className={s.error}>{error}</span>}
    </>;

    if (isSpan) {
        return (
            <span className={classNames(s.label, className, {[s.bottomPadding]: isBottomPadding})}
                  style={{marginBottom}}>
                {content}
            </span>
        );
    }

    return (
        <label className={classNames(s.label, className, {[s.bottomPadding]: isBottomPadding})} style={{marginBottom}}>
            {content}
        </label>
    );
}

export default DeprecatedLabel;
