import {makeAutoObservable, reaction} from "mobx";
import {mergeDeep} from "../../utils";
import {IEntryFilter} from "./IEntryFilter";

export class EntryFilters<Q extends object> {
    all: IEntryFilter<Q, any>[];

    private prevSerializedRaw: string;
    private saveListener: ((serialized: string | null) => void) | null;

    constructor() {
        this.all = [];
        this.prevSerializedRaw = "";
        this.saveListener = null;
        makeAutoObservable(this, {}, {autoBind: true});

        reaction(
            () => this.mergedQueries,
            () => this.notifySaveListener(),
        );
    }

    setSaveListener(callback: ((serialized: string | null) => void) | null) {
        this.saveListener = callback;
    }

    get serialized() {
        const serialized: Record<string, any> = {};
        for (const filter of this.all) {
            const serializedFilter = filter.serialize();
            if (serializedFilter !== null) {
                serialized[filter.type] = serializedFilter;
            }
        }
        return Object.keys(serialized).length > 0 ? serialized : null;
    }

    setFilters(values: IEntryFilter<Q, any>[]) {
        this.all = values;
    }

    get isSomeUsing() {
        return this.all.some(filter => filter.isUsing);
    }

    get mergedQueries() {
        return this.using.reduce((prev, current) => mergeDeep(prev, current.query), {}) as Q;
    }

    getByTypeSafe(type: string) {
        const found = this.all.find(filter => filter.type === type);
        if (!found) {
            throw new Error(`Filter "${type}" not found`);
        }

        return found;
    }

    private get using() {
        return this.all.filter(filter => filter.isUsing);
    }

    resetAll() {
        for (const filter of this.all) {
            filter.reset();
        }
    }

    deserializeFilters(serializedRaw: string) {
        if (serializedRaw === this.prevSerializedRaw) {
            return;
        }

        try {
            const serialized = JSON.parse(serializedRaw);
            for (const filter of this.all) {
                if (serialized[filter.type]) {
                    filter.deserialize(serialized[filter.type]);
                }
            }
            this.prevSerializedRaw = serializedRaw;
        } catch (e) {
            console.error("Unable to deserialize filters", e);
        }
    }

    serialize() {
        const serialized = this.serialized;
        if (serialized === null) {
            return null;
        }

        return JSON.stringify(serialized);
    }

    private notifySaveListener() {
        if (!this.saveListener) {
            return;
        }

        const serialized = this.serialize();
        this.saveListener(serialized);
        this.prevSerializedRaw = serialized || "";
    }
}
