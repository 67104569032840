import {AgreeModal} from "citizens/modals/change_citizen_type/AgreeModal";
import {
    ChangeCitizenTypeStore,
    ChangeCitizenTypeStoreContext,
    ChangeTypeStep
} from "citizens/modals/change_citizen_type/ChangeCitizenTypeStore";
import {SelectAccessDateModal} from "citizens/modals/change_citizen_type/SelectAccessDateModal";
import {SelectOwnerModal} from "citizens/modals/change_citizen_type/SelectOwnerModal";
import {useRepositories} from "common/components/app/RepositoriesStore";
import {ErrorModal} from "common/components/modal/error";
import {observer} from "mobx-react-lite";
import {useMemo} from "react";

type Props = {
    store: ChangeCitizenTypeStore;
};

export function useChangeCitizenTypeStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new ChangeCitizenTypeStore(
        repos.citizen,
        repos.citizenAccess,
    ), [repos]);
}

export const ChangeCitizenTypeModal = observer((props: Props) => {
    const {store} = props;

    if (!store.isShow) {
        return null;
    }

    return (
        <ChangeCitizenTypeStoreContext.Provider value={store}>
            {store.step === ChangeTypeStep.AGREE && <AgreeModal/>}
            {store.step === ChangeTypeStep.SELECT_OWNER && <SelectOwnerModal/>}
            {store.step === ChangeTypeStep.SELECT_ACCESS_DATE && <SelectAccessDateModal/>}
            <ErrorModal dismiss={store.dismissError} error={store.errorMessage}/>
        </ChangeCitizenTypeStoreContext.Provider>
    );
});
