import {autorun, makeAutoObservable, runInAction} from "mobx";

export interface EditableField<K = number> {
    index: number;
    id: K | null;
    isDirty: boolean;
    hasError: boolean;
    validate: () => void;
}

export class EditableFieldListStore<T extends EditableField<K>, K = number> {
    items: T[];
    deletedItems: K[];

    constructor(private readonly createOutgoing?: () => T) {
        this.items = this.createOutgoing ? [this.createOutgoing()] : [];
        this.deletedItems = [];
        makeAutoObservable(this, {}, {autoBind: true});

        if (createOutgoing) {
            autorun(() => this.addBlankIfNeed());
        }
    }

    get canCreate() {
        return !!this.createOutgoing;
    }

    get isTouched() {
        return this.hasCreated || this.hasUpdated || this.hasDeleted;
    }

    get hasCreated() {
        return this.createdItems.length > 0;
    }

    get hasUpdated() {
        return this.updatedItems.length > 0;
    }

    get hasDeleted() {
        return this.deletedItems.length > 0;
    }

    get createdItems() {
        return this.changedItems.filter(item => item.id === null);
    }

    get updatedItems() {
        return this.items.filter(item => item.id !== null && item.isDirty);
    }

    get changedItems() {
        return (this.createOutgoing ? this.items[this.items.length - 1].isDirty : true)
            ? this.items
            : this.items.slice(0, this.items.length - 1);
    }

    remove(item: T) {
        const index = this.items.indexOf(item);
        if (index !== -1) {
            const item = this.items[index];
            if (item.id && !this.deletedItems.includes(item.id)) {
                this.deletedItems.push(item.id);
            }

            this.items.splice(index, 1);
        }
    }

    reset(items: T[] = []) {
        this.items = [...items];
        if (this.createOutgoing) {
            this.items.push(this.createOutgoing())
        }

        this.deletedItems = [];
    }

    invalidate() {
        const items = this.changedItems;
        for (const item of items) {
            item.validate();
        }

        return items.every(item => !item.hasError);
    }

    private addBlankIfNeed() {
        const createOutgoing = this.createOutgoing;
        if (!createOutgoing) throw new Error("Illegal state: createOutgoing is null")

        const lastItem = this.items[this.items.length - 1];
        if (lastItem && lastItem.isDirty) {
            runInAction(() => this.items.push(createOutgoing()))
        }
    }
}
