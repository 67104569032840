import {AuthorizedHttpClient} from "common/net";
import {ChildTask, ChildTaskResponse, CreateChildTaskParam, mapToChildTask} from "../types";

export class ChildTaskRepository {
    constructor(private client: AuthorizedHttpClient) {
    }

    create(params: CreateChildTaskParam): Promise<void> {
        return this.client.post(`v2/tasks/${params.parentId}/children`, {
            body: {
                executor_id: params.employeeId,
                type_id: params.typeId,
                description: params.description,
                attachments: params.attachments.map(item => ({
                    type: item.type,
                    uri: item.uri,
                    size_bytes: item.sizeBytes,
                    name: item.name,
                }))
            },
        });
    }

    /**
     * Получение иерархии дочерних заявок (согласование) для родительской заявки {@link id}.
     */
    async findAll(abortSignal: AbortSignal, taskId: number): Promise<ChildTask> {
        const response = await this.client.get<ChildTaskResponse>(`v2/tasks/${taskId}/children`, {
            signal: abortSignal,
        });
        return mapToChildTask(response);
    }
}
