import {ReactNode} from "react";
import s from "./PopupFooter.module.css";

interface IProps {
    secondary?: ReactNode;
    children?: ReactNode;
}

function DeprecatedPopupFooter(props: IProps) {
    const {secondary, children} = props;
    return (
        <div className={s.container}>
            {secondary && <div className={s.secondaryActions}>{secondary}</div>}
            {children && <div className={s.primaryActions}>{children}</div>}
        </div>
    );
}

export default DeprecatedPopupFooter;