import classNames from "classnames";
import {TextInput} from "common/components/input/text";
import {observer} from "mobx-react-lite";
import {KeyboardEventHandler, MouseEventHandler} from "react";
import IconSend18x18 from "../../../common/components/icons/IconSend18x18";
import {TaskDocumentType} from "../../types";
import {AttachClip} from "./AttachClip";
import {AttachedFiles} from "./AttachedFiles";
import s from "./MessageBox.module.css";
import {AttachedFileUiState} from "./state";

type Props = {
    className?: string;
    message: string;
    placeholder?: string;
    types: TaskDocumentType[];
    attachments: AttachedFileUiState[];
    typesError?: string;
    canSend?: boolean;
    isDisplaySend?: boolean;
    isTypesLoading: boolean;
    setMessage: (message: string) => void;
    onDetach: (attachment: AttachedFileUiState) => void;
    onAttach: (type: string, typeName: string, files: File[]) => void;
    onSend?: () => void;
    onLoadTypes: () => void;
};

export const MessageBox = observer((props: Props) => {
    const {
        className,
        message,
        placeholder,
        types,
        attachments,
        typesError,
        canSend,
        isDisplaySend = !!props.onSend,
        isTypesLoading,
        setMessage,
        onSend,
        onDetach,
        onAttach,
        onLoadTypes,
    } = props;

    const handleSend: MouseEventHandler | undefined = onSend ? e => {
        if (canSend) {
            e.stopPropagation();
            onSend();
        }
    } : undefined;

    const handleEnterSend: KeyboardEventHandler | undefined = onSend ? e => {
        if (e.key === "Enter" && canSend) {
            onSend();
        }
    } : undefined;

    return (
        <div className={classNames(s.messageBox, className)}>
            <AttachedFiles
                attachments={attachments}
                onDetach={onDetach}/>

            <TextInput
                value={message}
                heading={(
                    <AttachClip
                        types={types}
                        error={typesError}
                        isLoading={isTypesLoading}
                        onAttach={onAttach}
                        onLoadTypes={onLoadTypes}/>
                )}
                trailing={isDisplaySend && (
                    <IconSend18x18
                        className={classNames(
                            canSend ? s.action : s.actionDisabled,
                            s.actionSend,
                        )}
                        fill="var(--dark-primary-main)"
                        onClick={handleSend}/>
                )}
                placeholder={placeholder}
                maxLength={1000}
                onKeyUp={handleEnterSend}
                onValueChange={setMessage}/>
        </div>
    );
});
