import {createContext, ReactNode, useContext, useMemo} from "react";

type PopupSizeContentType = {
    width?: number,
    height?: number,
};

export const PopupSizeContext = createContext<PopupSizeContentType | null>(null);

export function usePopupSize(): PopupSizeContentType | null {
    return useContext(PopupSizeContext);
}

export const PopupSizeContextProvider = (props: { children: ReactNode }) => {
    const {children} = props;
    const size = useMemo(() => ({}), []);

    return (
        <PopupSizeContext.Provider value={size}>
            {children}
        </PopupSizeContext.Provider>
    )
};