import {P} from "common/components/typography";
import s from "./ContextMenu.module.css";

type Props = {
    message: string;
};

export const SkeletonContextMenuError = (props: Props) => {
    const {message} = props;
    return <P className={s.error} variant="Paragraph/Small/p" color="dark-status-danger-d300">{message}</P>;
};
