export class CollapsedCompanyRepository {
    private readonly KEY = "collapsed_companies";

    findAll(): number[] {
        const collapsed = localStorage.getItem(this.KEY);
        if (!collapsed) {
            return [];
        }

        try {
            const result = JSON.parse(collapsed);
            if (Array.isArray(result)) {
                return result;
            } else {
                return [];
            }
        } catch (e) {
            console.error("Unable to parse collapsed companies: ", e);
            return [];
        }
    }

    save(collapsed: number[]) {
        localStorage.setItem(this.KEY, JSON.stringify(collapsed));
    }
}
