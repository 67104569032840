import {CitizenRepository} from "citizens/repositories";
import {Citizen} from "citizens/types";
import {ConfirmationModal} from "common/stores";
import {makeAutoObservable} from "mobx";

export class DeleteCitizenStore {
    readonly modal: ConfirmationModal<Citizen>;
    private deleteListener: (() => void) | null;

    constructor(private readonly citizenRepo: CitizenRepository) {
        this.deleteListener = null;
        makeAutoObservable(this, {}, {autoBind: true});
        this.modal = new ConfirmationModal(this._deleteCitizen);
    }

    get isLoading() {
        return this.modal.isJobPending;
    }

    get errorMessage() {
        return this.modal.errorMessage;
    }

    dismissError() {
        return this.modal.dismissError();
    }

    setDeleteListener(listener: (() => void) | null) {
        this.deleteListener = listener;
    }

    show(citizen: Citizen) {
        this.modal.show(citizen);
    }

    private async _deleteCitizen(citizen: Citizen) {
        await this.citizenRepo.deleteUser(citizen.id);
        this.deleteListener?.();
    }
}