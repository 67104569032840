import {CommentAttachmentParams, CommentEvent, mapToTaskEvent, TaskEventResponse} from "./TaskEvent";
import {mapToTaskStatus, TaskStatus, TaskStatusResponse} from "./TaskStatus";

export interface ChildTask {
    id: number;
    number: number;
    executorId: number;
    executorName: string;
    executorAvatar: string;
    description: string;
    status: TaskStatus;
    companyId: number;
    houseId: number;
    systemId: number;
    lastComment: CommentEvent | null;
    delegationStatus: "to" | "from" | null;
    children: ChildTask[];
}

export interface CreateChildTaskParam {
    parentId: number;
    employeeId: number;
    typeId: number;
    description: string;
    attachments: CommentAttachmentParams[];
}

export interface ChildTaskResponse {
    id: number;
    number: number;
    executor_id: number;
    executor_name: string;
    executor_avatar: string;
    description: string;
    status: TaskStatusResponse;
    company_id: number;
    house_id: number;
    system_id: number;
    last_comment: TaskEventResponse | null;
    delegation_status: "from" | "to" | null;
    children: ChildTaskResponse[];
}

export function mapToChildTask(response: ChildTaskResponse): ChildTask {
    return {
        id: response.id,
        number: response.number,
        executorId: response.executor_id,
        executorName: response.executor_name,
        executorAvatar: response.executor_avatar,
        description: response.description,
        status: mapToTaskStatus(response.status),
        companyId: response.company_id,
        houseId: response.house_id,
        systemId: response.system_id,
        lastComment: response.last_comment ? mapToTaskEvent(response.last_comment) as CommentEvent : null,
        children: response.children.map(mapToChildTask),
        delegationStatus: response.delegation_status,
    };
}
