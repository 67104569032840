import {
    AnyIntercom,
    FindAllIntercoms,
    IntercomResponse,
    ListedIntercom,
    ListedIntercomResponse,
    mapToIntercom,
    mapToListedIntercom,
    mapToUdginForwards,
    mapUpsertParamToBody,
    UdginForwardsResponse,
    UpdateUdginForwards,
    UpsertAnyIntercom,
} from "citizens/types/ListedIntercom";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";

export class IntercomRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllIntercoms): Promise<Pageable<ListedIntercom>> {
        const response = await this.client.get<PageableResponse<ListedIntercomResponse>>(
            "intercoms",
            {
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search,
                }
            }
        );

        return mapPageable(response, mapToListedIntercom);
    }

    async findAllByHouseId(houseId: string): Promise<ListedIntercom[]> {
        const response = await this.client.get<ListedIntercomResponse[]>(`houses/${houseId}/intercoms`);
        return response.map(mapToListedIntercom);
    }

    async findById(intercomId: string, signal?: AbortSignal): Promise<AnyIntercom> {
        const response = await this.client.get<IntercomResponse>(`intercoms/${intercomId}`, {signal});
        return mapToIntercom(response);
    }

    async create(params: UpsertAnyIntercom): Promise<AnyIntercom> {
        const response = await this.client.post<IntercomResponse>(`intercoms`, {
            body: mapUpsertParamToBody(params),
        });
        return mapToIntercom(response);
    }

    async updateById(intercomId: string, params: UpsertAnyIntercom): Promise<AnyIntercom> {
        const response = await this.client.put<IntercomResponse>(`intercoms/${intercomId}`, {
            body: mapUpsertParamToBody(params),
        });
        return mapToIntercom(response);
    }

    async updateUdginForward(intercomId: string, params: UpdateUdginForwards) {
        const response = await this.client.put<UdginForwardsResponse>(`intercoms/${intercomId}/udgin-forwards`, {
            body: {
                flat_ranges: params.flatRanges.map(item => ({
                    first_flat_number: item.from,
                    last_flat_number: item.to,
                    offset: item.offset,
                    prefix: item.prefix,
                })),
                special_flats: params.specialFlats.map(item => ({
                    flat_number: item.flat,
                    internal_flat_number: item.internalNumber,
                })),
                sip_dispatcher: params.sipDispatcher,
                enable_test_flat: params.enableTestFlat,
                test_flat_number: params.testFlatNumber,
            },
        });
        return mapToUdginForwards(response);
    }

    async reload(intercomId: string) {
        return this.client.post(`intercoms/${intercomId}/reload`);
    }

    async open(intercomId: string) {
        return this.client.post(`intercoms/${intercomId}/open`);
    }

    async sendConfig(intercomId: string) {
        return this.client.post(`intercoms/${intercomId}/update-config-force`);
    }

    async sendForwardsConfig(intercomId: string) {
        return this.client.post(`intercoms/${intercomId}/update-forwards-force`);
    }
}
