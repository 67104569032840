import {ReactNode} from "react";
import s from "./PopupHeader.module.css";

interface IProps {
    children: ReactNode;
}

function DeprecatedPopupHeader(props: IProps) {
    const {children} = props;
    return (
        <div className={s.container}>{children}</div>
    );
}

export default DeprecatedPopupHeader;