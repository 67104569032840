import {Ref} from "react";

export type LoadOptionsFunc<T> = (search: string, page: number) => Promise<InputBoxItem<T>[] | {
    canLoadMore: boolean,
    items: InputBoxItem<T>[]
}>;

export type InputBoxOptionRenderer<T> = (state: InputBoxItemState<T>) => JSX.Element;
export type InputBoxBulletRenderer<T> = (state: InputBoxItemBulletState<T>) => JSX.Element;
export type InputBoxValueRenderer<T> = (state: InputBoxValueState<T>) => JSX.Element;

export type InputBoxItem<T> = {
    id: any;
    label: string;
    value: T;
};

export type SelectedInputBoxItem<T> = Omit<InputBoxItem<T>, "value"> & {
    value?: T;
};

export type InputBoxRef = {
    controlRef: HTMLElement;
    focus: () => void;
};

export type InputBoxItemState<T> = {
    isFocused: boolean;
    isDisabled: boolean;
    isSelected: boolean;
    isMulti: boolean;
    item: InputBoxItem<T>;
    innerProps: JSX.IntrinsicElements['div'];
    innerRef: Ref<HTMLDivElement> & any;
};

export type InputBoxItemBulletState<T> = {
    item: InputBoxItem<T>;
    innerProps: JSX.IntrinsicElements['div'];
    deselectItem: () => void;
};

export type InputBoxValueState<T> = {
    item: InputBoxItem<T>;
};

export const MAX_INPUT_BOX_MENU_HEIGHT = 200;