export interface UserGeoDetailsResponse {
    first_name: string;
    last_name: string;
    patronymic: string;
    avatar_url?: string;
    locations: UserGeoLocationResponse[];
}

export interface UserGeoLocationResponse {
    lat: number;
    lon: number;
    timestamp: number;
}

export interface UserGeoDetails {
    fullName: string;
    avatarUrl: string | null;
    locations: UserGeoLocation[];
}

export interface UserGeoLocation {
    lat: number;
    lon: number;
    createdAt: Date;
}

export interface FindAllUserGeoDetailParam {
    userId: number;
    page: number;
    limit: number;
    signal: AbortSignal;
}

export function mapToUserGeoDetails(response: UserGeoDetailsResponse): UserGeoDetails {
    return {
        fullName: `${response.last_name} ${response.first_name} ${response.patronymic}`.trim(),
        avatarUrl: response.avatar_url || null,
        locations: response.locations.map(location => ({
            lat: location.lat,
            lon: location.lon,
            createdAt: new Date(location.timestamp * 1000),
        }))
    };
}