import {AuthorizedHttpClient} from "common/net";
import {CreateRelatedTaskParams, UpdateRelatedTaskParams} from "../types";

export class RelatedTaskRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    createAll(taskId: number, params: CreateRelatedTaskParams[]): Promise<void> {
        return this.client.post(`v1/tasks${taskId}/related`, {
            body: params.map(item => ({
                number: item.number,
                program_id: item.programId,
                url: item.url,
            })),
        });
    }

    deleteAll(ids: number[]): Promise<void> {
        return this.client.delete("v1/tasks/related", {
            body: ids,
        });
    }

    updateAll(params: UpdateRelatedTaskParams[]): Promise<void> {
        return this.client.put("v1/tasks/related", {
            body: params.map(item => ({
                id: item.id,
                number: item.number,
                program_id: item.programId,
                url: item.url,
            })),
        });
    }
}
