import {useRepositories} from "common/components/app/RepositoriesStore";
import {P} from "common/components/typography";
import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo} from "react";
import DeprecatedButton from "../../../common/components/deprecated_button";
import DeprecatedPopup from "../../../common/components/deprecated_popup/DeprecatedPopup";
import DeprecatedPopupFooter from "../../../common/components/deprecated_popup/footer/DeprecatedPopupFooter";
import DeprecatedPopupHeader from "../../../common/components/deprecated_popup/header/DeprecatedPopupHeader";
import DeprecatedPopupSubTitle from "../../../common/components/deprecated_popup/sub_title/DeprecatedPopupSubTitle";
import DeprecatedPopupTitle from "../../../common/components/deprecated_popup/title/DeprecatedPopupTitle";
import s from "./EditProfile.module.css";
import {EditProfilePopupStore} from "./EditProfilePopupStore";
import Form from "./Form";
import FormSkeleton from "./FormSkeleton";

interface IProps {
    onUpdate?(firstName: string, surName: string, avatar: string): void;

    onCancel?(): void;
}

function useStore() {
    const repos = useRepositories();
    return useMemo(
        () => new EditProfilePopupStore(repos.users, repos.uploads),
        [repos.users, repos.uploads]);
}

function EditProfilePopup(props: IProps) {
    const {onUpdate, onCancel} = props;
    const store = useStore();

    useEffect(() => {
        store.onMount();
    }, [store])

    async function handleSaveClick() {
        await store.submitForm();
    }

    function close() {
        onCancel?.();
    }

    useEffect(() => {
        store.setClosePopupFunc((firstName, surName, avatar) => onUpdate?.(firstName, surName, avatar));
    }, [store, onUpdate]);

    return (
        <DeprecatedPopup onClose={close}>
            <DeprecatedPopupHeader>
                <DeprecatedPopupTitle>Профиль</DeprecatedPopupTitle>
                <DeprecatedPopupSubTitle>Основная информация</DeprecatedPopupSubTitle>
            </DeprecatedPopupHeader>
            {store.isGlobalError || store.isLoading ? <FormSkeleton/> : <Form store={store}/>}
            <P className={s.error}>{store.error}</P>
            <DeprecatedPopupFooter>
                <DeprecatedButton variant="PopupOutlined" onClick={close}>Закрыть без сохранений</DeprecatedButton>
                <DeprecatedButton
                    type="submit"
                    variant="PopupFilled"
                    onClick={handleSaveClick}>Сохранить</DeprecatedButton>
            </DeprecatedPopupFooter>
        </DeprecatedPopup>
    );
}

export default observer(EditProfilePopup);