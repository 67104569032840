import {UserStore, useUserStore} from "common/stores";
import {observer} from "mobx-react-lite";
import {DefinedUserRole} from "users/types";
import NotFoundRoute from "./NotFoundRoute";

type Props = {
    roles?: DefinedUserRole[];
    isAvailable?: (user: UserStore) => boolean;
    element: JSX.Element;
};

export const ProtectedRoute = observer((props: Props) => {
    const {roles, element, isAvailable} = props;
    const userStore = useUserStore();

    if (roles && roles.includes(userStore.roleId)) {
        return element;
    }

    if (isAvailable && isAvailable(userStore)) {
        return element;
    }

    return <NotFoundRoute/>;
});