import {ReactNode} from "react";
import {Button, ButtonSkeleton} from "../../button";
import {Modal} from "../Modal";
import {ModalContent} from "../ModalContent";
import {ModalFooter} from "../ModalFooter";
import {ModalHeader} from "../ModalHeader";
import s from "./NotificationModal.module.css";

type Props = {
    title: string;
    positive: string;
    negative?: string;
    isSwapButtonColors?: boolean;
    isLoading?: boolean;
    onPositive: () => void;
    onNegative?: () => void;
    onDismiss?: () => void;
    children: ReactNode;
};

export const NotificationModal = (props: Props) => {
    const {
        title,
        positive,
        negative,
        isLoading,
        isSwapButtonColors,
        onPositive,
        onDismiss,
        onNegative,
        children
    } = props;

    return (
        <Modal width={580} onDismiss={onDismiss || onNegative || onPositive}>
            <ModalHeader title={title}/>
            <ModalContent className={s.content}>
                {children}
            </ModalContent>
            <ModalFooter
                positive={
                    isLoading
                        ? <ButtonSkeleton width={125}/>
                        : <Button
                            color={isSwapButtonColors ? "Warning" : "Primary"}
                            autoFocus={!negative}
                            onClick={onPositive}>
                            {positive}
                        </Button>
                }
                negative={negative && (
                    isLoading
                        ? <ButtonSkeleton width={100}/>
                        : <Button
                            color={isSwapButtonColors ? "Primary" : "Warning"}
                            autoFocus={!!negative}
                            onClick={onNegative}>
                            {negative}
                        </Button>
                )}
            />
        </Modal>
    );
};
