export interface TaskType {
    id: number;
    name: string;
    daysCount: number;
}

export interface TaskTypeResponse {
    id: number;
    name: string;
    days_count: number;
}

export function mapToTaskType(entity: TaskTypeResponse): TaskType {
    return {
        id: entity.id,
        name: entity.name,
        daysCount: entity.days_count,
    };
}
