export const TaskProgramConstraints = {
    maxNameLength: 30,
};

export interface TaskProgram {
    id: number;
    name: string;
}

export interface CreateTaskProgramParams {
    name: string;
}

export interface UpdateTaskProgramParams {
    id: number;
    name: string;
}
