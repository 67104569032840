import {CitizenAccessRepository, CitizenHouseRepository, CitizenTariffRepository} from "citizens/repositories";
import {CitizenTariff} from "citizens/types";
import {AsyncJob} from "common/stores/job";
import {makeAutoObservable} from "mobx";

export class TariffToggleStore {
    tariffs: CitizenTariff[];
    modalError: string;

    private isAlreadyLoaded: boolean;
    private changingIds: string[];
    private changeListener: (() => void) | null;
    private readonly fetchJob: AsyncJob<typeof TariffToggleStore.prototype._fetch>;

    constructor(
        private readonly tariffRepo: CitizenTariffRepository,
        private readonly accessRepo: CitizenAccessRepository,
        private readonly houseRepo: CitizenHouseRepository,
    ) {
        this.tariffs = [];
        this.isAlreadyLoaded = false;
        this.changingIds = [];
        this.modalError = "";
        this.changeListener = null;
        makeAutoObservable(this, {}, {autoBind: true});

        this.fetchJob = new AsyncJob({job: this._fetch});
    }

    get isLoading() {
        return this.fetchJob.isCreated || this.fetchJob.isPending;
    }

    get errorMessage() {
        return this.fetchJob.errorMessage;
    }

    isChanging(accessId: string) {
        return this.changingIds.includes(accessId);
    }

    setChangeListener(listener: (() => void) | null) {
        this.changeListener = listener;
    }

    load() {
        if (!this.isAlreadyLoaded && !this.fetchJob.isPending) {
            this.fetchJob.start();
        }
    }

    change(itemId: string, tariffId: string, isHouseTariff: boolean) {
        if (this.changingIds.includes(itemId)) {
            return;
        }

        this.changingIds.push(itemId);
        const promise = isHouseTariff
            ? this.houseRepo.updateTariff(itemId, tariffId)
            : this.accessRepo.updateTariff(itemId, tariffId);
        promise
            .then(() => this.changeListener?.())
            .catch(e => this.modalError = e.toString())
            .finally(() => {
                this.changingIds = this.changingIds.filter(id => id !== itemId);
            });
    }

    dismissModalError() {
        this.modalError = "";
    }

    private* _fetch(signal: AbortSignal) {
        this.tariffs = yield this.tariffRepo.findAll(signal);
        this.isAlreadyLoaded = true;
    }
}