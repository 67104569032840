import {Button, ButtonSkeleton} from "common/components/button";
import {Modal, ModalContent, ModalFooter, ModalHeader} from "common/components/modal";
import {P} from "common/components/typography";
import {observer} from "mobx-react-lite";
import {useMemo} from "react";
import {CommentMessageBox, usePendingCommentStoreMemo} from "../../components/message_box";
import s from "./CommentModal.module.css";
import {CommentModalStore} from "./CommentModalStore";

type Props = {
    store: CommentModalStore;
};

export function useCommentStoreMemo() {
    const pendingCommentStore = usePendingCommentStoreMemo();
    return useMemo(() => new CommentModalStore(pendingCommentStore), [pendingCommentStore]);
}

export const CommentModal = observer((props: Props) => {
    const {store} = props;
    if (!store.isShow) {
        return null;
    }

    return (
        <Modal width={572} variant="full-size" onDismiss={store.close}>
            <ModalHeader title="Изменить статус"/>

            <ModalContent>
                <div className={s.content}>
                    <P>Для перехода в статус "<span className={s.status}>{store.newStatus}</span>", оставьте
                        комментарий</P>
                    <CommentMessageBox
                        store={store.pendingComment}
                        placeholder="Напишите комментарий"
                        isShowSend={false}/>
                </div>
            </ModalContent>

            <ModalFooter
                negative={(
                    <Button color="Warning" onClick={store.close}>
                        Отмена
                    </Button>
                )}
                positive={(
                    store.isLoading
                        ? <ButtonSkeleton width={100}/>
                        : <Button
                            color="Primary"
                            disabled={!store.canSend}
                            onClick={store.send}>
                            Отправить
                        </Button>
                )}/>
        </Modal>
    );
});
