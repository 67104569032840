import {useCitizenUserStore} from "citizens/routes/citizen_user/CitizenUserStore";
import {Breadcrumbs, BreadcrumbsItem} from "common/components/breadcrums";
import {observer} from "mobx-react-lite";
import {useLocation, useParams} from "react-router-dom";

export const CitizenUserNavigation = observer(() => {
    const store = useCitizenUserStore();
    const location = useLocation();
    const params = useParams<{ houseId: string; apartment: string }>();

    if (location.pathname.startsWith("/citizen/houses/")) {
        return (
            <Breadcrumbs>
                <BreadcrumbsItem href={`/citizen/houses/${params.houseId}`}>Дома</BreadcrumbsItem>
                <BreadcrumbsItem href={`/citizen/houses/${params.houseId}/apartments/${params.apartment}`}>
                    Квартира {params.apartment}
                </BreadcrumbsItem>
                <BreadcrumbsItem>{store.user?.fullName}</BreadcrumbsItem>
            </Breadcrumbs>
        );
    }

    return (
        <Breadcrumbs>
            <BreadcrumbsItem href={`/citizen`}>Житель</BreadcrumbsItem>
            <BreadcrumbsItem>{store.user?.fullName}</BreadcrumbsItem>
        </Breadcrumbs>
    );
});