import {CreateForwardRangeParams, FindAllIntercomForwards, IntercomForward} from "citizens/types";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";

export class IntercomForwardRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllIntercomForwards): Promise<Pageable<IntercomForward>> {
        const response = await this.client.get<PageableResponse<IntercomForward>>(
            `/intercoms/${params.intercomId}/forwards`, {
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search || undefined,
                },
            });
        return mapPageable(response, item => item);
    }

    async create(intercomId: string, flat: string, forwards: string[]): Promise<void> {
        return this.client.post(`intercoms/${intercomId}/forwards`, {
            body: {
                flat,
                forwards
            }
        });
    }

    async createRange(params: CreateForwardRangeParams): Promise<void> {
        return this.client.post(`intercoms/${params.intercomId}/forwards-range`, {
            body: {
                from_flat: params.fromFlat,
                to_flat: params.toFlat,
                user: params.user,
                host: params.host,
            }
        });
    }

    async update(intercomId: string, flat: string, forwards: string[]): Promise<void> {
        return this.client.put(`intercoms/${intercomId}/forwards`, {
            body: {
                flat,
                forwards
            }
        });
    }

    async delete(intercomId: string, forwardId: string) {
        return this.client.delete(`intercoms/${intercomId}/forwards/${forwardId}`);
    }
}
