import {useRepositories} from "common/components/app/RepositoriesStore";
import {observer} from "mobx-react-lite";
import {useMemo} from "react";
import {IMenuItemUiState, SuggestionsPopup} from "../../../common/components/suggestions_popup";
import {useCommentStoreMemo} from "../../modals/comment";
import {TaskStatus} from "../../types";
import {ApplyTransitionsStore} from "./ApplyTransitionsStore";

type Props = {
    store: ApplyTransitionsStore;
    taskId: number;
    children: (isOpen: boolean) => JSX.Element;
}

export function useTransitionsStoreMemo() {
    const repos = useRepositories();
    const commentModal = useCommentStoreMemo();
    return useMemo(() => new ApplyTransitionsStore(repos.taskStatuses, commentModal), [repos, commentModal]);
}

export const TransitionsPopup = observer((props: Props) => {
    const {store, taskId, children} = props;

    const items: IMenuItemUiState<TaskStatus>[] = useMemo(() => {
        if (!store.transitions) {
            return [];
        }

        return store.transitions.map(transition => ({
            key: transition.id,
            label: transition.localizedName,
            value: transition,
        }));
    }, [store.transitions]);

    const onFetch = () => store.fetchTransitions(taskId);
    return (
        <SuggestionsPopup
            items={items}
            retryLoad={onFetch}
            isLoading={store.isLoading}
            error={store.errorMessage}
            onSelect={item => store.applyTransition(taskId, (item.value as TaskStatus))}
            onOpen={onFetch}>
            {children}
        </SuggestionsPopup>
    );
});
