import React from "react";
import IconClose12 from "../../components/icons/IconClose12";
import {P} from "../typography";
import {useModalContext} from "./context";
import s from "./Modal.module.css";

type Props = {
    title: string;
    caption?: string;
};

export const ModalHeader = (props: Props) => {
    const {title, caption} = props;
    const context = useModalContext();

    return (
        <div className={s.header}>
            <div className={s.titles}>
                <P variant="Header/H2">{title}</P>
                {caption && <P className={s.caption} variant="Paragraph/Default/p" color="dark-gray-p75">{caption}</P>}
            </div>
            <IconClose12 className={s.headerClose} onClick={context.onDismiss}/>
        </div>
    );
};
