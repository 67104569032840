export type NotificationType = "in-app" | "push";

export function getNotificationTypeName(type: NotificationType) {
    if (type === "in-app") {
        return "In app";
    } else {
        return "Push";
    }
}

interface HousesAudience {
    type: "houses";
    mode: "inclusive" | "exclusive";
    ids: string[];
}

interface HouseCitizensAudience {
    type: "house-users";
    houseId: string;
    mode: "inclusive" | "exclusive";
    ids: string[];
}

interface ApartmentCitizensAudience {
    type: "users";
    ids: string[];
}

export type NotificationAudienceBody = HousesAudience | HouseCitizensAudience | ApartmentCitizensAudience;

export interface CreateNotificationBody {
    audience: NotificationAudienceBody;
    notification: {
        type: NotificationType,
        title: string,
        text: string,
        impressions: number; // Количество показов
    }
}

