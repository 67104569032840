import {ReactNode, RefObject, useEffect, useRef} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {Popup} from "../popup";
import s from "./ContextMenu.module.css";
import {DismissMenuContext} from "./DismissMenuContext";

type Props = {
    externalTriggerRef?: RefObject<HTMLElement>;
    trigger: ReactNode;
    children: ReactNode;
    height?: number;
    onOpen?: () => void;
}

export const ContextMenu = (props: Props) => {
    const {trigger, externalTriggerRef, height, children, onOpen} = props;
    const popupRef = useRef<PopupActions | null>(null);

    useEffect(() => {
        const element = externalTriggerRef?.current;
        if (!element) return;

        const handleClick = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            popupRef.current?.toggle();
        };
        element.addEventListener("click", handleClick);
        return () => element.removeEventListener("click", handleClick);
    }, [externalTriggerRef]);

    const dismissMenu = () => popupRef.current?.close();
    return (
        <DismissMenuContext.Provider value={dismissMenu}>
            <Popup
                ref={popupRef}
                trigger={<span>{trigger}</span>}
                position={["bottom center", "top center"]}
                keepTooltipInside
                onOpen={onOpen}>
                <div className={s.menu} style={height ? {height} : undefined}>
                    {children}
                </div>
            </Popup>
        </DismissMenuContext.Provider>
    );
};
