import {AuthorizedHttpClient} from "common/net";
import {AuthTokenResponse, mapToAuthToken} from "../types";

export class MimicAuthRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findTokenByUserId(id: number, deviceId: string) {
        const response = await this.client.get<AuthTokenResponse>(`v1/users/${id}/mimicjwt`, {
            params: {device_id: deviceId},
        });
        return mapToAuthToken(response);
    }
}
