import {BasipBackup, BasipBackupResponse, FindAllBasipBackup, mapToBasipBackup} from "citizens/types";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";

export class BasipBackupRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllBasipBackup): Promise<Pageable<BasipBackup>> {
        const response = await this.client.get<PageableResponse<BasipBackupResponse>>(
            `/intercoms/${params.intercomId}/backups`, {
                signal: params.signal,
                params: {
                    search: params.search,
                    page: params.page,
                    limit: params.limit,
                    type: params.type,
                },
            });
        return mapPageable(response, mapToBasipBackup);
    }

    async create(intercomId: string, type: "settings" | "data", description: string): Promise<void> {
        return this.client.post(`intercoms/${intercomId}/backups`, {
            body: {
                type,
                description
            }
        });
    }

    async update(intercomId: string, backupId: string, description: string): Promise<void> {
        return this.client.put(`intercoms/${intercomId}/backups/${backupId}`, {
            body: {description}
        });
    }

    async restore(intercomId: string, backupId: string): Promise<void> {
        return this.client.post(`intercoms/${intercomId}/backups/${backupId}/restore`);
    }

    async delete(intercomId: string, backupId: string) {
        return this.client.delete(`intercoms/${intercomId}/backups/${backupId}`);
    }
}
