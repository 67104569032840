import {UserStore, useUserStore} from "common/stores";
import {observer} from "mobx-react-lite";
import React, {ReactNode} from "react";
import {Link, useLocation} from "react-router-dom";
import {DefinedUserRole} from "users/types";
import {ReactComponent as EtdLogo} from "../../../../common/assets/etd_logo.svg";
import {ReactComponent as EtdLogoChristmas} from "../../../../common/assets/etd_logo_christmas.svg";
import s from "./Navigation.module.css";
import {NavigationItem} from "./NavigationItem";

export interface INavigationItem {
    title: string;
    path: string;
    icon: ReactNode;
    roles?: DefinedUserRole[];
    isAvailable?: (user: UserStore) => boolean;
}

type Props = {
    routes: INavigationItem[];
};

export const Navigation = observer((props: Props) => {
    const {routes} = props;
    const {pathname} = useLocation();
    const user = useUserStore();

    const selectedRoute = React.useMemo(() => {
        let selectedRoute: INavigationItem | null = null;
        let longestMatch = 0;
        for (const route of routes) {
            if (pathname.startsWith(route.path) && longestMatch < route.path.length) {
                selectedRoute = route;
                longestMatch = route.path.length;
            }
        }

        return selectedRoute;
    }, [pathname, routes]);

    const isChristmas = React.useMemo(() => {
        const now = new Date();
        return (now.getMonth() === 11 && now.getDate() >= 15) || (now.getMonth() === 0 && now.getDate() <= 10);
    }, []);

    return (
        <div className={s.navigation}>
            <Link className={isChristmas ? s.logoChristmas : s.logo} to="/">
                {isChristmas ? <EtdLogoChristmas/> : <EtdLogo/>}
            </Link>

            {routes.map((route, index) => {
                if (route.roles && !route.roles.includes(user.roleId)) {
                    return null;
                }

                if (route.isAvailable && !route.isAvailable(user)) {
                    return null;
                }

                return (
                    <NavigationItem
                        key={index}
                        title={route.title}
                        icon={route.icon}
                        path={route.path}
                        isSelected={selectedRoute === route}/>
                );
            })}
        </div>
    );
});
