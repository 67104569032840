import createSvgIcon from "./utils";

const IconDocument35 = createSvgIcon((
    <>
        <path
            stroke="transparent"
            d="M23.542 3.723c-.598-.598-1.634-.19-1.634.642v5.09c0 2.128 1.809 3.893 4.01 3.893 1.386.014 3.311.014 4.96.014.83 0 1.268-.977.684-1.56-2.1-2.115-5.862-5.92-8.02-8.08Z"/>
        <path
            stroke="transparent"
            d="M30.396 15.36H26.18a6.28 6.28 0 0 1-6.27-6.27V4.875c0-.802-.657-1.458-1.459-1.458H12.27c-4.492 0-8.123 2.916-8.123 8.123v12.92c0 5.207 3.631 8.123 8.123 8.123H23.73c4.492 0 8.123-2.916 8.123-8.123V16.82c0-.802-.656-1.459-1.458-1.459ZM17.27 26.385h-5.834a1.102 1.102 0 0 1-1.093-1.093c0-.598.496-1.094 1.094-1.094h5.833c.598 0 1.094.496 1.094 1.094 0 .598-.496 1.093-1.094 1.093Zm2.916-5.833h-8.75a1.102 1.102 0 0 1-1.093-1.094c0-.598.496-1.093 1.094-1.093h8.75c.597 0 1.093.495 1.093 1.093s-.496 1.094-1.093 1.094Z"/>
    </>
), {width: 35, height: 35});

export default IconDocument35;
