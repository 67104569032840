import {AuthorizedHttpClient} from "common/net";
import {GrantedHouseTag, PatchGrantedHouseTagParams} from "../types";

export class GrantedHouseTagRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findByUserId(companyId: number, userId: number): Promise<GrantedHouseTag[]> {
        return this.client.get<GrantedHouseTag[]>(`v1/users/${userId}/tags`);
    }

    patch(companyId: number, userId: number, params: PatchGrantedHouseTagParams): Promise<void> {
        return this.client.patch(`v1/users/${userId}/tags`, {body: params});
    }
}
