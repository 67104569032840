export class ServicesConfig {
    get landing(): string {
        return this.getEnvVar("REACT_APP_HOST_LANDING");
    }

    get main(): string {
        return this.getEnvVar("REACT_APP_HOST_MAIN");
    }

    get mainWs(): string {
        return this.getEnvVar("REACT_APP_HOST_MAIN_WS");
    }

    get citizen(): string {
        return this.getEnvVar("REACT_APP_HOST_CITIZEN");
    }

    private getEnvVar(name: string): string {
        const value = process.env[name];
        if (!value) {
            throw new Error(`Environment variable ${name} is not defined`);
        }

        return value;
    }
}
