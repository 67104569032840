import {DeleteAccessStore} from "citizens/modals/delete_access/DeleteAccessStore";
import {useRepositories} from "common/components/app/RepositoriesStore";
import {
    NotificationModal,
    NotificationModalHighlight,
    NotificationModalText
} from "common/components/modal/notification";
import {observer} from "mobx-react-lite";
import React, {useMemo} from "react";

type Props = {
    store: DeleteAccessStore;
};

export function useDeleteAccessStoreMemo() {
    const repos = useRepositories();
    return useMemo(() => new DeleteAccessStore(repos.citizenAccess), [repos]);
}

export const DeleteAccessModal = observer((props: Props) => {
    const {store} = props;
    const modal = store.modal;
    return modal.isShow && modal.item ? (
        <NotificationModal
            title="Подтвердите действие"
            positive="Удалить"
            negative="Закрыть"
            isSwapButtonColors
            onNegative={modal.close}
            onPositive={modal.confirm}>
            <NotificationModalText>
                Вы уверены, что
                хотите удалить доступ
                жителя <NotificationModalHighlight>{modal.item.citizenName}</NotificationModalHighlight> к
                адресу <NotificationModalHighlight>{modal.item.address},
                кв. {modal.item.apartment}</NotificationModalHighlight>?
            </NotificationModalText>
        </NotificationModal>
    ) : null;
});