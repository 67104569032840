export interface CitizenTariff {
    id: string;
    name: string;
    price: number;
    isDefault: string;
    connectedCitizens: number;
    description: CitizenTariffDescription[];
}

export interface CitizenTariffDescription {
    id: number;
    title: string;
    icon: string;
    text: string;
}

export interface CitizenTariffResponse {
    id: string;
    name: string;
    price: number;
    is_default: string;
    connected_citizens: number
    description: CitizenTariffDescription[];
}

export interface CreateCitizenTariffBody {
    name: string;
    price: number;
    description: {
        id?: number;
        title: string;
        icon: string;
        text: string;
    }[];
}

export interface UpdateCitizenTariffBody {
    id: string;
    name: string;
    price: number;
    description: {
        id?: number;
        title: string;
        icon: string;
        text: string;
    }[];
}

export function mapToTariff(response: CitizenTariffResponse): CitizenTariff {
    return {
        id: response.id,
        isDefault: response.is_default,
        price: response.price || 0,
        connectedCitizens: response.connected_citizens || 0,
        name: response.name,
        description: response.description,
    };
}