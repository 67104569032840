import createSvgIcon from "./utils";

const IconEmptyWallet16 = createSvgIcon((
    <>
        <path
            d="M12.0267 8.86666C11.7467 9.13999 11.5867 9.53333 11.6267 9.95333C11.6867 10.6733 12.3468 11.2 13.0668 11.2H14.3334V11.9933C14.3334 13.3733 13.2068 14.5 11.8268 14.5H5.08675C5.29342 14.3267 5.47341 14.1133 5.61341 13.8733C5.86008 13.4733 6.00008 13 6.00008 12.5C6.00008 11.0267 4.80675 9.83333 3.33341 9.83333C2.70675 9.83333 2.12675 10.0533 1.66675 10.42V7.50667C1.66675 6.12667 2.79341 5 4.17341 5H11.8268C13.2068 5 14.3334 6.12667 14.3334 7.50667V8.46667H12.9867C12.6134 8.46667 12.2734 8.61333 12.0267 8.86666Z"
            fill="transparent" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M1.66675 8.10686V5.06023C1.66675 4.2669 2.15341 3.5602 2.89341 3.2802L8.18675 1.2802C9.01341 0.96687 9.90009 1.58022 9.90009 2.46689V5.00021"
            fill="transparent" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M15.0393 9.14677V10.5201C15.0393 10.8868 14.746 11.1868 14.3726 11.2001H13.066C12.346 11.2001 11.686 10.6735 11.626 9.95345C11.586 9.53345 11.7459 9.14011 12.0259 8.86678C12.2726 8.61345 12.6126 8.4668 12.986 8.4668H14.3726C14.746 8.48013 15.0393 8.7801 15.0393 9.14677Z"
            fill="transparent" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.66675 7.83301H9.33341" fill="transparent" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M5.99984 12.5007C5.99984 13.0007 5.85984 13.474 5.61317 13.874C5.47317 14.114 5.29317 14.3273 5.08651 14.5007C4.61984 14.9207 4.0065 15.1673 3.33317 15.1673C2.35984 15.1673 1.51317 14.6473 1.05317 13.874C0.806505 13.474 0.666504 13.0007 0.666504 12.5007C0.666504 11.6607 1.05317 10.9073 1.6665 10.4207C2.1265 10.054 2.7065 9.83398 3.33317 9.83398C4.8065 9.83398 5.99984 11.0273 5.99984 12.5007Z"
            fill="transparent" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.38403 11.5518L4.28271 13.4504M2.38403 13.4504L4.28271 11.5518" fill="transparent"
              strokeLinecap="round"/>
    </>

), {width: 16, height: 16});

export default IconEmptyWallet16;