/**
 * Описывает возможность делегации заявок из компании {@link fromCompany} в компанию {@link toCompany}.
 */
export interface DelegationSetting {
    id: number;
    fromCompany: {
        id: number;
        name: string;
    },
    toCompany: {
        id: number;
        name: string;
    }
}

export interface CreateDelegationSettingParam {
    /**
     * Идентификатор компании, откуда можно делегировать заявки.
     */
    fromId: number;

    /**
     * Идентификатор компании, куда можно делегировать заявки.
     */
    toId: number;
}

export interface UpdateDelegationSettingParam extends CreateDelegationSettingParam {
    id: number;
}

export interface DelegationSettingResponse {
    id: number;
    from_company: {
        id: number;
        name: string;
    },
    to_company: {
        id: number;
        name: string;
    }
}

export function mapToDelegationSetting(response: DelegationSettingResponse): DelegationSetting {
    return {
        id: response.id,
        fromCompany: {
            id: response.from_company.id,
            name: response.from_company.name
        },
        toCompany: {
            id: response.to_company.id,
            name: response.to_company.name
        }
    };
}
