import {TriState} from "common/components/checkbox";
import {makeAutoObservable} from "mobx";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

export interface BooleanFilterOptions {
    key: string;
    title: string;
}

export class TriStateFilterStore implements BaseTaskFilterStore {
    state: TriState;
    readonly isVisible = true;

    constructor(private readonly options: BooleanFilterOptions) {
        this.state = TriState.Unchecked;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive(): boolean {
        return this.state !== TriState.Unchecked;
    }

    get title() {
        return this.options.title;
    }

    isOwnFilter(key: string) {
        return this.options.key === key;
    }

    toggle() {
        if (this.state === TriState.Indeterminate) {
            this.state = TriState.Unchecked;
        } else if (this.state === TriState.Checked) {
            this.state = TriState.Indeterminate;
        } else if (this.state === TriState.Unchecked) {
            this.state = TriState.Checked;
        }
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.state === TriState.Checked) {
            builder.upsert(this.options.key, this.options.title, "true");
        } else if (this.state === TriState.Indeterminate) {
            builder.upsert(this.options.key, this.options.title, "false");
        } else {
            throw new Error("Unexpected state");
        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        const value = builder.findValue(this.options.key);
        if (value === "true") {
            this.state = TriState.Checked;
        } else if (value === "false") {
            this.state = TriState.Indeterminate;
        } else {
            this.state = TriState.Unchecked;
        }
    }

    reset() {
        this.state = TriState.Unchecked;
    }
}