import {DateFormatter} from "common/utils";
import {makeAutoObservable} from "mobx";
import {IEntryFilter} from "./IEntryFilter";
import {DateRange, TDateRangeFilterOptions} from "./TFilterOptions";

type SerializedDateFilter = { id: string, start?: string, end?: string }[];

export class DateRangeEntryFilter<Q> implements IEntryFilter<Q, SerializedDateFilter> {
    selected: DateRange[];
    query: Q | null;
    private isDirty: boolean;
    private serialized: SerializedDateFilter | null;
    private readonly options: TDateRangeFilterOptions<Q>;

    constructor(options: TDateRangeFilterOptions<Q>) {
        this.selected = [];
        this.options = options;
        this.query = null;
        this.serialized = null;
        this.isDirty = false;

        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isDisabled() {
        return this.options.isDisabled;
    }

    get canSelectTime() {
        return this.options.canSelectTime;
    }

    get type() {
        return this.options.type;
    }

    get title() {
        return this.options.title;
    }

    get groups() {
        return this.options.groups || [{id: "", name: ""}];
    }

    get icon() {
        return this.options.icon;
    }

    get isUsing() {
        return this.query !== null;
    }

    get tooltip() {
        let result = "";
        for (const range of this.selected) {
            result += range.start ? DateFormatter.formatDateDefault(range.start) + " – " : "Не выбрано – ";
            result += range.end ? DateFormatter.formatDateDefault(range.end) : "Не выбрано";
        }

        return result || null;
    }

    init() {
    }

    free() {
    }

    serialize() {
        return this.serialized;
    }

    deserialize(values: SerializedDateFilter) {
        this.selected = values.map(({id, start, end}) => ({
            id,
            start: start ? new Date(start) : null,
            end: end ? new Date(end) : null
        }));
        this.recalculateInternal();
    }

    reset() {
        this.selected = [];
        this.query = null;
        this.serialized = null;
    }

    setFromDate(id: string, value: Date | null) {
        const date = this.selected.find(d => d.id === id);
        if (date) {
            date.start = value;
            date.end = null;
            if (value === null) {
                this.selected = this.selected.filter(d => d.id !== id);
            }
        } else if (value !== null) {
            this.selected.push({id, start: value, end: null});
            this.notifyDateChange(id);
        }
        this.isDirty = true;
    }

    setToDate(id: string, value: Date | null) {
        const date = this.selected.find(d => d.id === id);
        if (date) {
            date.end = value;
            if (value === null && date.start === null) {
                this.selected = this.selected.filter(d => d.id !== id);
            }
        } else {
            this.selected.push({id, start: null, end: value});
        }
        this.notifyDateChange(id);
        this.isDirty = true;
    }

    recalculateQuery() {
        if (!this.isDirty) {
            return;
        }

        this.recalculateInternal();
    }

    private recalculateInternal() {
        this.query = this.options.apply(this.selected);

        if (!this.selected.length) {
            this.serialized = null;
        } else {
            this.serialized = this.selected.map(({id, start, end}) => ({
                id,
                start: start ? start.toString() : undefined,
                end: end ? end.toString() : undefined,
            }));
        }
        this.isDirty = false;
    }

    private notifyDateChange(id: string) {
        if (this.options.onDateChange) {
            const date = this.selected.find(d => d.id === id);
            this.options.onDateChange?.(id, date || null);
        }
    }
}
