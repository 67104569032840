import {Avatar} from "common/components/avatar";
import {matchOptionalPassword, matchPasswordConfirm} from "common/utils";
import {useFormik} from "formik";
import {observer} from "mobx-react-lite";
import React, {FormEvent, useEffect, useRef, useState} from "react";
import * as Yup from 'yup';
import DeprecatedButton from "../../../common/components/deprecated_button";
import DeprecatedInput from "../../../common/components/deprecated_input";
import DeprecatedLabel from "../../../common/components/deprecated_label/DeprecatedLabel";
import IconClosedEye from "../../../common/components/icons/IconClosedEye";
import IconEye from "../../../common/components/icons/IconEye";
import {UserConstraints, UserValidations} from "../../validation";
import s from "./EditProfile.module.css";
import {AVATAR_MIME_TYPES, EditProfilePopupStore} from "./EditProfilePopupStore";
import {IProfileForm} from "./IProfileForm";

interface IProps {
    store: EditProfilePopupStore;
}


function Form(props: IProps) {
    const {store} = props;
    const inputAvatarRef = useRef<HTMLInputElement>(null);
    const [isPasswordShowed, setPasswordShowed] = useState(false);
    const [isPasswordRepeatShowed, setPasswordRepeatShowed] = useState(false);
    const formik = useFormik<IProfileForm>({
        initialValues: store.initialValues,
        validationSchema: Yup.object().shape({
            firstName: UserValidations.firstName,
            surName: UserValidations.secondName,
            middleName: UserValidations.middleName,
            password: matchOptionalPassword(),
            passwordConfirm: matchPasswordConfirm(),
        }),
        onSubmit: store.onSubmit,
        validateOnChange: false
    });

    function togglePasswordShow() {
        setPasswordShowed(!isPasswordShowed);
    }

    function togglePasswordRepeatShow() {
        setPasswordRepeatShowed(!isPasswordRepeatShowed);
    }

    useEffect(() => {
        store.setSubmitFormFunc(formik.handleSubmit);
    }, [store, formik.handleSubmit]);

    const handleAvatarChange = (e: FormEvent) => {
        const target = e.target as HTMLInputElement;
        const file = target.files?.[0];
        if (file) {
            store.changeAvatar(file);
            target.value = "";
        }
    }

    return (
        <div className={s.formContainer}>
            <form className={s.form}>
                <DeprecatedLabel title="Имя*" error={formik.errors.firstName}>
                    <DeprecatedInput
                        name="firstName"
                        variant="form"
                        maxLength={UserConstraints.firstNameMaxLength}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}/>
                </DeprecatedLabel>

                <DeprecatedLabel title="Фамилия*" error={formik.errors.surName}>
                    <DeprecatedInput
                        name="surName"
                        variant="form"
                        maxLength={UserConstraints.secondNameMaxLength}
                        value={formik.values.surName}
                        onChange={formik.handleChange}/>
                </DeprecatedLabel>

                <DeprecatedLabel title="Отчество" error={formik.errors.middleName}>
                    <DeprecatedInput
                        name="middleName"
                        variant="form"
                        maxLength={UserConstraints.middleNameMaxLength}
                        value={formik.values.middleName}
                        onChange={formik.handleChange}/>
                </DeprecatedLabel>

                <DeprecatedLabel title="Новый пароль" error={formik.errors.password}>
                    <div className={s.password}>
                        <DeprecatedInput
                            type={isPasswordShowed ? "text" : "password"}
                            name="password"
                            variant="form"
                            value={formik.values.password}
                            autoComplete="none"
                            onChange={formik.handleChange}/>

                        {isPasswordShowed ?
                            <IconClosedEye className={s.passwordShowIcon} onClick={togglePasswordShow}/>
                            : <IconEye className={s.passwordShowIcon} onClick={togglePasswordShow}/>}
                    </div>
                </DeprecatedLabel>

                <DeprecatedLabel title="Повторите новый пароль" error={formik.errors.passwordConfirm}>
                    <div className={s.password}>
                        <DeprecatedInput
                            type={isPasswordRepeatShowed ? "text" : "password"}
                            name="passwordConfirm"
                            variant="form"
                            value={formik.values.passwordConfirm}
                            autoComplete="none"
                            onChange={formik.handleChange}/>

                        {isPasswordRepeatShowed ?
                            <IconClosedEye className={s.passwordShowIcon} onClick={togglePasswordRepeatShow}/>
                            : <IconEye className={s.passwordShowIcon} onClick={togglePasswordRepeatShow}/>}
                    </div>
                </DeprecatedLabel>
            </form>
            <div className={s.avatarPanel}>
                <Avatar size={236} src={store.avatar}/>
                <DeprecatedButton
                    containerClassName={s.avatarChange}
                    onClick={() => inputAvatarRef.current?.click()}>
                    Изменить аватарку
                </DeprecatedButton>
                <input
                    className={s.realAvatarInput}
                    type="file"
                    ref={inputAvatarRef}
                    accept={AVATAR_MIME_TYPES}
                    onChange={handleAvatarChange}/>
            </div>
        </div>
    );
}

export default observer(Form);
