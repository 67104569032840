import {AuthorizedHttpClient} from "common/net";
import {UserRole} from "../types";

export class UserRoleRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(): Promise<UserRole[]> {
        return (await this.client.postV0<UserRole[]>({type: "get_roles"}));
    }
}
