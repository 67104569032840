import {ServerError} from "common/net";
import {AsyncJob} from "common/stores/job";
import {makeAutoObservable} from "mobx";
import {CommentModalStore} from "../../modals/comment";
import {TaskStatusRepository} from "../../repositories";
import {TaskStatus} from "../../types";

export class ApplyTransitionsStore {
    transitions: TaskStatus[] | null;
    isShowChildCompletionError: boolean;

    private prevTransition: {
        taskId: number,
        transition: TaskStatus,
    } | null;
    private applyTransitionJob: AsyncJob<typeof ApplyTransitionsStore.prototype._applyTransition>;
    private fetchTransitionsJob: AsyncJob<typeof ApplyTransitionsStore.prototype._fetchTransitions>;
    private applyListener: (() => void) | null;

    constructor(
        private readonly statusRepo: TaskStatusRepository,
        readonly commentModal: CommentModalStore,
    ) {
        this.prevTransition = null;
        this.transitions = null;
        this.applyListener = null;
        this.commentModal.setSendListener(() => {
            const prev = this.prevTransition;
            if (prev) {
                this.applyTransition(prev.taskId, prev.transition)
            }
        });
        this.isShowChildCompletionError = false;
        makeAutoObservable(this, {}, {autoBind: true});
        this.fetchTransitionsJob = new AsyncJob({job: this._fetchTransitions});
        this.applyTransitionJob = new AsyncJob({job: this._applyTransition});
    }

    get isLoading() {
        return this.fetchTransitionsJob.isCreated || this.fetchTransitionsJob.isPending;
    }

    get errorMessage() {
        return this.fetchTransitionsJob.errorMessage;
    }

    get applyError() {
        return this.applyTransitionJob.errorMessage;
    }

    get pendingStatus() {
        return this.prevTransition?.transition || null;
    }

    dismissError() {
        this.applyTransitionJob.clearError();
        this.isShowChildCompletionError = false;
    }

    setApplyListener(callback: (() => void) | null) {
        this.applyListener = callback;
    }

    applyTransition(taskId: number, transition: TaskStatus) {
        this.applyTransitionJob.start(taskId, transition);
    }

    fetchTransitions(taskId: number) {
        this.fetchTransitionsJob.start(taskId);
    }

    private* _fetchTransitions(_: AbortSignal, taskId: number) {
        this.transitions = yield this.statusRepo.findTransitionsByTaskId(taskId);
    }

    private* _applyTransition(_: AbortSignal, taskId: number, transition: TaskStatus) {
        try {
            yield this.statusRepo.applyTransition(transition.id, taskId);
            this.applyListener?.();
        } catch (e) {
            if (e instanceof ServerError) {
                if (e.code === 403) {
                    this.prevTransition = {
                        taskId,
                        transition,
                    };

                    this.commentModal.show(taskId, transition.localizedName);
                } else if (e.message === "All child tasks should be completed") {
                    this.isShowChildCompletionError = true;
                    this.prevTransition = {
                        taskId,
                        transition,
                    };
                } else {
                    throw e;
                }
            } else {
                throw e;
            }
        }
    }
}
