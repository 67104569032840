import {ForwardedRef, forwardRef, KeyboardEvent, useEffect, useRef, useState} from "react";
import {mergeRefs} from "react-merge-refs";
import Select, {InputActionMeta, MenuPlacement, Props as SelectProps} from "react-select";
import CreatableSelect from "react-select/creatable";
import {useInputBoxComponents, useInputBoxStyles} from "./styles";
import {
    InputBoxBulletRenderer,
    InputBoxItem,
    InputBoxOptionRenderer,
    InputBoxRef,
    InputBoxValueRenderer,
    MAX_INPUT_BOX_MENU_HEIGHT,
    SelectedInputBoxItem
} from "./types";
import useRemembered from "../../../hooks/useRemembered";

type CreatableProps = {
    creationMaxLength?: number;
    formatCreateLabel?: (itemName: string) => string;
    isCreatable?: boolean;
    onCreate?: (value: string) => void;
};

export type CommonInputBoxProps<T> = {
    className?: string;
    selected?: SelectedInputBoxItem<T> | SelectedInputBoxItem<T>[] | null;
    placeholder?: string;
    error?: string | null;
    menuWidth?: number;
    noOptionsMessage?: string;
    renderValue?: InputBoxValueRenderer<T>;
    renderOption?: InputBoxOptionRenderer<T>;
    renderBullet?: InputBoxBulletRenderer<T>;
    filterItem?: (item: InputBoxItem<T>) => boolean;
    isMulti?: boolean;
    isMultilineLabel?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    readOnly?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (item: InputBoxItem<T> | null) => void;
    onChangeValue?: (item: T | null) => void;
    onChangeMulti?: (items: InputBoxItem<T>[]) => void;
    onChangeMultiValue?: (items: T[]) => void;
} & CreatableProps;

type Props<T> = {
    items: InputBoxItem<T>[];
    search: string;
    isLoading?: boolean;
    isLoadingMore?: boolean;
    onSearchChange: (value: string) => void;
    onScrollToBottom?: () => void;
} & CommonInputBoxProps<T>;

function formatCreationValue(value: string, maxLength?: number) {
    let trimmedValue = value.trim();
    if (!trimmedValue) {
        return "";
    }

    if (maxLength && trimmedValue.length > maxLength) {
        trimmedValue = trimmedValue.substring(0, maxLength);
    }

    return trimmedValue;
}

/**
 * Определяет расположение всплывающего меню со списком элементов (сверху от поля или снизу)
 */
export function useMenuPlacement(isFocus: boolean) {
    const selectRef = useRef<InputBoxRef | null>(null);
    const [isPopupListBottom, setPopupListBottom] = useState<boolean>(true);

    useEffect(() => {
        if (selectRef.current && selectRef.current.controlRef) {
            const distance = window.innerHeight - selectRef.current.controlRef.getBoundingClientRect().bottom;
            setPopupListBottom(distance > MAX_INPUT_BOX_MENU_HEIGHT);
        }
    }, [isFocus]);

    return {
        selectRef,
        menuPlacement: (isPopupListBottom ? "bottom" : "top") as MenuPlacement,
    }
}

export const BaseInputBox = forwardRef(<T extends any>(props: Props<T>, ref: ForwardedRef<InputBoxRef>) => {
    const {
        className,
        selected,
        items,
        placeholder,
        search,
        error,
        readOnly,
        menuWidth,
        creationMaxLength,
        noOptionsMessage = "Ничего не найдено",
        renderValue,
        renderOption,
        renderBullet,
        formatCreateLabel,
        filterItem,
        isCreatable,
        isMulti,
        isMultilineLabel,
        isLoading,
        isLoadingMore,
        isSearchable = true,
        isDisabled,
        onSearchChange,
        onScrollToBottom,
        onFocus,
        onBlur,
        onCreate,
        onChange,
        onChangeValue,
        onChangeMulti,
        onChangeMultiValue,
    } = props;

    const handleInputChange = (value: string, meta: InputActionMeta) => {
        if (!isSearchable) {
            return;
        }

        const _onSearchChange = (value: string) => {
            if (!isMultilineLabel) {
                onSearchChange(value.replaceAll("\n", ""))
            } else {
                onSearchChange(value)
            }
        };

        if (meta.action === "input-change" || meta.action === "set-value") {
            _onSearchChange(value);
        } else if (meta.action === "input-blur" || meta.action === "menu-close") {
            //Для полей с множественным выбором не очищаем поисковый запрос при потере фокуса
            if (!isMulti) {
                const label = (selected as SelectedInputBoxItem<any> | null)?.label;
                if (value !== label) {
                    _onSearchChange(label || "");
                }
            }
        }
    };

    const handleDeselect = (deselected: InputBoxItem<T>) => {
        if (selected && Array.isArray(selected)) {
            const values = selected.filter(item => item !== deselected) as InputBoxItem<T>[];
            onChangeMulti?.(values);
            onChangeMultiValue?.(values.map(item => item.value).filter(Boolean));
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            e.stopPropagation();
        }
    };

    const [isFocus, setFocus] = useState(false);
    const {
        selectRef,
        menuPlacement,
    } = useMenuPlacement(isFocus);

    const styles = useInputBoxStyles(isMultilineLabel, menuWidth);
    const components = useInputBoxComponents({
        isSearchable,
        isLoadingMore,
        isMultilineLabel,
        renderValue,
        renderOption,
        renderBullet,
        onScrollToBottom: onScrollToBottom,
        onDeselect: handleDeselect,
    });

    const isOptionSelected = (option: InputBoxItem<T>) => {
        if (Array.isArray(selected)) {
            return selected.findIndex(selectedItem => selectedItem.id === option.id) !== -1;
        } else if (selected) {
            return selected.id === option.id;
        }
        return false;
    };

    const rememberedSearch = useRemembered(search);
    const rememberedOnSearch = useRemembered(onSearchChange);
    useEffect(() => {
        if (!isMulti && isSearchable) {
            const label = (selected as SelectedInputBoxItem<any> | null)?.label;
            if (rememberedSearch.current !== label) {
                rememberedOnSearch.current?.(label || "");
            }
        }
    }, [selected, isMulti, isSearchable, rememberedOnSearch, rememberedSearch]);

    const selectProps: SelectProps = {
        className,
        value: selected,
        styles,
        components,
        menuPlacement,
        isMulti,
        isSearchable: !readOnly && isSearchable,
        isDisabled,
        //Небольшой костыль - для показа ошибки включаем флаг загрузки и устанавливаем сообщение об ошибке в loadingMessage
        isLoading: isLoading || isLoadingMore || !!error,
        options: items,
        placeholder: placeholder || "",
        closeMenuOnSelect: !isMulti,
        inputValue: search,
        maxMenuHeight: MAX_INPUT_BOX_MENU_HEIGHT,
        menuIsOpen: readOnly ? false : undefined,
        menuPosition: "fixed",
        menuPortalTarget: document.body,
        filterOption: filterItem ? (item: any) => filterItem(item) : () => true,
        isClearable: false,
        hideSelectedOptions: false,
        blurInputOnSelect: false,
        isOptionSelected: isOptionSelected as (option: any) => boolean,
        noOptionsMessage: () => noOptionsMessage,
        loadingMessage: () => error || "Загрузка...",
        onInputChange: handleInputChange,
        onKeyDown: handleKeyDown,
        onChange: (newValue, meta) => {
            if (Array.isArray(newValue)) {
                if (meta.action === "deselect-option") {
                    // По какой-то причине при deselect-option в newValue передается массив с элементом, для которого нужно
                    // отменить выбор. Удаляем самостоятельно
                    const values = newValue.filter(item => item.id !== (meta.option as InputBoxItem<T>).id);
                    onChangeMulti?.(values);
                    onChangeMultiValue?.(values.map(item => item.value).filter(Boolean));
                } else {
                    onChangeMulti?.(newValue);
                    onChangeMultiValue?.(newValue.map(item => item.value));
                }
            } else {
                onChange?.(newValue as InputBoxItem<T>);
                onChangeValue?.((newValue as InputBoxItem<T>)?.value || null);

                if (isSearchable) {
                    onSearchChange((newValue as InputBoxItem<T>)?.label || "");
                }
            }
        },
        onFocus: () => {
            setFocus(true);
            onFocus?.();
        },
        onBlur: () => {
            setFocus(false);
            onBlur?.();
        },
    };

    if (isCreatable) {
        const handleCreate = onCreate ? (value: string) => {
            const _value = formatCreationValue(value, creationMaxLength);
            if (_value) {
                onCreate(_value);
                onSearchChange("");
            }
        } : undefined;
        const handleFormatCreateLabel = formatCreateLabel || (value => {
            return `Создать "${formatCreationValue(value, creationMaxLength)}"`;
        });

        return (
            <CreatableSelect
                ref={mergeRefs([ref as any, selectRef])}
                onCreateOption={handleCreate}
                formatCreateLabel={handleFormatCreateLabel}
                {...selectProps}/>
        )
    }

    return (
        <Select
            ref={mergeRefs([ref as any, selectRef])}
            {...selectProps}/>
    );
}) as <T extends any>(props: Props<T> & { ref?: any }, ref: ForwardedRef<InputBoxRef>) => JSX.Element;