import {observer} from "mobx-react-lite";
import {MouseEvent} from "react";
import {HeaderGroup} from "react-table";
import IconDelimiter18x15 from "../icons/IconDelimiter18x15";
import s from "./index.module.css";

interface IProps<D extends object> {
    column: HeaderGroup<D>;
    isHovered: boolean;
    isResizing: boolean;
}

function TableResizer<D extends object>(props: IProps<D>) {
    const {column, isHovered, isResizing} = props;

    if (!isHovered && !isResizing) {
        return null;
    }

    const resizerProps = column.getResizerProps();

    function handleMouseDown(e: MouseEvent) {
        //Убираем выделение текста
        e.preventDefault();

        //@ts-ignore
        resizerProps.onMouseDown(e);
    }

    return (
        <div className={s.iconsContainer}>
            <div className={s.resizeIcon}>
                <IconDelimiter18x15
                    className={isResizing ? s.headerIconActive : s.headerIcon}
                    {...resizerProps}
                    onMouseDown={handleMouseDown}/>

                {isResizing && <span className={s.resizeBorder}/>}
            </div>
        </div>
    );
}

export default observer(TableResizer);