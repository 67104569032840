import classNames from "classnames";
import {usePopupStack} from "common/hooks";
import useGlobalEvent from "common/hooks/useGlobalEvent";
import {ReactNode, useEffect, useId} from "react";
import s from "./Popup.module.css";

interface IProps {
    className?: string;
    children: ReactNode;
    closeOnEscape?: boolean;

    onClose(): void;
}

function DeprecatedPopup(props: IProps) {
    const {className, closeOnEscape = true, onClose, children} = props;
    const modalId = useId();
    const popupHost = usePopupStack();

    useEffect(() => {
        popupHost.putOnStack(modalId);
        return () => popupHost.removeFromStack(modalId);
    }, [popupHost, modalId]);

    useGlobalEvent("keydown", e => {
        if (e.key === "Escape" && closeOnEscape && popupHost.isTopOnStack(modalId)) {
            e.stopPropagation();
            onClose();
        }
    });

    return (
        <div className={s.overlay}>
            <div className={classNames(s.window, className)} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
}

export default DeprecatedPopup;
