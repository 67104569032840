import {TColumnsSizes} from "../types/TColumnsSizes";

export class TaskColumnRepository {
    private readonly KEY = "task_columns_sizes";

    update(sizes: TColumnsSizes): void {
        localStorage.setItem(this.KEY, JSON.stringify(sizes));
    }

    findAll(): TColumnsSizes {
        const sizes = localStorage.getItem(this.KEY);
        if (!sizes) {
            return {};
        }

        try {
            const parsed = JSON.parse(sizes);
            if (typeof parsed !== "object") {
                return {};
            }

            for (const parsedKey in parsed) {
                if (typeof parsed[parsedKey] !== "number" || parsed[parsedKey] < 0) {
                    delete parsed[parsedKey];
                }
            }

            return parsed;
        } catch (e) {
            return {};
        }
    }
}