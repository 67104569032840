import {useMemo} from "react";
import Skeleton from "react-loading-skeleton";
import {Column, UseResizeColumnsState} from "react-table";
import AutoTable, {TableVariant} from "../index";

interface IProps {
    headClassName?: string;
    fullWidth?: boolean;
    amountOfColumns: number;
    amountOfRows?: number;
    columns?: Record<number, Partial<Column>>;
    variant?: TableVariant;
    initialResized?: UseResizeColumnsState<any>["columnResizing"]["columnWidths"];
    columnOrder?: string[];
    hiddenColumns?: string[];
    isBlockLayout?: boolean;
}

function createLoadingColumns<D extends object>(amountOfColumns: number) {
    const columns: Column<D>[] = [];
    for (let i = 0; i < amountOfColumns; i++) {
        columns.push({
            Header: () => <Skeleton width="50%"/>,
            Cell: () => <Skeleton width="50%"/>,
            id: i.toString(),
        });
    }

    return columns;
}

function TableSkeleton(props: IProps) {
    const {
        headClassName,
        amountOfColumns,
        amountOfRows = 5,
        columns,
        variant,
        columnOrder,
        hiddenColumns,
        initialResized,
        fullWidth,
        isBlockLayout
    } = props;

    const data: object[] = useMemo(() => {
        return new Array(amountOfRows).fill({});
    }, [amountOfRows]);

    const cachedColumns = useMemo(() => {
        const cachedColumns = createLoadingColumns(amountOfColumns);
        if (columns) {
            for (const key in columns) {
                cachedColumns[key] = {
                    ...cachedColumns[key],
                    ...columns[key] as Column,
                };
            }
        }

        return cachedColumns;
    }, [amountOfColumns, columns]);
    return (
        <AutoTable
            headClassName={headClassName}
            columns={cachedColumns}
            data={data}
            variant={variant}
            initialResized={initialResized}
            isResizable={initialResized !== undefined}
            isShowResizeIndicator={false}
            fullWidth={fullWidth}
            columnOrder={columnOrder}
            hiddenColumns={hiddenColumns}
            isBlockLayout={isBlockLayout}
            highlightable={false}/>
    );
}

export default TableSkeleton;
