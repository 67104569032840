import {Avatar} from "common/components/avatar";
import React from "react";
import Skeleton from "react-loading-skeleton";
import DeprecatedLabel from "../../../common/components/deprecated_label/DeprecatedLabel";
import s from "./EditProfile.module.css";

const fields: string[] = [
    "Имя",
    "Фамилия",
    "Отчество",
    "Пароль",
    "Повторите пароль",
];

function FormSkeleton() {
    return (
        <div className={s.formContainer}>
            <div className={s.form}>
                {fields.map(field => (
                    <DeprecatedLabel key={field} title={field}>
                        <Skeleton width={240} height={40} borderRadius={10}/>
                    </DeprecatedLabel>
                ))}
            </div>
            <div className={s.avatarPanel}>
                <Avatar.Skeleton size={236}/>
                <Skeleton containerClassName={s.avatarChange} width={154} height={34} borderRadius={9}/>
            </div>
        </div>
    );
}

export default FormSkeleton;
