export const HouseTagConstraints = {
    NAME_MAX_LENGTH: 40,
}

export interface HouseTag {
    id: number;

    name: string;

    /**
     * Цвет для меток, задаваемых на карте. Задается в hex-формате (например, #FFFFFF).
     */
    color: string;

    companyName: string;
}

export interface HouseTagCreateParams {
    name: string;

    color: string;
}

export interface HouseTagUpdateParams {
    id: number;

    name: string;

    color: string;
}

export interface HouseTagResponse {
    id: number;

    name: string;

    color: string;

    company_name: string;
}

export function mapToHouseTag(entity: HouseTagResponse): HouseTag {
    return {
        id: entity.id,
        name: entity.name,
        color: entity.color,
        companyName: entity.company_name,
    };
}
