import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {FindAllHouseGroups, HouseGroup, HouseGroupResponse, mapToHouseGroup, UpsertHouseGroup} from "../types";

export class HouseGroupRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllHouseGroups): Promise<Pageable<HouseGroup>> {
        const response = await this.client.get<PageableResponse<HouseGroupResponse>>(
            "groups",
            {
                signal: params.signal,
                params: {
                    search: params.search || undefined,
                    page: params.page,
                    limit: params.limit,
                    houses: params.houses && params.houses.length > 0 ? params.houses.join(",") : undefined,
                }
            }
        );
        return mapPageable(response, mapToHouseGroup);
    }

    async findById(signal: AbortSignal, id: string): Promise<HouseGroup> {
        const response = await this.client.get<HouseGroupResponse>(`groups/${id}`, {signal: signal});
        return mapToHouseGroup(response);
    }

    async create(params: UpsertHouseGroup): Promise<Pageable<HouseGroup>> {
        return await this.client.post(
            `groups`,
            {
                signal: params.signal,
                body: {
                    name: params.name,
                    intercoms_ids: params.intercoms,
                    houses: params.houses.map(house => ({
                        house_id: house.houseId,
                        flat_ranges: house.flatRanges.map(range => ({
                            first_flat_number: range.from,
                            last_flat_number: range.to,
                        })),
                    })),
                }
            }
        );
    }

    async update(id: string, params: UpsertHouseGroup): Promise<Pageable<HouseGroup>> {
        return await this.client.put(
            `groups/${id}`,
            {
                signal: params.signal,
                body: {
                    name: params.name,
                    intercoms_ids: params.intercoms,
                    houses: params.houses.map(house => ({
                        house_id: house.houseId,
                        flat_ranges: house.flatRanges.map(range => ({
                            first_flat_number: range.from,
                            last_flat_number: range.to,
                        })),
                    })),
                }
            }
        );
    }

    deleteById(id: string): Promise<void> {
        return this.client.delete(`groups/${id}`);
    }
}
