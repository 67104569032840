import createSvgIcon from "./utils";

const IconCheckmark10x8 = createSvgIcon((
    <>
        <mask id="icon_checkmark_10_8_mask" stroke="transparent">
            <path
                fillRule="evenodd"
                d="M9.695.705a.7.7 0 0 1 0 .99l-5.6 5.6a.7.7 0 0 1-.99 0l-2.8-2.8a.7.7 0 0 1 .99-.99L3.6 5.81 8.705.705a.7.7 0 0 1 .99 0Z"
                clipRule="evenodd"/>
        </mask>
        <path
            stroke="transparent"
            fillRule="evenodd"
            d="M9.695.705a.7.7 0 0 1 0 .99l-5.6 5.6a.7.7 0 0 1-.99 0l-2.8-2.8a.7.7 0 0 1 .99-.99L3.6 5.81 8.705.705a.7.7 0 0 1 .99 0Z"
            clipRule="evenodd"/>
        <path
            stroke="transparent"
            d="m9.695.705 1.06-1.06-1.06 1.06ZM9.9 1.2h1.5-1.5Zm-.205.495 1.06 1.06-1.06-1.06Zm-5.6 5.6 1.06 1.06-1.06-1.06Zm-.99 0-1.06 1.06 1.06-1.06Zm-2.8-2.8L-.774 5.537l.01.01.008.009L.305 4.495Zm.99-.99 1.06-1.06-.009-.01-.009-.009-1.042 1.08ZM3.6 5.81 2.54 6.87 3.6 7.932l1.06-1.06L3.6 5.81ZM8.705.705l-1.06-1.06 1.06 1.06ZM9.2.5V2 .5Zm-.566 1.266A.8.8 0 0 1 8.4 1.2h3a2.2 2.2 0 0 0-.644-1.555l-2.122 2.12ZM8.4 1.2a.8.8 0 0 1 .234-.566l2.122 2.121A2.2 2.2 0 0 0 11.4 1.2h-3Zm.234-.566-5.6 5.6 2.122 2.122 5.6-5.6L8.634.634Zm-5.6 5.6A.8.8 0 0 1 3.6 6v3a2.2 2.2 0 0 0 1.555-.644l-2.12-2.122ZM3.6 6a.8.8 0 0 1 .566.234L2.045 8.356A2.2 2.2 0 0 0 3.6 9V6Zm.566.234-2.8-2.8-2.122 2.122 2.8 2.8 2.122-2.122ZM1.384 3.453a.8.8 0 0 1 .225.563l-3-.027a2.2 2.2 0 0 0 .617 1.548l2.158-2.084Zm.225.563a.8.8 0 0 1-.235.558L-.747 2.453a2.2 2.2 0 0 0-.644 1.536l3 .027Zm-.235.558a.8.8 0 0 1-.558.235l-.027-3a2.2 2.2 0 0 0-1.536.644l2.121 2.121Zm-.558.235a.8.8 0 0 1-.563-.225l2.084-2.158A2.2 2.2 0 0 0 .789 1.81l.027 3Zm-.582-.243L2.54 6.87 4.661 4.75 2.356 2.444.234 4.566ZM4.661 6.87l5.105-5.105L7.644-.356 2.54 4.75l2.122 2.12Zm5.105-5.105A.8.8 0 0 1 9.2 2v-3a2.2 2.2 0 0 0-1.555.644l2.12 2.122ZM9.2 2a.8.8 0 0 1-.566-.234l2.121-2.122A2.2 2.2 0 0 0 9.2-1v3Z"
            mask="url(#icon_checkmark_10_8_mask)"/>
    </>
), {width: 10, height: 8});

export default IconCheckmark10x8;