import {repeat} from "common/utils";
import Skeleton from "react-loading-skeleton";
import s from "./ContextMenu.module.css";

type Props = {
    items?: number;
};

export const SkeletonContextMenuContent = (props: Props) => {
    const {items} = props;
    const elements = repeat(items || 3, i => (
        <div key={i} className={s.menuItemBase}>
            <Skeleton width="100%" height={19}/>
        </div>
    ));
    return <>{elements}</>;
};
