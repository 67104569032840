import {HttpClient} from "common/net";
import {AuthToken, AuthTokenResponse, mapToAuthToken} from "../types";

export class AuthRepository {
    constructor(private readonly httpClient: HttpClient) {
    }

    async login(login: string, password: string, deviceId: string): Promise<AuthToken> {
        const response = await this.httpClient.post<AuthTokenResponse>("web/v1/auth/login", {
            body: {
                login,
                password,
                device_id: deviceId,
            }
        });
        return mapToAuthToken(response);
    }

    async delete(refreshToken: string, deviceId: string) {
        await this.httpClient.delete(`web/v1/auth/refresh_token`, {
            body: {
                device_token: deviceId,
            },
            headers: {
                "Authorization": `Bearer ${refreshToken}`,
            },
        })

    }

    async refreshToken(accessToken: string): Promise<AuthToken> {
        const response = await this.httpClient.post<AuthTokenResponse>(`web/v1/auth/refresh_token`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        });
        return mapToAuthToken(response);
    }
}
