import {useRepositories} from "common/components/app/RepositoriesStore";
import {useSearchFieldsStoreBuilderMemo} from "common/components/search_bar/with_fields";
import {useQueryNumber, useQueryPreserve, useQueryString} from "common/hooks";
import {useItemLimitStoreMemo, useUserStore} from "common/stores";
import {useMemo} from "react";
import {useTransitionsStoreMemo} from "../../components/transitions_popup";
import {useSaveFilterStoreMemo} from "../../modals/save_filter";
import {TaskFiltersStore} from "./filters";
import {useLocalTasksCacheStore} from "./LocalTasksCacheStore";
import {SavedFilterStore} from "./SavedFilterStore";
import {PAGE_LIMIT_KEY, TaskListStore} from "./TaskListStore";

const PAGE_KEY = "tasks_page";
const FILTER_KEY = "f";
const SAVED_FILTER_KEY = "saved_filter";

export function useSavedFilterStoreMemo(companyId: number | null, filters: TaskFiltersStore) {
    const userStore = useUserStore();
    const repos = useRepositories();
    const saveFilterModal = useSaveFilterStoreMemo();
    const serialized = useQueryString(SAVED_FILTER_KEY);
    return useMemo(() => {
        return new SavedFilterStore(
            companyId || userStore.companyId,
            saveFilterModal,
            serialized,
            repos.taskFilter,
            filters,
        );
    }, [companyId, saveFilterModal, repos, filters, serialized, userStore]);
}

export function useTaskFiltersStoreMemo(companyId: number | null) {
    const repos = useRepositories();
    const userStore = useUserStore();
    const serializedFilters = useQueryString(FILTER_KEY);
    const searchFieldsBuilder = useSearchFieldsStoreBuilderMemo();
    return useMemo(() => new TaskFiltersStore(
        userStore.companyId,
        companyId,
        serializedFilters,
        searchFieldsBuilder,
        repos.searchField,
        repos.newUser,
        repos.taskStatuses,
        repos.taskType,
        repos.companies,
        repos.houseSystems,
        repos.newHouse,
        repos.taskProgram,
        repos.order,
    ), [repos, userStore, companyId, serializedFilters, searchFieldsBuilder]);
}

export function useTaskListStoreMemo(companyId: number | null) {
    const repos = useRepositories();
    const page = useQueryNumber(PAGE_KEY);
    const filters = useTaskFiltersStoreMemo(companyId);
    const transitionsStore = useTransitionsStoreMemo();
    const taskListCache = useLocalTasksCacheStore();
    const savedFilterStore = useSavedFilterStoreMemo(companyId, filters);
    const limitStore = useItemLimitStoreMemo(PAGE_LIMIT_KEY);
    return useMemo(() => new TaskListStore(
        repos.dumpState,
        page,
        companyId,
        savedFilterStore,
        transitionsStore,
        filters,
        limitStore,
        repos.httpClient,
        repos.servicesConfig,
        taskListCache,
    ), [page, companyId, transitionsStore, filters, repos, taskListCache, limitStore, savedFilterStore]);
}

export function useTasksSerialization(store: TaskListStore) {
    useQueryPreserve(PAGE_KEY, store.page);
    useQueryPreserve(FILTER_KEY, store.filters.serialized);
    useQueryPreserve(SAVED_FILTER_KEY, store.savedFilter.serialized);
}

export function convertTasksToParams(store: TaskListStore) {
    const params = new URLSearchParams();
    if (store.page) {
        params.set(PAGE_KEY, store.page.toString());
    }

    const serialized = store.filters.serialized;
    if (serialized) {
        params.set(FILTER_KEY, serialized);
    }

    return params.toString();
}
