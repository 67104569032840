import FuzzySort from "fuzzysort";
import {ForwardedRef, forwardRef, useEffect, useMemo, useState} from "react";
import {BaseInputBox, CommonInputBoxProps} from "./BaseInputBox";
import {InputBoxItem, InputBoxRef} from "./types";

export type InputBoxProps<T> = {
    search?: string;
    selectedKey?: any;
    items: InputBoxItem<T>[];
    isLoading?: boolean;
    isFilterLocally?: boolean;
    onSearchChange?: (value: string) => void;
} & CommonInputBoxProps<T>;

export const InputBox = forwardRef(<T extends any>(props: InputBoxProps<T>, ref: ForwardedRef<InputBoxRef>) => {
    const {
        selected,
        selectedKey,
        search: userSearch,
        items,
        isLoading,
        isMulti,
        isFilterLocally = true,
        onSearchChange,
        ...defaultProps
    } = props;

    const [search, setSearch] = useState(userSearch || "")
    useEffect(() => {
        if (userSearch !== undefined) {
            setSearch(userSearch);
        }
    }, [userSearch]);

    const sortedItems = useMemo(() => {
        if (!search || !isFilterLocally) {
            return items;
        }

        return FuzzySort.go(search, items, {key: "label"}).map(item => item.obj)
    }, [search, items, isFilterLocally])

    let value = selected;
    if (selectedKey) {
        value = items.find(item => selectedKey === item.id) || null;
    }

    return (
        <BaseInputBox
            ref={ref}
            selected={value}
            items={sortedItems}
            search={search}
            isLoading={isLoading}
            isMulti={isMulti}
            onSearchChange={value => {
                if (onSearchChange) {
                    onSearchChange(value);
                }
                setSearch(value);
            }}
            {...defaultProps}/>
    );
}) as <T extends any>(props: InputBoxProps<T> & {
    ref?: ForwardedRef<InputBoxRef>
}, ref: ForwardedRef<InputBoxRef>) => JSX.Element;
