export const RelatedTaskConstraints = {
    urlMaxLength: 256,
};


export interface RelatedTask {
    id: number;
    programId: number;
    programName: string;
    number: string;
    url: string | null;
}

export interface CreateRelatedTaskParams {
    programId: number;
    number: string;
    url: string | null;
}

export interface UpdateRelatedTaskParams extends CreateRelatedTaskParams {
    id: number;
}

export interface RelatedTaskResponse {
    id: number;
    program_id: number;
    program_name: string;
    number: string;
    url: string | null;
}

export function mapToRelatedTask(response: RelatedTaskResponse): RelatedTask {
    return {
        id: response.id,
        number: response.number,
        programId: response.program_id,
        programName: response.program_name,
        url: response.url,
    };
}
