import {A, P} from "common/components/typography";
import {useHistoryStack} from "common/hooks";
import {findLastIndex} from "common/utils";
import {MouseEventHandler, ReactNode, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import s from "./Breadcrumbs.module.css";

type Props = {
    children: ReactNode;
    href?: string;
};

function useNavigateBack() {
    const navigate = useNavigate();
    const stack = useHistoryStack();
    return useCallback((supposedRoute: string) => {
        const found = findLastIndex(stack.paths, path => path === supposedRoute);
        if (found !== -1) {
            navigate(-(stack.paths.length - found - 1));
        } else {
            navigate(supposedRoute, {replace: true});
        }
    }, [navigate, stack]);
}

export const BreadcrumbsItem = (props: Props) => {
    const {children, href} = props;
    const navigateBack = useNavigateBack();

    if (!href) {
        return (
            <P
                className={s.item}
                variant="Paragraph/Small/p"
                color="none">
                {children}
            </P>
        );
    }

    const handleClick: MouseEventHandler = e => {
        if (href) {
            e.preventDefault();
            navigateBack(href);
        }
    };

    return (
        <A
            className={href ? s.itemActive : s.item}
            variant="Paragraph/Small/p"
            color="none"
            href={href}
            onClick={handleClick}>
            {children}
        </A>
    )
}
