export type TMenuItemKey = string | number;

export type TPopupPos = { x: number, y: number };

export type SelectionMode = "include" | "exclude";

export interface IMenuItemUiState<T> {
    key: TMenuItemKey;
    label: string;
    value: T;
}

export type MenuItemSerialized<T> = [TMenuItemKey, string, T];

export function deserializeMenuItem<T>(serialized: MenuItemSerialized<T>): IMenuItemUiState<T> {
    return {
        key: serialized[0],
        label: serialized[1],
        value: serialized[2],
    };
}

export function serializeMenuItem<T>(item: IMenuItemUiState<T>): MenuItemSerialized<T> {
    return [item.key, item.label, item.value];
}
