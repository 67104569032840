import {createContext, useContext} from "react";

export function createContextAndHook<F>() {
    // @ts-ignore
    const context = createContext<F>(null);

    return [
        context,
        () => useContext(context),
    ] as const;
}