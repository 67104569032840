import createSvgIcon from "./utils";

const IconSend18x18 = createSvgIcon((
    <path
        stroke="transparent"
        fillRule="evenodd"
        d="M2.347 16.19c.338.337.87.622 1.71.622.795 0 1.875-.255 3.33-.982l6.42-3.21c1.59-.795 2.468-1.905 2.468-3.12s-.877-2.325-2.475-3.12L7.38 3.17C4.41 1.677 3 2.15 2.347 2.81c-.66.652-1.132 2.062.36 5.04l.653 1.297c.082.173.082.533 0 .705l-.653 1.298c-1.485 2.977-1.02 4.38-.36 5.04Zm.795-12.585c.195-.195.51-.293.915-.293.698 0 1.68.293 2.82.863l6.42 3.202c1.178.585 1.853 1.358 1.853 2.115 0 .758-.668 1.53-1.845 2.115l-6.42 3.21c-1.8.9-3.203 1.118-3.743.57-.547-.54-.33-1.934.57-3.735l.653-1.297c.044-.09.08-.189.108-.293H8.13a.567.567 0 0 0 .562-.562.567.567 0 0 0-.562-.563H4.473a1.612 1.612 0 0 0-.108-.292L3.712 7.34c-.9-1.793-1.117-3.195-.57-3.735Z"
        clipRule="evenodd"/>
), {width: 18, height: 18});

export default IconSend18x18;
