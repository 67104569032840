import {TriState} from "common/components/checkbox";
import {makeAutoObservable} from "mobx";
import {BaseTaskFilterStore} from "./BaseTaskFilterStore";
import {SerializedFilterBuilderStore} from "./SerializedFilterBuilderStore";

interface FilterOptions {
    key: string;
    title: string;
}

export class TextFilterStore implements BaseTaskFilterStore {
    state: TriState;
    value: string;
    readonly isVisible = true;

    constructor(private readonly options: FilterOptions) {
        this.state = TriState.Unchecked;
        this.value = "";
        makeAutoObservable(this, {}, {autoBind: true});
    }

    get isActive() {
        return this.state !== TriState.Unchecked;
    }

    get title() {
        return this.options.title;
    }

    isOwnFilter(key: string) {
        return this.options.key === key;
    }

    toggle() {
        if (this.state === TriState.Indeterminate) {
            this.state = TriState.Unchecked;
        } else if (this.state === TriState.Checked) {
            this.state = TriState.Indeterminate;
            this.value = "";
        } else {
            this.state = TriState.Checked;
        }
    }

    setValue(value: string) {
        if (value.startsWith("-")) {
            this.value = "-" + value.replaceAll(/\D/g, "");
        } else {
            this.value = value.replaceAll(/\D/g, "");
        }
    }

    apply(builder: SerializedFilterBuilderStore) {
        if (this.state === TriState.Indeterminate) {
            builder.upsert(this.options.key, this.options.title, "false");
        } else if (this.state === TriState.Checked) {
            if (this.value.trim().length) {
                builder.upsert(this.options.key, `${this.options.title} ${this.value.trim()}`, this.value.trim());
            } else {
                builder.upsert(this.options.key, this.options.title, "true");
            }

        }
    }

    deserialize(builder: SerializedFilterBuilderStore) {
        const value = builder.findValue(this.options.key);
        if (value === "false") {
            this.state = TriState.Indeterminate;
            this.value = "";
        } else if (value === "true") {
            this.state = TriState.Checked;
            this.value = "";
        } else if (value) {
            this.state = TriState.Checked;
            this.value = value;
        } else {
            this.reset();
        }
    }

    reset() {
        this.state = TriState.Unchecked;
        this.value = "";
    }
}