import {AuthorizedHttpClient} from "common/net";
import {CursorPageableResponse, mapCursorPageable, mapPageable, Pageable, PageableResponse} from "common/types";
import {FindScheduledTaskFilterParam} from "tasks/types";
import {
    Company,
    CompanyResponse,
    CreateCompanyGroupParam,
    CreateCompanyParam,
    FindAllListedCompanyParam,
    ICompanyChild,
    ICompanyEntity,
    IListedCompanyEntity,
    ListedCompany,
    mapToCompany,
    UpdateCompanyParams
} from "../types";

export class CompanyRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    update(id: number, param: CreateCompanyParam): Promise<void> {
        return this.client.postV0({
            type: "update_company",
            id,
            ...param,
        });
    }

    async updateV2(id: number, params: UpdateCompanyParams): Promise<Company> {
        const response = await this.client.put<CompanyResponse>(`web/v1/companies/${id}`, {
            body: {
                name: params.name,
                legal_entity: params.legalEntityName,
                idn: params.idn,
                kpp: params.kpp,
                bank_name: params.bankName,
                ito: params.ito,
            },
        });
        return mapToCompany(response);
    }

    updateGroup(id: number, param: CreateCompanyGroupParam): Promise<void> {
        return this.client.postV0({
            type: "update_group_company",
            id,
            ...param,
        });
    }

    async add(param: CreateCompanyParam) {
        return await this.client.postV0<number>({
            type: "add_company",
            ...param,
        });
    }

    async addGroup(param: CreateCompanyGroupParam) {
        return await this.client.postV0<number>({
            type: "add_group_company",
            ...param,
        });
    }

    async all(allowGroup?: boolean, signal?: AbortSignal): Promise<IListedCompanyEntity[]> {
        allowGroup ||= false;
        return this.client.get<IListedCompanyEntity[]>("web/v1/companies/", {
            signal,
            params: {
                allow_group: allowGroup,
            },
        });
    }

    async allWithGroups(signal?: AbortSignal): Promise<IListedCompanyEntity[]> {
        const companies = await this.all(true, signal);
        for (const company of companies) {
            if (!company.group_id || !company.group_name) {
                continue;
            }

            const isAlreadyAdded = companies.some(item => item.id === company.group_id);
            if (isAlreadyAdded) {
                continue;
            }

            companies.push({
                id: company.group_id,
                name: company.group_name,
                idn: "",
                is_group: true,
            });
        }

        return companies;
    }

    async findById(id: number): Promise<Company> {
        const response = await this.client.get<CompanyResponse>(`web/v1/companies/${id}`);
        return mapToCompany(response);
    }

    find(id: number) {
        return this.client.postV0<ICompanyEntity>({type: "info_company", id});
    }

    async findChild(groupId: number, includeGroup?: boolean, signal?: AbortSignal): Promise<ICompanyChild[]> {
        const allCompanies = await this.all(true, signal);
        let companies: ICompanyChild[] = allCompanies.filter(company => company.group_id === groupId);
        if (includeGroup) {
            const group = await this.find(groupId);
            companies.push({
                id: group.id,
                idn: group.idn,
                name: group.name,
            });
        }

        return companies;
    }

    async findAllListed(param: FindAllListedCompanyParam): Promise<Pageable<ListedCompany>> {
        const response = await this.client.get<PageableResponse<ListedCompany>>(
            "v1/tasks/filters/companies", {
                params: {
                    page: param.page,
                    limit: param.limit || 25,
                    search: param.search,
                    company_id: param.companyId || undefined,
                },
                signal: param.signal,
            });
        return mapPageable(response, item => item);
    }

    async findAllForScheduledTaskFilter(param: FindScheduledTaskFilterParam) {
        const response = await this.client.get<CursorPageableResponse<ListedCompany>>(
            "web/v1/scheduled-tasks/filters", {
                params: {
                    type_filter: "companies",
                    cursor: param.cursor || undefined,
                    limit: param.limit,
                    search: param.search.trim() || undefined,
                    company_id: param.companyId || undefined,
                },
            });
        return mapCursorPageable(response, item => item);
    }

    findPossible(userId: number): Promise<ICompanyChild[]> {
        return this.client.get<ICompanyChild[]>(`web/v1/users/${userId}/possible-companies`);
    }

    async findDocumentFilters(param: FindAllListedCompanyParam): Promise<Pageable<ICompanyChild>> {
        const response = await this.client.get<PageableResponse<ICompanyChild>>(
            "web/v1/tasks/documents/filters/companies", {
                params: {
                    page: param.page,
                    limit: param.limit || 25,
                    search: param.search || undefined,
                    company_id: param.companyId || undefined,
                },
            }
        );
        return mapPageable(response, item => item);
    }

    findTaskReplace(taskId: number): Promise<IListedCompanyEntity[]> {
        return this.client.get(`v1/tasks/${taskId}/companies/replacements`);
    }
}
