export interface GrantedHouse {
    id: number;

    address: string;

    tags: number[];

    accessesToSystems: {
        systemId: number,
        hasAccess: boolean,
    }[];
}

export interface GrantedHouseResponse {
    id: number;

    address: string;

    tags: number[];

    accesses_to_systems: { system_id: number, has_access: boolean }[];
}

export function mapToGrantedHouse(entity: GrantedHouseResponse): GrantedHouse {
    return {
        id: entity.id,
        address: entity.address,
        accessesToSystems: entity.accesses_to_systems.map(access => ({
            systemId: access.system_id,
            hasAccess: access.has_access,
        })),
        tags: entity.tags,
    }
}
