import {useChangeCitizenTypeStore} from "citizens/modals/change_citizen_type/ChangeCitizenTypeStore";
import {getCitizenTypeName} from "citizens/types";
import {
    NotificationModal,
    NotificationModalHighlight,
    NotificationModalText
} from "common/components/modal/notification";
import React from "react";

export const AgreeModal = () => {
    const store = useChangeCitizenTypeStore();
    const type = getCitizenTypeName(store.currentType);
    return (
        <NotificationModal
            title="Подтвердите действие"
            positive="Подтвердить"
            negative="Закрыть"
            isLoading={store.isLoading}
            onNegative={store.close}
            onPositive={store.next}>
            <NotificationModalText>
                Вы уверены, что хотите изменить
                статус
                с <NotificationModalHighlight>{type}</NotificationModalHighlight> на <NotificationModalHighlight>{getCitizenTypeName(store.newType)}</NotificationModalHighlight>?
            </NotificationModalText>
        </NotificationModal>
    );
};