import {makeAutoObservable} from "mobx";
import {TaskEventRepository} from "../../repositories";
import {CommentAttachmentParams} from "../../types";
import {BasePendingCommentStore} from "./BasePendingCommentStore";
import {AttachedFileUiState} from "./state";

export class PendingCommentStore {
    private taskId: number | null;
    private sendListener: (() => void) | null;

    constructor(
        private readonly eventRepo: TaskEventRepository,
        private readonly baseComment: BasePendingCommentStore,
    ) {
        this.taskId = null;
        this.sendListener = null;
        makeAutoObservable(this, {}, {autoBind: true});
        this.baseComment.setSendListener(this._send);
    }

    get typeList() {
        return this.baseComment.typeList;
    }

    get message() {
        return this.baseComment.message;
    }

    get isSending() {
        return this.baseComment.isSending;
    }

    get canSend() {
        return this.baseComment.canSend;
    }

    get errorMessage() {
        return this.baseComment.errorMessage;
    }

    get attachments() {
        return this.baseComment.attachments;
    }

    setMinimalMessageLength(value: number) {
        this.baseComment.setMinimalMessageLength(value);
    }

    setTaskId(taskId: number | null) {
        this.taskId = taskId;
    }

    setMessage(value: string) {
        this.baseComment.setMessage(value);
    }

    dismissError() {
        this.baseComment.dismissError();
    }

    setSendListener(callback: (() => void) | null) {
        this.sendListener = callback;
    }

    async attachFiles(type: string, typeName: string, files: File[]) {
        await this.baseComment.attachFiles(type, typeName, files);
    }

    removeDocument(attachment: AttachedFileUiState) {
        this.baseComment.removeDocument(attachment);
    }

    send() {
        this.baseComment.send();
    }

    private async _send(message: string, attachments: CommentAttachmentParams[]) {
        const taskId = this.taskId;
        if (taskId === null) throw new Error("Illegal state: taskId is null");

        await this.eventRepo.createCommentByTaskId(taskId, {
            message: message,
            attachments: attachments,
        });
        this.sendListener?.();
    }
}
