import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {FindReportSliceParams, mapReportSliceParamsToBody} from "reports/types";
import {mapToTaskStatus, TaskStatus, TaskStatusResponse} from "../types";

export class TaskStatusRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(signal?: AbortSignal): Promise<TaskStatus[]> {
        const response = await this.client.get<TaskStatusResponse[]>("v1/tasks/statuses/", {signal});
        return response.map(mapToTaskStatus);
    }

    /**
     * Возвращает список возможных статусов, исходя из уже выбранных срезов для отчета.
     */
    async findAllBySlices(params: FindReportSliceParams): Promise<Pageable<TaskStatus>> {
        const response = await this.client.post<PageableResponse<TaskStatusResponse>>("web/v1/reports/statuses", {
            body: mapReportSliceParamsToBody(params),
            signal: params.signal,
        });
        return mapPageable(response, mapToTaskStatus);
    }

    /**
     * Возвращает список возможных переходов для заявки с идентификатором {@link taskId}.
     */
    async findTransitionsByTaskId(taskId: number): Promise<TaskStatus[]> {
        const response = await this.client.get<TaskStatusResponse[]>(`v2/tasks/${taskId}/transitions`);
        return response.map(mapToTaskStatus);
    }

    /**
     * Применяет переход статуса к заявке с идентификатором {@link taskId}.
     */
    async applyTransition(transitionId: number, taskId: number): Promise<void> {
        return await this.client.put(`v2/tasks/${taskId}/transitions`, {
            body: {id: transitionId},
        });
    }
}
