import {AuthorizedHttpClient} from "common/net";
import {CursorPageableResponse, mapCursorPageable} from "common/types";
import {FindScheduledTaskFilterParam, HouseEmployee, HouseEmployeeResponse, mapToHouseEmployee} from "../types";

export class HouseEmployeeRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(companyId: number, houseId: number, systemId: number): Promise<HouseEmployee[]> {
        const response = await this.client.get<HouseEmployeeResponse[]>(
            `web/v1/houses/${houseId}/systems/${systemId}/employees`, {
                params: {
                    company_id: companyId
                },
            });
        return response.map(mapToHouseEmployee);
    }

    async findAllForScheduledTaskFilter(param: FindScheduledTaskFilterParam) {
        const response = await this.client.get<CursorPageableResponse<HouseEmployeeResponse>>(
            "web/v1/scheduled-tasks/filters", {
                params: {
                    type_filter: "executor",
                    cursor: param.cursor || undefined,
                    limit: param.limit,
                    search: param.search.trim() || undefined,
                    company_id: param.companyId || undefined,
                },
            });
        return mapCursorPageable(response, mapToHouseEmployee);
    }
}
