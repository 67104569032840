import classNames from "classnames";
import {DetailedHTMLProps, ThHTMLAttributes} from "react";
import s from "./Table.module.css";

type Props = {
    className?: string
} & DetailedHTMLProps<ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;


export const TableHeaderCell = (props: Props) => {
    const {className, ...defaultProps} = props;
    return <th className={classNames(s.headerCell, className)} {...defaultProps}/>
};