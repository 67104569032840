// noinspection JSUnusedGlobalSymbols
export enum DefinedUserRole {
    ADMIN_ETD = 1,
    ADMIN,
    USER,
    DISPATCHER,
    ENGINEER,
}

export interface UserRole {
    id: number;
    name: string,
}
