import {AuthorizedHttpClient} from "common/net";
import {UpdateCompanyPassParams} from "../types";

export class CompanyPassRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async update(companyId: number, params: UpdateCompanyPassParams): Promise<void> {
        return this.client.put(`web/v1/companies/${companyId}/pass`, {
            body: {
                stamp_url: params.stampUrl || null,
                signature_url: params.signatureUrl || null,
                logo_url: params.logoUrl || null,
            }
        });
    }
}
