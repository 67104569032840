import createSvgIcon from "./utils";

const IconCheck16 = createSvgIcon((
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3656 4.23435C13.5155 4.38437 13.5998 4.58782 13.5998 4.79995C13.5998 5.01208 13.5155 5.21553 13.3656 5.36555L6.96556 11.7656C6.81554 11.9155 6.61209 11.9998 6.39996 11.9998C6.18783 11.9998 5.98438 11.9155 5.83436 11.7656L2.63436 8.56555C2.48863 8.41467 2.408 8.21259 2.40982 8.00283C2.41164 7.79307 2.49578 7.59242 2.6441 7.4441C2.79243 7.29577 2.99308 7.21163 3.20284 7.20981C3.4126 7.20799 3.61468 7.28863 3.76556 7.43435L6.39996 10.0688L12.2344 4.23435C12.3844 4.08437 12.5878 4.00012 12.8 4.00012C13.0121 4.00012 13.2155 4.08437 13.3656 4.23435Z"
        stroke="transparent"/>
), {width: 16, height: 16});

export default IconCheck16;
