export interface ConnectedIntercom {
    id: string;
    name: string;
    description: string;
}

export interface FlatRange {
    from: number;
    to: number;
}

export interface ConnectedHouse {
    houseId: string;
    address: string;
    flatRanges: FlatRange[];
}

export interface FlatRangeResponse {
    first_flat_number: number;
    last_flat_number: number;
}

export interface ConnectedHouseResponse {
    house_id: string;
    address: string;
    flat_ranges: FlatRangeResponse[];
}

export interface UpdateConnectedHouse {
    houseId: string;
    flatRanges: FlatRange[];
}

export interface HouseGroup {
    id: string;
    name: string;
    intercomCount: number;
    houseCount: number;
    flatsCount: number;
    intercoms: ConnectedIntercom[];
    houses: ConnectedHouse[];
}

export interface HouseGroupResponse {
    group_id: string;
    name: string;
    intercom_count: number;
    house_count: number;
    flats_count: number;
    intercoms: ConnectedIntercom[];
    houses: ConnectedHouseResponse[];

}

export interface UpsertHouseGroup {
    name: string;
    intercoms: string[];
    houses: UpdateConnectedHouse[];
    signal: AbortSignal;
}

export function mapToHouseGroup(model: HouseGroupResponse): HouseGroup {
    return {
        id: model.group_id,
        name: model.name,
        houseCount: model.house_count,
        flatsCount: model.flats_count,
        intercomCount: model.intercom_count,
        intercoms: model.intercoms || [],
        houses: model.houses ? model.houses.map(house => ({
            houseId: house.house_id,
            address: house.address,
            flatRanges: house.flat_ranges.map(range => ({
                from: range.first_flat_number,
                to: range.last_flat_number,
            })),
        })) : [],
    };
}

export interface FindAllHouseGroups {
    signal?: AbortSignal;
    search: string;
    page: number;
    limit: number;
    houses?: string[];
}