import {observer} from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";

type Props = {
    className?: string;
    width?: number | string;
    height?: number | string;
};

export const ButtonSkeleton = observer((props: Props) => {
    const {className, width, height = 36} = props;
    return <Skeleton containerClassName={className} width={width} height={height} borderRadius={10}/>;
});
