import {adjustTimezoneMillis} from "common/utils";
import {ListedUser, ListedUserResponse, mapToListedUser} from "users/types";

export interface ReportSlice {
    type: "address" | "system" | "type" | "status";
    mode?: "include" | "exclude";
    ids: number[];
}

export type ReportInterval = "day" | "week" | "month" | "year";
export type ReportSortOrder = "fio_a_z" | "fio_z_a";

export interface FindReportSliceParams {
    search: string;
    page: number;
    limit?: number;
    companyId: number | null;
    periodFrom: Date;
    periodTo: Date;
    executors: {
        ids: number[];
        mode: "include" | "exclude";
    };
    columns: ReportSlice[];
    rows: ReportSlice[];
    signal?: AbortSignal;
}

export function mapReportSliceParamsToBody(params: FindReportSliceParams) {
    return {
        search: params.search,
        page: params.page,
        limit: params.limit || 10,
        company_id: params.companyId || undefined,
        columns: params.columns,
        rows: params.rows,
        from_date: adjustTimezoneMillis(params.periodFrom) / 1000,
        to_date: adjustTimezoneMillis(params.periodTo) / 1000,
        executors: params.executors,
    };
}

export interface FindReportParams {
    page: number;
    limit: number;
    fromDate?: Date;
    toDate?: Date;
    interval?: ReportInterval;
    executors?: number[];
    excludeExecutors?: number[];
    sortOrder?: ReportSortOrder;
    sumType: "total" | "sections";
    columns?: ReportSlice[];
    rows?: ReportSlice[];
    companyId?: number;
}

export function mapFindReportParamsToBody(params: FindReportParams) {
    const fromWithOffset = params.fromDate ? adjustTimezoneMillis(params.fromDate) / 1000 : undefined;
    const toWithOffset = params.toDate ? adjustTimezoneMillis(params.toDate) / 1000 : undefined;
    return {
        page: params.page,
        limit: params.limit,
        from_date: fromWithOffset,
        to_date: toWithOffset,
        interval: params.interval,
        executors: params.executors,
        exclude_executors: params.excludeExecutors,
        sort_order: params.sortOrder,
        sum_type: params.sumType,
        columns: params.columns,
        rows: params.rows,
        company_id: params.companyId,
    };
}

export interface Report {
    maxPage: number;
    total: number;
    executors: ListedUser[];
    rows: {
        name: string;
        level: number;
    }[];
    columns: {
        name: string;
        level: number;
    }[];
    columnSum: number[];
    days: {
        date: string;
        rows: {
            sum: number;
            columns: number[];
            index: number;
        }[];
    }[]
}

export interface ReportResponse {
    max_page: number;
    total: number;
    executors: ListedUserResponse[];
    rows: {
        name: string;
        level: number;
    }[];
    columns: {
        name: string;
        level: number;
    }[];
    columns_sum: number[];
    days: {
        date: string;
        rows: {
            sum: number;
            columns: [];
            index: number;
        }[];
    }[]
}

export function mapToReport(response: ReportResponse): Report {
    return {
        maxPage: response.max_page,
        total: response.total,
        executors: response.executors.map(mapToListedUser),
        rows: response.rows,
        columns: response.columns,
        columnSum: response.columns_sum,
        days: response.days,
    };
}