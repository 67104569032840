import {FocusEvent, ForwardedRef, forwardRef, HTMLProps, MouseEventHandler, ReactNode, useRef, useState} from "react";
import {mergeRefs} from "react-merge-refs";
import {InputBaseContainer} from "./InputBaseContainer";
import {InputBaseRealInput} from "./InputBaseRealInput";

export type InputBaseProps = {
    containerClassName?: string;
    heading?: ReactNode;
    trailing?: ReactNode;
    //В DateInput необходимо, чтобы onClick вызывался при клике на контейнер
    onClick?: MouseEventHandler<HTMLElement>;
} & Omit<HTMLProps<HTMLInputElement>, "onClick">;

export const InputBase = forwardRef((props: InputBaseProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        containerClassName,
        trailing,
        heading,
        disabled,
        onFocus,
        onBlur,
        onClick,
        value,
        ...defaultProps
    } = props;
    const [isFocus, setFocus] = useState(false);
    const realInputRef = useRef<HTMLInputElement>(null);

    const handleFocusRequest: MouseEventHandler<HTMLDivElement> = e => {
        realInputRef.current?.focus()
        onClick?.(e)
    };
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setFocus(true);
        onFocus?.(e);
    };
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        onBlur?.(e);
    };

    return (
        <InputBaseContainer
            className={containerClassName}
            hasHeading={!!heading}
            hasTrailing={!!trailing}
            disabled={disabled}
            isFocus={isFocus}
            onClick={handleFocusRequest}>
            {heading}
            <InputBaseRealInput
                ref={mergeRefs([realInputRef, ref]) as any}
                value={value}
                disabled={disabled}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...defaultProps}/>
            {trailing}
        </InputBaseContainer>
    );
});
