import {AuthorizedHttpClient} from "common/net";
import {CitizenPageableResponse, mapCitizenPageable, Pageable} from "common/types";
import {
    CitizenHouse,
    CitizenHouseResponse,
    CreateCitizenHouseParams,
    mapToCitizenHouse,
    UpdateCitizenHouseParams
} from "../types";

export class CitizenHouseRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async create(params: CreateCitizenHouseParams): Promise<void> {
        await this.client.post(
            "houses", {
                body: {
                    address_standard: params.address,
                    admin_company_id: params.adminCompanyId,
                    delegate_company_id: params.delegateCompanyId,
                    has_intercoms: params.hasIntercoms,
                    has_video: params.hasVideo,
                    flat_ranges: params.flatRanges,
                    groups: params.groups.map(group => ({
                        group_id: group.id,
                        flat_ranges: group.flatRanges.map(range => ({
                            first_flat_number: range.from,
                            last_flat_number: range.to,
                        }))
                    }))
                },
            },
        );
    }

    async update(id: string, params: UpdateCitizenHouseParams): Promise<void> {
        await this.client.patch(
            `houses/${id}`, {
                body: {
                    description: params.description,
                    admin_company_id: params.adminCompanyId,
                    delegate_company_id: params.delegationCompanyId,
                    has_video: params.hasVideo,
                    has_intercoms: params.hasIntercoms,
                    flat_ranges: params.flatRanges,
                    groups: params.groups ? params.groups.map(group => ({
                        group_id: group.id,
                        flat_ranges: group.flatRanges.map(range => ({
                            first_flat_number: range.from,
                            last_flat_number: range.to,
                        }))
                    })) : undefined,
                },
            },
        );
    }

    async updateTariff(id: string, tariffId: string): Promise<void> {
        await this.client.put(
            `tariff/house/${id}/`, {
                body: {
                    tariff_id: tariffId,
                },
            },
        );
    }

    async findAllLike(search: string, signal?: AbortSignal): Promise<Pageable<CitizenHouse>> {
        const response = await this.client.get<CitizenPageableResponse<CitizenHouseResponse>>(
            "houses", {
                signal,
                params: {
                    address_standard__ilike: search,
                },
            },
        );

        return mapCitizenPageable(response, mapToCitizenHouse);
    }

    async findAll(page: number, search: string, limit: number, signal?: AbortSignal): Promise<Pageable<CitizenHouse>> {
        const response = await this.client.get<CitizenPageableResponse<CitizenHouseResponse>>(
            "houses", {
                params: {
                    page: page + 1,
                    size: limit,
                    address_standard__ilike: search,
                },
                signal,
            },
        );

        return mapCitizenPageable(response, mapToCitizenHouse);
    }

    async findById(id: string, signal?: AbortSignal): Promise<CitizenHouse> {
        const response = await this.client.get<CitizenHouseResponse>(`houses/${id}`, {signal});
        return mapToCitizenHouse(response);
    }

    deleteById(id: string): Promise<void> {
        return this.client.delete(`houses/${id}`);
    }
}
