import {Action} from "@remix-run/router";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {etdRouter} from "../components/app/routes";

const HistoryStackContext = React.createContext<{ paths: string[] }>({paths: []});

export const HistoryStackProvider = ({children}: { children: ReactNode }) => {
    const [stack, setStack] = useState<string[]>([]);
    useEffect(() => {
        return etdRouter.subscribe((state) => {
            const location = state.location;
            if (state.historyAction === Action.Push) {
                setStack(stack => [...stack, location.pathname]);
            } else if (state.historyAction === Action.Pop) {
                setStack(stack => {
                    if (stack[stack.length - 2] === location.pathname) {
                        return stack.slice(0, stack.length - 1);
                    }

                    return stack;
                });
            } else if (state.historyAction === Action.Replace) {
                setStack(stack => [...stack.slice(0, stack.length - 1), location.pathname]);
            }
        });
    }, []);

    return (
        <HistoryStackContext.Provider value={{paths: stack}}>
            {children}
        </HistoryStackContext.Provider>
    );
};

export function useHistoryStack() {
    return useContext(HistoryStackContext);
}
