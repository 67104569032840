import {RefCallback, useCallback, useLayoutEffect, useState} from "react";

function useDimensions(): [RefCallback<HTMLElement | null>, DOMRect | undefined] {
    const [node, setNode] = useState<HTMLElement | null>(null);
    const [dimensions, setDimensions] = useState<DOMRect | undefined>();
    const ref = useCallback((node: HTMLElement | null) => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () => {
                const rect = node.getBoundingClientRect();
                if (rect.width === 0 && rect.height === 0) {
                    return;
                }

                setDimensions(rect)
            };
            measure();

            const observer = new ResizeObserver(measure);
            observer.observe(node);

            return () => observer.disconnect();
        }
    }, [node]);

    return [ref, dimensions];
}

export default useDimensions;