import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {
    FindAllGrantedHouseParams,
    GrantedHouse,
    GrantedHouseResponse,
    mapToGrantedHouse,
    PatchGrantedHouseParams
} from "../types";

export class GrantedHouseRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(userId: number, params: FindAllGrantedHouseParams): Promise<Pageable<GrantedHouse>> {
        const response = await this.client.get<PageableResponse<GrantedHouseResponse>>(
            `/v1/users/${userId}/possible-houses`, {
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search,
                    systems: params.systems?.join(","),
                    tags: params.tags?.join(","),
                }
            });

        return mapPageable(response, mapToGrantedHouse);
    }

    patch(userId: number, params: PatchGrantedHouseParams): Promise<void> {
        return this.client.patch(`v1/users/${userId}/houses`, {
            body: params,
        });
    }
}
