import createSvgIcon from "./utils";

const IconArrowRight16x16 = createSvgIcon((
    <path
        stroke="transparent" fillRule="evenodd"
        d="M5.834 11.765a.8.8 0 0 1 0-1.13L8.47 8 5.834 5.366a.8.8 0 0 1 1.132-1.132l3.2 3.2a.8.8 0 0 1 0 1.132l-3.2 3.2a.8.8 0 0 1-1.132 0Z"
        clipRule="evenodd"/>
), {width: 16, height: 16});

export default IconArrowRight16x16;
