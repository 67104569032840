import {ReactNode} from "react";
import s from "./NotificationModal.module.css";

type Props = {
    children: ReactNode;
};

export const NotificationModalHighlight = (props: Props) => {
    const {children} = props;
    return <span className={s.highlight}>{children}</span>
};
