export const DELEGATION_STATUS_ID = 9;

/**
 * Определяет статус заявки - состояние выполнения заявки в момент времени.
 */
export interface TaskStatus {
    /**
     * Уникальный идентификатор статуса заявки.
     */
    id: number;

    /**
     * Название статуса заявки.
     */
    localizedName: string;

    /**
     * Цвет статуса заявки для темной темы в формате HEX (с начальным #).
     */
    darkColor: string;
}

export interface TaskStatusResponse {
    id: number;
    name: string;
    black_theme_color: string;
}

export function mapToTaskStatus(entity: TaskStatusResponse): TaskStatus {
    return {
        id: entity.id,
        localizedName: entity.name,
        darkColor: entity.black_theme_color,
    };
}
