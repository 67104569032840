import {ErrorModal} from "common/components/modal/error";
import {observer} from "mobx-react-lite";
import {CommentModal} from "../../modals/comment";
import {ApplyTransitionsStore} from "./ApplyTransitionsStore";
import {CompletionErrorModal} from "./CompletionErrorModal";

type Props = {
    store: ApplyTransitionsStore;
}

export const ApplyTransitionsHost = observer((props: Props) => {
    const {store} = props;
    return <>
        <ErrorModal error={store.applyError} dismiss={store.dismissError}/>
        <CommentModal store={store.commentModal}/>
        {store.isShowChildCompletionError && store.pendingStatus && (
            <CompletionErrorModal
                status={store.pendingStatus.localizedName}
                onDismiss={store.dismissError}/>
        )}
    </>
});
