import {action, FlowCancellationError, makeAutoObservable} from "mobx";
import {RefreshTokenError} from "../../net";
import {CachePolicy, ICachePolicy} from "./CachePolicy";
import {JobState} from "./JobState";

interface AsyncJobOptions<F extends MobXFlowUndecorated> {
    job: F,
    policy?: ICachePolicy,
}

export class AsyncJob<F extends MobXFlowUndecorated> {
    //Config
    private readonly _job: MobXFlow;
    private readonly policy: ICachePolicy;

    //State
    private _pendingPromise: CancellablePromise | null;
    private lastCacheTime: number;
    private abortController: AbortController;

    constructor(private readonly options: AsyncJobOptions<F>) {
        this._state = JobState.CREATED;
        this._job = options.job as unknown as MobXFlow;
        this._pendingPromise = null;
        this._error = null;
        this.lastCacheTime = -1;
        this.policy = options.policy || CachePolicy.NEVER;
        this.abortController = new AbortController();
        makeAutoObservable(this, {}, {autoBind: true});
    }

    private _error: Error | null;

    get error() {
        return this._error;
    }

    private _state: JobState;

    get state() {
        return this._state;
    }

    get errorMessage() {
        return this._error?.message;
    }

    get isPending() {
        return this._state === JobState.PENDING;
    }

    get isCreated() {
        return this._state === JobState.CREATED;
    }

    get isRejected() {
        return this._state === JobState.REJECTED;
    }

    clearError() {
        this._error = null;
    }

    resetTimeout() {
        this.lastCacheTime = 0;
    }

    startQuietly(...params: DropFirst<Parameters<F>>) {
        this._start(true, false, params);
    }

    start(...params: DropFirst<Parameters<F>>) {
        this._start(false, false, params);
    }

    startForce(...params: DropFirst<Parameters<F>>) {
        this._start(false, true, params);
    }

    stop() {
        this.cancelPendingPromise();
        this._state = JobState.FULFILLED;
    }

    private _start(isQuietly: boolean, force: boolean, params: DropFirst<Parameters<F>>) {
        if (!this.isCreated && !this.isRejected && (!this.policy.isExpire(this.lastCacheTime) || force)) {
            return;
        }

        if (isQuietly) {
            if (this._state === JobState.CREATED || this._state === JobState.REJECTED) {
                this._state = JobState.PENDING;
            }
        } else {
            this._state = JobState.PENDING;
        }

        this.lastCacheTime = Date.now();
        this.clearError();
        this.cancelPendingPromise();
        // @ts-ignore
        this._pendingPromise = this._job(this.abortController.signal, ...params);
        this._pendingPromise
            .then(this.onPromiseThen)
            .catch(action(async e => {
                console.error(e);
                this._pendingPromise = null;

                if (e.name === "AbortError") {
                    return;
                }

                if (e.message === "Generator is already running") {
                    return;
                }

                if (e instanceof RefreshTokenError) {
                    return;
                }

                if (e instanceof FlowCancellationError) {
                    return;
                }

                this._state = JobState.REJECTED;
                this._error = e;
            }));
    }

    private onPromiseThen() {
        this._state = JobState.FULFILLED;
        this._pendingPromise = null;
    }

    private cancelPendingPromise() {
        if (this._pendingPromise) {
            this.abortController.abort();
            this.abortController = new AbortController();
            this._pendingPromise.cancel();
            this._pendingPromise = null;
        }
    }
}
