import classNames from "classnames";
import {isDarkHex} from "common/utils";
import IconClose8 from "../../../components/icons/IconClose8";
import {P} from "../../typography";
import s from "./InputBox.module.css";

export type InputBoxBulletProps = {
    label: string;
    color?: string;
    shouldOverflow?: boolean;
    onDeselect: () => void;
} & Omit<JSX.IntrinsicElements['div'], "onClick" | "onMouseDown">;

export const InputBoxBulletBase = (props: InputBoxBulletProps) => {
    const {className, label, color, shouldOverflow, onDeselect, ...defaultProps} = props;
    return (
        <div
            className={classNames(
                s.bullet,
                className
            )}
            style={{
                backgroundColor: color || "var(--dark-primary-main)",
                color: color && isDarkHex(color) ? "var(--dark-white)" : "var(--dark-gray-p500)"
            }}
            onClick={onDeselect}
            onMouseDown={e => e.stopPropagation()}
            {...defaultProps}>
            <P
                className={shouldOverflow ? s.bulletLabel : undefined}
                variant="Button/Small"
                color="inherit">
                {label}
            </P>
            <IconClose8 className={s.bulletClose} fill="currentColor"/>
        </div>
    )
};
