import React from 'react';
import NotFoundImage from "../../../common/assets/404.png"
import {Route} from "../route";
import s from "./index.module.css";

function NotFoundRoute() {
    return (
        <Route title="Страница не найдена">
            <div className={s.notFound}>
                <img className={s.notFoundImage} src={NotFoundImage} alt="route not found"/>
            </div>
        </Route>
    )
}

export default NotFoundRoute;
