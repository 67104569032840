import {AuthorizedHttpClient} from "common/net";
import {CreateTaskProgramParams, TaskProgram, UpdateTaskProgramParams} from "../types";

export class TaskProgramRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    createAll(companyId: number, params: CreateTaskProgramParams[]): Promise<number[]> {
        return this.client.post<number[]>(`v1/companies/${companyId}/program`, {
            body: params,
        });
    }

    findAll(companyId: number, signal?: AbortSignal): Promise<TaskProgram[]> {
        return this.client.get<TaskProgram[]>(`v1/companies/${companyId}/program`, {signal})
    }

    updateAll(params: UpdateTaskProgramParams[]): Promise<void> {
        return this.client.patch(`v1/companies/program`, {
            body: params,
        });
    }

    deleteAll(programIds: number[]): Promise<void> {
        return this.client.delete("v1/companies/program", {body: programIds});
    }
}
