import {AuthorizedHttpClient} from "common/net";
import {TaskFilter} from "../types";
import {SavedTaskFilter, UpdateSavedTaskFilterParams} from "../types/SavedTaskFilter";

export class SavedTaskFilterRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async save(companyId: number, name: string, filters: TaskFilter[]): Promise<SavedTaskFilter> {
        return await this.client.post(`web/v1/tasks/filters`, {
            params: {
                company_id: companyId
            },
            body: {
                name,
                filters,
            }
        });
    }

    async findAll(companyId: number): Promise<SavedTaskFilter[]> {
        return await this.client.get(`web/v1/tasks/filters`, {
            params: {
                company_id: companyId
            },
        });
    }

    updateAll(params: UpdateSavedTaskFilterParams[]): Promise<void> {
        return this.client.put("web/v1/tasks/filters", {
            body: params.map(item => ({
                id: item.id,
                name: item.name,
                filters: item.filters,
            }))
        });
    }

    deleteAll(ids: number[]): Promise<void> {
        return this.client.delete("web/v1/tasks/filters", {
            body: ids,
        });
    }
}
