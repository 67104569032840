import {AuthorizedHttpClient} from "common/net";
import {AnyTaskEvent, CreateCommentParams, mapToTaskEvent, TaskEventResponse} from "../types";

export class TaskEventRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAllByTaskId(abortSignal: AbortSignal, taskId: number): Promise<AnyTaskEvent[]> {
        const response = await this.client.get<TaskEventResponse[]>(`web/v1/tasks/${taskId}/events`, {
            signal: abortSignal,
        });
        const events: AnyTaskEvent[] = [];
        for (const event of response) {
            const model = mapToTaskEvent(event);
            if (model) {
                events.push(model);
            }
        }

        return events;
    }

    createCommentByTaskId(taskId: number, params: CreateCommentParams): Promise<void> {
        return this.client.post(`v1/tasks/${taskId}/comments/`, {
            body: {
                message: params.message,
                files: params.attachments.map(item => ({
                    type: item.type,
                    uri: item.uri,
                    size_bytes: item.sizeBytes,
                    name: item.name,
                })),
            }
        });
    }

    transferComment(commentId: number): Promise<void> {
        return this.client.post(`web/v1/tasks/events/${commentId}/transfer`);
    }

    transferCommentToCitizen(commentId: number): Promise<void> {
        return this.client.post(`web/v1/tasks/events/${commentId}/transfer-citizen`);
    }
}
